
import { CircularProgress, Box } from '@mui/material';

const LoadingOverlay = ({ isLoading, children }) => {
  // Sizes of the spinner with a base size and min/max constraints
  const spinnerSize = '10vw'; // 10% of the viewport width
  const minSpinnerSize = 40; // Minimum spinner size in pixels
  const maxSpinnerSize = 100; // Maximum spinner size in pixels

  return (
    <Box sx={{ position: 'relative', width: '100%', height: '100%', overflow: 'hidden' }}>
      {children}
      {isLoading && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1300,
          }}
        >
          <CircularProgress 
            size={`min(max(${spinnerSize}, ${minSpinnerSize}px), ${maxSpinnerSize}px)`} 
          />
        </Box>
      )}
    </Box>
  );
};

export default LoadingOverlay;
