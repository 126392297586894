
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  collection: []
};

export const playerCollectionSlice = createSlice({
  name: 'playerCollection',
  initialState,
  reducers: {
    setCollection: (state, action) => {
      // console.log("setCollection", action)
      state.collection = action.payload;
    },
    resetCollection: (state) => {
      state.collection = {};
    },
  }
});

export const { setCollection, resetCollection } = playerCollectionSlice.actions;
export default playerCollectionSlice.reducer;
