
import Grid from '@mui/material/Grid2'; // Grid version 2
import { useState } from "react";
import {cdnUrl } from "../../constants.js";
import { cardFooterBStyle } from "../New/Styles.jsx";
import { hoverButtonStyle } from "../New/Styles.jsx";


export const overlayStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Black overlay with 50% opacity
  zIndex: 1, // Ensures it sits above the image
};


function RightGrid() {

  const [isHovered, setIsHovered] = useState(false)

  const cardImageBStyle = { 
    width: '100%', 
    height: 'auto', 
    display: 'block', 
    margin: 0
  }

  const onMouseEnterHandler = (e) => {
    e.currentTarget.style.backgroundImage = `url(${cdnUrl}/battle-menu/button-onhover.png)`
    // setIsHovered(true)
  }

  const onMouseExitHandler = (e) => {
    e.currentTarget.style.backgroundImage = `url(${cdnUrl}/battle-menu/button.png)`
    // setIsHovered(false)
  }

  return (
    <Grid size={{ xs: 12 }}>
      <div 
        onMouseEnter={() => setIsHovered(true)} 
        onMouseLeave={() => setIsHovered(false)} 
        style={{
          position: 'relative',
          borderTop: '1px solid #937341',   // Top border
          borderLeft: '1px solid #937341',  // Left border
          borderRight: '1px solid #937341', // Right border
          borderBottom: 'none',              // No bottom border
          borderRadius: '9px 9px 0 0',      // Rounded corners (top-left and top-right)
          overflow: 'hidden'                 // Ensures the image doesn't overflow
        }}
      >
        <img 
          src={`${cdnUrl}/app/shop/shop2.webp`} 
          style={cardImageBStyle} 
        />
        {isHovered && 
        <>
          <div style={overlayStyle}></div> 
          <button
            style={{
              ...hoverButtonStyle
            }}
          >Coming soon</button>
          </>
        }
      </div>
      <div style={cardFooterBStyle}>
        <button 
          style={{
            backgroundImage: `url(${cdnUrl}/battle-menu/button.png)`, // Default background image
            backgroundSize: '90% auto',    // Make sure the background covers the button
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',    // Ensure the background image is centered
            border: 'none',                  // Removes the default border
            color: '#EAC88C',                // Text color
            padding: '10px 20px',           // Adds padding to the button
            cursor: 'pointer',               // Changes the cursor to a pointer
            fontSize: 'inherit',             // Inherit font size from the parent
            opacity: 0.8,                    // Adjust opacity for transparency
            width: '516px',
            height: '76px',
            backgroundColor: '#101217',      // Background color
            fontFamily: 'Germania One',
            transition: 'background-image 0.3s, opacity 0.3s', // Smooth transition for image and opacity
          }}
          onMouseEnter={(e) => onMouseEnterHandler(e)} 
          onMouseLeave={(e) => onMouseExitHandler(e)} 
        >
          Skins, Avatars & Cosmetics
        </button>
      </div>
    </Grid>
  )
}

export default RightGrid;