
/* eslint-disable */

import { useState } from 'react';
import { Modal, Box, Typography, TextField, Button } from '@mui/material';

const FullScreenUsernameModal = ({ open, onClose, onSubmit }) => {
  const [username, setUsername] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (username.trim().length < 3) {
      setError('Username must be at least 3 characters long');
    } else {
      onSubmit(username);
      handleClose();
    }
  };

  const handleClose = () => {
    setUsername('');
    setError('');
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          bgcolor: 'background.paper',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 3,
          borderRadius: { xs: '0', sm: '8px' }, // Full screen on mobile, rounded on larger screens
        }}
      >
        <Typography
          id="modal-title"
          variant="h5"
          sx={{ mb: 2, color: 'text.primary' }}
        >
          ⚔️ Enter Your Username ⚔️
        </Typography>
        <form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: 400 }}>
          <TextField
            fullWidth
            label="Username"
            variant="outlined"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            error={!!error}
            helperText={error}
            sx={{
              mb: 2,
              input: { color: 'text.primary' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'primary.main', // Border color for default state
                },
                '&:hover fieldset': {
                  borderColor: 'primary.main', // Border color on hover
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'primary.main', // Border color when focused
                },
              },
            }}
            InputLabelProps={{
              style: { color: 'text.primary' },
            }}
          />
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Submit
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default FullScreenUsernameModal;
