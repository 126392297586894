import { GlowFilter } from '@pixi/filter-glow';
import { useEffect, useRef, useState, forwardRef } from "react";
import { gsap, wait } from "../../utils"
import * as PIXI from 'pixi.js';
import { Sprite, Container } from "react-pixi-fiber";
import Card from "./Card";

const cdnUrl = import.meta.env.VITE_CDN_URL;
const flipCardSoundUrl = `${cdnUrl}/audio/flipcard.mp3`;
const flipCardAudio = new Audio(flipCardSoundUrl);

const cymbals = ["1", "2", "4"];
const randomCymbal = cymbals[Math.floor(Math.random() * cymbals.length)];
const cymbalsSoundUrl = `${cdnUrl}/audio/cymbals/cymbals${randomCymbal}.mp3`;
const cymbalsAudio = new Audio(cymbalsSoundUrl);

const glowFilter = new GlowFilter({
    distance: 15,
    outerStrength: 2,
    color: 0xffc107, // Example: golden color
    quality: 0.5,
});

const neutralMatrix = [
    1, 0, 0, 0, 0,   // Red
    0, 1, 0, 0, 0,   // Green
    0, 0, 1, 0, 0,   // Blue
    0, 0, 0, 1, 0,   // Alpha
];

const goldenYellowMatrix = [
    1.7, 0, 0, 0, 0.15,  // Red: Significantly increase the red component for a deeper orange.
    0.05, 0.9, 0, 0, 0.05, // Green: Minimally adjust green to keep the hue warm without shifting to yellow.
    0, 0, 0, 0, 0,        // Blue: Essentially remove the blue component's influence on the orange hue.
    0, 0, 0, 1, 0,        // Alpha: No change
];

const shaking = (container) => {
  return new Promise((resolve) => {
    const tl = gsap.timeline({
      repeat: 4,
      yoyo: true,
      repeatDelay: 0.1,
      onComplete: () => resolve("Animation completed") // Resolve the promise when the timeline completes
    });

    // Add slight random movements to simulate shaking
    tl.to(container, {x: "+=5", duration: 0.05})
      .to(container, {x: "-=10", duration: 0.05})
      .to(container, {x: "+=5", duration: 0.05})
      .to(container, {y: "+=5", duration: 0.05})
      .to(container, {y: "-=10", duration: 0.05})
      .to(container, {y: "+=5", duration: 0.05});

    // Start the animation
    tl.play();
  });
};

function applyShineEffect(card) {
    const shineOverlay = Math.random() > 0.5 ? PIXI.Sprite.from('shine1') : PIXI.Sprite.from('shine2')
    
    shineOverlay.x = -shineOverlay.width / 1; 
    shineOverlay.y = -shineOverlay.height * 0.9; 
    
    card.mask = shineOverlay;
    card.parent.addChild(shineOverlay);

    const cardClone = new PIXI.Sprite(card.texture); // Clone the card
    // Match the position
    cardClone.anchor.set(card.anchor.x, card.anchor.y); // Match the anchor
    cardClone.position.set(card.x, card.y);
    // Match the scale
    cardClone.scale.set(card.scale.x, card.scale.y);
    // Start with the clone being transparent
    cardClone.alpha = 0;

    // Add the clone to the same parent container as the original card
    card.parent.addChild(cardClone);

    gsap.to(shineOverlay, {
        x: -shineOverlay.width * 0.1,
        y: -shineOverlay.height * 0.3,
        duration: 3, // Duration of the fade out animation in seconds
        onComplete: () => {
            gsap.to(cardClone, {
              alpha: 1, 
              duration: 1,
              onComplete: () => {
                card.mask = null; // Remove the mask from the card after the fade out is complete
                card.parent.removeChild(shineOverlay); // Optionally, remove the shine overlay from the container
                card.parent.removeChild(cardClone);
              }
            })
        }
    });
}

const animateToGoldenYellow = (container) => {
  return new Promise((resolve) => {
    const cardSprite = container.children[1]
    const filter = new PIXI.ColorMatrixFilter();
    cardSprite.filters = [filter];
    
    // Proxy object for the animation
    const matrixProxy = {t: 0};

    gsap.to(matrixProxy, {
        t: 1,
        delay: 2,
        duration: 1, // Duration in seconds
        onUpdate: () => {
            // Interpolate between the neutral and golden yellow matrices
            for (let i = 0; i < filter.matrix.length; i++) {
                filter.matrix[i] = neutralMatrix[i] + (goldenYellowMatrix[i] - neutralMatrix[i]) * matrixProxy.t;
            }
        },
        onComplete: () => {
          resolve()
        }
    });
  })
};

const Cards = forwardRef(({ ready, opened, app, cardsMinted, setScale, revealAll }, ref) => {

    const unifyAndCountRepeats = arr => Object.values(arr.reduce((acc, {cardId, foil, ...rest}) => {
      const key = `${cardId}-${foil}`;
      acc[key] = acc[key] ? {...acc[key], repeated: acc[key].repeated + 1} : {cardId, foil, ...rest, repeated: 1};
      return acc;
    }, {}));

    const frontCardsRef = useRef([])
    const uniqueCards = unifyAndCountRepeats(cardsMinted);
    const [frontCardScale, setFrontCardScale] = useState(uniqueCards.length > 15 ? setScale * 0.94 : setScale * 1.4)
    const [backCardScale, setBackCardScale] = useState(uniqueCards.length > 15 ? setScale * 1.4 : setScale * 2)
    const [topMargin, setTopMargin] = useState(app.screen.height * 0.3)

    // useEffect(() => {
    //   console.log(uniqueCards)
    // }, [uniqueCards])

    useEffect(() => {
      if (cardsMinted && cardsMinted.length > 0 && setScale) {
        setFrontCardScale(uniqueCards.length > 15 ? setScale * 0.94 : setScale * 1.4)
        setBackCardScale(uniqueCards.length > 15 ? setScale * 1.4 : setScale * 2)
        setTopMargin(app.screen.height * 0.25)
      }
    }, [cardsMinted, setScale])

    useEffect(() => {
      if (revealAll) {
        ref.current.children.forEach((container) => {
          flipCardToFront(container)
        })
      }
    }, [revealAll])

    // Function to flip from back to front
    const flipCardToFront = (container) => {
      return new Promise((resolve) => {
        const backCard = container.children[1]          
        const frontCard = container.children[0].children[0]
        // console.log("frontCard", frontCard)
        const isGold = frontCard.gold;
        // Ensure back card is visible and front card is hidden at the start
        backCard.visible = true;
        frontCard.visible = false;
        // setTimeout(() => {
        //   flipCardAudio.play()
        // }, ((frontCard.gold || frontCard.legendary) ? 2.6 : 0))
        gsap.to(backCard.scale, {
            delay: isGold ? 2 : 0,
            x: 0, // Scale horizontally to 0
            duration: isGold ? 0.6 : 0.3,
            onStart: () => {
              flipCardAudio.play()
              if (frontCard.gold || frontCard.legendary) applyShineEffect(frontCard)
            },
            onComplete: () => {  
                backCard.visible = false; // Hide back card
                frontCard.visible = true; // Show front card
                frontCard.scale.x = 0
                gsap.to(frontCard.scale, { 
                  x: frontCardScale, 
                  duration: isGold ? 0.6 : 0.3,
                  onComplete: () => {
                    resolve()
                  }
                }); // Scale back to original width
            }
        });
      })
    };

    const onCardMouseOver = (event) => {
      var container = event.currentTarget;
      if (container.filters && container.filters.includes(glowFilter)) return;
      if (!container.filters) container.filters = [glowFilter];
      else container.filters.push(glowFilter);
    }

    const onCardMouseOut = (event) => {
      var container = event.currentTarget;
      if (!container.filters || !container.filters.includes(glowFilter)) return;
      container.filters = container.filters.filter(f => f !== glowFilter);
    }

    const onPointerClick = async (event) => {
      const container = event.currentTarget;
      await wait(500)
      container.off("pointertap", onPointerClick);

      if (container.gold || container.legendary) {
        setTimeout(() => {
          cymbalsAudio.play()
        }, 900)
        await shaking(container)
        if (container.gold) await animateToGoldenYellow(container)
        else await wait(3000)
        
      }
      
      await flipCardToFront(container)
      // applyShiningEffectToCard(container.children[0])
        
    };

    useEffect(() => {
      if (opened) {
        for (let i = 0; i < ref.current.children.length; i++) {
          const container = ref.current.children[i];
          container.interactive = true;
          container.buttonMode = true; // Optional, shows a pointer cursor
          container.cardIndex = i;
          container.gold = uniqueCards[i].foil == 2;
          container.legendary = uniqueCards[i].rarity == 4;
          container.on("pointerover", onCardMouseOver);
          container.on("pointerout", onCardMouseOut);
          container.on("pointertap", onPointerClick);
        }   
      }

      // Cleanup
      return () => {
        for (let i = 0; i < ref.current?.children?.length; i++) {
          const container = ref.current.children[i];
          container.off("pointerover", onCardMouseOver);
          container.off("pointerout", onCardMouseOut);
          container.off("pointertap", onPointerClick);
        }  
      };
    }, [opened]); // Consider dependencies that ensure re-binding when necessary



    return (
        <Container ref={ref}>
          {uniqueCards.map((card, index) => {
            const cardsPerRow = uniqueCards.length > 15 ? 10 : 5;
            const rowIndex = Math.floor(index / cardsPerRow);
            const indexInRow = index % cardsPerRow;
            const numberOfCardsInRow = Math.min(uniqueCards.length - rowIndex * cardsPerRow, cardsPerRow);
            const totalSideMargin = uniqueCards.length > 15 ? app.screen.width / 9 : app.screen.width / 7; // Total side margin for both sides
            const spriteWidth = PIXI.utils.TextureCache[`card-back`].width * backCardScale;
            const spriteHeight = PIXI.utils.TextureCache[`card-back`].height * backCardScale;
            const totalSpriteWidth = spriteWidth * numberOfCardsInRow;
            const usableScreenWidth = app.screen.width - totalSideMargin; // Screen width minus side margins
            const totalGaps = numberOfCardsInRow - 1; // Adjusted for the actual number of gaps between the cards
            const gapWidth = totalGaps > 0 ? (usableScreenWidth - totalSpriteWidth) / totalGaps : 0; // Width of each gap, if there are cards to have gaps between them

            // Adjusting the starting x position to account for center anchoring
            // If there are gaps (more than one card), distribute the cards with gaps in between.
            // If only one card, it should be centered, thus dividing the usableScreenWidth by 2.
            const x = totalGaps > 0
                      ? (app.screen.width - usableScreenWidth) / 2 + spriteWidth / 2 + (spriteWidth + gapWidth) * indexInRow
                      : (app.screen.width / 2); // Center the card if it's the only one

            const y = topMargin + rowIndex * (spriteHeight + 20); // 20 is an arbitrary gapHeight for rows

            return (
              <Container
                  key={`cont-${index}`}
                  x={x}
                  y={y}
              >
              {
                ready &&
                <>
                  <Card 
                    ref={(el) => frontCardsRef.current[index] = el} 
                    ready={ready} 
                    setScale={frontCardScale} 
                    card={card} 
                    x={0} 
                    y={0}
                  />
                  <Sprite
                      key={`backcard-${index}`}
                      texture={PIXI.utils.TextureCache[`card-back`]}
                      anchor={[0.5, 0.5]}
                      visible={true}
                      scale={backCardScale}
                  />
                </>
              }
              </Container>
            );
          })}
        </Container>
    );
})

Cards.displayName = "Cards";

export default Cards;