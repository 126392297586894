
/* eslint-disable */

import { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Link, useLocation } from 'react-router-dom'; // Import Link
import { trimAddress } from "../utils";
import { useAccount } from 'wagmi';
import { cdnUrl } from "../constants.js";
import { useMediaQuery } from '@mui/material';
import { useAppKitAccount } from "@reown/appkit/react";

const iconStyle = {
  width: '20px',
  height: '20px'
}

const activeTabStyle = {
  color: '#937341',
  borderBottom: '1px solid #937341', // Underline with 3px thickness
  paddingBottom: '0px', // Adds space between the text and the underline
  borderRadius: '0px', // Ensures no rounded corners
  '&:hover': {
    borderBottom: '1px solid #937341' // Optional: Add hover effect (gold color)
  }
}

function Navbar({ setRoute, address, login, logout, wallet, accountData }) {
  const { isDisconnected, isConnected } = useAccount();
  const [username, setUsername] = useState(null);
  const location = useLocation();
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));
  const { address: evmAddress, caipAddress, status } = useAppKitAccount()

  useEffect(() => {
    console.log("navbar wallet", wallet)
  }, [wallet])

  useEffect(() => {
    if (accountData?.name) setUsername(accountData.name);
    else if (address) setUsername(trimAddress(address));
  }, [accountData, address]);

  // useEffect(() => {
  //   console.log("wallet", wallet)
  // }, [wallet])

  // useEffect(() => {
  //   console.log("isConnected", isConnected)
  // }, [isConnected])

  return (
    <AppBar position="fixed" className="NavBar" 
      sx={{
        background: 'black',
        boxShadow: 'none',
        color: 'white',
        height: isSmallScreen ? '96px' : '144px',
        padding: '0px 0',
        overflow: 'hidden',
        width: '100%',
        fontFamily: 'Inter, sans-serif'
      }}
    >
      <Toolbar
        sx={{
          height: '100%', // Ensures the Toolbar takes the full height
          display: 'flex',
          alignItems: 'center', // Centers content vertically
          justifyContent: 'space-between', // Adds space between elements
        }}
      >
        {/* Logo as Home Button */}
        <Box className="logo" sx={{ display: 'flex', alignItems: 'center', marginRight: '20px', marginLeft: '40px' }}>
          <Button
            onClick={() => setRoute("/")}
          >
            <img 
            src={`${cdnUrl}/landing/small-logo.png`} 
            alt="Game Logo" 
            style={{ 
              width: isSmallScreen ? '97px' : '130px', 
              height: 'auto', 
              cursor: 'pointer' 
            }} 
            />
          </Button>
        </Box>

        <Box className="logo" sx={{ display: 'flex', alignItems: 'center', marginRight: '20px', marginLeft: '20px' }}>
          <img src={`${cdnUrl}/battle-menu/navbar/divider.svg`} alt="Game Logo" style={{ width: 'auto', height: '100%' }} />
        </Box>

        {
          !isSmallScreen &&
          <>
        <Button 
          className="button" 
          color="inherit" 
          component={Link} 
          // to="/battle"
          onClick={() => setRoute("/battle")}
          sx={location.pathname == '/battle' ? activeTabStyle : null}
        >
          Battle
        </Button>
        <Button 
          className="button" 
          color="inherit" 
          component={Link} 
          // to="/collection"
          onClick={() => setRoute("/shop")}
          sx={location.pathname == '/shop' ? activeTabStyle : null}
        >
          Shop
        </Button>
        {wallet == "MetaMask" && (
          <Button 
            className="button" 
            color="inherit" 
            component={Link} 
            // to="/openpacks"
            onClick={() => setRoute("/openpacks")}
            sx={location.pathname == '/openpacks' ? activeTabStyle : null}
          >
            Open Packs
          </Button>
        )}
        {(wallet == "Kondor" || wallet == "Konio") && (
          <Button 
            className="button" 
            color="inherit" 
            href="https://presale.lordsforsaken.com/openpacks" 
            target="_blank"
            sx={location.pathname == '/openpacks' ? activeTabStyle : null}
          >
            Open Packs
          </Button>
        )}
        <Button 
          className="button" 
          color="inherit" 
          component={Link} 
          // to="/collection"
          onClick={() => setRoute("/collection")}
          sx={location.pathname == '/collection' ? activeTabStyle : null}
        >
          Collection
        </Button>
        <Button 
          className="button" 
          color="inherit" 
          component={Link} 
          // to="/referral"
          onClick={() => setRoute("/referral")}
          sx={location.pathname == '/referral' ? activeTabStyle : null}
        >
          Referrals
        </Button>
        <Button 
          className="button" 
          color="inherit" 
          component={Link} 
          // to="/referral"
          onClick={() => setRoute("/wallet")}
          sx={location.pathname == '/wallet' ? activeTabStyle : null}
        >
          Wallet
        </Button>
        <Button className="button" color="inherit" component="a" href="https://wiki.lordsforsaken.com" target="_blank">
          <img 
            src={`${cdnUrl}/app/footer/socials/wiki.svg`} 
            alt="Wiki" 
            style={iconStyle} 
          />
        </Button>
        <Button className="socials button" color="inherit" component="a" href="https://t.me/+1qTLJufP6Zg4NzY0" target="_blank">
          <img 
            src={`${cdnUrl}/app/battle-menu/navbar/socials/telegram.svg`} 
            alt="Telegram" 
            style={iconStyle} 
          />
        </Button>
        {/* Discord Button */}
        <Button className="socials button" color="inherit" component="a" href="https://discord.gg/VBKmvh2u6S" target="_blank">
          <img 
            src={`${cdnUrl}/app/footer/socials/discord.svg`} 
            alt="Discord" 
            style={iconStyle} 
          />
        </Button>
          </>
        }



        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {/* Place for logo or brand name */}
        </Typography>
        {
          !isSmallScreen && (
            isDisconnected || (wallet === "Kondor" || wallet === "Konio") ? (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {address && (
                  <div className="navbar-box">
                    <Typography variant="subtitle1">
                      {username}
                    </Typography>
                  </div>
                )}
                {!address && (
                  <Button className="button" color="inherit" onClick={login} sx={{ marginLeft: 'auto' }}>
                    Login
                  </Button>
                )}
                {address && (
                  <Button className="button" color="inherit" onClick={logout}>
                    Logout
                  </Button>
                )}
              </Box>
            ) : (
              <appkit-button />
            )
          )
        }


      </Toolbar>
      <Box 
        sx={{ 
          width: '100%', 
          position: 'absolute', 
          top: isSmallScreen ? '60px' : '106px'
        }}>
        <img
          src={`${cdnUrl}/battle-menu/divider.png`}
          alt="divider"
          style={{ width: '100%', height: '72px' }}
        />
      </Box>


    </AppBar>
  );
}

export default Navbar;
