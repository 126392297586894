import React from "react"
import { Text } from 'react-pixi-fiber';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.scale = this.props.scale
    this.app = this.props.app
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
    console.log("*** DEBUG ERROR LOGS ***")
    console.log(error)
    console.log(info)
    // logErrorToMyService(error, info.componentStack);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Text
          text={"ERROR DETECTED"}
          anchor={{x: 0.5, y: 0.5 }}
          x={this.app.screen.width * 0.5}
          y={this.app.screen.height * 0.5}
          style={{
            fill: 'red',
            fontFamily: "Almendra",
            fontStyle: "bold",
            fontSize: 200 * this.scale
          }}
        />
      )
      // return this.props.fallback;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;