import { forwardRef, useState, useEffect, useRef } from 'react';
import { Sprite, Container, Text } from 'react-pixi-fiber';
import * as PIXI from 'pixi.js';
import Melee from "../icons/Melee";
import Health from "../icons/Health";
import Magic from "../icons/Magic";
import Speed from "../icons/Speed";
import Ranged from "../icons/Ranged";
import Armor from "../icons/Armor";
import Mana from "../icons/Mana";
import TextWrapper from "../../TextWrapper"

const Standard = forwardRef(({ baseStatsLoaded, baseStats, data, state, ready, scale, level, visible, isIntro, onHover, cardStatuses }, ref) => {
    // console.log(`${data.name} state`, state)
    const isGod = data.type == "Summoner";
    const critterImageRef = useRef();
    const iconScale = 1.5;
    const parchmentScale = 2.6;
    const frameScale = 3;
    const [frameWidth, setFrameWidth] = useState(null)
    const [frameHeight, setFrameHeight] = useState(null)
    const [iconWidth, setIconWidth] = useState(null)
    const [fontScale, setFontScale] = useState(null)
    const abilitiesRef = useRef([]);
    const [starWidth, setStarWidth] = useState(null)
    const starScale = 0.6;

    useEffect(() => {
      if (starScale && ready) {
        setStarWidth(PIXI.utils.TextureCache[`star2`].width * starScale)
      }
    }, [starScale, ready])

    useEffect(() => {
      if (ready) {
        setFrameWidth(PIXI.utils.TextureCache["standard-silver-1"].width * frameScale)
        setFrameHeight(PIXI.utils.TextureCache["standard-silver-1"].height * frameScale)
        setIconWidth(PIXI.utils.TextureCache['challenge'].width * iconScale)
        critterImageRef.current.isCritterSprite = true;
        critterImageRef.current.interactive = true;
      }
    }, [ready, iconScale, frameScale])

    useEffect(() => {
        const newScale = Math.min(1 / (scale * 3), 2)
        setFontScale(newScale)
    }, [scale])

    return (
        <Container ref={ref} scale={1} x={0} y={0} visible={visible} z-index={2}>
            <>
                <Sprite 
                    ref={critterImageRef}
                    texture={PIXI.utils.TextureCache[`standard-${data.foil == 1 ? 'silver' : 'gold'}-${data.cardId}`]} 
                    x={0} 
                    y={0} 
                    anchor={[0.5, 0.5]} 
                    visible={true}
                    scale={frameScale}
                />
                {
                    (isIntro || onHover) &&
                    <Container
                        x={frameWidth} 
                        y={0} 
                        scale={0.95}
                        z-index={2}
                    >
                        <Sprite 
                            texture={PIXI.utils.TextureCache['parchment']} 
                            x={0} 
                            y={0} 
                            anchor={[0.5, 0.5]} 
                            visible={true}
                            scale={parchmentScale}
                        />
                        {
                          data.baseStats.abilities.map((ability, index) => (
                            <Container key={ability}>
                                <Container ref={(el) => abilitiesRef.current[index] = el}>
                                    <Sprite 
                                        key={`ability-${ability}`} // Using the ability name (or key) as the key prop
                                        texture={PIXI.utils.TextureCache[`${ability.replace(/\s+/g, '-').toLowerCase()}`]}
                                        x={-frameWidth*0.33} 
                                        y={-frameHeight * 0.28 + (index * iconWidth)}
                                        anchor={[0.5, 0.5]} 
                                        visible={true}
                                        scale={iconScale}
                                    />
                                    <Text
                                      key={`title-${ability}`}
                                      text={ability}
                                      anchor={{x: 0, y: 0.5 }}
                                      x={-frameWidth/4.5}
                                      y={-frameHeight * 0.28 + (index * iconWidth)}
                                      style={{
                                        fill: 'black',
                                        fontFamily: "Almendra",
                                        fontStyle: "bold",
                                        fontSize: (60 * fontScale)
                                      }}
                                    />
                                </Container>
                            </Container>
                          ))                        
                        }
                        {
                          cardStatuses.map((status, index) => (
                            <Container key={status}>
                              <Container ref={(el) => abilitiesRef.current[index] = el}>
                                <Sprite 
                                  key={`sprite-${data.tokenId}-${status}`}
                                  texture={PIXI.utils.TextureCache[`${status.replace(/\s+/g, '-').toLowerCase()}`]}
                                  x={-frameWidth*0.33} 
                                  y={-frameHeight * 0.28 + (index * iconWidth) + (data.baseStats.abilities.length * iconWidth)}
                                  anchor={[0.5, 0.5]} 
                                  visible={true}
                                  scale={iconScale}
                                />
                                <Text
                                  key={`text-${data.tokenId}-${status}`}
                                  text={status}
                                  anchor={{ x: 0, y: 0.5 }}
                                  x={-frameWidth/4.5}
                                  y={-frameHeight * 0.28 + (index * iconWidth) + (data.baseStats.abilities.length * iconWidth)}
                                  style={{
                                    fill: 'black',
                                    fontFamily: "Almendra",
                                    fontStyle: "bold",
                                    fontSize: (60 * fontScale) * 11 / (status.length)
                                  }}
                                />
                              </Container>
                            </Container>
                          ))                        
                        }
                        {
                          isGod && Object.entries(data.baseStats).filter(([stat, value]) => stat !== "abilities" && stat !== "mana" && value !== 0).map(([stat, value], index) => (
                            <Container key={stat}>
                              <Sprite 
                                key={stat} // Adding a key for each item when rendering a list
                                texture={PIXI.utils.TextureCache[`${stat == "attack" ? "melee" : stat}`]} 
                                x={-iconWidth*0.5}
                                y={frameHeight*0.4 * (-1.2 + (index + data.baseStats.abilities.length))}
                                anchor={{ x: 0.5, y: 0.5 }}
                                visible={true}
                                scale={0.5}
                              />
                              <Text
                                text={isGod ? (value > 0 ? `+${value}` : `${value}`) : value}
                                anchor={{x: 0.5, y: 0.5 }}
                                x={-iconWidth*0.5}
                                y={frameHeight*0.4 * (-1.2 + (index + data.baseStats.abilities.length))}
                                style={{
                                  fill: 'white',
                                  fontFamily: "Almendra",
                                  fontStyle: "bold",
                                  fontSize: 120 * fontScale
                                }}
                              />
                              <TextWrapper
                                key={`description-${stat}`}
                                text={"\n" + `${value > 0 ? "+" : "-"} ${value} ${stat} to every critter of ${value > 0 ? "friendly" : "enemy"} units`}
                                boxWidth={frameWidth*0.8}
                                boxHeight={frameHeight*0.5}
                                x={-frameWidth*0.4}
                                y={frameHeight*0.35 * (-1.2 + (index + data.baseStats.abilities.length))}
                                style={{
                                  fill: 'black',
                                  fontFamily: "Almendra",
                                  fontStyle: "bold",
                                  fontSize: 60 * fontScale
                                }}
                              />
                            </Container>
                          ))
                        }

                        {
                          Array.from({ length: data.level }, (_, index) => (
                            <Sprite 
                              key={`star-${index}`} // Adding a key for each item when rendering a list
                              texture={PIXI.utils.TextureCache[`star2`]} 
                              x={-frameWidth * 0.35 + index%5 * starWidth}
                              y={index < 5 ? frameHeight * 0.3 : frameHeight * 0.4}
                              anchor={{ x: 0.5, y: 0.5 }}
                              visible={true}
                              scale={starScale}
                            />
                          ))
                        }

                    </Container>
                }
            </>
            {
                baseStats && baseStatsLoaded &&
                <>
                    <Melee 
                        baseValue={isGod ? baseStats?.attack : baseStats?.attack?.[level]}
                        data={data}
                        value={state.attack}
                        ready={ready}
                        scale={0.5}
                        standard={true}
                    />

                    {(isGod || state.health > 0) && (
                        <Health 
                            baseValue={isGod ? baseStats?.health : baseStats?.health?.[level]}
                            data={data}
                            value={state.health}
                            ready={ready}
                            scale={0.5}

                            standard={true}
                        />
                    )}

                    <Speed 
                        baseValue={isGod ? baseStats?.speed : baseStats?.speed?.[level]}
                        data={data}
                        value={state.speed}
                        ready={ready}
                        scale={0.5}
                        standard={true}
                    />

                    <Mana 
                        baseValue={isGod ? baseStats?.mana : baseStats?.mana?.[level]}
                        data={data}
                        value={state.mana}
                        ready={ready}
                        scale={0.25}
                        standard={true}
                    />

                    <Magic 
                        baseValue={isGod ? baseStats?.magic : baseStats?.magic?.[level]}
                        data={data}
                        value={state.magic}
                        ready={ready}
                        scale={0.5}
                        standard={true}
                    />

                    {(isGod || state.armor > 0) && (
                        <Armor 
                            baseValue={isGod ? baseStats?.armor : baseStats?.armor?.[level]}
                            data={data}
                            value={state.armor}
                            ready={ready}
                            scale={0.5}

                            standard={true}
                        />
                    )}

                    <Ranged 
                        baseValue={isGod ? baseStats?.ranged : baseStats?.ranged?.[level]}
                        data={data}
                        value={state.ranged}
                        ready={ready}
                        scale={0.5}
                        standard={true}
                    />
                </>
            }
        </Container>
    );
});

Standard.displayName = "Standard";

export default Standard;
