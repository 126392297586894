import { useEffect } from 'react';
import { Container, Paper, Typography, Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import {dbApiUrl} from "../constants";

function Unsubscribe() {
  const location = useLocation();
  // const [token, setToken] = useState(null);

  useEffect(() => {
    // Function to parse query parameters
    const queryParams = new URLSearchParams(location.search);
    const tokenFromParams = queryParams.get('token');

    if (tokenFromParams) {
      // setToken(tokenFromParams);

      // Example: send token to server for processing
      axios.post(`${dbApiUrl}/unsubscribe`, { token: tokenFromParams })
        .then(response => {
          console.log('Unsubscribed successfully:', response.data);
        })
        .catch(error => {
          console.error('Error unsubscribing:', error);
        });
    }
  }, [location.search]);

  return (
    <Container component="main" maxWidth="xs" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Paper elevation={3} sx={{ padding: 4, textAlign: 'center' }}>
        <Typography variant="h5" component="h1" gutterBottom>
          You have been unsubscribed
        </Typography>
        <Typography variant="body1">
          We`&apos;`re sorry to see you go. If you have any feedback or concerns, feel free to contact us.
        </Typography>
        <Box mt={2}>
          <Typography variant="body2" color="textSecondary">
            If this was a mistake, you can resubscribe at any time by visiting our website.
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
}

export default Unsubscribe;
