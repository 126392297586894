// import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography } from '@mui/material';

const Confirmation = ({ trxId, handleClose, wallet }) => {
  const lineaScanUrl = `https://lineascan.build/tx/${trxId}`;
  const koinosblocksUrl = `https://koinosblocks.com/tx/${trxId}`
  return (
    <Dialog 
      open={!!trxId} 
      onClose={handleClose}
      PaperProps={{
        sx: { 
          border: '2px solid #675330', 
          borderRadius: '8px', 
          backgroundColor: 'black',
          padding: '10px'
        }
      }}
    >
      <DialogTitle sx={{ fontFamily: 'Poppins', fontWeight: '600', textAlign: 'center' }}>
        Transaction Confirmed
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', border: '1px solid #675330', borderRadius: '8px' }}>
        <Typography variant="body1" sx={{ fontFamily: 'Poppins', fontWeight: '400', textAlign: 'center', marginBottom: '16px' }}>
          Your transaction has been successfully confirmed!
        </Typography>
        <Typography variant="body2" sx={{ fontFamily: 'Poppins', fontWeight: '400', textAlign: 'center' }}>
          Transaction ID: {trxId}
        </Typography>
        <a 
          href={wallet == 'Kondor' || wallet == 'Konio' ? koinosblocksUrl : lineaScanUrl } 
          target="_blank" 
          rel="noopener noreferrer" 
          style={{ fontFamily: 'Poppins', textDecoration: 'underline', color: '#937341', marginTop: '12px' }}
        >
          View on { wallet == 'Kondor' || wallet == 'Konio' ? 'KoinosBlocks' : 'Linea Scan' }
        </a>
      </DialogContent>
      <DialogActions>
        <Button className="image-button" onClick={handleClose} sx={{ fontFamily: 'Poppins', color: '#C4B998', width: '150px', height: '45px' }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Confirmation;
