import axios from "axios";
import { Contract, Provider, utils } from "koilib";
import packsAbi from "./contracts/koinos/abis/packs.json";
import cardsAbi from "./contracts/koinos/abis/cards.json";

export const colors = ["White", "Yellow", "Purple"];
export const MONTH = 30 * 24 * 60 * 60 * 1000;

export const nodeEnv = import.meta.env.VITE_NODE_ENV;
export const isProd = nodeEnv == 'production';
export const cdnUrl = import.meta.env.VITE_CDN_URL;
export const dbApiUrl = import.meta.env.VITE_KOINOS_DB_API_URL;
export const wsUrl = nodeEnv === "development" ? "ws://localhost:8343" : import.meta.env.VITE_WSS_URL;
export const chatUrl = nodeEnv === "development" ? "ws://localhost:8344" : import.meta.env.VITE_CHAT_URL;
export const faithDecimals = 8;
export const koinDecimals = 8;

let dataCache = {};
export let constantsLoaded = false;

export const gameDataLoader = async () => {
  if (!constantsLoaded) {
    const promise1 = axios.get(`${cdnUrl}/units.v4.json`).then(res => res.data);
    const promise2 = axios.get(`${cdnUrl}/levels.json`).then(res => res.data);
    const promise3 = axios.get(`${cdnUrl}/abilities.json`).then(res => res.data);
    const promise4 = axios.get(`${cdnUrl}/rewards/official-2.json`).then(res => res.data);
    const promise5 = axios.get(`${cdnUrl}/levelCap.json`).then(res => res.data);
    const promise6 = axios.get(`${cdnUrl}/lore.json`).then(res => res.data);
    const promise7 = axios.get(`${cdnUrl}/config.json`).then(res => res.data);
    const promise8 = axios.get(`${cdnUrl}/custom-pixi-particles/particlesDefaultConfig.json`).then(res => res.data);
    const promise9 = axios.get(`${cdnUrl}/statuses.v2.json`).then(res => res.data);
    let dependenciesPromises;

    try {
      dependenciesPromises = await Promise.all([promise1, promise2, promise3, promise4, promise5, promise6, promise7, promise8, promise9])
    } catch (error) {
      console.error('Failed to load static dependencies:', error);
      throw error;
    }

    dataCache.units = dependenciesPromises[0];
    dataCache.critters = dataCache.units.filter(card => card.type === "Monster");
    dataCache.gods = dataCache.units.filter(card => card.type === "Summoner");
    dataCache.cardIds = dataCache.units.map(card => card.id);
    dataCache.levels = dependenciesPromises[1];
    dataCache.abilities = dependenciesPromises[2];
    dataCache.rewards = dependenciesPromises[3];
    dataCache.levelCap = dependenciesPromises[4];
    dataCache.lore = dependenciesPromises[5];
    dataCache.config = dependenciesPromises[6];
    dataCache.particlesDefaultConfig = dependenciesPromises[7];
    dataCache.statuses = dependenciesPromises[8];

    dataCache.network = dataCache.config.contracts.koinos.alphaEdition.mainnet;
    dataCache.provider = new Provider([dataCache.network.urls.rpcNode]);

    dataCache.packsContract = new Contract({
      id: dataCache.network.addresses.packs,
      provider: dataCache.provider,
      abi: packsAbi,
    });
    dataCache.cardsContract = new Contract({
      id: dataCache.network.addresses.cards,
      provider: dataCache.provider,
      abi: cardsAbi,
    });

    dataCache.koinContract = new Contract({
      id: dataCache.network.addresses.koin,
      abi: utils.tokenAbi,
      provider: dataCache.provider,
      // signer: user1,
    });

    dataCache.faithContract = new Contract({
      id: "14Dj8RjYqy8hQ1GarrJGGeaGu6AuCwxcgy", // faith contract id
      abi: utils.tokenAbi,
      provider: dataCache.provider
    });

    console.log("Game data constantsLoaded!")
    constantsLoaded = true;
    return dataCache;
  }
};

export const getProvider = () => {
  if (!constantsLoaded) {
    throw new Error("Data is not constantsLoaded yet");
  }
  return dataCache.provider;
}

export const getNetwork = () => {
  if (!constantsLoaded) {
    throw new Error("Data is not constantsLoaded yet");
  }
  return dataCache.network;
}

export const getContracts = () => {
  if (!constantsLoaded) {
    throw new Error("Data is not constantsLoaded yet");
  }
  return { 
    packsContract: dataCache.packsContract, 
    cardsContract: dataCache.cardsContract, 
    koinContract: dataCache.koinContract,
    faithContract: dataCache.faithContract
  }
}

// Synchronous function to get critters
export const getCritters = () => {
  if (!constantsLoaded) {
    throw new Error("Data is not constantsLoaded yet");
  }
  return dataCache.critters
};

// Synchronous function to get gods
export const getGods = () => {
  if (!constantsLoaded) {
    throw new Error("Data is not constantsLoaded yet");
  }
  return dataCache.gods
};

export const getLevels = () => {
  if (!constantsLoaded) {
    throw new Error("Data is not constantsLoaded yet");
  }
  return dataCache.levels
};

export const getLevelCap = () => {
  if (!constantsLoaded) {
    throw new Error("Data is not constantsLoaded yet");
  }
  return dataCache.levelCap;
};

export const getUnits = () => {
  if (!constantsLoaded) {
    throw new Error("Data is not constantsLoaded yet");
  }
  return dataCache.units;
};

export const getLore = () => {
    if (!constantsLoaded) {
        throw new Error("Data is not constantsLoaded yet")
    }
    return dataCache.lore;
}

export const getAbilities = () => {
    if (!constantsLoaded) {
        throw new Error("Data is not constantsLoaded yet")
    }
    return dataCache.abilities;
}

export const getRewards = () => {
    if (!constantsLoaded) {
        throw new Error("Data is not constantsLoaded yet")
    }
    return dataCache.rewards;
}

export const getConfig = () => {
    if (!constantsLoaded) {
        throw new Error("Data is not constantsLoaded yet")
    }
    return dataCache.config;
}

export const getParticlesDefaultConfig = () => {
  if (!constantsLoaded) {
    throw new Error("Data is not constantsLoaded yet")
  }
  return JSON.parse(JSON.stringify(dataCache.particlesDefaultConfig));
}

export const getStatuses = () => {
  if (!constantsLoaded) {
    throw new Error("Data is not constantsLoaded yet")
  }
  return dataCache.statuses;
}
