import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function AlertDialog({ packsBalance, setIsOpening, openAmount, setOpenAmount, setRevertOpening, showDialog, setShowDialog }) {
  // Handle the slider's change event
  const handleSliderChange = (event, newValue) => {
    setOpenAmount(newValue);
  };

  const handleClose = () => {
    setShowDialog(false);
    setRevertOpening(true);
  };

  const handleAgree = () => {
    setIsOpening(true);
    setShowDialog(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={showDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Proceed to opening Pack/s?"}
        </DialogTitle>
        <DialogContent >  {/* Set text color to white */}
          <DialogContentText id="alert-dialog-description" sx={{ color: 'white' }}>
            This action is irreversible. Each card pack creates 5 random NFTs.
          </DialogContentText>
          {/* Use a flex container to center the slider */}
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
            <Typography variant="h6" component="div">
              Amount: {openAmount}
            </Typography>
            <Box sx={{ width: '50%' }}>
              <Slider 
                defaultValue={1} 
                aria-label="Pack Amount" 
                valueLabelDisplay="auto" 
                max={Math.min(20, Math.floor(packsBalance))} // Set max to 5 or packsBalance, whichever is smaller
                min={1}
                value={openAmount}
                onChange={handleSliderChange}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleAgree} autoFocus>
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
