/* eslint-disable */

import { useState, useEffect, useCallback, useRef } from 'react';

const useWebSocket = (url) => {
  const [readyState, setReadyState] = useState(WebSocket.CLOSED);
  const [messageQueue, setMessageQueue] = useState([]);
  const [ws, setWs] = useState(null);
  const reconnectInterval = useRef(null);

  const addMessageToQueue = (newMessage) => setMessageQueue(prevMessages => [...prevMessages, newMessage]);

  const connect = useCallback(() => {
    const socket = new WebSocket(url);
    setWs(socket);

    socket.onopen = () => {
      setReadyState(WebSocket.OPEN);
      clearInterval(reconnectInterval.current);
    };
    socket.onclose = () => {
      setReadyState(WebSocket.CLOSED);
      reconnectInterval.current = setTimeout(() => connect(), 3000);
    };
    socket.onerror = (error) => {
      console.error('WebSocket error:', error)
    };
    socket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      addMessageToQueue(message);
    };
  }, [url]);

  useEffect(() => {
    connect(); // Start connection

    return () => {
      if (ws) {
        ws.close();
      }
      if (reconnectInterval.current) {
        clearInterval(reconnectInterval.current);
      }
    };
  }, []);

  const sendMessage = useCallback((message) => {
    // console.log("*** sendMessage ***")
    // console.log(message);
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(message);
    }
  }, [ws]);

  return {
    readyState,
    sendMessage,
    messageQueue,
    setMessageQueue
  };
};

export default useWebSocket;
