import { useState } from 'react';
import { Modal, Box, Typography, TextField, Button } from '@mui/material';
import {cdnUrl} from "../../constants.js";

const UsernameModal = ({ open, onClose, onSubmit }) => {
  const [username, setUsername] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (username.trim().length < 3) {
      setError('Username must be at least 3 characters long');
    } else {
      onSubmit(username);
      onClose();
      setUsername('');
      setError('');
    }
  };

  const handleClose = () => {
    setUsername('');
    setError('');
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '30%',
          height: '20%',
          bgcolor: 'background.paper',
          border: '1px solid #E1B15E',
          borderRadius: '8px',
          padding: '10px'
        }}
      >
        <Typography id="modal-title" variant="h6" component="h2" sx={{ color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          ⚔️ Enter Your Username ⚔️
        </Typography>
        <img
          src={`${cdnUrl}/app/referral/separator.svg`}
          alt="divider"
          style={{ width: '100%', height: 'auto', position: 'relative', zIndex: 3 }}
        />
        <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Username"
          variant="outlined"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          sx={{
            mt: 2,
            mb: 2,
            input: { color: 'white' },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'white', // Default border color
              },
              '&:hover fieldset': {
                borderColor: 'white', // Border color when hovered
              },
              '&.Mui-focused fieldset': {
                borderColor: 'white', // Border color when focused
              },
            },
          }}
          error={!!error}
          helperText={error}
          InputLabelProps={{
            style: { color: 'white' },
          }}
        />
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default UsernameModal;
