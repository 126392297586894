
import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid2'; // Grid version 2
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import axios from "axios";
import { dbApiUrl } from '../constants';
import ReferralsTable from "../components/Referral/ReferralsTable";
import ReferralContent from "../components/Referral/ReferralContent";
import { cdnUrl } from "../constants.js";

function Referral({ referralCode, address }) {
  const [open, setOpen] = useState(false);
  const [referralLink, setReferralLink] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [layer1Ref, setLayer1Ref] = useState(null);
  const [layer2Ref, setLayer2Ref] = useState(null);

  const headerStyle = {
    fontFamily: 'Germania One',
    fontWeight: '400',
    fontSize: '32px',
    color: '#EAC88C'
  }

  useEffect(() => {
    if (address) axios.get(`${dbApiUrl}/referrals/${address}`).then((res) => {
      // console.log("DEBUGGGG")
      // console.log(res.data)
      setLayer1Ref(res.data.layer1Referrals)
      setLayer2Ref(res.data.layer2Referrals)
    })
  }, [address])

  useEffect(() => {
    const domain = import.meta.env.VITE_NODE_ENV === 'development' ? 'http://localhost:5173' : 'https://app.lordsforsaken.com';
    if (referralCode) setReferralLink(`${domain}?ref=${referralCode}`);
  }, [referralCode]);


  const handleCopy = () => {
    navigator.clipboard.writeText(referralLink);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const backgroundImgStyle = {
    paddingTop: '420px',
    zIndex: 2
  };


  return (
    <>
      <div style={{
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, #0A0A0C 100%), url("${cdnUrl}/app/referral/header.webp")`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top',
        backgroundSize: 'cover',
        top: '140px',
        left: 0,
        width: '100vw',
        height: '30vh',
        position: 'absolute',
        zIndex: 1,
        }}
      ></div>

      <div style={{
        backgroundColor: '#0A0A0C',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        zIndex: -1,
        filter: 'brightness(1.5)', // Adjust the brightness (1 is the original, 1.5 is 50% brighter)
      }}></div>

      <div style={{
        backgroundColor: '#0A0A0C',
        backgroundImage: `url("${cdnUrl}/battle-menu/background.png")`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top',
        backgroundSize: 'cover', // or 'contain' based on your needs
        position: 'absolute',
        top: '51%',
        left: 0,
        width: '100vw',
        height: '624px',
        zIndex: -1,
        filter: 'brightness(1.5)', // Adjust the brightness (1 is the original, 1.5 is 50% brighter)
      }}></div>

      <Grid 
        container 
        justifyContent="center"
        style={{ 
          marginLeft: `auto`, 
          marginRight: `auto`,
          ...backgroundImgStyle,
          maxWidth: '1000px',
        }}
      >
        <Grid 
          size={{ xs: 12 }} 
          style={{ 
            position: 'relative', 
            zIndex: 3, 
            marginBottom: '40px', 
            fontSize: '64px',  
            fontFamily: 'Germania One',
            fontWeight: '400',
            color: '#E1B15E',
            textAlign: 'center',
            width: '100%',
            height: 'auto'
          }}>
          <p>Referral</p>
        </Grid>
        <Grid size={{ xs: 12 }} style={{ position: 'relative', zIndex: 3, marginBottom: '40px' }}>
          <img
            src={`${cdnUrl}/app/referral/separator.svg`}
            alt="divider"
            style={{ width: '100%', height: 'auto' }}
          />
        </Grid>

        <Grid size={{ xs: 12 }} style={{marginBottom: '40px'}}>
          <Box style={{ display: 'flex', color: '#675330', fontFamily: 'Poppins', justifyContent: 'center' }}>
            <img 
            src={`${cdnUrl}/app/referral/alert.svg`} height="30"/>
            <p>Minimum payout $10. Payouts take place once a week.</p>
          </Box>
        </Grid>

        <Grid size={{ xs: 12 }} container>
          <ReferralContent 
            isHovered={isHovered}
            setIsHovered={setIsHovered}
            layer1Ref={layer1Ref}
            layer2Ref={layer2Ref}
            handleCopy={handleCopy}
            referralLink={referralLink}
          />
        </Grid>
        <Grid size={{ xs: 12 }}>

          {
            layer1Ref && layer1Ref.length > 0 &&
            <>
              <center><p style={headerStyle}>L1 List</p></center>
              <ReferralsTable referrals={layer1Ref}/>
            </>
          }
        </Grid>
      </Grid>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message="Referral link copied!"
        action={
          <React.Fragment>
            <IconButton size="small" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </>
  );
}

export default Referral;
