const mod = 9;

export default function adjust (app, containerRef, discarded) {
  let cardWidth, cardHeight;
  const windowAR = window.innerWidth / window.innerHeight;
  const cardAR = 4/3;

  if (windowAR < cardAR) {
    // Window is narrower than the background aspect ratio
    cardWidth = window.innerWidth  / mod;
    cardHeight = window.innerWidth / mod / cardAR;

  } else {
    // Window is wider than the background aspect ratio
    cardHeight = window.innerHeight / mod;
    cardWidth = window.innerHeight / mod * cardAR;

  }

  if (discarded.length < 2) {
    containerRef.current.x = app.screen.width * 0.5;
    containerRef.current.y = app.screen.height * 0.33;
  } else {
    containerRef.current.x = app.screen.width * 0.1;
    containerRef.current.y = app.screen.height * 0.85;
  }

  return { cardHeight, cardWidth }
}