import { useEffect, useRef, useState, forwardRef } from "react";
import * as PIXI from 'pixi.js';
import { Container, Sprite, Text } from "react-pixi-fiber";
import { GlowFilter } from '@pixi/filter-glow';
import { customPixiParticles } from 'custom-pixi-particles'
import { gsap } from "../../utils"
import Cards from "./Cards.jsx"
import { getParticlesDefaultConfig } from "../../constants"

const cdnUrl = import.meta.env.VITE_CDN_URL;
const heartSoundUrl = `${cdnUrl}/audio/heart-beat.mp3`;
const heartAudio = new Audio(heartSoundUrl);
const explosionSoundUrl = `${cdnUrl}/audio/explosion.mp3`;
const explosionAudio = new Audio(explosionSoundUrl);

const glowFilter = new GlowFilter({
    distance: 15,
    outerStrength: 2,
    color: 0xffc107, // Example: golden color
    quality: 0.5,
});

const Pack = forwardRef(({ ready, scale, app, setIsDragging, x, y, packsBalance, isOpening, floatingAnimationRef, cardsContainerRef, openAmount, cardsMinted, setRevertOpening, revertOpening, setReset, opened, setOpened, revealAll }, ref) => {
  const packSpriteRef = useRef();
  const [packText, setPackText] = useState(null)
  const magicAuraContainer = useRef(null);
  const packContainerRef = useRef(null)

  // global card listenters
  const onCardMouseOver = () => {
    if (packSpriteRef.current.filters && packSpriteRef.current.filters.includes(glowFilter)) return;
    if (!packSpriteRef.current.filters) packSpriteRef.current.filters = [glowFilter];
    else packSpriteRef.current.filters.push(glowFilter);
  }

  const onCardMouseOut = () => {
    if (!packSpriteRef.current.filters || !packSpriteRef.current.filters.includes(glowFilter)) return;
    packSpriteRef.current.filters = packSpriteRef.current.filters.filter(f => f !== glowFilter);
  }

  const onPointerDown = () => {
    setIsDragging(true)
    // ref.current.destroy()
    floatingAnimationRef.current.pause()
  }

  useEffect(() => {
      if (!isOpening) setPackText(`My Packs: ${Math.round(packsBalance * 100) / 100}`)
  }, [packsBalance])

  function createWhiteFlash() {
    if (!app?.screen?.width || !app?.screen?.height) return
    // Create a new Graphics object and draw a white rectangle
    let flash = new PIXI.Graphics()
        .beginFill(0xFFFFFF) // White color
        .drawRect(0, 0, app.screen.width, app.screen.height) // Covering the entire canvas
        .endFill();

    // Add the rectangle to the stage
    app.stage.addChild(flash);

    // Set an initial alpha value (fully opaque)
    flash.alpha = 1;

    // Create the animation to fade out the flash effect
    app.ticker.add((delta) => {
        flash.alpha -= 0.01 * delta; // Adjust fade speed as needed

        // Once the flash is completely transparent, remove it
        if (flash.alpha <= 0) {
            app.stage.removeChild(flash);
            setOpened(true)
            // flash.destroy(); // Optional: Destroy the object to free up resources
        }
    });
  }

  const onPointerClick = () => {
    const emitter = magicAuraContainer.current.addChild(customPixiParticles.create(getParticlesDefaultConfig().darkMagicSmoke))

    emitter.play()

    // Start the animation with a slightly scaled-up state for anticipation
    const tl = gsap.timeline({
      paused: true,
      repeat: -1, // Repeat indefinitely
      onRepeat: decreaseDuration, // Custom function to decrease duration
    });

    tl.to(packSpriteRef.current, {
      pixi: { scaleX: 0.7, scaleY: 0.7 },
      duration: 0.2,
      rotation: 1,
      ease: "back.out(1.7)",
    })
    .to(packSpriteRef.current, {
      x: "+=10",
      repeat: 5,
      yoyo: true,
      duration: 0.05,
      ease: "linear",
    })
    .to(packSpriteRef.current, {
      pixi: { scaleX: 0.5, scaleY: 0.5 },
      rotation: 0,
      x: 0,
      y: 0,
      duration: 0.1,
      ease: "none",
      autoAlpha: 1,
    });

    // Function to decrease the duration of the timeline's animations
    function decreaseDuration() {
      // Increase the timeScale to make the timeline play faster, thus decreasing its effective duration
      // Ensure there's a limit to prevent it from becoming too fast
      tl.timeScale(tl.timeScale() * 1.1); // Adjust the 1.2 value to control the acceleration rate
    }

    // Start the animation
    tl.play();

    heartAudio.playbackRate = 2;

    const interval = setInterval(() => {
        heartAudio.playbackRate += 0.10
    }, 400)

    heartAudio.play();
    setTimeout(() => {
        explosionAudio.play()
    }, 5700)
    heartAudio.addEventListener("ended", function () {
      // Trigger the flash effect
      createWhiteFlash();
      if (packContainerRef.current) packContainerRef.current.destroy()
      // packSpriteRef.current.visible = false;
      emitter.stop()
      clearInterval(interval)
      tl.kill()
    })
  }

  useEffect(() => {
    if (app && ready && isOpening) {
      ref.current.on("pointertap", onPointerClick)
    }
    
    return () => {
        if (ref.current) ref.current.off("pointertap", onPointerClick)
    }
  }, [isOpening])

  function generateEllipticCoordinates(_x = x, _y = y) {
    const numCoordinates = 360;
    const cycleRadiusX = 5; // Adjust these values as needed
    const cycleRadiusY = 15;  // to control the shape of the ellipse
    const coordinates = [];

    for (let i = 0; i < numCoordinates; i++) { // Note the change here to "<=" to include the last point
      const angle = (i / numCoordinates) * 2 * Math.PI;
      const __x = cycleRadiusX * Math.cos(angle) + _x;
      const __y = cycleRadiusY * Math.sin(angle) + _y;
      coordinates.push({ x: __x, y: __y });
    }

    return coordinates;
  }

  function initFloating() {
    const ellipticCoordinates = generateEllipticCoordinates(ref.current.x, ref.current.y);
    gsap.set(ref.current, { x: ellipticCoordinates[0].x, y: ellipticCoordinates[0].y });
    floatingAnimationRef.current = gsap.to(ref.current, {
        duration: 7, // Duration of the animation
        ease: "none", // Easing function for smooth motion
        repeat: -1, // Repeat the animation indefinitely
        motionPath: {
            path: ellipticCoordinates,
            autoRotate: false // No rotation of the element
        }
    });
  }

  useEffect(() => {
    if (cardsMinted || revertOpening) {
      setPackText(`x${openAmount}`)
      ref.current.x = app.screen.width / 2;
      ref.current.y = app.screen.height / 2;
      gsap.to(ref.current, {
        pixi: {
          x: revertOpening ? app.screen.width * 0.08 : app.screen.width * 0.5,
          y: revertOpening ? app.screen.height * 0.2 : app.screen.height * 0.5,
          scaleX: 1,
          scaleY: 1
        },
        duration: 1,
        onComplete: () => {
            if (revertOpening) {
              setRevertOpening(false)
              setReset()
              setPackText(`My Packs: ${Math.round(packsBalance * 100) / 100}`)
              // gsap.to(ref.current, { x: app.screen.width * 0.08, y: app.screen.height * 0.2, duration: 1, onComplete:() => initFloating() })
              return
            }
            initFloating()
        }
      })
      }
  }, [cardsMinted, revertOpening])

  useEffect(() => {
    if (ready && app) {
      ref.current.interactive = true;
      console.log(" *** initFloating ***")
      initFloating()

      ref.current.on("pointerover", onCardMouseOver)
      ref.current.on("pointerout", onCardMouseOut)
      ref.current.on("pointerdown", onPointerDown)
    }

    return () => {
      if (ref.current) {
          // floatingAnimation.stop()
        ref.current.off("pointerover", onCardMouseOver)
        ref.current.off("pointerout", onCardMouseOver)
        ref.current.off("pointerdown", onPointerDown)
      }
    };
  }, [ready, app])

  return (
    <Container scale={1}>
      <Container ref={ref} x={x} y={y}>
          <Container scale={0.5} ref={magicAuraContainer}></Container>
          <Container ref={packContainerRef}>
            <Text
              text={packText}
              anchor={{x: 0.5, y: 0.5 }}
              x={app.screen.width * 0.03}
              y={-app.screen.height * 0.1}
              style={{
                fill: "white",
                fontFamily: "Cinzel",
                fontStyle: "bold",
                fontSize: scale * 150
              }}
            />
            <Sprite 
              anchor={[0.5, 0.5]} 
              ref={packSpriteRef}
              texture={PIXI.utils.TextureCache['alpha-pack']}
              scale={scale * 2}
              x={app.screen.width * 0.03}
              y={app.screen.height * 0.01}
            >
            </Sprite>
          </Container>
      </Container>
      {
          opened && app && cardsMinted && cardsMinted.length > 0 &&
          <Cards 
              setScale={scale}
              ref={cardsContainerRef}
              opened={opened} 
              app={app} 
              cardsMinted={cardsMinted}
              revealAll={revealAll}
              ready={ready}
          />
      }
    </Container>
  )
});

Pack.displayName = "Pack";

export default Pack;