import { useState, useEffect } from 'react';
import { Button, Modal, Box, Typography } from '@mui/material';

function formatDateToMMDDYY(date) {
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const year = String(date.getFullYear()).slice(-2);

  return `${month}-${day}-${year}`;
}

const UserModal = ({ player, setPlayer, sendMessage }) => {
  const [playerName, setPlayerName] = useState('');
  const [playerRating, setPlayerRating] = useState('');
  const [memberSince, setMemberSince] = useState('');

  useEffect(() => {
    if (player) {
      setPlayerName(player.name ? player.name : player.address);
      setPlayerRating(player.elo);
      setMemberSince(formatDateToMMDDYY(new Date(player.createdAt)));
    }
  }, [player]);

  const handleClose = () => setPlayer(null);

  const handleRequestChallenge = () => {
    const msg = JSON.stringify({ type: 'challengeRequest', data: { address: player.address } });
    sendMessage(msg);
    handleClose();
  };

  return (
    <Modal
      open={!!player}
      onClose={handleClose}
      aria-labelledby="user-modal-title"
      aria-describedby="user-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          color: 'white', // Set the font color to white
          backgroundColor: '#333', // Optional: Dark background for contrast
        }}
      >
        <Typography id="user-modal-title" variant="h6" component="h2" sx={{ color: 'white' }}>
          {playerName}
        </Typography>
        <Typography id="user-modal-description" sx={{ mt: 2, color: 'white' }}>
          Rating: {playerRating}
        </Typography>
        <Typography id="user-modal-description" sx={{ mt: 2, color: 'white' }}>
          Member since: {memberSince}
        </Typography>
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
          <Button disabled variant="contained" color="primary">
            Request Friendship
          </Button>
          <Button variant="contained" color="secondary" onClick={handleRequestChallenge}>
            Request Challenge
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default UserModal;
