import { forwardRef, useRef, useEffect, useState } from "react";
import { Container } from "react-pixi-fiber";
import Card from "./card"
import ErrorBoundary from "./ErrorBoundary.jsx"

const OpponentGodCard = forwardRef(
  function OpponentGodCard({ ready, trigger, app, websocketData, scale, particlesDefaultConfig }, godRef) {

    const [opponentGodData, setOpponentGodData] = useState(null);
    const containerRef = useRef([]); // Container for card refs

    // const gods = units.filter(el => el.type == "Summoner")

    function adjust() {
      if (godRef && godRef.current) {
        godRef.current.containerRef.current.x = app.screen.width * 0.8;
        godRef.current.containerRef.current.y = app.screen.height * 0.2;
      }
    }

    useEffect(() => {
      // When websocketData is updated, check for the opponent's god card
      const opponentSeat = websocketData.mySeat === 'p1' ? 'p2' : 'p1';
      const opponentGodCardId = websocketData[opponentSeat]?.board?.god;
      // console.log("opponentGodCardId", opponentGodCardId)
      if (opponentGodCardId && !opponentGodData) {

        // TODO // you need to find opponent god in collection instead of units

        const godData = websocketData[websocketData.opponentSeat].board.god;
        // console.log("Setting opponent god data", godData)
        setOpponentGodData(godData);
      }
    }, [websocketData[websocketData?.opponentSeat]?.board?.god, opponentGodData]);

    useEffect(() => {
      if (app && ready && godRef && godRef.current && opponentGodData) {
        // console.log("Placing opponent God")
        godRef.current.placeGod()
      }      
    }, [ready, app, opponentGodData])

    useEffect(() => {
      if (trigger) {
        adjust()
        // resetTrigger(); // Reset the trigger after executing
      }
    }, [trigger]);

    return (
      <ErrorBoundary app={app} scale={scale}>
      <Container ref={containerRef} scale={1} x={0} y={0} zIndex={1}>
        {opponentGodData && (
          <Card
            setScale={scale}
            ref={godRef}
            data={opponentGodData}
            ready={ready}
            isOpponent={true}
            app={app}
            round={websocketData.round}
            particlesDefaultConfig={particlesDefaultConfig}
          />
        )}
      </Container>
      </ErrorBoundary>
    )
  }
)

export default OpponentGodCard