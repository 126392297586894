
import { ChainIds, WebWalletConnectKoinos, Methods } from "@armana/walletconnect-koinos-sdk-js"
import {cdnUrl} from "../constants.js";

const network = import.meta.env.VITE_NODE_ENV == "development2" ? ChainIds.Harbinger : ChainIds.Mainnet;

console.log(network)

const metadata = {
  name: 'Lords Forsaken - App',
  description: 'p2e mythologycal NFT game',
  url: 'https://app.lordsforsaken.com', // origin must match your domain & subdomain
  icons: [`${cdnUrl}/logos-icons/main2.png`]
}

const walletConnectKoinos = new WebWalletConnectKoinos(
  {
    projectId: import.meta.env.VITE_WALLET_CONNECT_ID,
    // your application information
    metadata,
    modalOptions: {
      explorerRecommendedWalletIds: [
        'de9e8421961e35c5e35cb63e3cd7be9af328a62c7b5a11f95ca116bf128a7424'
      ],
      enableExplorer: true,
    },
  }
)

export async function getAccounts() {
  return new Promise((resolve) => {
    setTimeout(() => {
      return walletConnectKoinos.connect(
        [network],
        [
          Methods.ReadContract,
          Methods.SignAndSendTransaction,
          Methods.PrepareTransaction,
          Methods.WaitForTransaction,
          Methods.PrepareTransaction,
          Methods.SignTransaction,
          Methods.SignMessage
        ]
      ).then(_accounts => resolve(_accounts))
    }, 1000)
  })
}

export function getSigner(address) {
  return walletConnectKoinos.getSigner(address);
}