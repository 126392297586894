import * as PIXI from "pixi.js";
import { gsap } from "gsap";
import { PixiPlugin, MotionPathPlugin } from "gsap/all";
import * as playerCollectionUtils from "./collection"

// register the plugin
gsap.registerPlugin(PixiPlugin, MotionPathPlugin);

// give the plugin a reference to the PIXI object
PixiPlugin.registerPIXI(PIXI);

function tickGSAPWhileHidden(value) {
  if (value === false) {
    document.removeEventListener("visibilitychange", tickGSAPWhileHidden.fn);
    return clearInterval(tickGSAPWhileHidden.id);
  }
  const onChange = () => {
    clearInterval(tickGSAPWhileHidden.id);
    if (document.hidden) {
      gsap.ticker.lagSmoothing(0); // keep the time moving forward (don't adjust for lag)
      tickGSAPWhileHidden.id = setInterval(gsap.ticker.tick, 500);
    } else {
      gsap.ticker.lagSmoothing(500, 33); // restore lag smoothing
    }
  };
  document.addEventListener("visibilitychange", onChange);
  tickGSAPWhileHidden.fn = onChange;
  onChange(); // in case the document is currently hidden.
}

tickGSAPWhileHidden(true);

function absoluteCoords (container) {
  return {
    x: container.parent.x + container.x,
    y: container.parent.y + container.y
  }
}

function wait(ms = 1000) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, ms)
  })
}

function trimAddress(address, length = 6) {
  // console.log("trimAddress", address)
  return `${address.substring(0, length)}...${address.substring(address.length - length)}`;
}


export { 
  gsap, 
  absoluteCoords, 
  wait, 
  trimAddress, 
  playerCollectionUtils
}
