import { useEffect, useState, useRef } from 'react';
import { Text } from 'react-pixi-fiber';
import * as PIXI from 'pixi.js';

const BlinkingText = ({ websocketData, app, ready, pointerDown, combatBusy, isMyTurn, isReplay }) => {

  const [alpha, setAlpha] = useState(1);
  const [message, setMessage] = useState(null)
  const warningRef = useRef()
  const [warningMessage, setWarningMessage] = useState("Not enough Mana")

  const style = new PIXI.TextStyle({
      fontFamily: 'Almendra',
      fontSize: 36,
      fontStyle: 'italic',
      fontWeight: 'bold',
      fill: ['#ffffff', '#00ff99'], // gradient
      stroke: '#4a1850',
      strokeThickness: 5,
      dropShadow: true,
      dropShadowColor: '#000000',
      dropShadowBlur: 4,
      dropShadowAngle: Math.PI / 6,
      dropShadowDistance: 6,
      wordWrap: true,
      wordWrapWidth: 440,
      lineJoin: 'round',
  });

  useEffect(() => {
    let interval;
    let increasing = true;

    if (app && ready) {
      if (isReplay) {
        setMessage("Replay")
        interval = setInterval(() => {
          setAlpha(prevAlpha => {
            if (prevAlpha >= 1) increasing = false;
            else if (prevAlpha <= 0) increasing = true;

            return increasing ? prevAlpha + 0.15 : prevAlpha - 0.15;
          });
        }, 100); // Adjust for blinking speed
      } else if (combatBusy) {
        setMessage("⚔️Combat⚔️")
        setAlpha(1); // Start with full visibility
        interval = setInterval(() => {
          setAlpha(prevAlpha => {
            const newAlpha = prevAlpha - 0.05; // Decrease alpha value
            return newAlpha < 0 ? 0 : newAlpha; // Ensure alpha doesn't go below 0
          });
        }, 100); // Adjust the fade-out speed
      } else if (pointerDown && pointerDown > websocketData[websocketData.mySeat].mana) {
        setWarningMessage("🔮Not enough Mana🔮")
        warningRef.current.alpha = 1;
        setTimeout(() => {
          warningRef.current.alpha = 0;
        }, 2000)
      } else if (pointerDown && websocketData[websocketData.mySeat].board.critters.length == 6) {
        warningRef.current.alpha = 1;
        setWarningMessage("🃏Reached max number of cards in board🃏")
        setTimeout(() => {
          warningRef.current.alpha = 0;
        }, 2000)
      } else if (!websocketData.winner) {
        let message;
        if (!isMyTurn) {
          message = "⏳Waiting for opponent...⏳"
        } else {
          switch (websocketData.round) {
            case (2):
              message = "Pick your God"
              break
            case (3): 
              message = "Discard two monsters"
              break;
            default:
              message = "Your turn..."
              break
          }
        }
        setAlpha(1)
        setMessage(message)

        interval = setInterval(() => {
          setAlpha(prevAlpha => {
            if (prevAlpha >= 1) increasing = false;
            else if (prevAlpha <= 0) increasing = true;

            return increasing ? prevAlpha + 0.15 : prevAlpha - 0.15;
          });
        }, 100); // Adjust for blinking speed
      } else if (websocketData.winner == websocketData.mySeat) {
        setAlpha(1)
        setMessage("You Win!")
      } else if (websocketData.winner == 'draw') {
        setAlpha(1)
        setMessage("Draw")
      } else if (websocketData.winner == websocketData.opponentSeat) {
        setAlpha(1)
        setMessage("You Lose!")
      } else {
        setAlpha(0); // Hide text when it's not the player's turn
      }
    } 


    return () => {
      if (interval) clearInterval(interval)
    }
  
  }, [isMyTurn, combatBusy, pointerDown, ready, websocketData.turn]);

  return (
    <>
      <Text
        text={message}
        anchor={new PIXI.Point(0.5, 0.5)}
        x={app.screen.width / 2}
        y={app.screen.height * 0.1}
        style={style}
        alpha={alpha}
        zIndex={20}
      />
      <Text
        ref={warningRef}
        text={warningMessage}
        anchor={new PIXI.Point(0.5, 0.5)}
        x={app.screen.width / 2}
        y={app.screen.height * 0.7}
        style={style}
        alpha={0}
        zIndex={20}
      />
    </>
  );

};

export default BlinkingText;
