
import { useRef, useState, Fragment } from 'react';
import Grid from '@mui/material/Grid2'; // Grid version 2
import { cdnUrl } from "../constants.js";
import Box from '@mui/material/Box';
import Ranking from "../components/Ranking/Ranking.jsx";
import TableTabs from "../components/New/TableTabs.jsx";
import LeftGrid from "../components/New/LeftGrid.jsx";
import RightGrid from "../components/New/RightGrid.jsx";
import { backgroundImgStyle } from "../components/New/Styles.jsx";
import Matchmaking from "../components/New/Matchmaking.jsx";
import Matchfound from "../components/New/Matchfound.jsx";
import HistoryTable from "../components/Menu/HistoryTable"
import UsernameModal from "../components/Username/UsernameModal.jsx";
import UsernameModalMobile from "../components/Username/UsernameModalMobile.jsx";
// import { nodeEnv } from "../constants";
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

function New({
  ranking,
  history,
  sendMessage,
  isSearching,
  setIsSearching,
  gameStart,
  websocketData,
  waitingForOpponent,
  showCivs,
  isReplay,
  colors,
  handleUsernameSubmit,
  nicknameModal,
  setNicknameModal,
  accountData,
  handleLogin,
  address,
  setRoute
}) {
  
  const [activeTab, setActiveTab] = useState('Leaderboard');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const gridRef = useRef(null); // Create a ref for the Grid

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const spacing = isSmallScreen ? 2 : 5;

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };


  return (
    <Box>
      {
        !isSmallScreen &&
        <UsernameModal
          open={nicknameModal}
          onClose={() => setNicknameModal(false)}
          onSubmit={(username) => {
            handleUsernameSubmit(username).then(() => setOpenSnackbar(true))
          }}
        />
      }
      {
        isSmallScreen &&
          <UsernameModalMobile
            open={nicknameModal}
            onClose={() => setNicknameModal(false)}
            onSubmit={(username) => {
              handleUsernameSubmit(username).then(() => setOpenSnackbar(true))
            }}
          />
      }
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message="Username saved!"
        action={
          <Fragment>
            <IconButton size="small" color="inherit" onClick={handleCloseSnackbar}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Fragment>
        }
      />
      <div style={{
        backgroundColor: '#0A0A0C',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100%',
        zIndex: -1,
        filter: 'brightness(1.5)', // Adjust the brightness (1 is the original, 1.5 is 50% brighter)
      }}></div>

      <div style={{
        backgroundColor: '#0A0A0C',
        backgroundImage: `url("${cdnUrl}/battle-menu/background.png")`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top',
        backgroundSize: 'cover', // or 'contain' based on your needs
        position: 'absolute',
        top: '55%',
        left: 0,
        width: '100vw',
        height: '624px',
        zIndex: -1,
        filter: 'brightness(1.5)', // Adjust the brightness (1 is the original, 1.5 is 50% brighter)
      }}></div>

      <Matchmaking 
        open={!gameStart && isSearching} 
        setIsSearching={setIsSearching}
        sendMessage={sendMessage}
      />
      <Matchfound 
        websocketData={websocketData} 
        gameStart={gameStart} 
        sendMessage={sendMessage}
        waitingForOpponent={waitingForOpponent}
        showCivs={showCivs}
        isReplay={isReplay}
        colors={colors}
      />

      <Grid 
        container 
        spacing={spacing}
        ref={gridRef} // Attach the ref to the Grid
        style={{
          ...backgroundImgStyle,
          maxWidth: '1500px', // Limit the width
          width: isSmallScreen ? '90%' : '85%',
          margin: isSmallScreen ? '120px auto 0' : '180px auto 0',
          padding: '20px'
        }}
      >

        <Grid size={{ xs: 12 }} >
          <img
            src={isSmallScreen ? `${cdnUrl}/app/mobile/battle/separator-top.svg` : `${cdnUrl}/battle-menu/separator1.svg`}
            alt="divider"
            style={{ width: '100%', height: 'auto' }}
          />
        </Grid>
        
        {
          !isSmallScreen &&
          <>
            {/* Left Grid */}
            <LeftGrid 
              accountData={accountData}
              sendMessage={sendMessage}
              setIsSearching={setIsSearching}
              handleLogin={handleLogin}
              address={address}
              setRoute={setRoute}
              spacing={spacing}
            />

            {/* Right Grid */}
            <RightGrid 
              sendMessage={sendMessage}
              setIsSearching={setIsSearching}
              handleLogin={handleLogin}
              address={address}
              spacing={spacing}
            />
          </>
        }

        {
          isSmallScreen &&
          <>
            {/* Right Grid */}
            <RightGrid 
              sendMessage={sendMessage}
              setIsSearching={setIsSearching}
              handleLogin={handleLogin}
              address={address}
              spacing={spacing}
            />

            {/* Left Grid */}
            <LeftGrid 
              accountData={accountData}
              sendMessage={sendMessage}
              setIsSearching={setIsSearching}
              handleLogin={handleLogin}
              address={address}
              setRoute={setRoute}
              spacing={spacing}
            />
          </>
        }

        <Grid size={{ xs: 12 }} >
          <img
            src={isSmallScreen ? `${cdnUrl}/app/mobile/battle/separator-bottom.svg` : `${cdnUrl}/battle-menu/separator1.svg`}
            alt="divider"
            style={{ width: '100%', height: 'auto' }}
          />
        </Grid>

        <Grid 
          size={{ xs: 12 }} 
          sx={{ display: 'flex', paddingTop: '0px' }} // Align items to the right
        >
          <TableTabs activeTab={activeTab} setActiveTab={setActiveTab}/>
        </Grid>

        {/* Bottom Grid */}
        <Grid size={{ xs: 12 }} sx={{ paddingTop: '10px', textAlign: 'center' }}>
          {activeTab == 'Leaderboard' && <Ranking rows={ranking} />}
          {activeTab == 'History' && <HistoryTable items={history} sendMessage={sendMessage}/>}
          {activeTab == 'Following' && <p style={{ color: '#EAC88C', fontFamily: 'Germania One', fontWeight: '400', fontSize: '48px' }}>Coming soon..</p>}
        </Grid>

      </Grid>
    </Box>
  );
}

export default New;
