
import { useEffect, useState, useRef } from "react";
import { Dialog, DialogContent, Typography, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import Grid from '@mui/material/Grid2'; // Grid version 2
import { cdnUrl } from "../../constants.js";
import './SimplePopup.css';
import { Tooltip } from '@mui/material';
import CoinsEffect from "./CoinsEffect.jsx";
import { useMediaQuery } from '@mui/material';

const Matchend = ({ dependenciesLoaded, backToMenu, isGameOver, websocketData, effectContainerRef }) => {
  const dialogRef = useRef(null); // Ref to the dialog
  const canvasDivRef = useRef(null);

  const [status, setStatus] = useState(null);
  const [eloDelta, setEloDelta] = useState(0);
  const [foilMultiplier, setFoilMultiplier] = useState(0);
  const [eloMultiplier, setEloMultiplier] = useState(0);
  const [faith, setFaith] = useState(0); // Assume these values are placeholders from the server
  const [animatedFaith, setAnimatedFaith] = useState(0);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const handleBackToMenu = () => {
    setTimeout(() => {
      setFaith(0)
    }, 300)
    if (effectContainerRef.current) effectContainerRef.current.destroy()
    backToMenu()
  };

  useEffect(() => {
    if (websocketData?.[websocketData?.mySeat]?.result?.faith) setFaith(websocketData[websocketData.mySeat].result.faith)
    if (websocketData?.[websocketData?.mySeat]?.result?.eloMultiplier) setEloMultiplier(websocketData[websocketData.mySeat].result.eloMultiplier + 1)
    if (websocketData?.[websocketData?.mySeat]?.result?.foilMultiplier) setFoilMultiplier(websocketData[websocketData.mySeat].result.foilMultiplier + 1)  
    if (websocketData?.[websocketData?.mySeat]?.result?.eloDelta) setEloDelta(websocketData[websocketData.mySeat].result.eloDelta)
  }, [websocketData])

  useEffect(() => {
    if (isGameOver) {
      setStatus(websocketData.winner === 'draw' ? 'Draw' : (websocketData.winner === websocketData.mySeat ? 'Victory' : 'Defeat'));
    }
  }, [isGameOver]);

  // useEffect(() => {
  //   if (websocketData.winner) console.log("DEBUGG", websocketData)
  // }, [websocketData.winner]);

  useEffect(() => {
    const targetFaith = parseFloat(faith).toFixed(3); // Final value to reach
    const duration = 4000; // Total duration in milliseconds (2 seconds)
    const increments = 100; // Number of increments
    const incrementValue = (parseFloat(targetFaith) / increments).toFixed(3); // Increment value per step
    const intervalTime = duration / increments; // Time per increment

    let currentFaith = 0; // Start from 0
    const interval = setInterval(() => {
      currentFaith += parseFloat(incrementValue);
      
      // Set the animatedFaith and stop if it reaches the target
      setAnimatedFaith(() => {
        if (currentFaith >= targetFaith) {
          clearInterval(interval); // Clear interval if target is reached
          return targetFaith; // Set to target value
        }
        return currentFaith.toFixed(3); // Update animated faith value
      });
    }, intervalTime); // Use calculated interval time

    return () => clearInterval(interval); // Cleanup on unmount
  }, [faith]);


  return (
    <div>
      <Dialog
        ref={dialogRef} // Set ref to dialog
        open={isGameOver}
        fullWidth
        maxWidth={false}
        PaperProps={{
          sx: {
            width: isSmallScreen ? '80%' : '70%',
            height: isSmallScreen ? '80%' : 'auto',
            maxHeight: '90vh',
            backgroundColor: '#101318',
            color: '#fff',
            margin: 0,
            border: '1px solid #E1B15E',
            position: 'relative',
            overflow: 'hidden',
            maxWidth: '1280px',
          },
        }}
      >
        {/* Background Image */}
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: '#0A0A0C',
          backgroundImage: `url("${cdnUrl}/app/matchmaking/combat.webp")`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          zIndex: 0,
        }}></div>

        <CoinsEffect 
          dependenciesLoaded={dependenciesLoaded} 
          dialogRef={dialogRef} 
          status={status}
          effectContainerRef={effectContainerRef}
          canvasDivRef={canvasDivRef}
        />

        {/* Content on top of the background */}
        <DialogContent sx={{ position: 'relative', zIndex: 2 }}>
          <Grid container size={{ xs: 12 }} spacing={2} justifyContent="center" alignItems="center" style={{ paddingTop: '5%' }}>

            {/* Status */}
            <Grid size={{ xs: 12 }} style={{ marginBottom: '0%' }}>
              <Typography 
              component="h2" 
              style={{ 
                color: '#937341', 
                fontFamily: 'Germania One', 
                fontWeight: '400', 
                fontSize: isSmallScreen ? '24px' : '42px',
                letterSpacing: '-2%', 
                textAlign: 'center' 
              }}>
                {status}
              </Typography>
            </Grid>

            <Grid size={{ xs: 12 }} style={{ marginBottom: '1%' }}>
              <img
                src={`${cdnUrl}/app/referral/separator.svg`}
                alt="divider"
                style={{ width: '100%', height: 'auto', position: 'relative', zIndex: 3 }}
              />
            </Grid>

            {/* Breakdown Title */}
            <Grid size={{ xs: 12 }} display="flex" justifyContent="center" style={{ marginBottom: '30px', fontFamily: 'Germania One', fontWeight: '400', letterSpacing: '-2%', lineHeight: '28px', color: '#937341', fontSize: '24px' }}>
              Breakdown
            </Grid>

            {/* Breakdown Table */}
            <Grid size={{ xs: 12 }} 
              display="flex" 
              justifyContent="center" 
              alignItems="center"
            >
              <TableContainer style={{ width: isSmallScreen ? '100%' : '50%', margin: 'auto' }}>
                <Table sx={{ minWidth: 250 }}>
                  <TableBody>

                    {/* Faith Payout Row */}
                    <TableRow>
                      <TableCell 
                        style={{ 
                          color: '#E1B15E', 
                          fontFamily: 'Poppins', 
                          borderBottom: 'none', 
                          fontSize: isSmallScreen ? '12px': '24px'
                        }}>
                        Payout
                        <Tooltip title={`Elo multiplier x${eloMultiplier} Foil multiplier x${foilMultiplier}`} arrow>
                          <span style={{ marginLeft: '4px', textDecoration: 'underline', cursor: 'pointer' }}>?</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell 
                        align='right' 
                        style={{ 
                          color: '#E1B15E', 
                          fontFamily: 'Poppins', 
                          borderBottom: 'none', 
                          fontSize: isSmallScreen ? '12px': '24px'
                        }}>
                        $FAITH {animatedFaith}
                        <img 
                          src={`${cdnUrl}/faith-token/faith-white-fish.png`} 
                          width={isSmallScreen ? '15px' : '30px' } 
                          style={{ marginLeft: '10px', verticalAlign: 'middle' }} 
                          alt="Faith Token" 
                        />
                      </TableCell>
                    </TableRow>

                    {/* Elo Change Row */}
                    <TableRow>
                      <TableCell 
                      style={{ 
                        color: '#E1B15E', 
                        fontFamily: 'Poppins', 
                        borderBottom: 'none',
                        fontSize: isSmallScreen ? '12px': '24px'
                      }}>
                        Elo Change
                      </TableCell>
                      <TableCell 
                      align="right" 
                      style={{ 
                        color: '#E1B15E', 
                        fontFamily: 'Poppins', 
                        borderBottom: 'none',
                        fontSize: isSmallScreen ? '12px': '24px'
                      }}>
                        {eloDelta > 0 ? `+${eloDelta}` : `${eloDelta}`}
                      </TableCell>
                    </TableRow>

                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid xs={12} display="flex" justifyContent="center" style={{ marginTop: '20px' }}>
              <button
                className="image-button"
                style={{
                  maxWidth: '100%',
                  width: isSmallScreen ? '100px' : '150px', // Adjust width for mobile
                  height: isSmallScreen ? '25px' : '50px',
                  fontSize: isSmallScreen ? '12px' : '20px', // Adjust font size for mobile
                  margin: '0 auto', // Center button horizontally
                  // padding: isSmallScreen ? '8px 16px' : '10px 20px', // Adjust padding for mobile
                  overflow: 'hidden',
                  textOverflow: 'ellipsis', // Ensure text stays within bounds
                }}
                onClick={handleBackToMenu}
              >
                Back to Menu
              </button>
            </Grid>

          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Matchend;

