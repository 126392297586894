/* eslint-disable */
import { Stage, Container, Text } from "react-pixi-fiber";
import * as PIXI from "pixi.js";
import { useEffect, useState, useRef } from "react";
import { Dialog, DialogContent, Typography, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import Grid from '@mui/material/Grid2'; // Grid version 2
import { cdnUrl, getParticlesDefaultConfig } from "../../constants.js";
import './SimplePopup.css';
import { customPixiParticles } from 'custom-pixi-particles';
import { loadFx } from "../../loaders/loadFx.js";
import { Tooltip } from '@mui/material';
import { coinsAudio, randomSlot } from "../../audio.js";



function CoinsEffect({ dependenciesLoaded, dialogRef, status, effectContainerRef }) {
	const [fxLoaded, setFxLoaded] = useState(false);
	const canvasDivRef = useRef(null); // New reference for canvas-div
	// const effectContainerRef = useRef(null);
	const particles = useRef(null);
	useEffect(() => {
	  loadFx().then(() => setFxLoaded(true));
	}, []);

	useEffect(() => {
	  if (dependenciesLoaded && fxLoaded && status == 'Victory') {
	    const app = new PIXI.Application({
	      backgroundAlpha: 0,
	      backgroundColor: 'white', // Set transparent background
	      resolution: window.devicePixelRatio || 1,
	      autoDensity: true,
	      // view: document.createElement('canvas'), // Create a canvas
	      // width: window.innerWidth
	    });

	    canvasDivRef.current.appendChild(app.view); // Append the canvas to the div

	    // Set canvas size to match the dialog size
	    const dialogRect = dialogRef.current.getBoundingClientRect();
	    app.renderer.resize(dialogRect.width, dialogRect.height);

	    const config = getParticlesDefaultConfig().coinsRing;
	    console.log("config", getParticlesDefaultConfig())
	    effectContainerRef.current = new PIXI.Container();
	    // effectContainerRef.current.position.set(app.width / 2, app.height / 2)
	    effectContainerRef.current.scale.set(1);
	    app.stage.addChild(effectContainerRef.current);

	    particles.current = customPixiParticles.create(config);
	    effectContainerRef.current.addChild(particles.current);
	    particles.current.updatePosition({ x: 0, y: 0 });
	    particles.current.play();
	    setTimeout(() => {
	    	particles.current.stop()
	    }, 3000) 
	    setTimeout(() => {
	    	coinsAudio.play()
	    }, 1000)
	    randomSlot()
	    return () => {
	      // Cleanup the PIXI application on component unmount
	      app.destroy(true, { children: true });
	    };
	  }
	}, [dependenciesLoaded, fxLoaded, status]);

	return <div id="canvas-div" ref={canvasDivRef} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 10 }}></div>
}

export default CoinsEffect;