import { forwardRef, useRef, useEffect, useState } from "react";
import { Container } from "react-pixi-fiber";
import Card from "../card";
import adjust from "./adjust";
import { gsap, wait } from "../../utils";
import ErrorBoundary from "../ErrorBoundary.jsx";

const HeroCritterCards = forwardRef(({ isReplay, discarded, setDiscarded, ready, app, setDiscardedCards, trigger, slotRef, pointerDown, pointerUp, godRef1, godRef2, sendMessage, websocketData, acknowledgeAction, isPlayReady, scale, setPlayReady, onDragStart }, cardRefs) => {

  const [heroHand, setHeroHand] = useState([]);
  const addToHand = (newVal) => setHeroHand((oldVal) => [...oldVal, newVal]);
  const removeFromHand = (tokenIdToRemove) => {
    setHeroHand((oldHand) => oldHand.filter((item) => item.tokenId !== tokenIdToRemove));
  };
  const containerRef = useRef([]);
  const addDiscarded = (newVal) => setDiscarded((oldVal) => [...oldVal, newVal]);
  const [slotCoords, setSlotCoords] = useState([])

  function sort() {
    const { cardHeight, cardWidth } = adjust(app, containerRef, discarded)
    
    const centerRectangle1 = slotRef.current.children.find(el => el.isHero).children.find(el => el.role == "front")
    const supportRectangle1 = slotRef.current.children.find(el => el.isHero).children.find(el => el.role == "support")

    const slotCoords = []
    slotCoords.push({
        x: Math.round((centerRectangle1.x + cardWidth / 2) * 100) / 100,
        y: Math.round((centerRectangle1.y + cardHeight / 2) * 100) / 100
    })
    // Setting slot coords
    for (let i = 0; i < 5; i++) {
      slotCoords.push({
        x: Math.round((supportRectangle1.x + (4.5 - i) * cardWidth*1.1) * 100) / 100,
        y: Math.round((supportRectangle1.y + cardHeight / 2) * 100) / 100
      })
    }
    setSlotCoords(slotCoords)
  }

  useEffect(() => {
    return () => {
      cardRefs.current = [];
      if (containerRef.current) containerRef.current.destroy()
    }
  }, [])

  useEffect(() => {
    if (ready && app) {
      sort()
    }
  }, [ready, app]);

  // This useEffect is in charge of adding new cards component 'hand' state variable
  useEffect(() => {
    const currentHand = websocketData[websocketData?.mySeat]?.hand.filter(card => !!card)
    if (currentHand?.length > 0) {
      for (let i = 0; i < currentHand.length; i++) {
        if (!heroHand.map(el => el.tokenId).includes(currentHand[i].tokenId)) {
          // console.log("Adding new card to hand", currentHand[i].name)
          addToHand(currentHand[i])
        }
      }
    }
  }, [websocketData[websocketData?.mySeat]?.hand]);

  // This useEffect is in charge of adding new cards to hand from deck every new turn
  useEffect(() => {
    if (heroHand.length > 0 && websocketData.round > 3) {
      cardRefs.current.filter(card => !!card && !card.state.destroyed && !card.state.inPlay).forEach((card, index) => {
        card.drawPosition(index)
      });
    }
  }, [heroHand])
  // This useEffect is in charge of adding new cards to hand from deck every new turn
  useEffect(() => {
    if (websocketData.round > 4 && !isPlayReady) {
      cardRefs.current.filter(card => !!card && !card.state.destroyed && !card.state.inPlay).forEach((card, index) => {
        card.drawPosition(index)
      });    
    }
  }, [isPlayReady])

  useEffect(() => {
    if (app && ready && websocketData?.requestAction?.length > 0 && websocketData?.requestAction.includes("discardMonsters")) {
      // cardRefs.current.forEach((cardRef => {
      //   console.log(cardRef.containerRef.current)
      // }))
      acknowledgeAction()
      const promises = [];
      for (let i = 0; i < cardRefs.current.length; i++) {
        const card = cardRefs.current[i];
        promises.push(card.intro(cardRefs.current.length))
      }
      Promise.all(promises).then(() => console.log("Critters loaded"))
    }
  }, [websocketData.requestAction]);

  useEffect(() => {
    let isCancelled = false; // Flag to indicate whether the effect has been cleaned up

    if (discarded.length == 2) {
      wait(500).then(() => {
        if (isCancelled) return; // Exit if the effect has been canceled
        for (let i = 0; i < discarded.length; i++) {
          console.log(`Removing from hand: ${discarded[i]}`)
          removeFromHand(discarded[i]);
        }
        setDiscardedCards(true);
        const msg = JSON.stringify({ type: "discardMonsters", data: { tokenIds: discarded } });
        sendMessage(msg);
        const cards = cardRefs.current.filter(card => !!card && !card.state.destroyed && !card.state.inPlay);
        const promises = [];
        cards.forEach((card, index) => {
          promises.push(card.drawPosition(index));
        });
        const parentContainerPositioning = new Promise((resolve) => {
          gsap.to(containerRef.current, { x: app.screen.width * 0.1, y: app.screen.height * 0.85, duration: 1.5, onComplete: () => { return resolve() } })
        })
        promises.push(parentContainerPositioning)
        Promise.all(promises).then(() => console.log("Cards loaded"))
      });
    }

    return () => {
      isCancelled = true; // Set the flag to true to indicate cleanup
    };
  }, [discarded]);

  useEffect(() => {
      if (trigger) {
        sort()
      }
  }, [trigger]);

  return (
    
    <Container 
      ref={containerRef} 
      scale={1} 
      x={0} 
      y={0} 
      zIndex={2}
    >
      {slotCoords.length > 0 &&
        heroHand.map((critter, i) => {
          return (
            <ErrorBoundary key={`error-${critter.tokenId}`} app={app} scale={scale}>
              <Card
                key={critter.tokenId}
                ref={(el) => (cardRefs.current[i] = el)}
                data={critter}
                setScale={scale}
                ready={ready}
                index={i}
                discarded={discarded}
                addDiscarded={addDiscarded}
                app={app}
                slotRef={slotRef}
                godRef1={godRef1}
                godRef2={godRef2}
                pointerDown={(mana) => pointerDown(mana)}
                pointerUp={pointerUp}
                isOpponent={false}
                sendMessage={sendMessage}
                slotCoords={slotCoords}
                turn={websocketData?.turn}
                round={websocketData?.round}
                mana={websocketData[websocketData?.mySeat]?.mana}
                numberOfCritters={websocketData[websocketData?.mySeat]?.board?.critters?.length}
                isPlayReady={isPlayReady}
                trigger={trigger}
                onDragStart={onDragStart}
                winner={websocketData?.winner}
                setPlayReady={setPlayReady}
                isReplay={isReplay}
              />
            </ErrorBoundary>
          );
        })
      }
    </Container>
    
  );
});

HeroCritterCards.displayName = "HeroCritterCards";

export default HeroCritterCards;