import { forwardRef } from 'react';
import { Container } from 'react-pixi-fiber';
import Standard from './cardRenders/Standard'; // Import the new component
import { getUnits } from "../../../constants"

// import * as PIXI from "pixi.js";
// import { gsap, absoluteCoords, wait } from '../../utils';
// import { GlowFilter } from '@pixi/filter-glow';
// import { customPixiParticles } from 'custom-pixi-particles'
// const cdnUrl = import.meta.env.VITE_CDN_URL;

const Card = forwardRef(({ ready, setScale, card, x, y }, ref) => {
  const transformObj = obj => Object.fromEntries(Object.entries(obj).map(([k, v]) => [k, Array.isArray(v) ? (v.length === 0 ? v : Array.isArray(v[0]) ? v[0] : v[0]) : v]));

  let data = JSON.parse(JSON.stringify(getUnits().find(el => el.cardId == card.cardId)))
  data.stats = transformObj(data.stats)


  return (
    <Container ref={ref} x={x} y={y}>
      <Standard
          repeated={card.repeated}
          card={card}
          baseStats={data.stats}
          data={data}
          state={data.stats}
          ready={ready}
          setScale={setScale}
          level={0} // Or the appropriate level
          visible={false}
          isPlayReady={true}
          isIntro={false}
          onHover={false}
      />
    </Container>
  );
})

Card.displayName = "Card";

export default Card;

