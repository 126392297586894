import { forwardRef, useState, useEffect, useRef } from 'react';
import { Sprite, Container, Text } from 'react-pixi-fiber';
import * as PIXI from 'pixi.js';
import Melee from "../card/icons/Melee";
import Health from "../card/icons/Health";
import Magic from "../card/icons/Magic";
import Speed from "../card/icons/Speed";
import Ranged from "../card/icons/Ranged";
import Armor from "../card/icons/Armor";
import Mana from "../card/icons/Mana";
import TextWrapper from "../card/../TextWrapper"
import { getAbilities } from "../../constants";

const Standard = forwardRef(({ baseStatsLoaded, baseStats, data, state, ready, scale, level, visible, isIntro, onHover }, ref) => {
    
    const isGod = data.type == "Summoner";
    const critterImageRef = useRef();
    const iconScale = 1;
    const parchmentScale = 2.6;
    const frameScale = 3;
    const [frameWidth, setFrameWidth] = useState(null)
    const [frameHeight, setFrameHeight] = useState(null)
    const [iconWidth, setIconWidth] = useState(null)
    const [fontScale, setFontScale] = useState(null)
    const abilitiesRef = useRef([]);
    const abilities = getAbilities();

    useEffect(() => {
      if (ready) {

        setFrameWidth(PIXI.utils.TextureCache["standard-silver-1"].width * 3)
        setFrameHeight(PIXI.utils.TextureCache["standard-silver-1"].height * 3)
        setIconWidth(PIXI.utils.TextureCache['challenge'].width * 0.3)
        critterImageRef.current.isCritterSprite = true;
        critterImageRef.current.interactive = true;

        let grayscaleFilter = new PIXI.filters.ColorMatrixFilter();
        grayscaleFilter.desaturate(1);

        let colorMatrix = new PIXI.filters.ColorMatrixFilter();
        colorMatrix.brightness(0.7);
        // colorMatrix.contrast(1.2);

        // critterImageRef.current.tint = 0x808080; // Add grayish tint
        critterImageRef.current.filters = [grayscaleFilter, colorMatrix]; // Apply filters

        let stoneTexture = PIXI.utils.TextureCache[`standard-stone`]; // Load your stone texture
        let stoneSprite = new PIXI.Sprite(stoneTexture);
        stoneSprite.alpha = 0.5; // Make it semi-transparent
        stoneSprite.scale.set(frameScale)
        stoneSprite.anchor.set(0.5, 0.5)
        // Add the stone overlay as a child of the card sprite
        ref.current.addChild(stoneSprite);

      }
    }, [ready])

    useEffect(() => {
        const newScale = Math.min(1 / (scale * 3), 2)
        setFontScale(newScale)
    }, [scale])

    return (
        <Container ref={ref} scale={0.3} x={500} y={400} visible={visible} z-index={2}>
            <>
                <Sprite 
                    ref={critterImageRef}
                    texture={PIXI.utils.TextureCache[`standard-${data.foil == 1 ? 'silver' : 'gold'}-${data.cardId}`]} 
                    x={0} 
                    y={0} 
                    anchor={[0.5, 0.5]} 
                    visible={true}
                    scale={frameScale}
                />
                {
                    (isIntro || onHover) &&
                    <Container
                        x={frameWidth} 
                        y={0} 
                        scale={0.95}
                        z-index={2}
                    >
                        <Sprite 
                            texture={PIXI.utils.TextureCache['parchment']} 
                            x={0} 
                            y={0} 
                            anchor={[0.5, 0.5]} 
                            visible={true}
                            scale={parchmentScale}
                        />
                        {
                          data.stats.abilities.map((ability, index) => (
                            <Container key={ability}>
                                <Container ref={(el) => abilitiesRef.current[index] = el}>
                                    <Sprite 
                                        key={`ability-${ability}`} // Using the ability name (or key) as the key prop
                                        texture={PIXI.utils.TextureCache[`${ability.replace(/\s+/g, '-').toLowerCase()}`]} // Assuming the key can directly map to the texture cache
                                        x={-frameWidth*0.33} // Assuming the value object has x and y coordinates
                                        y={-frameHeight * 0.28 * (1 - index * fontScale * 0.7)}
                                        anchor={[0.5, 0.5]} 
                                        visible={true}
                                        scale={iconScale} // Assuming the value object has a scale property
                                    />
                                    <Text
                                      key={`title-${ability}`}
                                      text={abilities.find(el => el.name == ability).name}
                                      anchor={{x: 0, y: 0.5 }}
                                      x={-frameWidth/4.5}
                                      y={-frameHeight * 0.3 * (1 - index * fontScale * 0.7)}
                                      style={{
                                        fill: 'black',
                                        fontFamily: "Almendra",
                                        fontStyle: "bold",
                                        fontSize: 60 * fontScale
                                      }}
                                    />
                                </Container>
                            </Container>
                        ))                        
                      }

                      {
                        isGod && Object.entries(data.stats).filter(([stat, value]) => stat !== "abilities" && stat !== "mana" && value !== 0).map(([stat, value], index) => (
                          <Container key={stat}>
                            <Sprite 
                              key={stat} // Adding a key for each item when rendering a list
                              texture={PIXI.utils.TextureCache[`${stat == "attack" ? "melee" : stat}`]} 
                              x={-iconWidth*0.5}
                              y={frameHeight*0.4 * (-1.2 + (index + data.stats.abilities.length))}
                              anchor={{ x: 0.5, y: 0.5 }}
                              visible={true}
                              scale={0.5}
                            />
                            <Text
                              text={isGod ? (value > 0 ? `+${value}` : `${value}`) : value}
                              anchor={{x: 0.5, y: 0.5 }}
                              x={-iconWidth*0.5}
                              y={frameHeight*0.4 * (-1.2 + (index + data.stats.abilities.length))}
                              style={{
                                fill: 'white',
                                fontFamily: "Almendra",
                                fontStyle: "bold",
                                fontSize: 120 * fontScale
                              }}
                            />
                            <TextWrapper
                              key={`description-${stat}`}
                              text={"\n" + `${value > 0 ? "+" : "-"} ${value} ${stat} to every critter of ${value > 0 ? "friendly" : "enemy"} units`}
                              boxWidth={frameWidth*0.8}
                              boxHeight={frameHeight*0.5}
                              x={-frameWidth*0.4}
                              y={frameHeight*0.35 * (-1.2 + (index + data.stats.abilities.length))}
                              style={{
                                fill: 'black',
                                fontFamily: "Almendra",
                                fontStyle: "bold",
                                fontSize: 60 * fontScale
                              }}
                            />
                          </Container>
                        ))
                      }

                      {
                        <Text
                          text={'⭐'.repeat(data.level)}
                          anchor={{x: 0.5, y: 0.5 }}
                          x={-frameWidth * 0.2}
                          y={frameHeight*0.3}
                          style={{
                            fill: 'black',
                            fontFamily: "Almendra",
                            fontStyle: "bold",
                            fontSize: 100 * fontScale
                          }}
                        />
                      }

                    </Container>
                }
            </>
            {
                baseStats && baseStatsLoaded &&
                <>
                    <Melee 
                        baseValue={isGod ? baseStats?.attack : baseStats?.attack[level]}
                        data={data}
                        value={state.attack}
                        ready={ready}
                        scale={0.5}
                        standard={true}
                    />

                    {(isGod || state.health > 0) && (
                        <Health 
                            baseValue={isGod ? baseStats?.health : baseStats?.health[level]}
                            data={data}
                            value={state.health}
                            ready={ready}
                            scale={0.5}

                            standard={true}
                        />
                    )}

                    <Speed 
                        baseValue={isGod ? baseStats?.speed : baseStats?.speed[level]}
                        data={data}
                        value={state.speed}
                        ready={ready}
                        scale={0.5}
                        standard={true}
                    />

                    <Mana 
                        baseValue={isGod ? baseStats?.mana : baseStats?.mana[level]}
                        data={data}
                        value={state.mana}
                        ready={ready}
                        scale={0.25}
                        standard={true}
                    />

                    <Magic 
                        baseValue={isGod ? baseStats?.magic : baseStats?.magic[level]}
                        data={data}
                        value={state.magic}
                        ready={ready}
                        scale={0.5}
                        standard={true}
                    />

                    {(isGod || state.armor > 0) && (
                        <Armor 
                            baseValue={isGod ? baseStats?.armor : baseStats?.armor[level]}
                            data={data}
                            value={state.armor}
                            ready={ready}
                            scale={0.5}

                            standard={true}
                        />
                    )}

                    <Ranged 
                        baseValue={isGod ? baseStats?.ranged : baseStats?.ranged[level]}
                        data={data}
                        value={state.ranged}
                        ready={ready}
                        scale={0.5}
                        standard={true}
                    />
                </>
            }
        </Container>
    );
});

Standard.displayName = "Standard";

export default Standard;
