
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid2';
import StyleIcon from '@mui/icons-material/Style';

export default function IconButtons({ foil, setFoil, direction = 'row', size = 56, legend = false }) {

  const handleButtonClick = (foilType) => {
    setFoil(foilType);
  };

  const renderButton = (label, color, borderColor, backgroundColor) => (
    <>
      <Grid size={{xs: 'auto'}}>
        <IconButton
          aria-label={label}
          onClick={() => handleButtonClick(label.toLowerCase())}
          sx={{
            color: foil === label.toLowerCase() ? color : `rgba(${color}, 0.5)`, // Lower opacity when inactive
            backgroundColor: foil === label.toLowerCase() ? backgroundColor : 'rgba(0, 0, 0, 0.5)', // Lower opacity when inactive
            width: size,
            height: size,
            border: `2px solid ${borderColor}`,
            borderRadius: '50%',
            '&:hover': {
              backgroundColor: '#e0e0e0',
            },
          }}
        >
          <StyleIcon sx={{ fontSize: size / 2 }} />
        </IconButton>
      </Grid>
      {legend && (
        <Grid xs>
          <p>{label}</p>
        </Grid>
      )}
    </>
  );

  return (
    <Grid container spacing={0} direction={direction} sx={{ display: 'flex', justifyContent: 'left' }}>
      <Grid size={{ xs: 6 }} sx={{ display: 'flex', justifyContent: 'left' }}>
        {renderButton('Silver', '#097969', '#097969', '#000000')}
      </Grid>
      <Grid size={{ xs: 6 }} sx={{ display: 'flex', justifyContent: 'left' }}>
        {renderButton('Gold', '#FFD700', '#FFD700', '#000000')}
      </Grid>
    </Grid>
  );
}
