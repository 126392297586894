import { forwardRef, useRef, useEffect, useState } from "react";
import { Container } from "react-pixi-fiber";
import Card from "./card"
import ErrorBoundary from "./ErrorBoundary.jsx"

const GodCards = forwardRef(
  function GodCards({ ready, trigger, _setGodSelected, app, sendMessage, websocketData, acknowledgeAction, scale, particlesDefaultConfig }, godRef) {
    const [godSelected, setGodSelected] = useState(-1);
    const [gods, setGods] = useState([]);
    const containerRef = useRef([]); // Container for card refs

    // const gods = _units.filter(el => el.type == "Summoner")

    function adjust() {
      const godCard = godRef.current.find(el => !!el)
      if (godSelected > -1 && godCard && godCard.containerRef) {
        // console.log("Adjusting god position..")
        godCard.containerRef.current.x = app.screen.width * 0.15;
        godCard.containerRef.current.y = app.screen.height * 0.58;
      }
    }

    useEffect(() => {
      return () => {
        godRef.current = [];
        if (containerRef.current) containerRef.current.destroy()
      }
    }, [])

    useEffect(() => {
      if (app && ready) {
        adjust()
      }
    }, [ready, app])

    useEffect(() => {
      // console.log("websocketData gods useEffect", websocketData[websocketData?.mySeat]?.gods)
      // console.log(app , ready , gods.length == 0 , units , websocketData.isLoaded , websocketData.started , websocketData[websocketData.mySeat].gods , websocketData[websocketData.mySeat].gods.length > 0)
      if (app && ready && gods.length == 0 && websocketData.isLoaded && websocketData.started && websocketData[websocketData.mySeat].gods && websocketData[websocketData.mySeat].gods.length > 0) {
        // const _gods = JSON.parse(JSON.stringify(units.filter(el => websocketData[websocketData.mySeat].gods.includes(el.id))))
        setGods(websocketData[websocketData.mySeat].gods)
      }
    }, [app, ready, websocketData[websocketData?.mySeat]?.gods])

    useEffect(() => {
      // console.log("gods useEffect", gods)
      if (app && ready && gods.length > 0 && websocketData.isLoaded && websocketData.started) {
        if (websocketData.requestAction.includes("chooseGod")) {
          // godRef.current.forEach((cardRef => {
          //   console.log(cardRef.containerRef.current)
          // }))
          acknowledgeAction()
          const promises = [];
          setTimeout(() => {
            for (let i = 0; i < godRef.current.length; i++) {
              const card = godRef.current[i];
              // console.log("GODS INTRO DEBUG CARD", card)
              promises.push(card.intro(godRef.current.length))
            }
            Promise.all(promises).then(() => console.log("Gods loaded"))
          }, 500)
        }
      }
    }, [websocketData.requestAction, gods])

    useEffect(() => {
      if (app && godSelected > -1) {
        for (let i = godRef.current.length - 1; i > -1; i--) {
          const card = godRef.current[i]
          if (card && i !== godSelected) {
            console.log(`Destroying god with index ${i}`)
            card.destroy()
          } else {
            // console.log("godSelected DEBUG", card)
            _setGodSelected(card.data.tokenId)
            setGods([gods[godSelected]])
            const msg = JSON.stringify({ type: "chooseGod", data: { tokenId: card.data.tokenId } })
            sendMessage(msg)
            card.placeGod()
          }
        }
      }
    }, [godSelected])

    useEffect(() => {
        if (trigger) {
          adjust()
          // resetTrigger(); // Reset the trigger after executing
        }
    }, [trigger]);

    return (
      
      <Container ref={containerRef} scale={1} x={0} y={0} zIndex={1}>
        { gods && gods.length > 0 &&
          gods.map((god, i) => (
            <ErrorBoundary key={`error-${god.tokenId}`} app={app} scale={scale}>
            <Card
              key={god.tokenId}
              ref={el => godRef.current[i] = el}
              data={god}
              setScale={scale}
              ready={ready}
              index={i}
              setGodSelected={setGodSelected}
              godSelected={godSelected}
              isOpponent={false}
              app={app}
              round={websocketData.round}
              particlesDefaultConfig={particlesDefaultConfig}
            />
            </ErrorBoundary>
          ))
        }
      </Container> 
    )
  }
)

export default GodCards