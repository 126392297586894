import { useState, useEffect } from 'react';
import axios from "axios";
import Grid from '@mui/material/Grid2';
import { Button, Box } from '@mui/material';
import Gallery from "../components/Gallery.jsx";

import Roles from "../components/CollectionSidebar/Role.jsx";
import Rarities from "../components/CollectionSidebar/Rarities.jsx";
import Conditions from "../components/CollectionSidebar/Conditions.jsx";
import Foils from "../components/CollectionSidebar/Foils.jsx";
import Civs from "../components/CollectionSidebar/Civs.jsx";
import Divider from '@mui/material/Divider';
import DeckWarningModal from "../components/Collection/DeckWarningModal.jsx";
import { combineToUnique } from "../utils/collection";

const rarityMapper = {
  "1": "common",
  "2": "rare",
  "3": "epic",
  "4": "legendary"
};

const foilMapper = {
  "1": "silver",
  "2": "gold"
};

const civMapper = {
  "Yellow": "Egypt",
  "White": "Greece",
  "Purple": "Norse"
}

const colors = ["White", "Yellow", "Purple"];
const types = ["Summoner", "Monster"];

const dbApiUrl = import.meta.env.VITE_KOINOS_DB_API_URL;

function Collection({  setIsLoading, playerCollection, units, payload, setRefreshCollection }) {
  // console.log("*** Collection ***")
  const [foil, setFoil] = useState(['gold', 'silver']);
  const [role, setRole] = useState(['Monster', 'Summoner']);
  const [rarity, setRarity] = useState(['common', 'rare', 'epic', 'legendary']);
  const [color, setColor] = useState(['White', 'Yellow', 'Purple']);

  const [editDeck, setEditDeck] = useState(false);
  const [deckChanges, setDeckChanges] = useState([])
  const [enableDeckWarning, setEnableDeckWarning] = useState(false);
  const [deckEnabled, setDeckEnabled] = useState(true);
  const [simulation, setSimulation] = useState([]);
  const [conditions, setConditions] = useState({});
  const [combinedCards, setCombinedCards] = useState([])

  useEffect(() => {
    // console.log("playerCollection", playerCollection)
    // console.log("object vallues", combineToUnique(playerCollection))
    setCombinedCards(combineToUnique(playerCollection))
    setSimulation(combineToUnique(playerCollection))
  }, [playerCollection])

  // useEffect(() => {
  //   console.log("combinedCards", combinedCards)
  // }, [combinedCards])

  const discardChanges = () => {
    setDeckChanges([]);
  };

  const handleClose = () => {
    setEnableDeckWarning(false)
  }

  const handleConfirm = async () => {
    setIsLoading(true)
    setEnableDeckWarning(false)
    setDeckChanges([]);
    setEditDeck(false)
    await axios.post(`${dbApiUrl}/deckfilter`, { filters: deckChanges, payload })
    await setRefreshCollection(2000)
  }

  const commitChanges = async () => {
    if (!deckEnabled) return setEnableDeckWarning(true)
    setIsLoading(true)
    setDeckChanges([]);
    setEditDeck(false)
    await axios.post(`${dbApiUrl}/deckfilter`, { filters: deckChanges, payload })
    await setRefreshCollection(2000)
  };

  useEffect(() => {
    // console.log("deckChanges", deckChanges)
    if (deckChanges.length > 0) {
      setDeckEnabled(true)
      const simulation = combineToUnique(playerCollection).map(el => {
        const match = deckChanges.find(x => x.cardId == el.cardId && x.foil == el.foil)
        const deckEnabled = match ? match.enable : el.deckEnabled;
        return { ...el, deckEnabled  }
      })
      setSimulation(simulation)
    }
  }, [deckChanges])

  useEffect(() => {
    if (simulation.length > 0) {
      // console.log("SIMULATION CHANGED", simulation.filter(el => el.color == "Purple").length)
      for (let i = 0; i < colors.length; i++) {
        for (let j = 0; j < types.length; j++) {
          const _color = colors[i]
          const type = types[j]
          const condition = type == 'Summoner' ? 1 : 7;
          const conditionKey = `${civMapper[_color]} ${type == 'Summoner' ? 'Divinity' : 'Unit'}`
          const filtered = simulation.filter(el => el.color == _color && el.type == type && el.deckEnabled)
          const filteredUniqueCardId = [...new Map(filtered.map(item => [item.cardId, item])).values()];
          // console.log(`${color}-${type}`, filtered)
          // console.log(conditionKey, `below condition: ${filtered.length < condition}`)
          if (filteredUniqueCardId.length < condition) setDeckEnabled(false)
          setConditions(prevState => ({ ...prevState, [conditionKey]: filteredUniqueCardId.length }))
        }
      }
    }
  }, [simulation])

  // useEffect(() => {
  //   console.log("conditions", conditions)
  // }, [conditions])

  const editDeckHandler = () => {
    setEditDeck(!editDeck)
  }

  const cancelDeckHandler = () => {
    setEditDeck(false)
    discardChanges()
  }

  useEffect(() => {
    if (playerCollection && playerCollection.length > 0) {
      const newFilteredCollection = playerCollection.filter(el => {
        return foil.includes(foilMapper[el.foil]) && role.includes(el.type) && rarity.includes(rarityMapper[el.rarity]) && color.includes(el.color);
      });
      setCombinedCards(combineToUnique(newFilteredCollection));
    }
  }, [playerCollection, foil, role, rarity, color]);

  const addFoil = (newFoil) => {
    if (!foil.includes(newFoil)) {
      setFoil([...foil, newFoil]);
    }
  };

  const removeFoil = (foilToRemove) => {
    setFoil(foil.filter(f => f !== foilToRemove));
  };

  const addRole = (newRole) => {
    if (!role.includes(newRole)) {
      setRole([...role, newRole]);
    }
  };

  const removeRole = (roleToRemove) => {
    setRole(role.filter(r => r !== roleToRemove));
  };

  const addRarity = (newRarity) => {
    if (!rarity.includes(newRarity)) {
      setRarity([...rarity, newRarity]);
    }
  };

  const removeRarity = (rarityToRemove) => {
    setRarity(rarity.filter(r => r !== rarityToRemove));
  };

  const addColor = (newColor) => {
    if (!color.includes(newColor)) {
      setColor([...color, newColor]);
    }
  };

  const removeColor = (colorToRemove) => {
    setColor(color.filter(r => r !== colorToRemove));
  };


  // if (address && wallet === "MetaMask") {
  //   return (
  //     <div className="gallery">
  //       <center><h1>Linea Network integration coming soon..</h1></center>
  //     </div>
  //   );
  // }

  // if (!address) {
  //   return (
  //     <div className="gallery">
  //       <center><h1>You are not logged in</h1></center>
  //     </div>
  //   );
  // }

  return (
    <Box sx={{ flexGrow: 1, marginTop: '150px' }}>
      {
        <DeckWarningModal open={enableDeckWarning} handleConfirm={handleConfirm} handleClose={handleClose}/>
      }

      {/*Column #1*/}
      <Grid 
        container 
        direction="column" 
        size={{ xs: 12, xl: 2 }} 
        spacing={2} 
        sx={{ 
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            color: '#EAC88C',
            fontFamily: 'Poppins',
            borderRadius: '10px', 
            marginTop: '120px', 
            opacity: '1', 
            border: '2px solid #000',  // Adding border
            position: { md: 'fixed' },  // Fixing position from 'md' breakpoint onwards
            top: { md: '20px' },        // Adjust as needed
            left: { md: '0px' },       // Adjust as needed
            width: { md: '300px' },     // Adjusting width from 'md' breakpoint onwards
            height: { md: 'calc(100% - 100px)' }, // Adjusting height to fit the screen, minus margins
            overflowY: 'scroll', // Adding vertical overflow to make it scrollable
            padding: '20px'
        }}
      >
        <Grid container direction="row" spacing={2}>

          <Grid container size={{ xs: 12 }}>
            <Grid size={{ xs: 12 }}>
              <center><h5>Deck Conditions</h5></center>
            </Grid>
            <Grid size={{ xs: 12 }}>
              <Box style={{ border: '1px solid #937341', borderRadius: '8px' }}>
                <Conditions conditions={conditions}/>
              </Box>
            </Grid>
            <Grid size={{ xs: 12 }}>
              <center><h5>Deck Manager</h5></center>
            </Grid>
            <Grid size={{ xs: 6 }}>
              <Box sx={{ border: '1px solid #937341', borderRadius: '8px' }}>
                <Button 
                  onClick={editDeckHandler} 
                  disabled={editDeck}
                  sx={{ color: 'white' }} // Add this line to set the font color to white
                >
                  Edit
                </Button>
              </Box>
            </Grid>
            <Grid size={{ xs: 6 }}>
              <Box sx={{ border: '1px solid #937341', borderRadius: '8px' }}>
                <Button 
                  onClick={cancelDeckHandler} 
                  disabled={!editDeck}
                  sx={{ color: 'white' }} // Add this line to set the font color to white
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Grid size={{ xs: 12 }}>
            <Divider />
          </Grid>
          <Grid size={{ xs: 6 }}>
            <center><h5>Foils</h5></center>
            <Box sx={{ border: '1px solid #937341', borderRadius: '8px' }}>
              <Foils addFoil={addFoil} removeFoil={removeFoil} direction={'column'} size={40} legend={true}/>
            </Box>
          </Grid>
          <Grid size={{ xs: 6 }}>
            <center><h5>Roles</h5></center>
            <Box sx={{ border: '1px solid #937341', borderRadius: '8px' }}> 
              <Roles addRole={addRole} removeRole={removeRole} direction={'column'} size={40} legend={true}/>
            </Box>
          </Grid>
          <Grid size={{ xs: 12 }}>
            <center><h5>Rarities</h5></center>
            <Box sx={{ border: '1px solid #937341', borderRadius: '8px' }}> 
              <Rarities addRarity={addRarity} removeRarity={removeRarity} direction={'column'} size={40} legend={true}/>
            </Box>
          </Grid>
          <Grid size={{ xs: 12 }}>
            <center><h5>Civs</h5></center>
            <Box sx={{ border: '1px solid #937341', borderRadius: '8px' }}> 
              <Civs addColor={addColor} removeColor={removeColor} direction={'column'} size={40} legend={true}/>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      {/*Column #2*/}
      <Grid 
        size={{ xs: 12, sm: 10, xl: 10 }}  
        sx={{ 
          marginLeft: { md: '340px' }, // Adjusting margin from 'md' breakpoint onwards
          padding: '20px' 
        }}
      >
        {
          units && units.length > 0 &&
          <Gallery 
            combinedCards={combinedCards} 
            units={units} 
            editDeck={editDeck} 
            deckChanges={deckChanges}
            setDeckChanges={setDeckChanges}
            discardChanges={discardChanges}
            commitChanges={commitChanges}
          />
        }
      </Grid>
    </Box>
  );
}

export default Collection;
