import { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid2';
import StyleIcon from '@mui/icons-material/Style';

export default function IconButtons({ addFoil, removeFoil, direction = 'row', size = 56, legend = false }) {

  const [isSilver, setSilver] = useState(true)
  const [isGold, setGold] = useState(true)

  useEffect(() => {
    if (isSilver) {
      addFoil('silver')
    } else {
      removeFoil('silver')
    }
  }, [isSilver])

  useEffect(() => {
    if (isGold) {
      addFoil('gold')
    } else {
      removeFoil('gold')
    }
  }, [isGold])

  const renderButton = (label, color, borderColor, backgroundColor, clickHandler, state) => (
    <>
      <Grid size={{ xs: 'auto'}}>
        <IconButton
          aria-label={label}
          onClick={() => clickHandler(!state)}
          sx={{
            margin: '5px',
            color: state ? color : `rgba(${color}, 0.5)`, // Lower opacity when inactive
            backgroundColor: state ? backgroundColor : 'rgba(0, 0, 0, 0.5)', // Lower opacity when inactive
            width: size,
            height: size,
            border: `2px solid ${borderColor}`,
            borderRadius: '50%',
            '&:hover': {
              backgroundColor: '#e0e0e0',
            },
          }}
        >
          <StyleIcon sx={{ fontSize: size / 2 }} />
        </IconButton>
      </Grid>
      {legend && (
        <Grid size={{ xs: 'auto'}}>
          <p>{label}</p>
        </Grid>
      )}
    </>
  );

  return (
    <Grid container spacing={0} direction={direction} sx={{ display: 'flex', justifyContent: 'left' }}>
      <Grid size={{ xs: 6 }} sx={{ display: 'flex', justifyContent: 'left' }} alignItems="center">
        {renderButton('Silver', '#097969', '#097969', '#000000', setSilver, isSilver)}
      </Grid>
      <Grid size={{ xs: 6 }} sx={{ display: 'flex', justifyContent: 'left' }} alignItems="center">
        {renderButton('Gold', '#FFD700', '#FFD700', '#000000', setGold, isGold)}
      </Grid>
    </Grid>
  );
}
