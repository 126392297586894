import axios from "axios";

const payload = {
    "chainId": 59144,
    "inputTokens": [
        {
            "tokenAddress": "0x0000000000000000000000000000000000000000",
            "amount": "10000000000000000"
        }
    ],
    "outputTokens": [
        {
            "tokenAddress": "0x373d04A7F3594FF9011Af5084587182C0056721e",
            "proportion": 1
        }
    ],
    "userAddr": "0x82850252f67E19A3749eFb4eA8186AC0D19Dd43c",
    "slippageLimitPercent": "3",
    "sourceBlacklist": [],
    "simulate": true,
    "pathId": true,
    // "referralCode": 2514055506,
    "partnerFeePercent": 0
}

export function getFaithEthPrice() {
	// test for 0.01 ETH
	return axios.post(`https://api.odos.xyz/sor/quote/v2`, payload).then(res => 0.01 * 10**18 / res.data.outAmounts[0])
}

export function getEthereumPrice() {
  return axios.get('https://api.binance.com/api/v3/ticker/price', {
          params: {
              symbol: 'ETHUSDT'
          }
      }).then(res => res.data.price)
}

export function getPackFaithPrice() {
	return axios.get(`https://aggregator-api.kyberswap.com/linea/api/v1/routes?tokenIn=0x46BF60BD1d8e61fE8975B4122224f8ED81C1164f&tokenOut=0x373d04A7F3594FF9011Af5084587182C0056721e&amountIn=1000000000000000000&source=ramses&feeAmount=5&chargeFeeBy=currency_out&isInBps=true&feeReceiver=0x44376f8F52407ed66E6D3356535410A7DCd4652B
`).then(res => res.data.data.routeSummary.amountOut / 10 ** 18)
}

export async function getFaithUsdPrice() {
  const ethPrice = await getEthereumPrice()
  const faithEthPrice = await getFaithEthPrice()
  return ethPrice * faithEthPrice;
}

// (async () => {
// 	const ethPrice = await getEthereumPrice()
// 	console.log("ethPrice", ethPrice)
	
// 	const faithEth = await getFaithEthPrice()
// 	const faithUsd = faithEth * ethPrice
// 	console.log("faithUsd", faithUsd)

// 	const packFaithPrice = await getPackFaithPrice()
// 	console.log("packFaithPrice", packFaithPrice)
// })();