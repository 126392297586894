import React, { Component } from 'react';
import {  Container } from 'react-pixi-fiber';
import * as PIXI from "pixi.js";
import { gsap, absoluteCoords, wait } from '../../utils';
import { GlowFilter } from '@pixi/filter-glow';
import { customPixiParticles } from 'custom-pixi-particles'
import { reviveAudio, laststandAudio, armoredassaultAudio, frenzyAudio, stampedeAudio, paralyzeAudio, playRepairAudio, playEvilLaughAudio, attackAudio, magicAudio, magicAudio2, rangedAudio, shieldAudio, shieldAudio2, cardSelectAudio, dropAudio, meleeMissAudio, rangedMissAudio, cardBuffAudio, healingAudio } from '../../audio';
import Tactical from './cardRenders/Tactical'; // Import the new component
import Standard from './cardRenders/Standard'; // Import the new component
import { MagicEffect, Shield, Blood, GodBuff } from "../../effects"

import { getUnits, getParticlesDefaultConfig } from "../../constants";

const glowFilter = new GlowFilter({
    distance: 15,
    outerStrength: 2,
    color: 0xffc107, // Example: golden color
    quality: 0.5,
});

class Card extends Component {
    constructor(props) {
        super(props);
        // console.log("NEW CARD", this.props.data.name)
        // console.log(this.props.round)
        this.state = {
            destroyed: false,
            inPlay: props.isOpponent ? true : false,
            stats: props.data.baseStats,
            mode: this.props.data.type == "Monster" && props.isOpponent ? "tactical" : "standard",
            isIntro: false,
            onHover: false,
            statuses: []
        };
        this.data = props.data;
        this.snapThreshold = this.props.setScale * 400; // TODO => should be based on height
        this.isReplay = props.isReplay;
        this.index = props.index;
        this.name = props.data.name;
        this.isGod = props.data.type === "Summoner";
        this.isOpponent = props.isOpponent;
        this.setGodSelected = props.setGodSelected ? props.setGodSelected : null;
        this.slotCoords = this.props.slotCoords;
        this.dragging = false;
        this.pointerDown = props.pointerDown;
        this.pointerUp = props.pointerUp;
        this.containerRef = React.createRef();
        this.tacticalRef = React.createRef();
        this.standardRef = React.createRef();
        this.containerRefLoaded = false;
        this.godRef1 = this.props.godRef1;
        this.godRef2 = this.props.godRef2;
        this.sendMessage = props.sendMessage;
        this.gsapFlags = {};
        this.isPlayReady = this.props.isPlayReady;
        this.onMouseEventListenerEnabled = false;
        this.slotIndex = null;
        this.turn = this.props.turn;
        this.round = this.props.round;
        this.mana = this.props.mana;
        this.baseStatsLoaded = false;
        this.level = 0; // TO BE REPLACED WITH THE ACTUAL NFT LEVEL
        this.permanentEventListenerEnabled = false;
        this.position = [-500, -500]
        this.discarded = this.props.discarded;
        this.onDragStart = this.props.onDragStart;
        this.godSelected = false;
        this.winner = this.props.winner;
        this.isFlashing = false;
        this.particleContainer = new PIXI.Container()
        this.particleContainer2 = new PIXI.Container()
        this.toxicEffectContainer = new PIXI.Container()
        this.props.app.stage.addChild(this.particleContainer)
        this.particles = {};
        this.particles2 = {};
        this.setPlayReady = this.props.setPlayReady;

        if (this.particleContainer2 && this.particleContainer2.scale) this.particleContainer2.scale.set(1.5)
        if (this.toxicEffectContainer && this.toxicEffectContainer.scale) this.toxicEffectContainer.scale.set(1.5)
        // Stone overlays
        this.tacticalStoneOverlay = React.createRef();
        this.standardStoneOverlay = React.createRef();
        this.standardStoneOverlay.current = new PIXI.Sprite(PIXI.utils.TextureCache[`standard-stone`]);
        this.tacticalStoneOverlay.current = new PIXI.Sprite(PIXI.utils.TextureCache[`tactical-stone`]);
        this.standardStoneOverlay.current.alpha = 0; // Make it semi-transparent
        if (this.standardStoneOverlay.current && this.standardStoneOverlay.current.scale) this.standardStoneOverlay.current.scale.set(3)
        if (this.standardStoneOverlay.current && this.standardStoneOverlay.anchor) this.standardStoneOverlay.current.anchor.set(0.5, 0.5)
        if (this.tacticalStoneOverlay.current) this.tacticalStoneOverlay.current.alpha = 0; // Make it semi-transparent
        if (this.tacticalStoneOverlay.current && this.tacticalStoneOverlay.current.scale) this.tacticalStoneOverlay.current?.scale?.set(3)
        if (this.tacticalStoneOverlay.current && this.tacticalStoneOverlay.current.anchor) this.tacticalStoneOverlay.current.anchor.set(0.5, 0.5)

    }

    componentDidMount() {
        if (!this.isReplay) this.setupEventListeners()
        this.loadSlotContainers()
    }

    loadSlotContainers = () => {
      if (this.isGod) return
      this.slotsContainer1 = this.props.slotRef.current.children.find(el => el.isHero)
      this.slotsContainer2 = this.props.slotRef.current.children.find(el => !el.isHero)
    }

    componentWillUnmount() {
      // Call the existing destroy method to handle cleanup
      // Pass false if you do not want to destroy the PIXI container immediately
      console.log(`Unmounting ${this.name}`)
      this.destroy(true);
      if (this.gsap) this.gsap.kill()
    }

    componentDidUpdate(prevProps) {
      if (this.props.godSelected !== prevProps.godSelected) {
        this.godSelected = this.props.godSelected
      }

      if (this.props.setScale !== prevProps.setScale) {

        this.snapThreshold = this.props.setScale * 300;

        if (this.isGod && this.godIntroFinished && this.godSelected == -1) {
          // console.log("updating god position on resize ***")
          const localPos = this.getIntroCoords(3)
          this.containerRef.current.x = localPos.x;
          this.containerRef.current.y = localPos.y;
        }

        if (!this.isGod && this.critterIntroFinished && this.discarded < 2) {
          // console.log("updating critter position on resize ***")
          const localPos = this.getIntroCoords(5)
          this.containerRef.current.x = localPos.x;
          this.containerRef.current.y = localPos.y;
        }
        if (this.particleContainer && this.particleContainer.scale && !this.particleContainer.destroyed) {
          // console.log("setting scale to particle container", this.props.setScale * 4)
          // console.log(this.particleContainer.x, this.particleContainer.y)
          this.particleContainer.scale.set(this.props.setScale * 4)
        }
      }

      if (this.containerRef && this.containerRef.current && this.tacticalRef && this.tacticalRef.current && this.standardRef && this.standardRef.current && !this.containerRefLoaded) {
        this.containerRef.current.zIndex = 2;
        // Ensure the parent container sorts children based on zIndex
        this.containerRef.current.sortableChildren = true;
        this.containerRef.current.zIndex = 2;
        this.containerRef.current.isCard = true;
        this.containerRef.current.inPlay = this.state.inPlay;
        this.containerRef.current.name = this.data.name

        this.tacticalRef.current.type = "tactical"
        this.standardRef.current.type = "standard"

        this.containerRefLoaded = true;
        this.tacticalRef.current.name = this.data.name
        this.standardRef.current.name = this.data.name

        if (!this.isGod && !this.isOpponent && !this.state.inPlay) this.containerRef.current.on('pointerdown', (event) => this.onDragStart(event, this.data.tokenId), this.containerRef.current);

        // PARTICLES LOADING

        if (this.isGod && !this.isOpponent) {
          let config = getParticlesDefaultConfig().magic1
          switch (this.data.color) {
            case ("White"): {
              config.emitterConfig.behaviours[3].start = { _r: 0, _g: 20, _b: 20, _alpha: 1}
              break
            }
            case ("Yellow"): {
              // config.emitterConfig.behaviours[3].start = { _r: 67, _g: 182, _b: 72, _alpha: 1}
              break
            }
            case ("Purple"): {
              config.emitterConfig.behaviours[3].start = { _r: 20, _g: 0, _b: 20, _alpha: 1}
              break
            }
            default: {
              break;
            }
          }
          this.particleContainer.x = 0;
          this.particleContainer.y = 0;

          this.particles = this.particleContainer.addChild(customPixiParticles.create(config))
        }
      }

      if (getUnits() && getUnits().length > 0 && !this.baseStatsLoaded) {
        const match = getUnits().find(unit => unit.cardId == this.props.data.cardId)
        // console.log("props data", this.props.data)
        // console.log("match", match)
        this.baseStats = match.stats
        // console.log(`${this.name} basestats`, this.baseStats)
        this.baseStatsLoaded = true;
      }

      if (!this.permanentEventListenerEnabled && this.containerRef && this.containerRef.current && this.tacticalRef && this.tacticalRef.current && this.standardRef && this.standardRef.current) {
        this.setUpPermanentEventListeners()
        this.permanentEventListenerEnabled = true;
      }

      if (this.props.trigger && this.props.trigger !== prevProps.trigger) {
        this.slotCoords = this.props.slotCoords
        if (this.slotCoords.length == 0 || this.state.destroyed || this.isGod || !this.state.inPlay || !this.tacticalRef.current || !this.standardRef.current) return
        
        // Readjust position
        const cardNames = this.getCards().map(el => el.name)
        let slotIndex = this.containerRef.current.slotIndex // cardNames.indexOf(this.name)
        if (!slotIndex) slotIndex = cardNames.indexOf(this.name)
        if (slotIndex == -1 || slotIndex == undefined) return

        try {
          this.containerRef.current.x = this.slotCoords[slotIndex].x
          this.containerRef.current.y = this.slotCoords[slotIndex].y
          this.anchor()
        } catch(e) {
          console.log(this.name, slotIndex)
          console.error("Card Readjust position error: Bad SlotCoord")
        }
      }

      if (this.props.discarded !== prevProps.discarded) {
        this.discarded = this.props.discarded;
        if (this.discarded.length == 2) this.setState({ isIntro: false })
      }

      if (this.props.winner !== prevProps.winner) {
        this.winner = this.props.winner;
      }

      if (this.props.data !== prevProps.data) {
        this.data = this.props.data;
      }

      if (this.props.round !== prevProps.round) {
        this.round = this.props.round;
        if (this.isGod && this.state.isIntro && this.round > 2) this.setState({ isIntro: false })
        if (!this.isGod && this.state.isIntro && this.round > 3) this.setState({ isIntro: false })
      }

      if (this.props.mana !== prevProps.mana) {
        this.mana = this.props.mana;
      }

      if (this.props.isPlayReady !== prevProps.isPlayReady) {
        this.isPlayReady = this.props.isPlayReady;
        if (this.isPlayReady) this.checkGameConditions("isPlayReady")
      }

      if (this.props.turn !== prevProps.turn) {
        this.turn = this.props.turn;
        if (this.round < 5) this.checkGameConditions("turn")
      }
    }

    moveMagic = ({x, y}) => {
      this.particles.updatePosition({ x, y })
    }

    flashEffect = async (cardContainer = this.containerRef.current) => {
      if (!cardContainer || cardContainer.children.length == 0 || !cardContainer.children[0].children || cardContainer.children[0].children.length == 0) return
      const flashDuration = 50; // Duration of each flash in milliseconds
      
      for (let i = 0; i < 4; i++) {
        cardContainer.isFlashing = !cardContainer.isFlashing; // Toggle flashing state
        
        cardContainer.children[0].children.forEach(child => {
          if (cardContainer.isFlashing) {
            // Apply flashing effect
            let colorMatrix = new PIXI.filters.ColorMatrixFilter();
            colorMatrix.brightness(3, false);
            child.filters = [colorMatrix];
          } else {
            // Remove filters to return to original appearance
            child.filters = [];
          }
        });
        
        // Wait for specified duration before toggling the state again
        await new Promise(resolve => setTimeout(resolve, flashDuration));
      }
    }

    checkGameConditions = () => {
      // console.log(`** checkGameConditions ** trigger ${trigger} | round ${this.round} | turn ${this.turn}`)
      const baseCondition = !this.state.destroyed && !this.isOpponent && this.turn == this.data.player && !this.state.inPlay && this.isPlayReady && !this.winner
      const condition1 = this.round < 4
      const condition2 = this.isGod && this.round < 2;
      const condition3 = this.discarded && this.discarded.length == 2;
      if ((baseCondition && (condition1 || condition2 || condition3))) {
        this.startBlinkingEffect().then(() => {
            // After blinking effect completes, enable card dragging
            if (!this.onMouseEventListenerEnabled) this.setupEventListeners();
        });
      } else if (this.onMouseEventListenerEnabled) {
        this.removeEventListeners()
      }
    }

    startBlinkingEffect = () => {
      return new Promise((resolve) => {
        if (!this.whiteOverlay && this.containerRef.current && !this.containerRef.current.destroyed) {
          this.whiteOverlay = new PIXI.Graphics();
          this.whiteOverlay.beginFill(0xFFFFFF);
          let scaleX = 1;
          let scaleY = 1;
          if (this.containerRef.current && this.containerRef.current.scale) {
            scaleX = Math.abs(this.containerRef.current.scale.x)
            scaleY = Math.abs(this.containerRef.current.scale.y)
          }

          // Adjust dimensions for scale by dividing
          const cardWidth = this.containerRef.current.width / scaleX;
          const cardHeight = this.containerRef.current.height / scaleY;

          // With the anchor set to 0.5, 0.5, draw the overlay such that its center aligns with the container's origin
          // This means starting the rectangle at negative half-width and negative half-height
          this.whiteOverlay.drawRect(-cardWidth / 2, -cardHeight / 2, cardWidth, cardHeight);
          this.whiteOverlay.endFill();
          this.whiteOverlay.alpha = 0;

          this.containerRef.current.addChild(this.whiteOverlay);
        }

        this.blinkTween = gsap.to(this.whiteOverlay, {
          alpha: 1,
          repeat: 3, // Adjust based on the desired number of blinks
          yoyo: true,
          duration: 0.25,
          onComplete: () => {
              resolve();
              this.stopBlinkingEffect();
          }
        });
      });
    }

    addStoneOverlay = async () => {
      // Add the stone overlay as a child of the card sprite
      this.tacticalRef.current.addChild(this.tacticalStoneOverlay.current)
      this.standardRef.current.addChild(this.standardStoneOverlay.current)

      paralyzeAudio.play()

      // Use GSAP to animate the alpha value
      gsap.to(this.standardStoneOverlay.current, { 
        alpha: 0.7, 
        duration: 2,
        onComplete: () => {
          // this.tacticalRef.current.filters = [colorMatrix];
        }
      });
      await gsap.to(this.tacticalStoneOverlay.current, { 
        alpha: 0.7, 
        duration: 2,
        onComplete: () => {
          // this.standardRef.current.filters = [colorMatrix];
        }
      });
    }

    removeStoneOverlay = async () => {
      // Use GSAP to animate the alpha value
      gsap.to(this.standardStoneOverlay.current, { 
        alpha: 0, 
        duration: 2,
        onComplete: () => {
          // this.tacticalRef.current.filters = [colorMatrix];
        }
      });
      await gsap.to(this.tacticalStoneOverlay.current, { 
        alpha: 0, 
        duration: 2,
        onComplete: () => {
          // this.standardRef.current.filters = [colorMatrix];
        }
      });
    }

    executeStatusEffect = async (targetCard, action) => {
      if (action.status == "Poisoned") {
        await this.flashEffect()
        await await targetCard.gsap
        await this.gsap
        await this.applyImpact(targetCard, action)
      }
    }

    passiveAttack = async (targetCard, action) => {
      if (action.ability == "Counterstrike" || action.ability == 'Magic Reflect') {
        await this.flashEffect()
        await await targetCard.gsap
        await this.gsap
        await this.applyImpact(targetCard, action)
        // await this.applyImpact(this, action)
      }
    }

    statusEffect = async (initiatorCard, targetCard, action) => {
      const { status, stat, operator, value, stats, values } = action;

      this.setState(prevState => ({
        statuses: [...prevState.statuses, status]  // Use the spread operator to append newItem
      }));

      if (status == 'Laststanding') {
        const targetCardContainer = targetCard.containerRef.current;
        laststandAudio.play()
        for (let i = 0; i < stats.length; i++) {
          await this.flashEffect(targetCardContainer)
          this.updateStat(stats[i], operator, values[i])
        }

        const config = JSON.parse(JSON.stringify(getParticlesDefaultConfig().magic1))
        this.particleContainer2.scale.set(2)
        this.particles = this.particleContainer2.addChild(customPixiParticles.create(config))
        this.containerRef.current.addChild(this.particleContainer2)
        this.particles.play()
        
        this.shakeScreen(1000, 20)
        targetCard.displayDamage(action)

        await wait(1000)
      }

      if (status == "Frenzyed") {
        const targetCardContainer = targetCard.containerRef.current;
        frenzyAudio.play()
        for (let i = 0; i < stats.length; i++) {
          await this.flashEffect(targetCardContainer)
          this.updateStat(stats[i], operator, values[i])
        }
        const config = JSON.parse(JSON.stringify(getParticlesDefaultConfig().sun))
        this.particles = this.particleContainer2.addChild(customPixiParticles.create(config))
        this.containerRef.current.addChild(this.particleContainer2)
        this.particles.play()
        this.shakeScreen(1000, 20)
        setTimeout(() => { this.particles.stop() }, 2500)
        await wait(2500)
      }
      if (status == "Poisoned") {
        let config = getParticlesDefaultConfig().magic2
        config.emitterConfig.behaviours[3].start = { _r: 56, _g: 128, _b: 48, _alpha: 1}
        this.particles = this.toxicEffectContainer.addChild(customPixiParticles.create(config))
        this.containerRef.current.addChild(this.toxicEffectContainer)
        this.particles.play()
      }
      if (status == "Inspired") {
        const targetCardContainer = targetCard.containerRef.current;
        const initiatorCardContainer = initiatorCard.containerRef.current;
        this.flashEffect(initiatorCardContainer)
        cardBuffAudio.play()
        const effect = new GodBuff(this.props.app, targetCardContainer, true, this.props.setScale)
        this.updateStat(stat, operator, value)
        targetCard.displayDamage(action)
        await effect.start().then(() => effect.dispose())
      }
      if (status == "Nimble") {
        const targetCardContainer = targetCard.containerRef.current;
        const initiatorCardContainer = initiatorCard.containerRef.current;
        this.flashEffect(initiatorCardContainer)
        cardBuffAudio.play()
        const effect = new GodBuff(this.props.app, targetCardContainer, true, this.props.setScale)
        this.updateStat(stat, operator, value)
        targetCard.displayDamage(action)
        await effect.start().then(() => effect.dispose())
      }
      if (status == "Diminished") {
        const targetCardContainer = targetCard.containerRef.current;
        const initiatorCardContainer = initiatorCard.containerRef.current;
        this.flashEffect(initiatorCardContainer)
        cardBuffAudio.play()
        const effect = new GodBuff(this.props.app, targetCardContainer, false, this.props.setScale)
        this.updateStat(stat, operator, value)
        targetCard.displayDamage(action)
        await effect.start().then(() => effect.dispose())
      }
      if (status == "Paralyzed") {
        if (this.state.stats.health > 0) await this.addStoneOverlay()
      }
      if (status == "Intimidated") {
        const targetCardContainer = targetCard.containerRef.current;
        const initiatorCardContainer = initiatorCard.containerRef.current;
        this.flashEffect(initiatorCardContainer)
        cardBuffAudio.play()
        const effect = new GodBuff(this.props.app, targetCardContainer, false, this.props.setScale)
        this.updateStat(stat, operator, value)
        targetCard.displayDamage(action)
        await effect.start().then(() => effect.dispose())
      }
    }

    removeStatus = async (targetCard, action) => {
      const { status, value, stat, operator } = action;
      targetCard.setState(prevState => ({
        statuses: prevState.statuses.filter(_status => _status !== status)
      }));
      const targetCardContainer = targetCard.containerRef.current;
      cardBuffAudio.play()
      const effect = new GodBuff(this.props.app, targetCardContainer, value > 0, this.props.setScale)
      targetCard.updateStat(stat, operator, value)
      await effect.start().then(() => effect.dispose())
      if (status == 'Paralyzed') await this.removeStoneOverlay()
    }

    stopBlinkingEffect = () => {
        if (this.blinkTween) {
            this.blinkTween.kill();
        }
        if (this.whiteOverlay && this.containerRef.current) {
            this.containerRef.current.removeChild(this.whiteOverlay);
            this.whiteOverlay = null;
        }
    }

    // Card mode switch and onHover trigger for parchment
    setUpPermanentEventListeners = () => {

      if (this.permanentEventListenerEnabled) return
      // console.log("*** setUpPermanentEventListeners *** ")
      this.containerRef.current.interactive = true;
      this.standardRef.current.children[0].on('pointerover', this.onCardMouseOverShowCard);
      this.standardRef.current.children[0].on('pointerout', this.onCardMouseOutRemoveCard);
      this.tacticalRef.current.children[0].on('pointerover', this.onCardMouseOverShowCard);
      this.tacticalRef.current.children[0].on('pointerout', this.onCardMouseOutRemoveCard);
    }

    // Glow filters and card controls
    setupEventListeners(container = this.containerRef) {
      if (this.isOpponent || !container || !container.current || this.state.inPlay) return
      // console.log(this.name, "ENABLING")
      // container.current.interactive = true;
      // container.current.buttonMode = true;

      container.current.on('pointerover', this.onCardMouseOver);
      container.current.on('pointerout', this.onCardMouseOut);
      container.current.on('pointertap', this.onMouseClick);   

      if (this.discarded && this.discarded.length == 2) {
        container.current.on('pointerdown', this.onPointerDown);
        container.current.on('pointerup', this.onPointerUp);
        container.current.on('pointermove', this.onPointerMove);
      }

      this.onMouseEventListenerEnabled = true;   
    }

    removePermanentListeners = () => {
      if (!this.permanentEventListenerEnabled) return
      this.standardRef?.current?.children[0]?.off('pointerover', this.onCardMouseOverShowCard);
      this.standardRef?.current?.children[0]?.off('pointerout', this.onCardMouseOutRemoveCard);
      this.tacticalRef?.current?.children[0]?.off('pointerover', this.onCardMouseOverShowCard);
      this.tacticalRef?.current?.children[0]?.off('pointerout', this.onCardMouseOutRemoveCard);
    }

    removeEventListeners = (container = this.containerRef) => {
      if (this.isOpponent || !container || !container.current) return
      // console.log(this.name, "DISABLING")
      container?.current?.off('pointerover', this.onCardMouseOver);
      container?.current?.off('pointerout', this.onCardMouseOut);
      container?.current?.off('pointertap', this.onMouseClick); 
      container?.current?.off('pointerdown', this.onPointerDown);
      container?.current?.off('pointerup', this.onPointerUp);
      container?.current?.off('pointermove', this.onPointerMove);
      this.onMouseEventListenerEnabled = false;
      this.onCardMouseOut()
    }

    toggleCardMode = () => {
      if (this.state.destroyed) return
      // console.log("toggleCardMode", this.name)
      if (this.state.mode == "tactical") {
        this.setState({mode: "standard"})
      } else {
        this.setState({mode: "tactical"})
      }
    }

    getNumCardsInPlay = () => {
      return this.slotsContainer1.children.filter(el => el.isCard).length
    }

    getSlotIndex = () => {
      const cardBounds = this.tacticalRef.current.getBounds(); // Get the bound<s of the card
      const slotsContainer = this.isOpponent ? this.slotsContainer2 : this.slotsContainer1;

      for (let i = 0; i < this.slotCoords.length; i++) {
        // Convert the local slot coordinates to a PIXI Point
        let localPoint = new PIXI.Point(this.slotCoords[i].x, this.slotCoords[i].y);
        // Get the global position of the slot
        let globalSlotPosition = slotsContainer.toGlobal(localPoint);

        // Now get the global center of the card
        let globalCardCenter = new PIXI.Point(cardBounds.x + cardBounds.width / 2, cardBounds.y + cardBounds.height / 2);

        // Calculate the distance between the global center of the card and the global position of the slot
        let dx = globalSlotPosition.x - globalCardCenter.x;
        let dy = globalSlotPosition.y - globalCardCenter.y;
        let distance = Math.sqrt(dx * dx + dy * dy);

        // If the distance is less than the snap threshold, return the index of that slot
        if (distance < this.snapThreshold) {
          return i;
        }
      }

      // If no slot is close enough, return -1
      return -1;
    }

    executeGodBuff = async () => {
      const buff = this.data.preBattle;
      for (const [stat, valueArr] of Object.entries(buff)) {
        for (let i = 0; i < valueArr.length; i++) {
          const value = valueArr[i]
          // console.log("*** EXECUTING GOD BUFF EFFECT", stat, value)
          cardBuffAudio.play()
          const effect = new GodBuff(this.props.app, this.containerRef.current, value > 0, this.props.setScale)
          this.updateStat(stat, "inc", value)

          if ((value > 0 && !this.isOpponent) || (value < 0 && this.isOpponent)) {
            this.flashEffect(this.godRef1.current.find(el => !!el).containerRef.current)
          } else {
            this.flashEffect(this.godRef2.current.containerRef.current)
          }
          await effect.start().then(() => effect.dispose())
        }
      }
    }

    getCards = (useCurrentCoords = false) => {
      // console.log("** GETCARDS DEBUG ***", this.name)
      // console.log("isOpponent", this.isOpponent)
      const slotContainers = this.isOpponent ? this.slotsContainer2 : this.slotsContainer1;
      return slotContainers.children.filter(el => el.isCard).sort((a, b) => {
        if (this.isOpponent) {
          if (a.customY !== b.customY) {
            return useCurrentCoords ? (b.y - a.y) : (b.customY - a.customY); // First sort by customY in descending order
          } else {
            return useCurrentCoords ? (a.x - b.x) : (a.customX - b.customX); // If customY values are equal, sort by customX in ascending order
          }
        } else {
          if (a.customY !== b.customY) {
            return useCurrentCoords ? (a.y - b.y) : (a.customY - b.customY); // First sort by customY in ascending order
          } else {
            return useCurrentCoords ? (b.x - a.x) : (b.customX - a.customX); // If customY values are equal, sort by customX in descending order
          }
        }
      });
    }

    onPointerMove = () => {
      if (!this.dragging || !this.onMouseEventListenerEnabled || this.state.inPlay || this.props?.numberOfCritters == 6) return;

      // const newPosition = this.containerRef.current.data.getLocalPosition(this.containerRef.current.parent);
      // this.containerRef.current.x = newPosition.x;
      // this.containerRef.current.y = newPosition.y;

      const numbCardsInPlay = this.getNumCardsInPlay();
      const slotIndex = this.getSlotIndex();
      const cardContainers = this.getCards();

      this.containerRef.current.alpha = (numbCardsInPlay > 0 && slotIndex > -1) || (numbCardsInPlay === 0 && slotIndex === 0) ? 0.5 : 1;

      if (slotIndex > -1) {
        // console.log("**** DBUG *****")
        // console.log("slotIndex", slotIndex)
        // console.log(this.slotCoords)
        for (let i = 0; i < cardContainers.length; i++) {
          const container = cardContainers[i];
          if (!this.gsapFlags[i] && slotIndex == (i + 1) && (container.customX > container.x || container.customY < container.y)) {
            this.gsapFlags[i] = true;
            gsap.to(container, {
              x: this.slotCoords[i].x, 
              y: this.slotCoords[i].y, 
              duration: 0.2,
              onComplete: () => {
                this.gsapFlags[i] = false;
              }
            });
          }
        }

        for (let i = slotIndex; i < cardContainers.length; i++) {
          const container = cardContainers[i];
          if (!this.gsapFlags[i]) {
            this.gsapFlags[i] = true;
            gsap.to(container, {
              x: this.slotCoords[i + 1].x, 
              y: this.slotCoords[i + 1].y, 
              duration: 0.2,
              onComplete: () => {
                this.gsapFlags[i] = false;
              }
            });
          }
        }
      } else {
        // For each container, move it back to its original position if not already animating
        for (let i = 0; i < cardContainers.length; i++) {
          const container = cardContainers[i];
          if (!this.gsapFlags[i]) {
            this.gsapFlags[i] = true;
            gsap.to(container, {
              x: this.slotCoords[i].x,
              y: this.slotCoords[i].y,
              duration: 0.2,
              onComplete: () => {
                this.gsapFlags[i] = false;
              }
            });
          }
        }
      }
    };

    releaseCardOnSlot = (slotIndex) => {
      // console.log("** releaseCardOnSlot DEBUG  slotIndex **", slotIndex)
      // console.log("** releaseCardOnSlot DEBUG  mode **", this.state.mode)
      const numbCardsInPlay = this.getNumCardsInPlay()
      if (!slotIndex && typeof slotIndex !== "number") slotIndex = this.getSlotIndex()
      // console.log(`*** Dropping ${this.containerRef.current.name} at index ${slotIndex} ***`)
      this.setState({ inPlay: true, mode: "tactical" });
      dropAudio.play()

      slotIndex = slotIndex >= numbCardsInPlay ? numbCardsInPlay : slotIndex;
      
      // PlayCard WS broadcast 
      const msg = JSON.stringify({ type: "playCard", data: { tokenId: this.data.tokenId, index: slotIndex } })
      this.sendMessage(msg)
      this.setPlayReady(false)

      const cardContainers = this.getCards()

      // console.log("** cardContainers DEBUG **")
      // console.log(cardContainers)
      // console.log(slotIndex)
      for (let i = slotIndex; i < cardContainers.length; i++) {
        // console.log("cardContainer loop, i =", i)
        cardContainers[i].x = this.slotCoords[i+1].x;
        cardContainers[i].y = this.slotCoords[i+1].y;
      }

      this.removeParent(this.containerRef.current)
      this.slotsContainer1.addChild(this.containerRef.current)

      this.containerRef.current.rotation = 0;

      this.containerRef.current.x = this.slotCoords[slotIndex].x;
      this.containerRef.current.y = this.slotCoords[slotIndex].y;

      this.anchor()
      
      for (let i = slotIndex; i < cardContainers.length; i++) {
        this.anchor(cardContainers[i])
      }
      for (let i = slotIndex; i < cardContainers.length; i++) {
        this.setCurrentIndex(cardContainers[i])
      }

      const that = this;
      setTimeout(() => {
        that.getCards().forEach((container, index) => {
          container.x = that.slotCoords[index].x
          container.y = that.slotCoords[index].y
        })
      }, 300)
      this.executeGodBuff()
    }

    onPointerUp = () => {
      if (this.turn !== this.data.player || !this.isPlayReady) return

      this.pointerUp() // function prop for slot highlights in green/red
      this.containerRef.current.zIndex = 2;
      this.dragging = false;
      this.containerRef.current.alpha = 1; // Reset alpha
      this.containerRef.current.data = null;

      const numbCardsInPlay = this.getNumCardsInPlay()
      let slotIndex = this.getSlotIndex()
      console.log(`${this.name} | slotIndex ${slotIndex}`)
      // console.log("numberOfCritters", this.props.numberOfCritters)
      if (slotIndex > -1 && !(slotIndex > 0 && numbCardsInPlay == 0) && this.mana >= this.state.stats.mana && this.props.numberOfCritters < 6) { 
        this.releaseCardOnSlot(slotIndex)
      } else {
        this.setState({ inPlay: false, mode: "standard" });
        // back to original place
        this.containerRef.current.x = this.containerRef.current.customX;
        this.containerRef.current.y = this.containerRef.current.customY;

        wait(200).then(() => {
          this.getCards().forEach((container, index) => {
            container.x = this.slotCoords[index].x
            container.y = this.slotCoords[index].y
          })
        })
      }
    }


    onPointerDown = (event) => {
      if (!this.inPlay && (this.turn !== this.data.player || !this.isPlayReady)) return
      this.setState({ mode: "tactical" });
      this.containerRef.current.zIndex = 5
      this.containerRef.current.parent.sortChildren()

      this.pointerDown(this.state.stats.mana)
      this.dragging = true;
      // Store the position where the drag started
      this.containerRef.current.data = event.data;
      // this.containerRef.current.alpha = 0.5; // Optional: change alpha to indicate dragging
    }

    removeParent = (container) => {
      container.parent.removeChild(container)
    }

    getGodContainer = () => {
      // NOTE: usually this should return the god according to each side, but we want to have a playing card asap to have a reference 
      // return this.props.godRef.current.find(card => !card.state.destroyed).containerRef.current
      return this.slotsContainer1.children[0]
    }

    getParentContainer = () => {
      return this.containerRef.current.parent;
    }

    isCenter = () => {
      return this.centerContainer.children.includes(this.containerRef.current)
    }

    isSupport = () => {
      return this.supportContainer.children.indexOf(this.containerRef.current)
    }

    moveContainer = (newX, newY, instant = false, container = this.containerRef.current) => {
      return this.gsap = new Promise((resolve) => {
        this.gsap = gsap.to(container, { 
          x: newX, 
          y: newY, 
          duration: instant ? 0.001 : 0.5,
          onComplete: () => {
            this.anchor(container)
            resolve()
          }
        });
      })
    };

    setCurrentIndex = (container = this.containerRef.current) => {
      const cardNames = this.getCards().map(el => el.name)
      const slotIndex = cardNames.indexOf(container.name)
      container.slotIndex = slotIndex
    }

    anchor = (container = this.containerRef.current) => {
      if (!container) return
      container.customX = container.x;
      container.customY = container.y;
    }

    setSlotIndex = (slotIndex, container = this.containerRef.current) => {
      // console.log(`setting slotIndex ${container.name} at ${slotIndex}`)
      container.slotIndex = slotIndex;
    }

    moveToSlot = (slotIndex, instant = false) => {
      // console.log("*** MOVETOSLOT  ***", this.name, slotIndex)
      return new Promise((resolve, reject) => {
        // Check if the provided slotIndex is within the valid range
        if (slotIndex < 0 || slotIndex >= this.slotCoords.length) reject("moveToSlot: Invalid slotIndex");

        this.setSlotIndex(slotIndex);

        // First, make room:
        let cardContainers = this.getCards()

        // Make sure new card is not included:
        cardContainers = cardContainers.filter(el => el.name !== this.name)
        for (let i = slotIndex; i < cardContainers.length; i++) {
          const container = cardContainers[i]
          this.moveContainer(this.slotCoords[i+1].x, this.slotCoords[i+1].y, false, container)
        }

        const targetSlot = this.slotCoords[slotIndex];

        // Calculate the target position
        const targetX = this.isOpponent ? 
            this.slotsContainer2.x + targetSlot.x - this.getParentContainer().x :
            this.slotsContainer1.x + targetSlot.x - this.getParentContainer().x;
        const targetY = this.isOpponent ? 
            this.slotsContainer2.y + targetSlot.y - this.getParentContainer().y :
            this.slotsContainer1.y + targetSlot.y - this.getParentContainer().y;

        // Animate the movement to the target position
        this.gsap = gsap.to(this.containerRef.current, {
            x: targetX,
            y: targetY,
            duration: instant ? 0.001 : 0.5,
            ease: "power1.inOut",
            onComplete: () => {
              this.anchor()
              for (let i = slotIndex; i < cardContainers.length; i++) {
                this.setCurrentIndex(cardContainers[i])
              }
              this.executeGodBuff().then(resolve())
            }
        });
      })
    }

    placeGod = () => {
        if (!this.isGod || this.state.destroyed) return

        const targetX = (this.isOpponent ? 0.8 : 0.15) * this.props.app.screen.width;
        const targetY = (this.isOpponent ? 0.2 : 0.58) * this.props.app.screen.height;
        // const target = this.isOpponent ? this.containerRef.current.parent : this.containerRef.current;
        this.gsap = gsap.to(this.containerRef.current, {
            x: targetX,
            y: targetY,
            // pixi: { scaleX: this.props.setScale, scaleY: this.props.setScale },
            rotation: 0,
            duration: 1,
            onUpdate: () => {
              if (this.isOpponent) return;
                const cardBounds = this.standardRef.current.children.find(el => el.isCritterSprite == true).getBounds();

                let cardCenterX = cardBounds.x + cardBounds.width / 2;
                let cardCenterY = cardBounds.y + cardBounds.height / 2;

                // Directly set particles to card's center on completion
                this.particles.updatePosition({
                  x: cardCenterX / (this.props.setScale * 4),
                  y: cardCenterY / (this.props.setScale * 4),
                });
            },
            onComplete: () => {
              this.setState({ inPlay: true })
              this.toggleCardMode()  
              if (!this.isOpponent) {
                this.particles.stop()
                // this.particleContainer.destroy()
              }
            }
        });
    }

    getIntroCoords = (totalCards, isResize = true) => {
        const { index } = this.props;
        this.containerRef.current.updateTransform();

        const cardWidth = this.standardRef.current.getBounds().width;

        const spacingBetweenCards = isResize ? cardWidth / 4 : cardWidth * 1.5;

        let cardsPerRow, totalWidthNeeded, startingX, rowHeight, topRowY, bottomRowY, row, rowIndex, globalY;

        if (this.isGod) {
            // Single-row layout
            totalWidthNeeded = totalCards * cardWidth + (totalCards - 1) * spacingBetweenCards;
            startingX = (this.props.app.screen.width - totalWidthNeeded) / 2 + cardWidth / 2;
            globalY = this.props.app.screen.height * 0.4; // Central y-position for single row
            row = 0; // Only one row
            rowIndex = index; // Index is the same as the original index
        } else {
            // Two-row layout
            cardsPerRow = Math.ceil(totalCards / 2);
            totalWidthNeeded = cardsPerRow * cardWidth + (cardsPerRow - 1) * spacingBetweenCards;
            startingX = (this.props.app.screen.width - totalWidthNeeded) / 2 + cardWidth / 2;

            rowHeight = this.props.app.screen.height * 0.3; // Adjustable based on desired gap between rows
            topRowY = this.props.app.screen.height * 0.3;
            bottomRowY = topRowY + rowHeight;

            row = index < cardsPerRow ? 0 : 1;
            rowIndex = index % cardsPerRow;
            globalY = row === 0 ? topRowY : bottomRowY;
        }

        const globalX = startingX + rowIndex * (cardWidth + spacingBetweenCards);
        const localPos = this.containerRef.current.parent.toLocal(new PIXI.Point(globalX, globalY));
        return localPos
    }

    intro = (totalCards) => {
        const localPos = this.getIntroCoords(totalCards, false)
        return new Promise((resolve) => {
          // Assuming this.tacticalRef references your card and is correctly positioned
          this.gsap = gsap.to(this.containerRef.current, {
            x: localPos.x,
            y: localPos.y,
            duration: 1.5,
            onStart: () => {
              if (this.isGod && !this.isOpponent) {
                this.particles.play()
              }
            },
            onUpdate: () => {
              if (this.isGod && !this.isOpponent) {
                const cardBounds = this.standardRef.current.children.find(el => el.isCritterSprite == true).getBounds();

                let cardCenterX = cardBounds.x + cardBounds.width / 2;
                let cardCenterY = cardBounds.y + cardBounds.height / 2;
                // Directly set particles to card's center on completion
                this.particles.updatePosition({
                  x: cardCenterX / (this.props.setScale * 4),
                  y: cardCenterY / (this.props.setScale * 4),
                });
              }
            },
            onComplete: () => {
              this.setState({ isIntro: true });
              if (this.isGod) this.godIntroFinished = true;
              else this.critterIntroFinished = true;
              return resolve(`God ${this.name} loaded`)
            }
          });
        })
    }


    drawPosition = (index) => {
        if (this.state.destroyed || this.state.inPlay) return;
        const spacing = this.props.app.screen.width / 15;
        return new Promise((resolve) => {
          this.gsap = gsap.to(this.containerRef.current, {
              x: index * spacing - this.props.app.screen.width / 28,
              y: 0,
              rotation: -0.2 + index * 0.1,
              // pixi: { scaleX: scale, scaleY: scale },
              duration: 1.5,
              onComplete: () => {
                this.anchor()
                return resolve()
              }
          });
        })
    }

    updateStat = (statKey, operator, val) => {
      this.setState(prevState => {
          // Copy the current stats from the previous state
          const newStats = { ...prevState.stats };
          // console.log("newStats", newStats)
          // Perform the operation based on the operator
          switch (operator) {
              case "inc":
                  newStats[statKey] = (newStats[statKey] || 0) + val;
                  break;
              case "set":
                  newStats[statKey] = val; // Directly set the new value
                  break;
              default:
                  break;
          }

          // Return the new state
          return { stats: newStats };
      });
    }


    displayDamage = (action) => { 
      const { operator, stat, value, hitResult, subtype, type, ability } = action;

      const absValue = Math.abs(value);
      const damage = operator == 'set' ? Math.max(0, this.state.stats[stat] - absValue) : absValue;
      if (!this.containerRef || !this.containerRef.current) return console.error("displayDamage: Container reference is not valid.")

      const card = this.getGodContainer().getBounds()
      let message = '';


      if (type == 'revive') {
        message = `Revive`;
      } else if (type == 'laststand') {
        message = 'Last Stand';
      } else if (type == 'armoredassault') {
        message = 'Armored Assault';
      } else if (subtype == 'stampede' && this.state.stats.abilities.includes("Stampede")) {
        message = subtype;
      } else if (subtype) {
        if (damage == 0) return;
        message = `-${damage}`
      } else if (this.state.statuses.includes(action?.status)) { 
        message = action.status;
      } else if (ability) {
        message = ability;
      } else if (hitResult == 'hit' && stat == 'health') {
        if (damage == 0) return;
        message = `-${damage}`
      } else if (hitResult == 'miss') { 
        message = `Miss!`
      } else { // in case of armor taking damage we show nothing
        message = ``
      }
      // Create a new text object for the damage
      const damageText = new PIXI.Text(message, {
          fontFamily: 'Almendra',
          fontSize: 400,
          fill: 0xFFFFFF,
          align: 'center',
          stroke: '#000000',
          strokeThickness: 12
      });

      // Position the text on the card
      // damageText.scale.set(10)
      damageText.x = card.width * 0.1;
      damageText.y = card.height / 2;
      damageText.anchor.set(0.5);

      // Set a high zIndex for the damage text to ensure it's rendered on top
      damageText.zIndex = 1000;

      // Add the text to the card's container
      this.containerRef.current.addChild(damageText);

      // Animate the damage text
      this.gsap = gsap.to(damageText, {
          y: damageText.y - 30, // move up by 30 pixels
          alpha: 0, // fade out
          duration: 2,
          onComplete: () => {
              // Remove the text after the animation is done
              if (this.containerRef && this.containerRef.current) this.containerRef.current.removeChild(damageText);
              damageText.destroy();
          }
      });
    }

    readjustCardPositions = (slotIndex, cardContainers) => {
      // const cardContainers = this.getCards()
      // console.log("*** DEBUG READJUSTCARDPOSITIONS ***", cardContainers.length)
      const cardPosAdjPromise = []

      for (let i = (slotIndex + 1); i < cardContainers.length; i++) {
        const container = cardContainers[i];
        console.log(`moving ${container.name} to slotIndex ${i}`)
        let promise = new Promise((resolve) => {
           this.gsap = gsap.to(container, {
            x: this.slotCoords[i - 1].x, 
            y: this.slotCoords[i - 1].y, 
            duration: 0.3,
            onComplete: () => {
              this.anchor(container)
              resolve()
            }
          });
        })
        cardPosAdjPromise.push(promise)
      }
      return Promise.all(cardPosAdjPromise)
    }

    killCard = async (killContainer = true) => {
      const slotIndex = this.getSlotIndex()
      // console.log(`*** KILLCARD ${this.containerRef.current.name} *** | isOpponent ${this.isOpponent} | SlotIndex`, slotIndex)
      let colorMatrix = new PIXI.ColorMatrixFilter();
      this.containerRef.current.filters = [colorMatrix, ...(this.containerRef.current.filters || [])];
      colorMatrix.blackAndWhite();
      let cardContainers = this.getCards()
      // console.log("** CARD CONTAINERS DEBUG **")
      // console.log(cardContainers.map(el => el.name))
      while (cardContainers.map(el => el.name).indexOf(this.containerRef.current.name) !== slotIndex) {
        console.error(`Missmatch between slotIndex and cardContainers index for ${this.containerRef.current.name}`)
        console.log("cardContainers", cardContainers)
        console.log("slotIndex", slotIndex)
        cardContainers = this.getCards()
        console.log(cardContainers)
        await wait(300)
      }
      // This loop will make sure that in case of resizing the new slots will be indexed (resize can interrupt gsap animations)
      for (let i = 0; i < cardContainers.length; i++) {
        this.setSlotIndex(cardContainers[i].slotIndex - 1, cardContainers[i])
      }

      let killAnimationPromise = new Promise((resolve) => {
        this.gsap = gsap.to(this.containerRef.current, { 
          rotation: 2, 
          y: this.props.app.screen.height * 1.2, 
          duration: 1,
          onComplete: () => {
            this.destroy(killContainer)
            resolve()
          }
        })
      })

      let readjustCardsPromise = this.readjustCardPositions(slotIndex, cardContainers)

      return Promise.all([killAnimationPromise, readjustCardsPromise])
    }

    applyImpact = async (targetCard, action) => {
      // console.log(action)
      const { stat, operator, value, hitResult } = action;
      const targetCardContainer = targetCard.containerRef.current;
      let promises = []

      targetCard.displayDamage(action)
      if (Number.isInteger(value) && stat && operator) targetCard.updateStat(stat, operator, value)

      if (hitResult == "miss") return
      if (stat == "health") {
        
        const shakeDuration = 0.05;
        const shakeIntensity = 5;

        let promise1 = new Promise((resolve) => {
          this.gsap = gsap.to(targetCardContainer, {
              x: `+=${shakeIntensity}`, // Move right
              yoyo: true,
              repeat: 5, // Number of shakes
              duration: shakeDuration,
              ease: 'linear',
              onComplete: () => {
                  // Reset position after shake
                  gsap.to(targetCardContainer, { 
                    x: targetCardContainer.x, 
                    duration: shakeDuration,
                    onComplete: () => resolve()
                  });
              }
          });
        })

        const bloodEffect = new Blood(this.props.app, targetCardContainer)
        let promise2 = wait(1500).then(() => {
          bloodEffect.dispose()
        }); // Remove update from ticker);
        promises = [promise1, promise2]
      } else {
        Math.random() > 0.5 ? shieldAudio.play() : shieldAudio2.play()
        const initial = absoluteCoords(targetCardContainer)
        const shield = new Shield(this.props.app, initial.x, initial.y, this.props.setScale)

        // Attack angle
        const initiatorPosition = this.containerRef.current;
        const targetPosition = targetCardContainer;

        // Calculate differences
        const dx = targetPosition.x - initiatorPosition.x;
        const dy = targetPosition.y - initiatorPosition.y;

        // Calculate angle in radians
        const angleRadians = Math.atan2(dy, dx);

        shield.hit(this.containerRef.current, this.props.setScale, angleRadians)
        let promise1 = new Promise((resolve) => {
          setTimeout(() => {
            shield.dispose()
            resolve()
          }, 1000)
        })
        promises.push(promise1)
      }
      
      return Promise.all(promises)
    }

    performHealing = async (targetCard, action) => { 
      const { stat, value, operator } = action;
      await this.flashEffect()
      targetCard.updateStat(stat, operator, value)
      if (this.particleContainer && this.particleContainer.scale && !this.particleContainer.destroyed) {
        this.particleContainer.scale.set(2.5)
      }
      this.particles = this.particleContainer.addChild(customPixiParticles.create(getParticlesDefaultConfig().runes))
      targetCard.containerRef.current.addChild(this.particleContainer)
      healingAudio.play()
      this.particles.play()
      targetCard.displayDamage(action)
      await wait(2000)
      this.particles.stop()
    }

    performScavenger = async (action) => { 
      const { stat, value, operator } = action;
      await this.flashEffect()
      this.updateStat(stat, operator, value)
      if (this.particleContainer && this.particleContainer.scale && !this.particleContainer.destroyed) {
        this.particleContainer.scale.set(2.5)
      }
      this.particles = this.particleContainer.addChild(customPixiParticles.create(getParticlesDefaultConfig().runes))
      this.containerRef.current.addChild(this.particleContainer)
      healingAudio.play()
      this.particles.play()
      this.displayDamage(action)
      await wait(2000)
      this.particles.stop()
    }

    performRepair = async (targetCard, action) => { 
      const { stat, value, operator } = action;
      await this.flashEffect()
      targetCard.updateStat(stat, operator, value)
      if (this.particleContainer && !this.particleContainer.destroyed) {
        this.particleContainer.scale.set(2.5)
      }
      let config = getParticlesDefaultConfig().bloodHand;
      let config2 = getParticlesDefaultConfig().counter;
      config.emitterConfig.behaviours[3].start = { _r: 0, _g: 20, _b: 20, _alpha: 1}
      config.emitterConfig.behaviours[3].end = { _r: 0, _g: 122, _b: 255, _alpha: 1}
      config.textures = ['hammer']
      if (this.particleContainer && this.particleContainer.scale && !this.particleContainer.destroyed) {
        this.particleContainer.scale.set(0.5)
      }
      this.particles = this.particleContainer2.addChild(customPixiParticles.create(config))
      this.particles2 = this.particleContainer.addChild(customPixiParticles.create(config2))
      targetCard.containerRef.current.addChild(this.particleContainer)
      targetCard.containerRef.current.addChild(this.particleContainer2)
      playRepairAudio()
      this.particles.play()
      this.particles2.play()
      targetCard.displayDamage(action)
      await wait(2000)
      this.particles.stop()
      this.particles2.stop()
    }

    performVampiric = async (targetCard, action) => { 
      const { value, stat, operator } = action;
      await this.flashEffect()
      this.updateStat(stat, operator, value)
      const config = JSON.parse(JSON.stringify(getParticlesDefaultConfig().bloodHand))
      config.textures = ['fangs']
      this.particles = this.particleContainer.addChild(customPixiParticles.create(config))
      targetCard.containerRef.current.addChild(this.particleContainer)
      playEvilLaughAudio()
      this.particles.play()
      this.displayDamage(action)
      setTimeout(() => { this.particles.stop() }, 1500)
      await wait(2000)
    }

    performRevive = async(initiatorCard, targetCard, action) => {
      const { values, stats, operator } = action;

      if (initiatorCard) await initiatorCard.flashEffect()

      let colorMatrix = new PIXI.ColorMatrixFilter();
      this.containerRef.current.filters = [colorMatrix, ...(this.containerRef.current.filters || [])];
      colorMatrix.blackAndWhite();

      const { x, y } = this.containerRef.current;

      await gsap.to(this.containerRef.current, { 
        rotation: 2, 
        y: this.props.app.screen.height * 1.2, 
        duration: 1
      })

      for (let i = 0; i < stats.length; i++) {
        console.log(`Updating ${stats[i]} with value ${values[i]}`)
        this.updateStat(stats[i], operator, values[i])
      }

      reviveAudio.play()

      this.containerRef.current.filters = [];
      await gsap.to(this.containerRef.current, { 
        rotation: 0,
        x, 
        y, 
        duration: 1
      })

      this.displayDamage(action)
    }

    performExplosive = async (targetCards, action) => { 
      const { value, stat, operator } = action;
      await this.flashEffect()
      for (let i = 0; i < targetCards.length; i++) {
        const targetCard = targetCards[i];
        targetCard.updateStat(stat, operator, value)
        targetCard.displayDamage(action)
        const targetCardContainer = targetCard.containerRef.current;
        const initial = absoluteCoords(this.containerRef.current)
        const target = absoluteCoords(targetCardContainer)
        const magic = new MagicEffect(this.props.app, initial.x, initial.y, target.x, target.y, this.props.setScale, targetCardContainer, false)
        magic.finalExplosion()
      }
    }

    performArmoredAssault = async (targetCard, action) => { 
      const { value, stat, operator } = action;
      await this.flashEffect()
      this.updateStat(stat, operator, value)
      // const config = JSON.parse(JSON.stringify(getParticlesDefaultConfig().bloodHand))
      // config.textures = ['fangs']
      // this.particles = this.particleContainer.addChild(customPixiParticles.create(config))
      // targetCard.containerRef.current.addChild(this.particleContainer)
      armoredassaultAudio.play()
      // this.particles.play()
      // setTimeout(() => { this.particles.stop() }, 1500)
      this.displayDamage(action)
    }

    performRangedAttack = async (targetCard, action) => {
      await this.flashEffect()
      const { hitResult } = action
      await targetCard.gsap
      await this.gsap
      const targetCardContainer = targetCard.containerRef.current;
      const shurikenSprite = new PIXI.Sprite(PIXI.Texture.from("shuriken"))
      const scale = this.props.app.screen.width * 0.0001
      if (shurikenSprite && shurikenSprite.scale) shurikenSprite.scale.set(scale)
      if (shurikenSprite && shurikenSprite.anchor) shurikenSprite.anchor.set(0.5)

      const container = new PIXI.Container()
      this.props.app.stage.addChild(container)
      container.addChild(shurikenSprite)

      container.zIndex = 3;
      container.x = this.getParentContainer().x + this.containerRef.current.x;
      container.y = this.getParentContainer().y + this.containerRef.current.y;

      hitResult == "hit" ? rangedAudio.play() : rangedMissAudio.play()

      const targetX = targetCardContainer.x + targetCardContainer.parent.x;
      const targetY = targetCardContainer.y + targetCardContainer.parent.y;

      // Extend the trajectory beyond the target
      const extendFactor = 1.5; // Adjust this factor to control how far past the target the shuriken travels
      const extendedX = targetX + (targetX - container.x) * extendFactor;
      const extendedY = targetY + (targetY - container.y) * extendFactor;

      return new Promise((resolve) => {
        this.gsap = gsap.to(container, {
          x: hitResult == "hit" ? targetX : extendedX,
          y: hitResult == "hit" ? targetY : extendedY,
          rotation: "+=20", // Adjust the rotation amount as needed
          duration: 0.7,
          ease: "none",
          onComplete: () => {
            container.destroy()
            return this.applyImpact(targetCard, action).then(() => resolve())
          }
        });
      })
    }

    performMagicAttack = async (targetCard, action) => {
        await this.flashEffect()
        await targetCard.gsap
        await this.gsap
        const targetCardContainer = targetCard.containerRef.current;
        const initial = absoluteCoords(this.containerRef.current)
        const target = absoluteCoords(targetCardContainer)
        // console.log("performMagicAttack", targetCard)
        const reflect = targetCard.state.stats.abilities.includes('Magic Reflect')
        const magic = new MagicEffect(this.props.app, initial.x, initial.y, target.x, target.y, this.props.setScale, targetCardContainer, reflect)
        Math.random() > 0.5 ? magicAudio.play() : magicAudio2.play();
        await magic.start()
        return Promise.all([this.applyImpact(targetCard, action)])
    }

    shakeScreen(duration = 500, magnitude = 20) {
        const startTime = Date.now();
        const shake = () => {
            const elapsed = Date.now() - startTime;
            const remaining = duration - elapsed;
            if (remaining > 0) {
                const x = (Math.random() - 0.5) * magnitude;
                const y = (Math.random() - 0.5) * magnitude;
                this.props.app.stage.x = x;
                this.props.app.stage.y = y;
                requestAnimationFrame(shake);
            } else {
                // Reset the stage position
                this.props.app.stage.x = 0;
                this.props.app.stage.y = 0;
            }
        };
        shake();
    }
    
    performMeleeAttack = async (targetCard, action) => {
        if (!targetCard) throw new Error("performMeleeAttack: Missing targetCard")
        if (!this.containerRef?.current) throw new Error("performMeleeAttack: Missing containerRef")
        await this.flashEffect()
        const { hitResult, subtype } = action
        
        if (subtype == 'stampede') {
          stampedeAudio.play()
          this.shakeScreen()
          this.displayDamage(action)
        }
        
        await await targetCard.gsap
        await this.gsap
        const targetCardContainer = targetCard.containerRef.current;
        this.anchor()

        // Calculate global position of the attacking card
        const attackingCardGlobalX = this.containerRef.current.x + this.containerRef.current.parent.x;
        const attackingCardGlobalY = this.containerRef.current.y + this.containerRef.current.parent.y;

        // Calculate global position of the target card
        const targetCardContainerGlobalX = targetCardContainer.x + targetCardContainer.parent.x;
        const targetCardContainerGlobalY = targetCardContainer.y + targetCardContainer.parent.y;

        // Determine relative movement for the attack
        const attackX = targetCardContainerGlobalX - attackingCardGlobalX - 20; // Adjust this value as needed
        const attackY = targetCardContainerGlobalY - attackingCardGlobalY - 20; // Adjust this value as needed

        // Animate the attack
        return new Promise((resolve) => {
          this.gsap = gsap.to(this.containerRef.current, {
              x: this.containerRef.current.x + attackX, 
              y: this.containerRef.current.y + attackY, 
              duration: 0.5,
              onComplete: () => {
                  hitResult == "hit" ? attackAudio.play() : meleeMissAudio.play()
                  // Collision detected, apply impact effect
                  this.applyImpact(targetCard, action);

                  // Return the attacking card to its stored original position
                  gsap.to(this.containerRef.current, { 
                      x: this.containerRef.current.customX, 
                      y: this.containerRef.current.customY, 
                      duration: 0.5,
                      onComplete: () => {
                        resolve()
                      }
                  });
              }
          });
        })
    }

    // listeners for cards that are in play (toggle tactical vs standard mode)
    onCardMouseOverShowCard = async () => {
        await gsap
        if (this.state.inPlay) this.setState({ mode: "standard" })
        this.setState({ onHover: true })
        if (this.isGod) return

        // Makes card standout in hand
        if (!this.inPlay) {
          const slotsContainer = this.isOpponent ? this.slotsContainer2 : this.slotsContainer1;
          this.containerRef.current.zIndex = 100
          slotsContainer.children.sort((a, b) => a.zIndex - b.zIndex);
        }
    }

    onCardMouseOutRemoveCard = () => {
        if (this.state.inPlay) this.setState({ mode: "tactical" })
        this.setState({ onHover: false })
        if (this.isGod) return

        if (!this.inPlay) {
          const slotsContainer = this.isOpponent ? this.slotsContainer2 : this.slotsContainer1;
          this.containerRef.current.zIndex = 2
          slotsContainer.children.sort((a, b) => a.zIndex - b.zIndex);
        }
    }


    // global card listenters for filters
    onCardMouseOver = () => {
        // this.setState({ onHover: true })
        if (this.containerRef.current.filters && this.containerRef.current.filters.includes(glowFilter)) return;
        if (!this.containerRef.current.filters) this.containerRef.current.filters = [glowFilter];
        else this.containerRef.current.filters.push(glowFilter);
        if (this.isGod) return
        this.containerRef.current.zIndex = 100
        this.containerRef.current.parent.children.sort((a, b) => a.zIndex - b.zIndex);
    }

    onCardMouseOut = () => {
        // this.setState({ onHover: false })
        if (!this.containerRef.current.filters || !this.containerRef.current.filters.includes(glowFilter)) return;
        this.containerRef.current.filters = this.containerRef.current.filters.filter(f => f !== glowFilter);
        if (this.isGod) return
        this.containerRef.current.zIndex = 2
        this.containerRef.current.parent.children.sort((a, b) => a.zIndex - b.zIndex);
    }

    onMouseClick = () => {
        cardSelectAudio.play()
        const { discarded, addDiscarded, index } = this.props;
        if (this.isGod && !this.godSelected && !this.isOpponent) {
          this.setState({ isIntro: false })
          return this.setGodSelected(index)
        }

        if (!this.isGod && discarded.length < 2 && !discarded.includes(this.data.tokenId)) {
            addDiscarded(this.data.tokenId);
            let colorMatrix = new PIXI.ColorMatrixFilter();
            this.containerRef.current.filters = [colorMatrix, ...(this.containerRef.current.filters || [])];
            colorMatrix.blackAndWhite();
            // this.setState({ mode: "none" })
        }
    }

    destroy(killContainer = true) {
      console.log(`Destroying ${this.name} ..`)
      this.setState({ destroyed: true });

      if (!this.particleContainer.destroyed) {
        if (this.particles && this.particles.stopImmediately) {
          this.particles.stopImmediately()
          this.particles.destroy()
        }
        this.particleContainer.destroy()
        this.toxicEffectContainer.destroy()
      }

      // Explicitly remove event listeners
      if (this.containerRef && this.containerRef.current) {
        this.removeEventListeners()
        this.removePermanentListeners()
      }

      // Finally, destroy the container if needed
      if (killContainer && this.containerRef && this.containerRef.current) {
          this.containerRef.current.destroy();
      }
      if (killContainer && this.containerRef && this.containerRef.current) {
          this.containerRef.current.destroy();
      }

    }

    render() {
        const { data, ready, setScale } = this.props;
        const {  stats, mode, isIntro, onHover, statuses } = this.state;

        return (
          <Container ref={this.containerRef} scale={setScale} x={this.position[0]} y={this.position[1]}>
            <Tactical
                ref={this.tacticalRef}
                baseStats={this.baseStats}
                data={data}
                state={stats}
                ready={ready}
                scale={setScale}
                level={0} // Or the appropriate level
                visible={mode == "tactical"}
                isPlayReady={this.isPlayReady}
                baseStatsLoaded={this.baseStatsLoaded}
                cardStatuses={statuses}
            />
            <Standard
                ref={this.standardRef}
                baseStats={this.baseStats}
                data={data}
                state={stats}
                ready={ready}
                scale={setScale}
                level={0} // Or the appropriate level
                visible={mode == "standard"}
                isPlayReady={this.isPlayReady}
                isIntro={isIntro}
                onHover={onHover}
                baseStatsLoaded={this.baseStatsLoaded}
                cardStatuses={statuses}
            />
          </Container>
        );
    }
}

export default Card;

