import { useState } from 'react';

const LeagueSelect = () => {
  const [selectedRank, setSelectedRank] = useState(''); // Start with an empty string

  const handleChange = (event) => {
    setSelectedRank(event.target.value);
  };

  return (
    <select 
      name="ranks" 
      id="ranks" 
      value={selectedRank} 
      onChange={handleChange} 
      style={{ 
        fontFamily: 'Inter',
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '-1%',
        fontWeight: '600',
        marginLeft: 'auto', 
        border: '1px solid #EAC88C', // Change #000 to your desired border color
        borderRadius: '0', // No rounding of corners
        backgroundColor: 'transparent', // Set background to transparent
        color: '#EAC88C',
        padding: '8px',
        height: '40px',
        width: '97px'
        // appearance: 'none'
      }}
    >
      <option value="" disabled>
        Ranks
      </option>
      <option value="bronze">Bronze</option>
      <option value="silver">Silver</option>
      <option value="gold">Gold</option>
    </select>
  );
};

export default LeagueSelect;

