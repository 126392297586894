import { useEffect, forwardRef } from 'react';
import { Container, Text } from 'react-pixi-fiber';

const Button = forwardRef(({ onClick, text, x, y, width, height, style }, ref) => {

  // When the component mounts, set the hitArea for the Container using the Graphics element
  useEffect(() => {
  }, [width, height]);

  useEffect(() => {
    ref.current.buttonMode = true;
    ref.current.interactive = true;
    ref.current.on("pointertap", onClick);

    return () => {
      if (ref.current) ref.current.off("pointertap", onClick);
    }
  }, [])

  return (
    <Container
      ref={ref}
      x={x}
      y={y}
    >
      <Text
        x={0}
        y={0}
        text={text}
        anchor={{ x: 0.5, y: 0.5 }}
        style={style}
      />
    </Container>
  );
});

Button.displayName = 'Button';

export default Button;