import { cdnUrl } from "../../constants.js";

export const playerPolygonStyle = {
  width: '64px',
  height: '64px',
  fontSize: '16px',
  color: '#EAC88C',
  fontFamily: 'Germania One',
  backgroundImage: `url(${cdnUrl}/app/matchfound/polygon.svg)`,
  backgroundSize: '100% 100%',
  backgroundRepeat: 'no-repeat',
  backgroundColor: 'rgba(0,0,0,0)',
  border: 'none'
}

export const playerInfoStyle = {
  fontFamily: 'Germania One',
  fontWeight: '400',
  fontSize: '24px',
  letterSpacing: '-2%',
  lineHeight: '28px',
  color: '#EAC88C'
}

export const civButtonStyle = {
  width: '180px',
  height: '45px',
  fontSize: '16px',
  color: '#EAC88C',
  fontFamily: 'Germania One',
  backgroundImage: `url(${cdnUrl}/landing/button.webp)`,
  backgroundSize: '100% 100%',
  backgroundRepeat: 'no-repeat',
  backgroundColor: 'rgba(0,0,0,0)',
  border: 'none',
  cursor: 'pointer'
}

export const disabledButtonStyle = {
  ...civButtonStyle,
  color: '#B8A788', // Muted color
  opacity: 0.5, // Reduced opacity
  filter: 'grayscale(100%)', // Grayscale filter
  cursor: 'not-allowed', // Not allowed cursor
};

export const overlayStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Black overlay with 50% opacity
  zIndex: 1, // Ensures it sits above the image
};

export const hoverButtonStyle = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)', // Center the button
  padding: '10px 20px',
  // backgroundColor: '#EAC88C',
  borderRadius: '5px',
  color: '#EAC88C',
  fontFamily: 'Germania One',
  fontSize: '16px',
  fontWeight: '600',
  cursor: 'pointer',
  zIndex: 2, // Ensures the button is above the overlay
  backgroundImage: `url(${cdnUrl}/landing/button.webp)`,
  backgroundSize: '100% 100%',
  backgroundRepeat: 'no-repeat',
  backgroundColor: 'rgba(0,0,0,0)',
  border: 'none',
  width: '180px',
  height: '45px',
};

export const cardFooterAStyle = { 
  display: 'flex',
  padding: '30px',
  justifyContent: 'center',
  height: '60px',
  backgroundColor: '#101217',
  color: '#937341',
  textAlign: 'center',
  alignItems: 'center',
  fontFamily: 'Poppins',
  fontSize: '1rem',
  fontWeight: '600',
  lineHeight: '20px',
  borderLeft: '1px solid #937341',  // Add left border
  borderRight: '1px solid #937341', // Add right border
  borderBottom: '1px solid #937341', // Add bottom border
  borderTop: 'none', // No top border
  borderRadius: '0 0 9px 9px' // 9px radius for bottom left and right corners
};

export const cardFooterBStyle = { 
  border: '1px solid #937341',       // Border on all sides
  borderRadius: '0 0 9px 9px',       // Rounded corners (bottom-left and bottom-right)
  display: 'flex',
  backgroundColor: '#101217',
  justifyContent: 'center',           // Center horizontally
  alignItems: 'center',               // Center vertically
  height: '107px',                    // Set a height for the container
  fontSize: '20px'
}

export const backgroundImgStyle = {
  minHeight: '100vh',  // Ensures the background covers the entire viewport height
};

export const tableTabStyle = {
  // border: 'none',
  borderColor: 'none',
  borderStyle: 'none',
  borderWidth: 'none',
  margin: '20px',
  fontFamily: 'Inter',
  color: '#EAC88C',
  fontSize: '15px',
  letterSpacing: '-0.5px',
  fontWeight: '600',
  backgroundColor: 'transparent',
  cursor: 'pointer'
}

export const activeTabStyle = {
  color: '#937341',
  // borderBottom: '2px solid #937341', // Underline with 2px thickness
  borderWidth: '0 0 2px 0',
  borderStyle: 'solid',
  borderColor: '#937341',
  paddingBottom: '4px', // Space between the text and the underline
  paddingLeft: '14px', // Add padding to the left
  paddingRight: '14px', // Add padding to the right
  borderRadius: '0px', // No rounded corners
  position: 'relative', // Allows positioning of the pseudo-element

  // Optional: Add hover effect
  '&:hover': {
    borderBottom: '1px solid #937341' // Change underline color on hover
  }
};