import * as PIXI from 'pixi.js';
import { fx } from "./loaders/loadFx.js"
import { customPixiParticles } from 'custom-pixi-particles'
import { magicHitAudio, magicHitAudio2 } from "./audio";

export class Shield extends PIXI.Container {
    constructor(app, x, y, scale) {
        super();
        this.app = app;
        this.fx = fx;
        this.subContainer = new PIXI.Container();

        this.emitter = fx.getParticleEmitter('plasma-shield');
        this.emitter.init(this, true, scale * 4);

        // this.baseX = x
        // this.baseY = y
        this.x = x;
        this.y = y;

        this.mod = 0;
        this.radius = 150;
        this.zIndex = 100;
        this.app.stage.addChild(this);
        app.stage.sortChildren();
    }

    update() {
        this.x = this.baseX + Math.sin(this.mod++ * 0.005) * 60;
        this.y = this.baseY + Math.sin(this.mod++ * 0.009) * 60;
    }

    hit(shot, scale, angle) {
        const effect = this.fx.getEffectSequence('plasma-shield-hit');
        effect.rotation = angle - Math.PI / 2;
        effect.init(this, 0, true, scale * 4);
        
    }

    dispose() {
        this.emitter.stop(false);
        this.destroy()
    }
}

export class Aid extends PIXI.Container {
    constructor(app, cardContainer, config) {
        super();
        this.app = app;
        this.particles = customPixiParticles.create(config)


        

        this.x = cardContainer.x;
        this.y = cardContainer.y;

        this.addChild(this.particles)
        this.zIndex = 0;
        this.scale.x = 2;
        this.scale.y = 2;
        cardContainer.addChild(this);
        this.particles.play()

        // this.particles.onComplete = () => {
        //     this.particles.stop()
        // }
    }
}

export class GodBuff extends PIXI.Container {
    constructor(app, cardContainer, isBuff, scale) {
        super();
        this.app = app;
        this.fx = fx;
        this.zIndex = 100;
        // Initialize a Promise and store its resolve function
        this.animationCompletePromise = new Promise((resolve) => {
            this.resolveAnimationComplete = resolve;
        });

        cardContainer.parent.addChild(this);

        cardContainer.parent.children.sort((a, b) => a.zIndex - b.zIndex);

        this.x = cardContainer.x;
        this.y = cardContainer.y;

        this.emitter = fx.getParticleEmitter("side-teleporter-field", true, true) // third arg is important, it will clone settings 
        this.emitter.init(this, true, scale * 4);
        // console.log("*** ISBUFF ***", isBuff)
        // console.log(JSON.parse(JSON.stringify(this.emitter.settings)))
        if (!isBuff) {
            this.emitter.settings.particleSettings.tintStart = this.hexToDecimal("#FA8072")
            this.emitter.settings.particleSettings.tintEnd = this.hexToDecimal("#D30000")
        }

        this.app.ticker.add(this.update.bind(this));
    }

    hexToDecimal(hex) {
      // Remove the '#' character if present
      if (hex.startsWith("#")) {
        hex = hex.slice(1);
      }

      // Parse the hex string to a decimal number
      const decimal = parseInt(hex, 16);

      return decimal;
    }

    update(dt) {
        // Calculate direction towards target
        this.emitter.update(dt * 0.001 * 30); // Ensure dt is in seconds if needed
        if (this.emitter.completed) this.resolveAnimationComplete();
    }

    dispose() {
        this.emitter.recycle()
        this.emitter.stop(true);
        this.destroy()
    }

    start() {
        // this.app.ticker.add(this.update.bind(this));
        return this.animationCompletePromise; // Return the promise
    }
}


export class MagicEffect extends PIXI.Sprite {
  constructor(app, initialX, initialY, targetX, targetY, scale, targetCardContainer, reflect = false) {
    super();
    this.app = app;
    this.reflect = reflect;
    this.explosion = false;
    this.isReflected = false;
    this._scale = scale;
    this.targetCardContainer = targetCardContainer;
    this.anchor.set(0.5, 0.5);

    // Initialize a Promise and store its resolve function
    this.animationCompletePromise = new Promise((resolve) => {
      this.resolveAnimationComplete = resolve;
    });

    const emitter = this.emitter = fx.getParticleEmitter('top-rocket');

    // Define scale based on container placement
    this.scaleFac = 0.5;

    this.container = new PIXI.Container();
    this.container.zIndex = 100;
    app.stage.addChild(this.container);
    app.stage.sortChildren();

    this.container.addChild(this);
    emitter.init(this.container, true, scale * 6);

    this.initialX = initialX;
    this.initialY = initialY;
    // Set initial and target positions
    this.x = initialX;
    this.y = initialY;
    this.targetX = targetX;
    this.targetY = targetY;

    this.speed = scale * 40;
    emitter.target = this;

    // Create and setup the worker
    // this.worker = new Worker();
    // this.worker.onmessage = this.handleWorkerMessage.bind(this);
  }

  // handleWorkerMessage(event) {
  //   const { delta } = event.data;
  //   this.update(delta);
  // }

  finalExplosion() {
    // console.log("***finalExplosion***")
    const emitter = fx.getParticleEmitter("top-big-explosion");
    this.explosionContainer = new PIXI.Container()
    this.app.stage.addChild(this.explosionContainer)
    this.explosionContainer.zIndex = 10;
    if (!this.reflect) {
      this.explosionContainer.x = this.targetCardContainer.parent.x + this.targetCardContainer.x;
      this.explosionContainer.y = this.targetCardContainer.parent.y + this.targetCardContainer.y;
    } else {
      this.explosionContainer.x = this.initialX;
      this.explosionContainer.y = this.initialY;
    }
    this.container.destroy();
    emitter.init(this.explosionContainer, true, this._scale * 4);
    Math.random() > 0.5 ? magicHitAudio.play() : magicHitAudio2.play()
    setTimeout(() => {
      this.resolveAnimationComplete();
      this.dispose();
    }, 1000);
  }

  update(delta) {
    // Calculate direction towards target
    this.emitter.update(delta * 0.001 * 30); // Ensure dt is in seconds

    const dx = this.targetX - this.x;
    const dy = this.targetY - this.y;
    const distance = Math.sqrt(dx * dx + dy * dy);

    // Normalize the direction
    const dirX = dx / distance;
    const dirY = dy / distance;

    // Update position
    this.x += dirX * this.speed * delta;
    this.y += dirY * this.speed * delta;

    // Stop the emitter and spaceship once it reaches the target (or gets very close)
    if (distance < 10) {
        if (this.reflect && !this.isReflected) {
          this.isReflected = true;
          // const angle = Math.random() * Math.PI * 2; // Random angle for the new direction
          // const reflectDistance = 500; // Arbitrary distance for the reflected path
          this.targetX = this.initialX;
          this.targetY = this.initialY;
        } else if (!this.explosion) {
          this.explosion = true;
          this.finalExplosion()
        }
    }
  }

  dispose() {
    if (this.app && this.app.ticker) this.app.ticker.remove(this.update, this);
    if (this.container) this.container.destroy();
    if (this.explosionContainer) {
      setTimeout(() => {
        this.explosionContainer.destroy();
      }, 1000)
    }
  }

  start() {
    this.app.ticker.add(this.update.bind(this));
    return this.animationCompletePromise; // Return the promise
  }
}

export class Blood extends PIXI.Container {
    constructor(app, cardContainer) {
        super();
        this.app = app;
        this.fx = fx;

        cardContainer.parent.addChild(this);
        // cardContainer.parent.toLocal(new PIXI.Point(cardContainer.x, cardContainer.y), null, this.position);
        this.x = cardContainer.x;
        this.y = cardContainer.y;

        this.emitter = Math.random() > 0.5 ? fx.getParticleEmitter("side-blood-explosion") : fx.getParticleEmitter("side-blood-slash");
        this.emitter.init(this);
    }

    dispose() {
        this.emitter.stop(false);
        this.destroy()
    }
}


