/* eslint-disable */


import TextField from '@mui/material/TextField';
import { cdnUrl } from "../../constants.js";
import Grid from '@mui/material/Grid2'; // Grid version 2
import Box from '@mui/material/Box';

function ReferralContent({ isHovered, setIsHovered, handleCopy, referralLink, layer1Ref, layer2Ref }) {

  const boxStyle = { border: '2px solid #675330', borderRadius: '8px', width: '100%' }

  const paragraphStyle = { color: '#937341', fontFamily: 'Poppins', fontWeight: '600', fontSize: '16px', lineHeight: '20px', padding: '10px' }

  const valuesStyle = { fontFamily: 'Germania One', fontWeight: '400', fontSize: '36px', lineHeight: '41px', color: '#EAC88C' }


	return (
	<>
<Grid container size={{ xs: 12 }} spacing={5} style={{ marginBottom: '40px', color: 'white', textAlign: 'center' }}>
  <Grid size={{ xs: 6 }}>
    <Box style={boxStyle}>
      <p style={paragraphStyle}>Total L1 Referrals</p>
      <p style={valuesStyle}>{layer1Ref && layer1Ref.length > 0 ? layer1Ref.length : 0}</p>
    </Box>
  </Grid>
  <Grid size={{ xs: 6 }}>
    <Box style={boxStyle}>
      <p style={paragraphStyle}>Total L2 Referrals</p>
      <p style={valuesStyle}>{layer2Ref && layer2Ref.length > 0 ? layer2Ref.length : 0}</p>
    </Box>
  </Grid>
</Grid>

<Grid container size={{ xs: 12 }} spacing={5} style={{ marginBottom: '40px', color: 'white', textAlign: 'center' }}>
  <Grid size={{ xs: 12 }}>
    <Box style={boxStyle}>
      <p style={paragraphStyle}>Total Earnings Last Week</p>
      <p style={valuesStyle}>$0.00</p>
    </Box>
  </Grid>
</Grid>

<Grid container size={{ xs: 12 }} spacing={5} style={{ marginBottom: '40px', color: 'white', textAlign: 'center' }}>
	<Grid size={{ xs: 12 }}>
    <Box style={{ ...boxStyle, height: '262px', padding: '20px', backgroundColor: '#0A0A0C' }}>
    	<Grid container size={{ xs: 12 }}>
	      <Grid size={{ xs: 12 }}>
	        <p style={{ backgroundColor: '#0A0A0C', fontFamily: 'Germania One', fontWeight: 400, fontSize: '36px', color: '#EAC88C' }}>Create Referral</p>
	      </Grid>
	      <Grid size={{ xs: 12 }}>
	        <p style={{ ...paragraphStyle, fontWeight: '400' }}>
	          Share your referral link and earn 10% on each card pack that any of your referrals opens, up to two layers!
	        </p>
	      </Grid>
	      <Grid size={{ xs: 12 }} style={{ marginBottom: '20px' }}>
	        <Box style={{
	          border: '1px solid #E1B15E',
	          width: '100%',
	          height: '52px'
	        }}>
	          {referralLink &&
	            <TextField
	              fullWidth
	              value={referralLink}
	              InputProps={{
	                readOnly: true,
	              }}
	            />
	          }
	        </Box>
	      </Grid>
	      <Grid size={{ xs: 12 }}>
	        <button
	          onClick={handleCopy}
	          onMouseEnter={() => setIsHovered(true)}
	          onMouseLeave={() => setIsHovered(false)}
	          style={{
	            border: '1px solid #E1B15E',
	            color: isHovered ? 'black' : '#EAC88C',
	            // padding: '10px 20px',
	            cursor: 'pointer',
	            fontFamily: 'Poppins',
	            fontWeight: '600',
	            fontSize: '14px',
	            lineHeight: '16px',
	            transition: 'background-image 0.3s, opacity 0.3s',
	            width: '225px',
	            height: '52px',
	            opacity: isHovered ? 1 : 0.8,
	            backgroundColor: isHovered ? '#E1B15E' : '#101217'
	          }}
	        >
	          <img src={`${cdnUrl}/app/referral/copy.svg`} width="18" alt="copy" /> COPY REFERRAL LINK
	        </button>
	      </Grid>
    	</Grid>
  	</Box>
  </Grid>
</Grid>

  </>
	)
}

export default ReferralContent;