import { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Box, TableFooter, TablePagination } from '@mui/material';
import { trimAddress } from "../../utils";
import { ThemeProvider } from '@mui/material/styles';
import Theme from "../Theme.jsx";
import { useNavigate } from 'react-router-dom';

function HistoryTable({ items }) {
  const navigate = useNavigate();

  // State for current page and items per page
  const [page, setPage] = useState(0);
  const itemsPerPage = 5;

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle replay button click
  const handleReplay = (item) => {
    navigate(`/replay/${item.id}`);
  };

  if (!items || items.length === 0) return <h1>Loading...</h1>;

  // Calculate the items to display on the current page
  const paginatedItems = items.slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage);

  return (
    <ThemeProvider theme={Theme}>
      <Box >
        <TableContainer sx={{ borderRadius: '16px', border: 'none' }}>
          <Table aria-label="History table">
            <TableHead>
              <TableRow sx={{ borderBottom: '2px solid #937341', height: '60px' }}>
                <TableCell sx={{ fontSize: '1.2rem', padding: '8px', textAlign: 'left', width: '50px' }}>ID</TableCell>
                <TableCell sx={{ fontSize: '1.2rem', padding: '8px', textAlign: 'right', width: '150px' }}>Player 1</TableCell>
                <TableCell sx={{ fontSize: '1.2rem', padding: '8px', textAlign: 'right', width: '150px' }}>Player 2</TableCell>
                <TableCell sx={{ fontSize: '1.2rem', padding: '8px', textAlign: 'right', width: '150px' }}>Winner</TableCell>
                <TableCell sx={{ fontSize: '1.2rem', padding: '8px', textAlign: 'right', width: '150px' }}>Mana</TableCell>
                <TableCell sx={{ fontSize: '1.2rem', padding: '8px', textAlign: 'right', width: '150px' }}>Rule 1</TableCell>
                <TableCell sx={{ fontSize: '1.2rem', padding: '8px', textAlign: 'right', width: '150px' }}>First to Act</TableCell>
                <TableCell sx={{ fontSize: '1.2rem', padding: '8px', textAlign: 'right', width: '150px' }}>Created At</TableCell>
                <TableCell sx={{ fontSize: '1.2rem', padding: '8px', textAlign: 'right', width: '150px' }}>Replay</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedItems.map((item) => (
                <TableRow key={item.id} sx={{ borderBottom: '1px solid #937341', height: '60px' }}>
                  <TableCell component="th" scope="row" sx={{ padding: '8px', textAlign: 'left' }}>{item.id}</TableCell>
                  <TableCell sx={{ padding: '8px', textAlign: 'right' }}>{trimAddress(item.p1)}</TableCell>
                  <TableCell sx={{ padding: '8px', textAlign: 'right' }}>{trimAddress(item.p2)}</TableCell>
                  <TableCell sx={{ padding: '8px', textAlign: 'right' }}>{trimAddress(item.winner)}</TableCell>
                  <TableCell sx={{ padding: '8px', textAlign: 'right' }}>{item.mana}</TableCell>
                  <TableCell sx={{ padding: '8px', textAlign: 'right' }}>{item.rule1}</TableCell>
                  <TableCell sx={{ padding: '8px', textAlign: 'right' }}>{item.firsttoact}</TableCell>
                  <TableCell sx={{ padding: '8px', textAlign: 'right' }}>{item.created_at}</TableCell>
                  <TableCell sx={{ padding: '8px', textAlign: 'right' }}>
                    <Button variant="contained" sx={{ backgroundColor: '#937341', color: 'black', fontSize: '15px', fontWeight: '600' }} onClick={() => handleReplay(item)}>
                      Replay
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5]}
                  count={items.length}
                  rowsPerPage={itemsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count}`}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>
    </ThemeProvider>
  );
}

export default HistoryTable;
