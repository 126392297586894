
import { useWalletInfo } from '@reown/appkit/react'
import { useEffect } from 'react';
import { useAccount, useSignMessage, useDisconnect } from 'wagmi'
import { MONTH } from '../../constants';

const useLogin = (payload, setPayload, address, setAddress, wallet, setWallet, readyState, sendMessage, setAccountAndFiltersLoaded, setAccountData) => {
  const { walletInfo } = useWalletInfo()
  const { address: evmAddress, isDisconnected, isConnected } = useAccount()
  const { signMessageAsync } = useSignMessage()
  const { disconnect } = useDisconnect()

  const handleLogout = () => {
    localStorage.removeItem("payload");
    // console.log("DEBUG", localStorage.getItem("payload"))
    localStorage.removeItem("canBuyPacks");
    if (wallet == "MetaMask") disconnect();
    setAddress(null);
    setWallet(null);
    setAccountAndFiltersLoaded(false);
    setAccountData(null)
    sendMessage(JSON.stringify({ type: 'logout' }))
  }

  async function signMessageWithMetamask(_wallet) {
    try {
      const message = "Login to LF\n" + new Date().getTime();
      const sign = await signMessageAsync({ message });
      // const _wallet = walletInfo?.rdns.toLowerCase().includes("metamask") || walletInfo?.name.toLowerCase().includes("metamask") ? 'MetaMask' : walletInfo.name;
      const payload = JSON.stringify({ message, sign, address: evmAddress, wallet: _wallet })
      localStorage.setItem("payload", payload)
      setPayload(JSON.parse(payload))
      setAddress(evmAddress)
      setWallet(_wallet)
    } catch (error) {
      console.error(error)
      // wait(3000).then(() => signMessageWithMetamask())
    }
  }

  useEffect(() => {
    if (address) console.log("useLogin address", address)
  }, [address])

  // DISCONNECT
  useEffect(() => {
    // console.log("isDisconnected DEBUG wallet =>", wallet)
    // console.log(localStorage.getItem("payload"))
    if (isDisconnected && localStorage.getItem("payload") && address && wallet == "MetaMask") handleLogout()
  }, [isDisconnected])


  useEffect(() => {
    if (payload && readyState == 1) {
      const msg = JSON.stringify({ type: 'login', data: payload, wallet });
      // console.log("msg", msg)
      sendMessage(msg);
    } else if (!localStorage.getItem("payload") && readyState == 1) { // guest mode
      const msg = JSON.stringify({ type: 'login' });
      sendMessage(msg);
    }
  }, [payload, readyState]);

  // Auto fetching login payload from localStorage (login session)
  useEffect(() => {
    // console.log("localStorage payload", localStorage.getItem("payload"))
    // console.log("payload", payload)
    if (!payload && localStorage.getItem("payload")) {
      // console.log("AUTOLOGIN: *** LOADING WALLET DATA FROM PAYLOAD IN LOCAL STORAGE ***")
      // if payload exist, extract address
      const payload = JSON.parse(localStorage.getItem("payload"));
      // console.log("payload", payload)
      const { address, message, wallet } = payload;
      const signTime = message.split('\n')[1];
      const timeDiff = new Date().getTime() - signTime;

      if (timeDiff > MONTH) {
        // alert("Expired credentials")
        if (wallet == "MetaMask") {
          alert("Credentials expired")
          console.log("disconnecting wallet..")
          setTimeout(() => {
            signMessageWithMetamask(wallet)
          }, 1000)
          // disconnect()
        }
        return localStorage.removeItem("payload")
      }
      setPayload(payload);
      setAddress(address);
      setWallet(wallet);
    }
  }, []);

  // METAMASK Auto request for signature after wallet connection (EVM)
  useEffect(() => {
    // console.log("walletInfo", walletInfo)
    // console.log("payload", localStorage.getItem("payload"))
    let _wallet = null;

    if (walletInfo?.rdns && walletInfo?.rdns.toLowerCase().includes("metamask")) _wallet = 'MetaMask';
    else if (walletInfo?.name && walletInfo?.name.toLowerCase().includes("metamask")) _wallet = _wallet = 'MetaMask';
    // console.log("wallet name", _wallet)
    // console.log("isConnected", isConnected)
    // console.log(localStorage.getItem("payload"))
    if (isConnected && _wallet && !localStorage.getItem("payload")) {
      // console.log("AUTOLOGIN: *** SINGING SIGNATURE ***")
      setTimeout(() => {
        signMessageWithMetamask(_wallet)
      }, 1000)
    }
  }, [walletInfo, isConnected]);

  return {
    handleLogout
  }
}

export default useLogin;