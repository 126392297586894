import { useEffect, useState, forwardRef, useRef } from 'react';
import { Sprite } from "react-pixi-fiber";
import * as PIXI from "pixi.js";
import { GlowFilter } from '@pixi/filter-glow';

const cdnUrl = import.meta.env.VITE_CDN_URL;
const civs = ["greece", "egypt", "norse"];
const randomCiv = civs[Math.floor(Math.random() * civs.length)];
const combatMusicUrl = `${cdnUrl}/audio/music/${randomCiv}.mp3`;
const combatMusicAudio = new Audio(combatMusicUrl);

combatMusicAudio.loop = true;
combatMusicAudio.volume = 0.4;

const glowFilter = new GlowFilter({
    distance: 15,
    outerStrength: 2,
    color: 0xffc107, // Example: golden color
    quality: 0.5,
});

const AudioButton = forwardRef(({ app, ready, isGameOver }, ref) => {

    const timeout = useRef();

    useEffect(() => {
      function fade() {
        
        if (combatMusicAudio.volume > 0) {
          try {
            combatMusicAudio.volume -= 0.005;
          } catch(e) {
            combatMusicAudio.currentTime = 0
            combatMusicAudio.volume = 1;
            combatMusicAudio.pause()
            clearTimeout(timeout.current)
          }
          timeout.current = setTimeout(fade, 25);
        }
      }

      if (isGameOver) fade()

      return () => {
        clearTimeout(timeout.current)
      }
    }, [isGameOver])

    const [isPlaying, setIsPlaying] = useState(() => {
        const storedValue = localStorage.getItem("audioPlaying");
        return storedValue !== null ? JSON.parse(storedValue) : true;
    });

    function onAudioTap() {
        console.log("onAudioTap")
        setIsPlaying(prevIsPlaying => !prevIsPlaying);
    }

    const onMouseOver = () => {
        if (!ref.current.filters.includes(glowFilter)) ref.current.filters.push(glowFilter)
    }

    const onMouseOut = () => {
        console.log("onMouseOut")
        ref.current.filters = ref.current.filters.filter(f => f !== glowFilter)
    }

    useEffect(() => {
        if (ready && app) {
            if (!isPlaying) {
                combatMusicAudio.pause();
                const grayscale = new PIXI.filters.ColorMatrixFilter();
                grayscale.desaturate();
                ref.current.filters = [grayscale];
            } else {
                combatMusicAudio.play();
                ref.current.filters = []
            }

            // Save the audio state to localStorage
            localStorage.setItem("audioPlaying", isPlaying);
        }

        return () => {
            combatMusicAudio.pause();
        };
    }, [isPlaying]);

    useEffect(() => {
        if (app && ready) {
            ref.current.interactive = true;
            ref.current.buttonMode = true;

            ref.current.on('pointertap', onAudioTap);
            ref.current.on('pointerover', onMouseOver);
            ref.current.on('pointerout', onMouseOut);
        }
        return () => {
            if (ref && ref.current) {
                ref.current.off('pointertap', onAudioTap);
                ref.current.off('pointerover', onMouseOver);
                ref.current.off('pointerout', onMouseOut);
            }
            combatMusicAudio.pause();
        };
    }, [ready, app]);


    return (
        <Sprite 
            ref={ref} 
            zIndex={2}
        />
    );
})

AudioButton.displayName = "AudioButton";

export default AudioButton;
