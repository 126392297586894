const mod = 9


export function adjust(app, slots1, slots2, centerRectangle1, centerRectangle2, supportRectangle1, supportRectangle2) {
  // if (!discardedCards || app.stage.children.length === 0) return;
  let cardWidth, cardHeight;
  const windowAR = window.innerWidth / window.innerHeight;

  const cardAR = 4/3;

  if (windowAR < cardAR) {
    // Window is narrower than the background aspect ratio
    cardWidth = window.innerWidth  / mod;
    cardHeight = window.innerWidth / mod / cardAR;

  } else {
    // Window is wider than the background aspect ratio
    cardHeight = window.innerHeight / mod;
    cardWidth = window.innerHeight / mod * cardAR;

  }

  // console.log(`scale ${scale}`)
  // setScale(scale * 0.2)

  slots1.current.x = (app.screen.width - (5 * cardWidth)) / 2
  slots1.current.y = app.screen.height * 0.51;

  slots2.current.x = slots1.current.x
  slots2.current.y = app.screen.height * 0.20;

  centerRectangle1.current.clear();
  centerRectangle1.current.zIndex = 0;
  centerRectangle1.current.lineStyle(2, 0x000000, 0.2);
  centerRectangle1.current.drawRect(0, 0, cardWidth, cardHeight);


  supportRectangle1.current.clear();
  supportRectangle1.current.zIndex = 0;
  supportRectangle1.current.lineStyle(2, 0x000000, 0.2);
  supportRectangle1.current.drawRect(0, 0, cardWidth * 5, cardHeight);


  centerRectangle2.current.clear();
  centerRectangle2.current.zIndex = 0;
  centerRectangle2.current.lineStyle(2, 0x000000, 0.2);
  centerRectangle2.current.drawRect(0, 0, cardWidth, cardHeight);


  supportRectangle2.current.clear();
  supportRectangle2.current.zIndex = 0;
  supportRectangle2.current.lineStyle(2, 0x000000, 0.2);
  supportRectangle2.current.drawRect(0, 0, cardWidth * 5, cardHeight);

  centerRectangle1.current.x = cardWidth * 3;
  centerRectangle1.current.y = 0;

  supportRectangle1.current.x = 0;
  supportRectangle1.current.y = app.screen.height * 0.25;

  centerRectangle2.current.x = cardWidth * 1;
  centerRectangle2.current.y = app.screen.height * 0.23;

  supportRectangle2.current.x = 0;
  supportRectangle2.current.y = 0;

  return {cardWidth, cardHeight}
}
