import { Box, Typography } from '@mui/material';
import { trimAddress } from "../../utils"; // Assuming this utility is available in your code

// Function to check if the message is a command
const isCommand = (text) => {
  const commands = ['/onlineplayers', '/help', '/stats']; // Add more as needed
  return commands.some(command => text.startsWith(command));
};

// Function to detect if it's a server message
const isServerMessage = (address) => {
  return address === "admin"; // Assuming server messages come from 'admin'
};

// Function to replace addresses with nicknames or trim long addresses
const replaceAddressesInText = (text, onlinePlayers) => {
  if (!text) return '';
  return text.split(' ').map(word => {
    // Check if the word matches an address in the nicknamesMapper
    if (onlinePlayers.nicknamesMapper?.[word]) {
      return `@${onlinePlayers.nicknamesMapper[word]}`; // Replace the address with its nickname
    }

    // Trim the address if it's long
    if (word.length === 42 || word.length === 34) return trimAddress(word);

    return word; // Keep the word as is if no match
  }).join(' ');
};

// MessageRenderer component
const MessageRenderer = ({ msg, onlinePlayers }) => {
  return (
    <Box display="flex" flexDirection="column" mb={1}>
      {msg.type === 'chat' && msg.address && (
        <Typography
          variant="body2"
          sx={{
            fontFamily: 'Poppins',
            color: isServerMessage(msg.address) ? '#937341' : '#EAC88C',
            fontStyle: isServerMessage(msg.address) ? 'italic' : 'normal', // Italic for server messages
          }}
        >
          {isServerMessage(msg.address) ? (
            // Server message (don't show the address)
            <>
              {replaceAddressesInText(msg.text, onlinePlayers)}
            </>
          ) : (
            // Non-server message (show nickname or trimmed address, and text)
            <>
              <strong>
                {onlinePlayers.nicknamesMapper?.[msg.address]
                  ? onlinePlayers.nicknamesMapper[msg.address]
                  : msg.address.length > 10
                  ? trimAddress(msg.address)
                  : msg.address}
                :
              </strong>{' '}
              {isCommand(msg.text) ? (
                // Display chat commands as styled code
                <Typography
                  component="span"
                  sx={{
                    fontFamily: 'monospace',  // Monospace font for code-like appearance
                    backgroundColor: '#f5f5f5', // Light grey background
                    padding: '2px 6px',
                    borderRadius: '4px',
                    fontSize: '0.875rem',
                    color: 'black'
                  }}
                >
                  {msg.text}
                </Typography>
              ) : (
                // Normal message text
                replaceAddressesInText(msg.text, onlinePlayers)
              )}
            </>
          )}
        </Typography>
      )}

    </Box>
  );
};

export default MessageRenderer;
