
/* eslint-disable */

import Grid from '@mui/material/Grid2'; // Grid version 2
import {cdnUrl } from "../../constants.js";
import { cardFooterBStyle } from "./Styles.jsx";
import { useMediaQuery } from '@mui/material';

function RightGrid({ sendMessage, setIsSearching, handleLogin, address, spacing }) {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));
  const cardImageBStyle = { 
    width: '100%', 
    height: 'auto', 
    display: 'block', 
    margin: 0
  }

  const battleHander = () => {
    if (!address) return handleLogin()
    setIsSearching(true)
    const msg = JSON.stringify({ type: 'play', data: { iamnotabot: true } });
    sendMessage(msg);
  }

  return (
  <Grid 
    container 
    size={{ xs: 12, md: 6 }} 
    spacing={spacing}
    justifyContent="center" 
    display="flex"
  >
    <div style={{
        position: 'relative',
        display: 'flex',           // Enable flexbox
        flexDirection: 'column',   // Stack children vertically
        height: '100%',            // Ensure the parent div takes full height
          borderTop: '1px solid #937341',   // Top border
          borderLeft: '1px solid #937341',  // Left border
          borderRight: '1px solid #937341', // Right border
          borderBottom: 'none',              // No bottom border
          borderRadius: '9px 9px 9px 9px',      // Rounded corners (top-left and top-right)
    }}>
      <img 
        src={isSmallScreen ? `${cdnUrl}/app/mobile/battle/start-battle.webp`: `${cdnUrl}/app/battle-menu/B.webp`} 
        style={{
          ...cardImageBStyle
        }} 
      />
      <div style={{ ...cardFooterBStyle, marginTop: 'auto', height: isSmallScreen ? '55px' : '107px' }}>
        <button 
          onClick={battleHander}
          style={{
            width: '100%',
            height: '100%',
            backgroundImage: `url(${cdnUrl}/battle-menu/button.png)`, // Default background image
            backgroundSize: '97% 80%',    // Make sure the background covers the button
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',    // Ensure the background image is centered
            border: 'none',                  // Removes the default border
            color: '#EAC88C',                // Text color
            padding: '10px 20px',           // Adds padding to the button
            cursor: 'pointer',               // Changes the cursor to a pointer
            fontSize: 'inherit',             // Inherit font size from the parent
            opacity: 0.8,                    // Adjust opacity for transparency

            backgroundColor: '#101217',      // Background color
            fontFamily: 'Germania One',
            transition: 'background-image 0.3s, opacity 0.3s', // Smooth transition for image and opacity
          }}
          onMouseEnter={(e) => e.currentTarget.style.backgroundImage = `url(${cdnUrl}/battle-menu/button-onhover.png)`} 
          onMouseLeave={(e) => e.currentTarget.style.backgroundImage = `url(${cdnUrl}/battle-menu/button.png)`} 
        >
          <img src={`${cdnUrl}/app/battle-menu/battle-icon.svg`} /> START BATTLE
        </button>
      </div>
    </div>
    </Grid>
  )
}

export default RightGrid;