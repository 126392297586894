
import { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid2';

import { cdnUrl} from "../../constants.js";

export default function IconButtons({ addColor, removeColor, direction = 'row', size = 56, legend = false }) {

  const [isWhite, setWhite] = useState(true)
  const [isYellow, setYellow] = useState(true)
  const [isPurple, setPurple] = useState(true)

  useEffect(() => {
    if (isWhite) {
      addColor('White')
    } else {
      removeColor('White')
    }
  }, [isWhite])

  useEffect(() => {
    if (isYellow) {
      addColor('Yellow')
    } else {
      removeColor('Yellow')
    }
  }, [isYellow])

  useEffect(() => {
    if (isPurple) {
      addColor('Purple')
    } else {
      removeColor('Purple')
    }
  }, [isPurple])


  const renderButton = (label, color, borderColor, backgroundColor, clickHandler, state, icon) => (
    <>
      <Grid size={{ xs: 'auto'}}>
        <IconButton
          aria-label={label}
          onClick={() => clickHandler(!state)}
          sx={{
            margin: '5px',
            color: state ? color : `rgba(${color}, 0.5)`, // Lower opacity when inactive
            backgroundColor: state ? backgroundColor : 'rgba(0, 0, 0, 0.5)', // Lower opacity when inactive
            width: size,
            height: size,
            border: `2px solid ${borderColor}`,
            borderRadius: '50%',
            '&:hover': {
              backgroundColor: '#e0e0e0',
            },
          }}
        >
          {icon}
        </IconButton>
      </Grid>
      {legend && (
        <Grid size={{ xs: 'auto'}}>
          <p>{label}</p>
        </Grid>
      )}
    </>
  );

  return (
    <Grid container spacing={0} direction={direction} sx={{ display: 'flex', justifyContent: 'left' }}>
      <Grid size={{ xs: 3 }} sx={{ display: 'flex', justifyContent: 'left' }} alignItems="center">
        {renderButton('Greece', 'gray', 'gray', '#000000', setWhite, isWhite, <img src={`${cdnUrl}/logos-icons/civs-icons/gray/greece.png`} width={25}/>)}
      </Grid>
      <Grid size={{ xs: 3 }} sx={{ display: 'flex', justifyContent: 'left' }} alignItems="center">
        {renderButton('Egypt', '#0096FF', '#0096FF', '#000000', setYellow, isYellow, <img src={`${cdnUrl}/logos-icons/civs-icons/gray/egypt.png`} width={15}/>)}
      </Grid>
      <Grid size={{ xs: 3 }} sx={{ display: 'flex', justifyContent: 'left' }} alignItems="center">
        {renderButton('Norse', '#FF00FF', '#FF00FF', '#000000', setPurple, isPurple, <img src={`${cdnUrl}/logos-icons/civs-icons/gray/norse.png`} width={20}/>)}
      </Grid>
    </Grid>
  );
}
