
import { Dialog, DialogActions, DialogContent, DialogContentText, Button, CircularProgress, Typography } from '@mui/material';
import { cdnUrl } from "../../constants.js";

const Matchmaking = ({ open, setIsSearching, sendMessage }) => {

  // Function to close the popup
  const handleClose = (reason) => {
    if (reason == 'backdropClick') return
    setIsSearching(false);
    const msg = JSON.stringify({ type: 'cancel' });
    sendMessage(msg);
  };

  return (
    <div>

      {/* Full-width popup with solid background */}
      <Dialog
        open={open}
        onClose={(_, reason) => handleClose(reason)}
        fullWidth
        maxWidth={false}  
        PaperProps={{
          sx: {
            width: '70%',
            height: 'auto',  // Set height to auto to adapt to content
            maxHeight: '90vh',  // Maximum height to avoid overflow
            backgroundColor: '#101318',
            color: '#fff',
            margin: 0,
            border: '1px solid #E1B15E',
            position: 'relative',
            overflow: 'hidden',  // Ensures no overflow in the dialog
            maxWidth: '1280px',
          },
        }}
      >

      {/* Background Image */}
      <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: '#0A0A0C',
        backgroundImage: `url("${cdnUrl}/app/matchmaking/combat.webp")`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover', // Cover ensures full container coverage
        zIndex: 0, // Background stays behind the content
      }}></div>

        {/* Content on top of the background */}
        <DialogContent sx={{ position: 'relative', zIndex: 2 }}>
          <DialogContentText sx={{ color: '#937341', textAlign: 'center', paddingTop: '10%' }}>
            <>
              <Typography component={'span'} style={{ fontFamily: 'Germania One', fontWeight: '400', fontSize: '48px', letterSpacing: '-2%'}}>BATTLE</Typography>
              <img
                src={`${cdnUrl}/app/referral/separator.svg`}
                alt="divider"
                style={{ width: '100%', height: 'auto', position: 'relative', zIndex: 3 }}
              />
              <CircularProgress size={'30px'} />
              <br />
              <Typography component={'span'} style={{ fontFamily: 'Germania One', fontWeight: '400', fontSize: '24px', letterSpacing: '-2%'}}>Searching for an opponent</Typography>
            </>
          </DialogContentText>
        </DialogContent>

        {/* Dialog Actions */}
        <DialogActions sx={{ position: 'relative', zIndex: 2 }}>
          <Button onClick={handleClose} color="primary" variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Matchmaking;
