import { useEffect } from "react";
import { Snackbar, Button } from '@mui/material';
import { cdnUrl } from '../constants';

const alertSoundUrl = `${cdnUrl}/audio/app/alert.mp3`;
const alertAudio = new Audio(alertSoundUrl);

function MatchmakingAlert({ open, onClose, onJoin }) {

  useEffect(() => {
    if (open) alertAudio.play()
  }, [open])

  return (
    <Snackbar
      open={open}
      message="A player is looking for a match!"
      action={
        <Button color="secondary" size="small" onClick={onJoin}>
          Join
        </Button>
      }
      onClose={onClose}
      autoHideDuration={6000}
    />
  );
}


export default MatchmakingAlert;