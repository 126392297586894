/* eslint-disable */


import { getAccounts, getSigner } from "../konio"
import * as kondor from "kondor-js";
import { Transaction, Contract, utils, Provider } from "koilib";
// import axios from "axios";
import { useState, useEffect } from 'react'; 
import Grid from '@mui/material/Grid2'; // Grid version 2
import Box from '@mui/material/Box';
// import { activeTabStyle, tableTabStyle } from "../components/New/Styles.jsx";
import {cdnUrl} from "../constants.js";
import { trimAddress, wait } from "../utils";

const fadeoutBoxStyle = {
  border: '1px solid transparent', // Set the border to transparent for the image effect
  borderRadius: '8px',
  borderImageSource: 'linear-gradient(180deg, #675330 0%, transparent 100%)',
  borderImageSlice: 1, // This is important to apply the gradient as a border
  padding: '20px',

};

const boxStyle = { 
  borderTop: '1px solid #675330', 
  borderLeft: '1px solid #675330', 
  borderRight: '1px solid #675330', 
  borderBottom: 'none', // No border on the bottom
  borderRadius: '8px 8px 0 0', // Radius only for top corners
  width: '100%' 
};

const headerStyle = { fontFamily: 'Germania One', fontWeight: '400', fontSize: '24px', color: '#937341', padding: '20px' };
const headerBoxStyle = { borderBottom: '2px solid #675330' };
const tabsStyle = { padding: '20px' };
const paragraphRegularStyle = { fontFamily: 'Poppins', fontWeight: '400', fontSize: '14px', color: '#937341' }
const withdrawBoxStyle = { width: '100%', border: '1px solid #675330', borderRadius: '16px', padding: '20px', margin: '10px' }
const confirmButtonContainerStyle = { textAlign: 'center', border: '1px solid #675330', width: '100%', padding: '20px', margin: '10px' }
const confirmButtonStyle = { cursor: 'pointer', width: '100%', height: '100%', fontFamily: 'Inter', fontWeight: '700', fontSize: '16px', color: '#C4B998', border: 'none', backgroundColor: 'rgba(0,0,0,0)' }
const confirmButtonHoverStyle = {
  backgroundColor: '#C4B998',
  color: '#000',
  boxShadow: '0 0 15px rgba(255, 255, 255, 0.8)',
};

function Packs({ setIsLoading, address, wallet, packsContract, provider, faithBalance, faithContract, faithPrice, setRefreshBalances }) {
  const [hover, setHover] = useState(false);
  const [packAmount, setPackAmount] = useState(0)
  const [faithAmount, setFaithAmount] = useState(0)
  const [maxAmount, setMaxAmount] = useState(0)
  const packPriceUsd = 5;
  const [allowance, setAllowance] = useState(null)
  const faithDecimals = 8;
  const extra_allowance = 1.5;

  // useEffect(() => {
  //   if (faithContract && faithAmount > 0) handleAllowance()
  // }, [faithContract, faithAmount])

  // useEffect(() => {
  //   if (faithContract) handleAllowance()
  // }, [faithContract])

  useEffect(() => {
    if (faithPrice) console.log("faithPrice", faithPrice)
  }, [faithPrice])

  useEffect(() => {
    if (allowance) console.log("allowance", allowance)
  }, [allowance])

  useEffect(() => {
    if (faithAmount) console.log("faithAmount", faithAmount)
  }, [faithAmount])

  useEffect(() => {
    if (faithPrice && packAmount) setFaithAmount(packAmount * 5 / faithPrice)
  }, [faithPrice, packAmount])

  useEffect(() => {
    if (packAmount && faithPrice) {
      setFaithAmount(Math.round(packAmount * packPriceUsd / faithPrice))
    }
  }, [packAmount, faithPrice])

  useEffect(() => {
    if (faithBalance) setMaxAmount(faithBalance)
  }, [faithBalance])

  useEffect(() => {
    if (address && faithContract) getAllowance()
  }, [address, faithContract])

  // const handleMaxAmount = () => {
  //   setAmount()
  // }

  // useEffect(() => {
  //   if (faithContract) {
  //     console.log(faithContract)
  //     handleAllowance()
  //   }
  // }, [faithContract])

  // useEffect(() => {
  //   handleAllowance()
  // }, [])

  const getAllowance = async () => {
    const _provider = new Provider(["http://api.koinos.io"]);
    
    const faith = new Contract({
      id: "14Dj8RjYqy8hQ1GarrJGGeaGu6AuCwxcgy", // faith contract id
      abi: utils.tokenAbi,
      provider: _provider
    });

    const { result } = await faithContract.functions.allowance({
      owner: address,
      spender: '1A4Nv58odXgVK91bFHgCFWPgiu7kfsxdVY' // the packs contract
    });

    setAllowance(result.value / (10**faithDecimals))
  }

  const handleBuyPacks = async () => {
    if (packAmount <= 0) return
    let signer;
    
    if (wallet == "Konio") {
      const accounts = await getAccounts()
      signer = await getSigner(accounts[0])
    } else if (wallet == "Kondor") {
      signer = await kondor.getSigner(address);

    }

    const transaction = new Transaction({
      provider,
      signer,
      options: {
        rcLimit: "1500000000"
      },
    });

    const payload = {
      account: address,
      amount: packAmount, // number of packs to open
      pay_with_faith: true
    }
    console.log("payload", payload)
    try{
      await transaction.pushOperation(faithContract.functions.approve, {
        owner: address,
        spender: "1A4Nv58odXgVK91bFHgCFWPgiu7kfsxdVY", // the packs contract
        value: ((faithAmount + 1) * 10**faithDecimals).toString(),
      });
      await transaction.pushOperation(packsContract.functions.normal_buy, payload);
      setIsLoading(true)
      await transaction.sign();
      setIsLoading(false)
      const receipt = await transaction.send();
      console.log("receipt", receipt)
      alert("Purchase completed!")
      setPackAmount(0)
      setRefreshBalances(2000)
    } catch(e) {
      console.error(e)
      setIsLoading(false)
    }
  }

  return (
    <Grid 
      container 
      offset={{ xs: 4 }}
      size={{ xs: 4 }} 
      style={{ 
        textAlign: 'center', 
        width: '544px', 
        margin: '200px auto',
        background: 'rgba(16, 18, 23, 0.6)', // Background with 60% opacity
      }}
    >

      <div
        style={{
          backgroundImage: `url(${cdnUrl}/app/wallet/background.webp)`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center', // Center the background image
          backgroundSize: 'cover', // Ensure the background image covers the area
          top: '150px',
          left: 0,
          width: '100vw',
          height: '30vh', // Adjust if necessary
          position: 'absolute',
          zIndex: -1, // Lower zIndex to prevent overlap
        }}
      ></div>

      <Box >
        <Grid size={{ xs: 12 }} style={{ justifyContent: 'center', textAlign: 'center' }}>
          <Box style={{...headerBoxStyle, ...boxStyle, padding: '10px' }}>
            <p style={headerStyle}>BUY PACKS</p>
            <img src={`${cdnUrl}/packs/alpha-pack3.png`} width="150" />
          </Box>
        </Grid>
        <Grid size={{ xs: 12 }} container style={{...tabsStyle, ...fadeoutBoxStyle}}>

          <Box style={withdrawBoxStyle}>
            <Grid size={{ xs: 12 }} container>
              <Grid size={{ xs: 6 }} style={{ display: 'flex' }}>
                <img src={`${cdnUrl}/packs/alpha-pack3.png`} style={{margin: '2px'}} height="24"/>
                <p style={{ textAlign: 'left', margin: '2px', fontFamily: 'Inter', fontWeight: '700', color: '#C4B998' }}>PACK</p>
              </Grid>
              <Grid size={{ xs: 6 }}>
                <input
                  
                  type="number"
                  style={{ 
                    ...paragraphRegularStyle, 
                    fontWeight: '700',
                    fontFamily: 'Inter',
                    fontSize: '20px',
                    color: '#937341',
                    textAlign: 'right', 
                    border: 'none', 
                    background: 'none', 
                    width: '100%', 
                    outline: 'none'
                  }}
                  value={packAmount} // You can add state management for the input value here
                  onChange={(e) => setPackAmount(e.target.value)} // Update the value when changed
                  placeholder="0.00"
                />

            </Grid>
            </Grid>
          </Box>

          <Grid size={{ xs: 12 }} container>
            <Grid size={{ xs: 12 }} container style={{ padding: '20px 20px 0px 20px' }}>
              <Grid size={{ xs: 6 }}>
                <p style={{...paragraphRegularStyle, textAlign: 'left' }}>Pay with</p>
              </Grid>
              <Grid size={{ xs: 6 }}>
                <p 
                // onClick={handleMaxAmount}
                style={{...paragraphRegularStyle, cursor: 'pointer', textAlign: 'right', color: '#C4B998', textDecoration: 'underline'}}
                >Available: {maxAmount} </p>
              </Grid>
            </Grid>

            
            <Box style={withdrawBoxStyle}>
              <Grid size={{ xs: 12 }} container>
                <Grid size={{ xs: 6 }} style={{ display: 'flex' }}>
                  <img src={`https://d1ji3mpeqp3ic0.cloudfront.net/app/wallet/faith.svg`} style={{margin: '2px'}} height="24"/>
                  <p style={{ textAlign: 'left', margin: '2px', fontFamily: 'Inter', fontWeight: '700', color: '#C4B998' }}>FAITH</p>
                </Grid>
                <Grid size={{ xs: 6 }}>
                  <input
                    type="number"
                    style={{ 
                      ...paragraphRegularStyle, 
                      fontWeight: '700',
                      fontFamily: 'Inter',
                      fontSize: '20px',
                      color: '#937341',
                      textAlign: 'right', 
                      border: 'none', 
                      background: 'none', 
                      width: '100%', 
                      outline: 'none'
                    }}
                    value={faithAmount} // You can add state management for the input value here
                    readOnly
                    // onChange={(e) => setFaithAmount(e.target.value)} // Update the value when changed
                    placeholder="0.00"
                  />

              </Grid>
              </Grid>
            </Box>
            
            <Grid size={{ xs: 12 }} style={{ padding: '20px 20px 0px 20px' }}>
              <p style={{...paragraphRegularStyle, textAlign: 'left' }}>Your Wallet</p>
            </Grid>

            <Box style={withdrawBoxStyle}>
              <Grid size={{ xs: 12 }}>
                <p style={{fontFamily: 'Inter', fontWeight: '700', fontSize: '14px', color: '#C4B998', textAlign: 'left'}}>
                { address ? trimAddress(address) : '' }
                </p>
              </Grid>
            </Box>
            <Grid 
            size={{ xs: 12 }} 
            style={{
              ...confirmButtonContainerStyle,
              ...(hover ? confirmButtonHoverStyle : {}) 
            }}
            >
              <button 
                onClick={handleBuyPacks}
                style={{ 
                  ...confirmButtonStyle, 
                  ...(hover ? { color: 'black'} : {}) 
                }}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                Buy packs
              </button>
            </Grid>

          </Grid>
        

        </Grid>
      </Box>
    </Grid>
  );
}

export default Packs;
