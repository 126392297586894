import RanksSelect from "./RanksSelect.jsx";
import { activeTabStyle, tableTabStyle } from "./Styles.jsx";

function TableTabs({activeTab, setActiveTab}) {
	return (
		<>
      <button 
        style={{
          ...tableTabStyle, 
          ...(activeTab === 'Leaderboard' ? activeTabStyle : {})
        }}
        onClick={() => setActiveTab('Leaderboard')}
      >
        Leaderboard
      </button>
      <button 
        style={{
          ...tableTabStyle, 
          ...(activeTab === 'History' ? activeTabStyle : {})
        }}
        onClick={() => setActiveTab('History')}
      >
        History
      </button>
      <button 
        style={{
          ...tableTabStyle, 
          ...(activeTab === 'Following' ? activeTabStyle : {})
        }}
        onClick={() => setActiveTab('Following')}
      >
        Following
      </button>
      <RanksSelect />
    </>
	)
}

export default TableTabs;