import * as PIXI from 'pixi.js';
import { Text } from 'react-pixi-fiber';

const wrapText = (text, style, maxWidth) => {
  const words = text.split(' ');
  let lines = [];
  let currentLine = words[0];

  const textStyle = new PIXI.TextStyle(style);
  
  for (let i = 1; i < words.length; i++) {
    const word = words[i];
    const width = PIXI.TextMetrics.measureText(`${currentLine} ${word}`, textStyle).width;
    if (width < maxWidth) {
      currentLine += ` ${word}`;
    } else {
      lines.push(currentLine);
      currentLine = word;
    }
  }
  
  lines.push(currentLine); // Push the last line

  return lines.join('\n');
};

// Usage in your component:
const TextWrapper = ({ text, style, boxWidth, x, y }) => {
  const wrappedText = wrapText(text, style, boxWidth);

  return (
    <Text 
      anchor={{x: 0.5, y: 0.5 }}
      text={wrappedText} 
      style={style} 
      x={x} 
      y={y}
    />
  )
};

export default TextWrapper