
import { useState, useEffect } from 'react'; 
import Grid from '@mui/material/Grid2'; // Grid version 2
import Box from '@mui/material/Box';
import axios from "axios";
import { cdnUrl, dbApiUrl } from "../constants.js";
import { trimAddress } from "../utils";
import { wait } from "../utils";
import Confirmation from "../components/Wallet/Confirmation";
import Popup from "../components/Wallet/Popup"; 

const tableTabStyle = {
  padding: '20px',
  margin: '10px'
};


const activeTabStyle = {
  borderBottom: '1px solid #675330' // Bottom border
};

const tabButtonStyle = {
  fontFamily: 'Inter',
  color: '#EAC88C',
  fontSize: '15px',
  letterSpacing: '-0.5px',
  fontWeight: '600',
  backgroundColor: 'transparent', // Maintain transparent background
  cursor: 'pointer',
  border: 'none', // Remove border
  outline: 'none', // Remove outline on focus
  boxShadow: 'none', // Remove any box shadow
  padding: '0', // Reset padding (optional)
  margin: '0', // Reset margin (optional)
};

const fadeoutBoxStyle = {
  border: '1px solid transparent', // Set the border to transparent for the image effect
  borderRadius: '8px',
  borderImageSource: 'linear-gradient(180deg, #675330 0%, transparent 100%)',
  borderImageSlice: 1, // This is important to apply the gradient as a border
  padding: '20px',

};

const boxStyle = { 
  borderTop: '1px solid #675330', 
  borderLeft: '1px solid #675330', 
  borderRight: '1px solid #675330', 
  borderBottom: 'none', // No border on the bottom
  borderRadius: '8px 8px 0 0', // Radius only for top corners
  width: '100%' 
};



const headerStyle = { fontFamily: 'Germania One', fontWeight: '400', fontSize: '24px', color: '#937341', padding: '20px' };
const headerBoxStyle = { borderBottom: '2px solid #675330' };
const tabsStyle = { padding: '20px' };
const paragraphRegularStyle = { fontFamily: 'Poppins', fontWeight: '400', fontSize: '14px', color: '#937341' }
const withdrawBoxStyle = { width: '100%', border: '1px solid #675330', borderRadius: '16px', padding: '20px', margin: '10px' }
const confirmButtonContainerStyle = { textAlign: 'center', border: '1px solid #675330', width: '100%', padding: '20px', margin: '10px' }
const confirmButtonStyle = { cursor: 'pointer', width: '100%', height: '100%', fontFamily: 'Inter', fontWeight: '700', fontSize: '16px', color: '#C4B998', border: 'none', backgroundColor: 'rgba(0,0,0,0)' }
const confirmButtonHoverStyle = {
  backgroundColor: '#C4B998',
  color: '#000',
  boxShadow: '0 0 15px rgba(255, 255, 255, 0.8)',
};

function Tabs({ activeTab, setActiveTab }) {
  return (
    <>
      <Grid size={{ xs: 6 }}>
      <Box
        style={{ 
          ...tableTabStyle, 
          ...(activeTab === 'Withdrawal' ? activeTabStyle : {})
        }}
      >
        <button 
          style={tabButtonStyle}
          onClick={() => setActiveTab('Withdrawal')}
        >
          Withdrawal
        </button>
      </Box>
      </Grid>
      <Grid size={{ xs: 6 }}>
      <Box
        style={{ 
          ...tableTabStyle, 
          ...(activeTab === 'Deposit' ? activeTabStyle : {})
        }}
      >
        <button 
          style={tabButtonStyle}
          // onClick={() => setActiveTab('Deposit')}
        >
          Deposit
        </button>
      </Box>
      </Grid>
    </>
  )
}

function Wallet({ accountData, address, wallet, payload, setIsLoading, setRefreshAccountData, handleLogin }) {
  const [hover, setHover] = useState(false);
  const [activeTab, setActiveTab] = useState('Withdrawal')
  const [amount, setAmount] = useState(0)
  const [maxAmount, setMaxAmount] = useState(0)
  // const [history, setHistory] = useState([])
  const [id, setId] = useState(null)
  const [trxId, setTrxId] = useState(null)
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    if (accountData) setMaxAmount(accountData.mfaith / 1000)
  }, [accountData])

  useEffect(() => {
    if (address) fetchHistory().then(res => console.log("history", res))  
  }, [address])

  const onPopupConfirm = () => {
    setPopupOpen(false)
    handleWithdrawal()
  }

  const onPopupClose = () => {
    setPopupOpen(false)
  }

  const handleClose = () => {
    setAmount(0)
    setTrxId(null)
    setId(null)
  }

  const onConfirmOrder = () => {
    if (!address) {
      return handleLogin()
    }

    if (amount < 11) return alert("Min 11 FAITH")
    console.log("onConfirmOrder")
    setPopupOpen(true)
    // if (wallet == "MetaMask") {
    //   setPopupOpen(true)
    // } else {
    //   alert("Koinos withdrawal coming soon!")
    // }
  }

  const handleClick = () => {
    setAmount(maxAmount)
  };

  const fetchHistory = () => {
    return axios.get(`${dbApiUrl}/withdraw/${address}`).then(res => res.data)
  }

  const scanConfirmation = async () => {
    console.log("** scanConfirmation **")
    let history = await fetchHistory()
    // console.log("history", history)
    let _trxId = history.find(el => el.id == id)?.trxId;

    while (!_trxId) {
      // console.log(`scan Confirmation waiting 3 sec..`)
      // console.log("id", id)
      // console.log("history", history)
      await wait(3000)
      history = await fetchHistory()
      _trxId = history.find(el => el.id == id)?.trxId;
    }
    setTrxId(_trxId)

    setIsLoading(false)
  }

  const handleWithdrawal = async () => {
    try {
      setIsLoading(true)
      const { data } = await axios.post(`${dbApiUrl}/withdraw`, { 
        payload, 
        currency: 'faith',
        amount: Math.round(amount * 1000) / 1000,
        wallet
      })
      console.log("withdrawal data", data)
      setId(data.id)
    } catch(e) {
      console.error(e)
      setIsLoading(false)
      alert("Withdraw error: Please contact administrator")
    }
  }

  useEffect(() => {
    if (id) {
      setRefreshAccountData(true)
      scanConfirmation()      
    }
  }, [id])

  return (
    <Grid 
      container 
      offset={{ xs: 4 }}
      size={{ xs: 4 }} 
      style={{ 
        textAlign: 'center', 
        width: '544px', 
        margin: '200px auto',
        background: 'rgba(16, 18, 23, 0.6)', // Background with 60% opacity
      }}
    >
      <Popup open={popupOpen} onClose={onPopupClose} onConfirm={onPopupConfirm} wallet={wallet}/>
      <Confirmation trxId={trxId} handleClose={handleClose} wallet={wallet}/>
      <div
        style={{
          backgroundImage: `url(${cdnUrl}/app/wallet/background.webp)`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center', // Center the background image
          backgroundSize: 'cover', // Ensure the background image covers the area
          top: '150px',
          left: 0,
          width: '100vw',
          height: '30vh', // Adjust if necessary
          position: 'absolute',
          zIndex: -1, // Lower zIndex to prevent overlap
        }}
      ></div>

      <Box >
        <Grid size={{ xs: 12 }} style={{ justifyContent: 'center', textAlign: 'center' }}>
          <Box style={{...headerBoxStyle, ...boxStyle, padding: '10px' }}>
            <p style={headerStyle}>WALLET</p>
          </Box>
        </Grid>
        <Grid size={{ xs: 12 }} container style={{...tabsStyle, ...fadeoutBoxStyle}}>
          <Tabs activeTab={activeTab} setActiveTab={setActiveTab}/>

          {
            activeTab == 'Withdrawal' &&
            <Grid size={{ xs: 12 }} container>
              <Grid size={{ xs: 12 }} container style={{ padding: '20px 20px 0px 20px' }}>
                <Grid size={{ xs: 6 }}>
                  <p style={{...paragraphRegularStyle, textAlign: 'left' }}>Withdraw Amount</p>
                </Grid>
                <Grid size={{ xs: 6 }}>
                  <p 
                  onClick={handleClick}
                  style={{...paragraphRegularStyle, cursor: 'pointer', textAlign: 'right', color: '#C4B998', textDecoration: 'underline'}}
                  >Available: {maxAmount} </p>
                </Grid>
              </Grid>

              
              <Box style={withdrawBoxStyle}>
                <Grid size={{ xs: 12 }} container>
                  <Grid size={{ xs: 6 }} style={{ display: 'flex' }}>
                    <img src={`${cdnUrl}/app/wallet/faith.svg`} style={{margin: '2px'}} height="24"/>
                    <p style={{ textAlign: 'left', margin: '2px', fontFamily: 'Inter', fontWeight: '700', color: '#C4B998' }}>FAITH</p>
                  </Grid>
                  <Grid size={{ xs: 6 }}>
                    <input
                      
                      type="number"
                      style={{ 
                        ...paragraphRegularStyle, 
                        fontWeight: '700',
                        fontFamily: 'Inter',
                        fontSize: '20px',
                        color: '#937341',
                        textAlign: 'right', 
                        border: 'none', 
                        background: 'none', 
                        width: '100%', 
                        outline: 'none'
                      }}
                      value={amount} // You can add state management for the input value here
                      onChange={(e) => setAmount(e.target.value)} // Update the value when changed
                      placeholder="0.00"
                    />

                </Grid>
                </Grid>
              </Box>
              
              <Grid size={{ xs: 12 }} style={{ padding: '20px 20px 0px 20px' }}>
                <p style={{...paragraphRegularStyle, textAlign: 'left' }}>Your Wallet</p>
              </Grid>

              <Box style={withdrawBoxStyle}>
                <Grid size={{ xs: 12 }}>
                  <p style={{fontFamily: 'Inter', fontWeight: '700', fontSize: '14px', color: '#C4B998', textAlign: 'left'}}>
                  { address ? trimAddress(address) : '' }
                  </p>
                </Grid>
              </Box>
              <Grid 
              size={{ xs: 12 }} 
              style={{
                ...confirmButtonContainerStyle,
                ...(hover ? confirmButtonHoverStyle : {}) 
              }}
              >
                <button 
                  style={{ 
                    ...confirmButtonStyle, 
                    ...(hover ? { color: 'black'} : {}) 
                  }}
                  onClick={onConfirmOrder}
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                >
                  Confirm Order
                </button>
              </Grid>

            </Grid>
          }

        </Grid>
      </Box>
    </Grid>
  );
}

export default Wallet;
