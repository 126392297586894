/* eslint-disable */

import { useEffect } from "react";
import { utils } from "koilib";
import * as kondor from "kondor-js";
import { getAccounts, getSigner } from "../konio";
import {cdnUrl} from "../constants.js";
import { useConnectors, useChainId, useConnect } from 'wagmi';
import { useMediaQuery, Dialog, DialogActions, DialogContent, DialogTitle, Button, List, ListItem, ListItemText } from '@mui/material';


const handleKonioLogin = async (onClose, setWallet, setPayload, setAddress) => {
  console.log('*** handleKonioLogin ***')
  onClose(); // Close the dialog after selection
  setWallet("Konio")

  getAccounts().then( async (accounts) => {
    console.log(`Setting new address to: ${accounts[0]}`)
    setAddress(accounts[0])
    localStorage.setItem("address", accounts[0])
    setWallet("Konio")
    localStorage.setItem("wallet", "Konio")

    const signer = getSigner(accounts[0]);
    console.log(signer)
    const message = "Login to LF\n" + new Date().getTime();
    const sign = await signer.signMessage(message).then(resAsUint8Array => {
      // NOTE: THIS IS A WORKAROUND - KONIO signMessage is broken

      // decode as text
      const resAsText = new TextDecoder().decode(resAsUint8Array);

      // convert the text into numbers
      const resAsNumbers = resAsText.split(",").map(p => Number(p));

      // finally, convert numbers to Uint8Array
      const solution = new Uint8Array(resAsNumbers);

      return utils.encodeBase64url(solution)
    })
    // console.log(sign)
    const payload = JSON.stringify({ message, sign, address: accounts[0], wallet: "Konio" })
    localStorage.setItem("payload", payload)
    console.log(payload)
    setPayload(payload)
  })
}


async function handleKondorLogin(onClose, setWallet, setPayload, setAddress) {

  onClose(); // Close the dialog after selection
  setWallet("Kondor")
    
  const address = await kondor.getAccounts().then(res => res[0].address)
  const signer = kondor.getSigner(address);
  const message = "Login to LF\n" + new Date().getTime();
  const sign = await signer.signMessage(message).then(res => utils.encodeBase64url(res))
  console.log(sign)
  const payload = JSON.stringify({ message, sign, address: address, wallet: "Kondor" })
  localStorage.setItem("payload", payload)

  setPayload(JSON.parse(payload))
  setAddress(address)
  return payload
}

// const metamaskHandler = (onClose, connect, connector, chainId) => {
//   connect({ connector, chainId })
//   onClose()
// }

function WalletSelector({ open, onClose, setWallet, setPayload, setAddress }) {
  const connectors = useConnectors();
  const chainId = useChainId();
  const { connect } = useConnect();
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));

  // useEffect(() => {
  //   console.log("connectors", connectors)
  // }, [connectors])

  return (
    <Dialog 
    open={open} 
    onClose={onClose}
    PaperProps={{ 
      sx: { 
        backgroundColor: 'black', 
        opacity: 1 ,
        border: '1px solid #E1B15E',
        borderRadius: '8px'
      }
    }}
    >
      <DialogTitle><center>Choose Your Wallet</center></DialogTitle>
      <img
        src={`${cdnUrl}/app/referral/separator.svg`}
        alt="divider"
        style={{ width: '100%', height: 'auto', position: 'relative', zIndex: 3 }}
      />
      <DialogContent style={{ textAlign: 'center' }}>
        <List style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>

{/*          {
              connectors.map((connector, index) => (
                <ListItem key={index}>
                  <button key={connector.uid} onClick={() => connect({ connector })}>
                    {connector.name}
                  </button>
                </ListItem>
              ))
          }*/}
            <ListItem> <appkit-button onClick={onClose}/> </ListItem>


{/*          {
            !isSmallScreen &&
            <ListItem 
              onClick={() => metamaskHandler(onClose, connect, connectors[1], chainId)}
              style={{ cursor: 'pointer' }} // Set pointer cursor on hover
            >
              <img src={"https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/512px-MetaMask_Fox.svg.png"} alt="Evm" style={{ marginRight: '10px', width: '24px', height: '24px' }} />
              <ListItemText primary="MetaMask" />
            </ListItem>
          }*/}
          {
            !isSmallScreen &&
            <ListItem 
              onClick={() => handleKondorLogin(onClose, setWallet, setPayload, setAddress)}
              style={{ cursor: 'pointer' }} // Set pointer cursor on hover
            >
              <img src={`${import.meta.env.VITE_CDN_URL}/misc/kondor-icon.png`} alt="Kondor" style={{ marginRight: '10px', width: '24px', height: '24px' }} />
              <ListItemText primary="Kondor" />
            </ListItem>
          }
{/*          {
            isSmallScreen &&
              <w3m-button
                onClick={onClose}
                style={{
                  width: '100%',
                  maxWidth: '300px', // Optional: Sets a max-width if you don't want it to be fully stretched
                  margin: '0 auto' // Optional: Centers the button
                }}
              />
          }*/}

          <ListItem 
            onClick={() => handleKonioLogin(onClose, setWallet, setPayload, setAddress)}
            style={{ cursor: 'pointer' }} // Set pointer cursor on hover
          >
            <img src={`${import.meta.env.VITE_CDN_URL}/misc/konio-icon.jpeg`} alt="Konio" style={{ marginRight: '10px', width: '24px', height: '24px' }} />
            <ListItemText primary="Konio" />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

export default WalletSelector;