
import { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid2';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CropSquareIcon from '@mui/icons-material/CropSquare';

export default function IconButtons({ addRole, removeRole, direction = 'row', size = 56, legend = false }) {

  const [isCritter, setCritter] = useState(true)
  const [isGod, setGod] = useState(true)

  useEffect(() => {
    if (isCritter) {
      addRole('Monster')
    } else {
      removeRole('Monster')
    }
  }, [isCritter])

  useEffect(() => {
    if (isGod) {
      addRole('Summoner')
    } else {
      removeRole('Summoner')
    }
  }, [isGod])

  const renderButton = (label, color, borderColor, backgroundColor, clickHandler, state, icon) => (
    <>
      <Grid size={{ xs: 'auto'}}>
        <IconButton
          aria-label={label}
          onClick={() => clickHandler(!state)}
          sx={{
            margin: '5px',
            color: state ? color : `rgba(${color}, 0.5)`, // Lower opacity when inactive
            backgroundColor: state ? backgroundColor : 'rgba(0, 0, 0, 0.5)', // Lower opacity when inactive
            width: size,
            height: size,
            border: `2px solid ${borderColor}`,
            borderRadius: '50%',
            '&:hover': {
              backgroundColor: '#e0e0e0',
            },
          }}
        >
          {icon}
        </IconButton>
      </Grid>
      {legend && (
        <Grid size={{ xs: 'auto'}}>
          <p>{label}</p>
        </Grid>
      )}
    </>
  );

  return (
    <Grid container spacing={0} direction={direction} sx={{ display: 'flex', justifyContent: 'left' }}>
      <Grid size={{ xs: 6 }} sx={{ display: 'flex', justifyContent: 'left' }} alignItems="center">
        {renderButton('Monster', '#FF0000', '#FF0000', '#000000', setCritter, isCritter, <FiberManualRecordIcon sx={{ fontSize: size / 2 }} />)}
      </Grid>
      <Grid size={{ xs: 6 }} sx={{ display: 'flex', justifyContent: 'left' }} alignItems="center">
        {renderButton('God', '#0000FF', '#0000FF', '#000000', setGod, isGod, <CropSquareIcon sx={{ fontSize: size / 2 }} />)}
      </Grid>
    </Grid>
  );
}
