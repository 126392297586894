
import { Card, CardContent, Typography, CardMedia, Switch, Button, IconButton } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid2';
import {cdnUrl} from "../constants.js";

const Gallery = ({ units, combinedCards, editDeck, deckChanges, setDeckChanges, discardChanges, commitChanges }) => {
  const navigate = useNavigate();

  const handleCardClick = (cardId, foil) => {
    navigate(`/card-details/${cardId}/${foil}`);
  };

  // useEffect(() => {
  //   console.log("combinedCards", combinedCards);
  // }, [combinedCards]);

  const handleToggle = (card, enabled) => {
    const combinedCard = combinedCards.find(el => el.cardId == card.cardId && el.foil == card.foil);

    if (combinedCard.deckEnabled == enabled) {
      setDeckChanges(prevDeckChanges => 
        prevDeckChanges.filter(item => !(item.cardId == card.cardId && item.foil == card.foil))
      );
    } else {
      setDeckChanges(prevDeckChanges => {
        const filteredDeckChanges = prevDeckChanges.filter(item => !(item.cardId == card.cardId && item.foil == card.foil));
        return [...filteredDeckChanges, { foil: card.foil, cardId: card.cardId, enable: enabled }];
      });
    }
  };

  const isCardEnabled = (card) => {
    const tempChange = deckChanges.find(change => change.cardId === card.cardId && change.foil === card.foil);
    return tempChange ? tempChange.enable : card.deckEnabled;
  };

  return (
    <Grid container>
      <Grid size={{ xs: 12 }}>
        <div>
          <Button 
            className="image-button" 
            onClick={commitChanges} 
            disabled={deckChanges.length === 0}
            style={{
              fontSize: '24px',
              fontFamily: 'Germania One',
              width: '200px',
              height: '50px',
              color: deckChanges.length == 0 ? 'grey' : '#EAC88C'
            }}
          >
            Save Deck
          </Button>
          <Button 
            className="image-button" 
            onClick={discardChanges} 
            disabled={deckChanges.length === 0} 
            style={{ 
              marginLeft: '10px', 
              fontSize: '24px', 
              fontFamily: 'Germania One',
              width: '250px',
              height: '50px',
              color: deckChanges.length == 0 ? 'grey' : '#EAC88C'
            }}
          >
            Discard Changes
          </Button>
        </div>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <div className="gallery">
          {units && units.length > 0 && (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', flexWrap: 'wrap' }}>
              {units.map((unit) => {
                const cards = combinedCards.filter(el => el.cardId === unit.cardId);
                let url = `${cdnUrl}/cards/webp/v3/silver/${unit.name.replace(/\s+/g, '-')}.webp`;

                if (cards && cards.length > 0) {
                  return cards.map((card) => {
                    const foil = card && card.foil === 2 ? "gold" : "silver";
                    url = `${cdnUrl}/cards/webp/v3/${foil}/${unit.name.replace(/\s+/g, '-')}.webp`;
                    const key = `${card.cardId}-${card.foil}`;
                    return (
                      <Card 
                        key={key}
                        style={{ 
                          width: 150,
                          margin: 10, 
                          position: 'relative', // Add this for positioning the icon
                          transition: 'transform 0.3s ease-in-out', 
                          cursor: 'pointer' 
                        }} 
                        onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
                        onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}
                      >
                        {card.canLevel && (
                          <IconButton
                            style={{
                              position: 'absolute',
                              top: 0,
                              right: 0,
                              color: 'gold',
                            }}
                            size="small"
                          >
                            <ArrowUpwardIcon />
                          </IconButton>
                        )}
                        <CardMedia
                          component="img"
                          height="auto"
                          image={url}
                          alt={unit.name}
                          onClick={() => handleCardClick(card.cardId, foil)}
                        />
                        <CardContent style={{ padding: '8px' }}>
                          <Typography variant="h5" component="div" sx={{ fontSize: '14px', fontFamily: "Almendra" }}>
                            totalCP: {card.totalCP} <br />
                            {Array(card.maxLevel).fill('⭐').map((star, index) => (
                              <span key={index}>{star}</span>
                            ))}
                          </Typography>
                          <Switch
                            checked={isCardEnabled(card)}
                            onChange={(e) => handleToggle(card, e.target.checked)}
                            disabled={!editDeck}
                          />
                          { isCardEnabled(card) ? 'Active' : 'Inactive'}
                        </CardContent>
                      </Card>
                    );
                  });
                }

                const key = `${unit.cardId}-silver`;

                return (
                  <Card 
                    key={key}
                    style={{ 
                      width: 150,
                      margin: 10, 
                      transition: 'transform 0.3s ease-in-out', 
                      cursor: 'pointer'
                    }} 
                    onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
                    onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}
                    onClick={() => handleCardClick(unit.cardId, 'silver')}
                  >
                    <CardMedia
                      component="img"
                      height="auto"
                      image={url}
                      alt={unit.name}
                      sx={{ opacity: 0.5 }}
                    />
                    <CardContent style={{ padding: '8px' }}>
                      <Typography variant="h5" component="div" sx={{ fontSize: '14px', fontFamily: "Almendra" }}>
                        CardId: {unit.cardId} <br />
                        Amount: {0}
                      </Typography>
                    </CardContent>
                  </Card>
                );
              })}
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default Gallery;
