// src/features/playerEthAddress/playerEthAddressSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  address: null // or empty string if you prefer
};

export const playerEthAddressSlice = createSlice({
  name: 'playerEthAddress',
  initialState,
  reducers: {
    setAddress: (state, action) => {
      state.address = action.payload;
    },
    clearAddress: state => {
      state.address = null; // or empty string
    }
  }
});

export const { setAddress, clearAddress } = playerEthAddressSlice.actions;
export default playerEthAddressSlice.reducer;
