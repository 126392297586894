import { useEffect, useRef, useState, useCallback } from "react";
import { trimAddress } from "../utils"
import { Stage, withApp } from "react-pixi-fiber";
import "../App.css";
import Background from "../components/Background"
import GodCards from "../components/GodCards"
import OpponentGodCard from "../components/OpponentGodCard"
import HeroHandCards from "../components/HeroHandCards"
import BoardCards from "../components/BoardCards"
import BlinkingText from "../components/BlinkingText"
import RevoltFX from "../RevoltFX";

const height = window.innerHeight;
const width = window.innerWidth;

const OPTIONS = {
  backgroundColor: 0x000000,
  height: height,
  width: width,
  antialias: true,
  resolution: window.devicePixelRatio || 1,
  autoDensity: true
};

const BackgroundWithApp = withApp(Background)

export default function Game({ combatBusy, setCombatBusy, manaParticleContainerRef, manaParticleContainerRef2, isGameOver, godRef1, godRef2, critterRef, slotRef, sendMessage, websocketData, combatRoundsData, address, acknowledgeAction, firstToAct, isReplay }) {
  const stageRef = useRef(null)
  const [trigger, setTrigger] = useState(false);
  const [app, setApp] = useState(null);
  const [godSelected, setGodSelected] = useState(false);
  const [discardedCards, setDiscardedCards] = useState(false);
  const [pointerDown, setPointerDown] = useState(false);
  const [isPlayReady, setPlayReady] = useState(true)
  const [scale, setScale] = useState(null);
  const [dragTargetId, setDragTargetId] = useState(null)
  // isMyTurn is used to know when firstPlayer to act is done with his move in case the client is playing secondToAct
  const [isMyTurn, setIsMyTurn] = useState(false)
  
  const [critters, setCritters] = useState([]);
  const [dead, setDead] = useState([]);
  const [ready, setReady] = useState(false);
  const [newCritter, setNewCritter] = useState(null);
  const [discarded, setDiscarded] = useState([]);

  const [mySeat, setMySeat] = useState(null);
  const [playerNameButton, setPlayerNameButton] = useState(null);
  const [playerNameTop, setPlayerNameTop] = useState(null);

  const handleSurrender = () => {
    sendMessage(JSON.stringify({ type: 'surrender' }))
  }

  useEffect(() => {
    if (!mySeat && websocketData?.p1?.player && websocketData?.p2?.player) {

      if (isReplay && websocketData?.p1?.replayAddress && websocketData?.p2?.replayAddress) { 
        setMySeat('p1')
        setPlayerNameButton(trimAddress(websocketData.p1.replayAddress))
        setPlayerNameTop(trimAddress(websocketData.p2.replayAddress))
      } else if (!isReplay) {
        setMySeat(websocketData.mySeat)
        setPlayerNameButton(websocketData[websocketData.mySeat]?.name ? `${websocketData[websocketData.mySeat].name}-${websocketData[websocketData.mySeat].elo}` : `${trimAddress(websocketData[websocketData.mySeat].player)}-${websocketData[websocketData.mySeat].elo}`)
        setPlayerNameTop(websocketData[websocketData.opponentSeat]?.name ? `${websocketData[websocketData.opponentSeat].name}-${websocketData[websocketData.opponentSeat].elo}` : `${trimAddress(websocketData[websocketData.opponentSeat].player)}-${websocketData[websocketData.opponentSeat].elo}`)
      }

    }
  }, [websocketData, isReplay])

  // REPLAY HOOKS //////////////////////
  useEffect(() => {
    if (app && ready && (isReplay || !godSelected) && websocketData[mySeat]?.board?.god && websocketData?.turn == websocketData?.mySeat && websocketData?.round == 2) {
      const godCard = godRef1.current.find(card => card.data.tokenId == websocketData[mySeat].board.god.tokenId)
      if (godCard) {
        setTimeout(() => { godCard.onMouseClick() }, 2000)
      }
      
    }
  }, [websocketData[mySeat]?.board?.god, websocketData?.round, websocketData?.turn])

  useEffect(() => {
    if (app && (isReplay || discarded?.length == 0) && websocketData[mySeat]?.cardBurn?.length > 0 && websocketData?.turn == mySeat && websocketData?.round == 3) {
      for (let i = 0; i < 2; i++) {
        // console.log(`Discarding card with tokenId ${websocketData[mySeat].cardBurn[i]}`)
        const discardedCard = critterRef.current.find(card => card.data.tokenId == websocketData[mySeat].cardBurn[i])
        // console.log("discardedCard", discardedCard)
        if (discardedCard) discardedCard.onMouseClick()
      }
    }
  }, [websocketData[mySeat]?.cardBurn, websocketData?.round, websocketData?.turn])

  useEffect(() => {
    if (app && newCritter) {
      const playCard = critterRef.current.find(card => card?.data?.tokenId == newCritter?.tokenId)
      // console.log("*** playCard DEBUG ***", playCard)
      if (playCard && (!playCard.state.inPlay || isReplay)) {
        playCard.toggleCardMode()
        try {
          playCard.releaseCardOnSlot(newCritter.startingIndex)
        } catch(e) {
          console.error("releaseCardOnSlot: parent container does not exist")
          console.log(newCritter)
          throw new Error(e)
        }
      }
    }
  }, [newCritter])

  useEffect(() => {
    const newCritters = websocketData[mySeat]?.board?.critters;
    if (app && isReplay && newCritters?.length > 0 && mySeat) {
      
      for (let i = 0; i < newCritters.length; i++) {
        if (!critters.map(el => el.tokenId).includes(newCritters[i].tokenId)) {
          setNewCritter(newCritters[i])
          setCritters(prevCritters => [...prevCritters, newCritters[i]])
        }
      }
    }
  }, [mySeat, websocketData[mySeat]?.board?.critters])

  useEffect(() => {
    const newDead = websocketData[mySeat]?.board?.dead;
    if (app && isReplay && newDead?.length > 0 && mySeat) {
      for (let i = 0; i < newDead.length; i++) {
        if (!dead.map(el => el.tokenId).includes(newDead[i].tokenId) && !critters.map(el => el.tokenId).includes(newDead[i].tokenId)) {
          setNewCritter(newDead[i])
          setDead(newDead)
        }
      }
    }

  }, [mySeat, websocketData[mySeat]?.board?.dead])

  useEffect(() => {
    if (app && isReplay && mySeat) {
      // console.log("*** setIsMyTurn USE-EFFECT **, mySeat, turn, firstToAct", websocketData.mySeat, websocketData.turn, firstToAct)
      if (websocketData.winner) setIsMyTurn(true)
      else if (websocketData[mySeat] == firstToAct && websocketData.turn == firstToAct) setIsMyTurn(true)
      else if (websocketData[mySeat] !== firstToAct && websocketData.turn !== firstToAct) setIsMyTurn(true)
      else setIsMyTurn(false)
    }
  }, [websocketData?.turn, mySeat])

  //////////////////////

  function debounce(func, wait) {
    let timeout;

    // This function is returned and will be called many times
    return function executedFunction(...args) {
      // The context (`this`) and arguments (`args`) are saved
      // in order to be passed to `func` later
      const context = this;

      // The function to be executed after the debounce delay
      const later = function() {
        // Nullify the timer, indicating the debounce ended
        timeout = null;

        // Execute the original function with the correct context and arguments
        func.apply(context, args);
      };

      // Clear the timer if the function is called again within the wait period
      clearTimeout(timeout);

      // Restart the debounce timer to wait another `wait` ms before calling `later`
      timeout = setTimeout(later, wait);
    };
  }
  
  useEffect(() => { 
    setApp(stageRef.current._app.current)

    // Other initialization code that sets 'ready' to true when appropriate
    // For example, setReady(true) when certain conditions are met

    window.addEventListener('resize', debounce(() => setTrigger(true), 50));
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', debounce(() => setTrigger(true), 50))
    };
  }, []);


  const onDragStart = (event, tokenId) => {
    if (!tokenId) return
    console.log("onDragStart", tokenId)
    setDragTargetId(tokenId); // Assuming setDragTargetId updates the state with the current card's ID
  }

  const onDragMove = useCallback((event) => {
    if (dragTargetId) {
      const dragTarget = critterRef.current.find(card => card.data.tokenId === dragTargetId);
      if (dragTarget && !dragTarget.state.inPlay) {
        const dragTargetContainer = dragTarget.containerRef.current;
        dragTargetContainer.parent.toLocal(event.global, null, dragTargetContainer.position);
        // dragTargetContainer.current.children[0].alpha = 0.5;
      }
    }
  }, [dragTargetId]);

  useEffect(() => {
    // console.log(`Adding or removing onDragMove listener for dragTargetId: ${dragTargetId}`);

    if (app && discardedCards) {
      const allowDrag = isPlayReady && websocketData.turn == websocketData.mySeat;

      if (dragTargetId && allowDrag && !websocketData.winner) {
        // Add the event listener
        app.stage.on('pointermove', onDragMove);
      } else {
        // Remove the event listener
        app.stage.off('pointermove', onDragMove);
      }

      // Cleanup function to remove the event listener when the component unmounts or app/dragTargetId changes
      return () => {
        console.log(`Cleanup: removing onDragMove listener`);
        if (app?.stage) app.stage.off('pointermove', onDragMove);
      };
    }
  }, [dragTargetId]); // Include onDragMove in the dependency array

  useEffect(() => {
    const onPointerUp = () => {
      setDragTargetId(null)
    }
    if (app) {
      stageRef.current._app.current.stage.sortableChildren = true;

      app.ticker.speed = 1;
      app.stage.eventMode = 'static';
      app.stage.hitArea = app.screen;

      app.stage.on('pointerup', onPointerUp);
      app.stage.on('pointerupoutside', onPointerUp);

      // Cleanup
      return () => {
        if (app?.stage) app.stage.off('pointerup', onPointerUp);
        if (app?.stage) app.stage.off('pointerupoutside', onPointerUp);
      };
    }
  }, [app]);

  return (
      <Stage options={OPTIONS} ref={stageRef} x={0} y={0}>
        <RevoltFX app={app} setReady={setReady} ready={ready} loader={"Game"}>
         <BackgroundWithApp
          anchor={0}
          x={0}
          y={0}
          trigger={trigger} 
          resetTrigger={() => setTrigger(false)}
          websocketData={websocketData}
          sendMessage={sendMessage}
          isPlayReady={isPlayReady}
          setScale={setScale}
          scale={scale}
          isMyTurn={isMyTurn}
          handleSurrender={handleSurrender}
          playerNameButton={playerNameButton}
          playerNameTop={playerNameTop}
          isGameOver={isGameOver}
          manaParticleContainerRef={manaParticleContainerRef}
          manaParticleContainerRef2={manaParticleContainerRef2}
        />
        
        {app && <BlinkingText websocketData={websocketData} isPlayReady={isPlayReady} app={ app } pointerDown={pointerDown} firstToAct={firstToAct} combatBusy={combatBusy} isMyTurn={isMyTurn} isReplay={isReplay}/>}
        {godSelected &&
          <BoardCards 
            ref={slotRef}
            trigger={trigger} 
            resetTrigger={() => setTrigger(false)}
            app={app}
            critterRef={critterRef}
            discardedCards={discardedCards}
            pointerDown={pointerDown}
            godRef1={godRef1}
            godRef2={godRef2}
            sendMessage={sendMessage}
            websocketData={websocketData}
            combatRoundsData={combatRoundsData}
            setPlayReady={(isReady) => setPlayReady(isReady)}
            scale={scale}
            firstToAct={firstToAct}
            isMyTurn={isMyTurn}
            setIsMyTurn={setIsMyTurn}
            combatBusy={combatBusy}
            setCombatBusy={setCombatBusy}
            isReplay={isReplay}
          />
        }
        <OpponentGodCard 
          ref={godRef2} 
          trigger={trigger} 
          resetTrigger={() => setTrigger(false)}
          app={app}
          sendMessage={sendMessage}
          websocketData={websocketData}
          address={address}
          scale={scale}
        />
        <GodCards
          ref={godRef1} 
          trigger={trigger} 
          resetTrigger={() => setTrigger(false)}
          app={app}
          _setGodSelected={(cardId) => setGodSelected(cardId)}
          sendMessage={sendMessage}
          websocketData={websocketData}
          acknowledgeAction={acknowledgeAction}
          scale={scale}
        />
        {godSelected &&
          <HeroHandCards
            ref={critterRef} 
            slotRef={slotRef}
            godRef1={godRef1}
            godRef2={godRef2}
            trigger={trigger} 
            resetTrigger={() => setTrigger(false)}
            app={app}
            setDiscardedCards={() => setDiscardedCards(true)}
            pointerDown={(mana) => setPointerDown(mana)}
            pointerUp={() => setPointerDown(false)}
            sendMessage={sendMessage}
            websocketData={websocketData}
            acknowledgeAction={acknowledgeAction}
            isPlayReady={isPlayReady}
            scale={scale}
            onDragStart={onDragStart}
            setPlayReady={setPlayReady}
            discarded={discarded}
            setDiscarded={setDiscarded}
            isReplay={isReplay}
          />
        }
        </RevoltFX>
      </Stage>
  );
}