import React, { useEffect, useState, useRef } from "react";
import { Text, Graphics } from "react-pixi-fiber";
import * as PIXI from 'pixi.js';
import * as GameLoader from "./loaders/Game";
import { fx, loadFx } from "./loaders/loadFx.js";


const LoadingBar = React.forwardRef(({ progress, app, textStyle }, ref) => {
  return (
    <>
      <Graphics ref={ref} />
      <Text 
        text={`${progress.toFixed(0)}%`} 
        style={textStyle} 
        x={app.screen.width / 2} 
        y={app.screen.height / 2} 
        anchor={0.5} 
      />
    </>
  );
});

LoadingBar.displayName = "LoadingBar";

const RevoltFX = ({ children, app, setReady, ready }) => {

  const [loadingProgress, setLoadingProgress] = useState(0);
  const loadingBarRef = useRef(null);
  const tickerFunctionAdded = useRef(false);

  const redrawLoadingBar = () => {
    const g = loadingBarRef.current;
    if (g && app) {
      const barWidth = app.screen.width * 0.5 * (loadingProgress / 100); // 50% of screen width
      const barHeight = 20;
      const barX = (app.screen.width - app.screen.width * 0.5) / 2; // Center the bar horizontally
      const barY = (app.screen.height - barHeight) / 2; // Center the bar vertically
      const framePadding = 2;

      g.clear();

      // Bar background (frame)
      g.beginFill(0x4a3a29); // Dark brown for frame
      g.drawRect(barX - framePadding, barY - framePadding, app.screen.width * 0.5 + framePadding * 2, barHeight + framePadding * 2);
      g.endFill();

      // Loading bar
      g.beginFill(0x8b572a); // Fantasy theme color for loading bar
      g.drawRect(barX, barY, barWidth, barHeight);
      g.endFill();
    }
  };

  useEffect(() => {
    if (loadingBarRef.current) redrawLoadingBar();
    
    return () => {
      if (loadingBarRef.current) loadingBarRef.current.clear()
    }
  }, [loadingProgress]);

  useEffect(() => {
    let isCancelled = false;

    if (!ready) {
      GameLoader.loadFX(setLoadingProgress).then(() => {
        if (!isCancelled) {
          loadFx().then(() => {
            if (!isCancelled) setReady(true);
          });
        }
      });
    }

    // Cleanup function
    return () => {
      isCancelled = true;
    };
  }, [ready]);

  useEffect(() => {
    const ticker = PIXI.Ticker.shared;

    const tick = (delta) => {
      try {
        fx.update(delta);
      } catch(e) {
        console.error(e)
      }
    };

    if (ready && !tickerFunctionAdded.current) {
      console.log("*** REVOLTFX *** ADDING TICKER");
      ticker.add(tick);
      tickerFunctionAdded.current = true;
    }

    // return () => {
    //   console.log("*** REVOLTFX *** REMOVING TICKER");
    //   ticker.remove(tick);
    //   tickerFunctionAdded.current = false; // Reset the flag
    // };
  }, [ready]);

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { ready });
    }
    return child;
  });

  const textStyle = new PIXI.TextStyle({
    fontFamily: 'Arial',
    fontSize: 20,
    fill: '#d4af37', // Gold color for text
    align: 'center',
  });

  return (
    <>
      {!ready && app && (
        <LoadingBar ref={loadingBarRef} progress={loadingProgress} app={app} textStyle={textStyle} />
      )}
      {childrenWithProps}
    </>
  );
};

export default RevoltFX;
