import axios from "axios";
import { addLevels, addFilters, addTypeAndColor } from './collection';
import { dbApiUrl } from "../constants";
// import { cardsContract } from "../contracts/koinos"

export const fetchDeckFilters = (address) => {
  return axios.get(`${dbApiUrl}/deckfilter`, { params: { address }}).then(res => res.data.filters);
}

export const fetchAccountData = (address) => {
  return axios.get(`${dbApiUrl}/account`, { params: { address }}).then(res => res.data.account);
}

export const fetchGameHistory = (address) => {
  return axios.get(`${dbApiUrl}/games`, { params: { address }}).then(res => res.data.history);
}

export const fetchRanking = () => {
  return axios.get(`${dbApiUrl}/ranking`).then(res => res.data.ranking);
}

export const fetchCollection = async (address, wallet) => {
  // console.log("address", address)
  // console.log("wallet", wallet)
  const deckFilters = await fetchDeckFilters(address);
  console.log("deckFilters", deckFilters)

  return axios.get(`${dbApiUrl}/collection`, { params: { address, network: wallet } })
    .then((res) => {
      console.log("collection", res.data.collection)
      const collection = res.data.collection;
      const addedLevels = addLevels(collection, address);
      const addedTypesAndColors = addTypeAndColor(addedLevels);
      const addedFilters = addFilters(addedTypesAndColors, deckFilters);
      // console.log("addedFilters", addedFilters)
      return addedFilters;
    })
}
