import { useEffect, useRef, useState } from 'react';
import { Sprite, Container, Text } from 'react-pixi-fiber';
import * as PIXI from "pixi.js";
// import { gsap, absoluteCoords, wait } from '../../../utils';

function Armor({ baseValue, value, ready, scale, data, standard }) {
  const isGod = data.type == "Summoner"
  const containerRef = useRef();
  const [width, setWidth] = useState(null)
  const [height, setHeight] = useState(null)
  const [fontColor, setFontColor] = useState("white")

  // const bounce = () => {
  //   gsap.to(containerRef.current, {
  //     pixi: { 
  //       scaleX: containerRef.current.scale.x * 1.2, // Enlarge scale on the X-axis
  //       scaleY: containerRef.current.scale.y * 1.2, // Enlarge scale on the Y-axis
  //     },
  //     duration: 0.5, // How long the animation takes to complete
  //     yoyo: true, // Make the animation reverse once it completes
  //     repeat: 1, // How many times the animation repeats
  //     ease: "power1.inOut", // The type of easing to apply to the animation
  //   });
  // }

  useEffect(() => {
    if (ready) {
      let sampleCard = standard ? PIXI.utils.TextureCache["standard-silver-1"] : PIXI.utils.TextureCache["tactical-silver-1"]
      setWidth(sampleCard.width)
      setHeight(sampleCard.height)
      if (baseValue > value) setFontColor("red")
    }
  }, [ready])

  return (
    <Container ref={containerRef}>
      {
        value !== 0 &&
        (
          <>
            <Sprite 
              texture={PIXI.utils.TextureCache["armor"]} 
              x={width}
              y={-height}
              anchor={{ x: 0.5, y: 0.5 }}
              visible={true}
              scale={scale}
            />
            <Text
              text={isGod ? (value > 0 ? `+${value}` : `${value}`) : value}
              anchor={{x: 0.5, y: 0.5 }}
              x={width}
              y={-height}
              style={{
                fill: fontColor,
                fontFamily: "Almendra",
                fontStyle: "bold",
                fontSize: scale * 400
              }}
            />
          </>
        )
      }

    </Container>
  );
}

export default Armor;
