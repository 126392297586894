import { configureStore } from '@reduxjs/toolkit';
import websocketDataReducer from '../features/websocketData/websocketDataSlice';
import onlinePlayersReducer from '../features/onlinePlayers/onlinePlayersSlice';
import playerEthAddressReducer from '../features/playerEthAddress/playerEthAddressSlice';
import combatDataReducer from "../features/combatRounds/combatDataSlice";
import playerCollectionReducer from "../features/playerCollection/playerCollectionSlice";

export const store = configureStore({
  reducer: {
    websocketData: websocketDataReducer,
    playerEthAddress: playerEthAddressReducer,
    combatRounds: combatDataReducer,
    playerCollection: playerCollectionReducer,
    onlinePlayers: onlinePlayersReducer
  },
});