import { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Typography } from '@mui/material';

const NFTTransferModal = ({ open, onClose, onConfirm }) => {
  const [receiver, setReceiver] = useState('');

  const handleConfirm = () => {
    if (receiver) {
      onConfirm(receiver);
      setReceiver(''); // Clear input after confirmation
    } else {
      alert('Please enter a receiver address');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>NFT Transfer</DialogTitle>
      <DialogContent>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          Enter the address of the receiver to transfer your NFT.
        </Typography>
        <TextField
          label="Receiver Address"
          fullWidth
          variant="outlined"
          value={receiver}
          onChange={(e) => setReceiver(e.target.value)}
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleConfirm} color="primary" variant="contained">
          Confirm Transfer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NFTTransferModal;
