/* eslint-disable */

import { useState, useEffect, useCallback, useRef } from 'react';
import { messageAudio } from "../../audio.js";

const useWebSocket = (url, address) => {
  const [readyState, setReadyState] = useState(WebSocket.CLOSED);
  const [messageQueue, setMessageQueue] = useState([]);
  const [ws, setWs] = useState(null);
  const reconnectInterval = useRef(null);
  const [newMessage, setNewMessage] = useState(0);

  const addMessageToQueue = (newMessage) => setMessageQueue(prevMessages => [...prevMessages, newMessage]);

  const connect = useCallback(() => {
    const socket = new WebSocket(url);
    setWs(socket);

    socket.onopen = () => {
      setReadyState(WebSocket.OPEN);
      clearInterval(reconnectInterval.current);
    };
    socket.onclose = () => {
      setReadyState(WebSocket.CLOSED);
      reconnectInterval.current = setTimeout(() => connect(), 3000);
    };
    socket.onerror = (error) => {
      console.error('WebSocket error:', error)
    };
    socket.onmessage = (event) => {
      const data = JSON.parse(event.data)
      const { type } = data;
      const message = JSON.parse(event.data);
      // console.log("message", message)
      if (type == 'chat' || type == 'chart') {
        addMessageToQueue(message);
        if (message.address !== 'admin') messageAudio.play()
        if (message.address !== address) setNewMessage(prev => prev + 1)
      }
      else if (type == 'history' && data.messages.length > 0) {
        setMessageQueue(prev => [prev, ...data.messages])
        // console.log("messages", data.messages)
      }
    };
  }, [url, address]);

  useEffect(() => {
    if (readyState == 3) connect(); // Start connection

    return () => {
      if (ws) {
        ws.close();
      }
      if (reconnectInterval.current) {
        clearInterval(reconnectInterval.current);
      }
    };
  }, [readyState]);

  const sendMessage = useCallback((message) => {
    // console.log("*** sendMessage ***")
    // console.log(message);
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(message);
    }
  }, [ws]);

  return {
    readyState,
    sendMessage,
    messageQueue,
    setMessageQueue,
    newMessage,
    setNewMessage
  };
};

export default useWebSocket;
