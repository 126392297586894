
import { Modal, Box, Typography, Button } from '@mui/material';

const DeckWarningModal = ({ open, handleClose, handleConfirm }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#333',  // Changed background to a dark color
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: 'white' }}>
          Warning
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2, color: 'white' }}>
          Your current deck does not satisfy the conditions.
          <br/><br/>
          Free-2-play deck will be used.
        </Typography>
        <Box mt={3} display="flex" justifyContent="space-between">
          <Button variant="contained" color="primary" onClick={handleConfirm}>
            Yes
          </Button>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            No
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeckWarningModal;
