// src/features/combatData/combatDataSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  rounds: []
};

export const combatDataSlice = createSlice({
  name: 'combatData',
  initialState,
  reducers: {
    addCombatRound: (state, action) => {
      state.rounds.push(action.payload);
    },
    resetCombatData: (state) => {
      state.rounds = [];
    },
  }
});

export const { addCombatRound, resetCombatData } = combatDataSlice.actions;
export default combatDataSlice.reducer;
