import { useEffect, useState } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';

const statsWithImage = ["Melee", "Ranged", "Magic", "Armor", "Speed", 'Health', 'Mana'];
const properties = ['cardPower', 'mana', 'melee', 'ranged', 'magic', 'armor', 'speed', 'health', 'abilities'];


function StatsTable({ tableData, levelSteps, foil, abilities }) {
 
  const [enrichedTableData, setEnrichedTableData] = useState();
  const [columns, setColumns] = useState([]);
  const [labels, setLabels] = useState([]);
  const [levels, setLevels] = useState([]);

  const capitalizeFirstChar = str => str.charAt(0).toUpperCase() + str.slice(1);
  const reorderProperties = (obj, order) => {
    const reorderedObj = {};
    const hasOwnProperty = Object.prototype.hasOwnProperty;

    order.forEach(key => {
      if (hasOwnProperty.call(obj, key)) {
        reorderedObj[key] = obj[key];
      }
    });
    return reorderedObj;
  };

  useEffect(() => {
    const { stats } = tableData;

    if (stats?.attack && !stats.attack.every(el => !el)) stats.melee = stats.attack;

    for (let key in stats) {
      // console.log(key, stats[key])
      if (stats[key] && stats[key].every(el => !el)) delete stats[key]
    }

    const _levelSteps = levelSteps[tableData.rarity - 1][foil];
    setLevels(Array.from({ length: stats.mana.length }, (_, i) => i + 1));
    setEnrichedTableData({ ...stats, cardPower: _levelSteps })
  }, [tableData, foil])


  useEffect(() => {
    if (enrichedTableData) {
      const reorderedStats = reorderProperties(enrichedTableData, properties);
      setColumns(Object.keys(reorderedStats)) // .filter(stat => stat !== 'mana'))
      setLabels(Object.keys(reorderedStats).map(el => el.replace(/([a-z])([A-Z])/g, '$1 $2'))) // .filter(stat => stat !== 'mana'))
    }
  }, [enrichedTableData])

  return (
    <Box sx={{ flexGrow: 1, padding: '20px', maxWidth: '800px' }}>
      <TableContainer sx={{ borderRadius: '16px', border: 'none' }}>
        <Table>
          <TableHead>
            <TableRow sx={{ borderBottom: '2px solid #937341', height: '60px' }}>
              <TableCell sx={{ fontSize: '1.2rem', padding: '8px', textAlign: 'left', width: '50px' }}>Level</TableCell>
              {labels.map((statName) => (
                <TableCell key={statName} sx={{ fontSize: '1.2rem', padding: '8px', textAlign: 'left', width: '50px' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {
                      !statsWithImage.includes(capitalizeFirstChar(statName)) ? 
                        statName.charAt(0).toUpperCase() + statName.slice(1)
                      :
                        <img  
                          src={`${import.meta.env.VITE_CDN_URL}/cards/icons/${capitalizeFirstChar(statName)}.png`} 
                          alt={`${statName} icon`} 
                          style={{ marginLeft: '8px', width: '50px', height: '50px' }}
                        />
                    }
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {levels.map((level) => (
              <TableRow key={level} sx={{ borderBottom: '1px solid #937341', height: '60px' }}>
                <TableCell sx={{ padding: '8px', textAlign: 'center' }}>
                  ⭐ {level}
                </TableCell>
                {columns.map((statName) => (
                  <TableCell key={statName} sx={{ padding: '8px', textAlign: 'center' }}>
                    {statName === "abilities" ? (
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {enrichedTableData[statName][level - 1].map((ability, index) => (
                          <Tooltip key={index} title={abilities.find(el => el.name == ability)?.description || 'No description available'}>
                            <img 
                              src={`${import.meta.env.VITE_CDN_URL}/abilities-icons/icons/${ability.replace(" ", "-").toLowerCase()}.png`} 
                              alt={`${ability} icon`} 
                              style={{ marginLeft: '8px', width: '30px', height: '30px' }}
                            />
                          </Tooltip>
                        ))}
                      </Box>
                    ) : (
                      enrichedTableData[statName][level - 1] ? enrichedTableData[statName][level - 1] : 'N/A'
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default StatsTable;
