
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const LevelCapTable = ({ rarityIndex, levelCap, levelSteps, foil }) => {
  // Check if the rarityIndex is valid and get the level caps
  const caps = levelCap[rarityIndex];

  if (!caps) {
    return null;
  }

  const getBackgroundColor = (index) => index % 2 === 0 ? 'rgba(255, 255, 255, 0)' : 'rgba(169, 169, 169, 0.5)';
  const rarityLabels = ['Common', 'Rare', 'Epic', 'Legendary'];

  return (
    <Box sx={{ flexGrow: 1, padding: '20px' }}>
      <TableContainer component={Paper} sx={{ backgroundColor: 'transparent' }}>
        <Table sx={{ backgroundColor: 'transparent' }} aria-label="level cap table">
          <TableHead>
            <TableRow sx={{ backgroundColor: 'rgba(169, 169, 169, 0.5)' }}>
              <TableCell sx={{ backgroundColor: 'rgba(169, 169, 169, 0.5)' }}>Level</TableCell>
              <TableCell sx={{ backgroundColor: 'rgba(169, 169, 169, 0.5)' }}>Cards</TableCell>
              {rarityLabels.map((label, index) => (
                <TableCell key={index} align="center" sx={{ backgroundColor: 'rgba(169, 169, 169, 0.5)' }}>
                  {label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {caps.map((cap, index) => (
              <TableRow key={index} sx={{ backgroundColor: getBackgroundColor(index) }}>
                <TableCell component="th" scope="row" sx={{ backgroundColor: 'transparent' }}>
                  ⭐ {index + 1}
                </TableCell>
                <TableCell component="th" scope="row" sx={{ backgroundColor: 'transparent' }}>
                  {levelSteps[rarityIndex][foil][index]}
                </TableCell>
                {caps[index].map((value, idx) => (
                  <TableCell key={idx} align="center" sx={{ backgroundColor: 'transparent', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                    {value}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default LevelCapTable;
