import { useEffect, useRef, useState } from 'react';
import { Sprite, Container, Text } from 'react-pixi-fiber';
import * as PIXI from "pixi.js";

function Mana({ value, ready, scale, standard }) {

  const containerRef = useRef(null);
  const [width, setWidth] = useState(null)
  const [height, setHeight] = useState(null)

  useEffect(() => {
    if (ready) {
      let sampleCard = standard ? PIXI.utils.TextureCache["standard-silver-1"] : PIXI.utils.TextureCache["tactical-silver-1"]
      setWidth(sampleCard.width)
      setHeight(sampleCard.height)
    }
  }, [ready])
  return (
    <Container ref={containerRef}>
      {value > 0 && (
        <>
          <Sprite 
            texture={PIXI.utils.TextureCache["mana"]} 
            x={-width}
            y={-height}
            anchor={{ x: 0.5, y: 0.5 }}
            visible={true}
            scale={scale}
          />
          <Text
            text={value}
            anchor={{x: 0.5, y: 0.55 }}
            x={-width}
            y={-height}
            style={{
              "fill": 'white',
              fontFamily: "Almendra",
              fontStyle: "bold",
              fontSize: scale * 600
            }}
          />
        </>
      )}
    </Container>
  );
}

export default Mana;
