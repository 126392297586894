
import { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close'; // Import Close Icon
import { useLocation } from 'react-router-dom';
import { trimAddress } from "../utils";
import { useAccount } from 'wagmi';
import { cdnUrl } from "../constants.js";
import { useMediaQuery, Drawer } from '@mui/material';

const activeTabStyle = {
  color: '#937341',
  borderBottom: '1px solid #937341',
  paddingBottom: '0px',
  borderRadius: '0px',
  '&:hover': {
    borderBottom: '1px solid #937341'
  }
}

function Navbar({ setRoute, address, login, logout, wallet, accountData }) {
  const { isDisconnected } = useAccount();
  const [username, setUsername] = useState(null);
  const location = useLocation();
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    if (accountData?.name) setUsername(accountData.name);
    else if (address) setUsername(trimAddress(address));
  }, [accountData, address]);

  // useEffect(() => {
  //   console.log("mobile navbar wallet", wallet)
  //   console.log("mobile navbar isDisconnected", isDisconnected)
  //   console.log("mobile navbar address", address)
  // }, [wallet, isDisconnected, address])

  const handleDropdownToggle = () => setDropdownOpen(!isDropdownOpen);
  const closeDropdown = () => setDropdownOpen(false);

  return (
    <AppBar position="fixed" className="NavBar" 
      sx={{
        background: 'black',
        boxShadow: 'none',
        color: 'white',
        height: isSmallScreen ? '96px' : '144px',
        padding: '0px 0',
        overflow: 'hidden',
        width: '100%',
        fontFamily: 'Inter, sans-serif'
      }}
    >
      <Toolbar
        sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {/* Logo as Home Button */}
        <Box className="logo" sx={{ display: 'flex', alignItems: 'center', marginRight: '20px', marginLeft: '40px' }}>
          <Button onClick={() => setRoute("/")}>
            <img 
              src={`${cdnUrl}/landing/small-logo.png`} 
              alt="Game Logo" 
              style={{ width: isSmallScreen ? '97px' : '130px', height: 'auto', cursor: 'pointer' }} 
            />
          </Button>
        </Box>

        {!isSmallScreen && (
          // Full navigation for desktop
          <>
            {/* Add other buttons here */}
          </>
        )}

        {isSmallScreen && (
          <IconButton onClick={handleDropdownToggle}>
            <img src={`${cdnUrl}/app/mobile/navbar/burger-icon.svg`} alt="menu icon" />
          </IconButton>
        )}
      </Toolbar>

      <Drawer
        anchor="top"
        open={isDropdownOpen}
        onClose={closeDropdown}
        sx={{
          "& .MuiDrawer-paper": {
            backgroundColor: 'black',
            color: 'white',
            width: '100%',
            height: '100%',
          }
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pt: 4 }}>
          <IconButton onClick={closeDropdown} sx={{ position: 'absolute', top: 10, right: 10, color: 'white' }}>
            <CloseIcon />
          </IconButton>
          <Button onClick={() => { setRoute("/battle"); closeDropdown(); }} sx={location.pathname === '/battle' ? activeTabStyle : null}>
            Battle
          </Button>
          <Button onClick={() => { setRoute("/shop"); closeDropdown(); }} sx={location.pathname === '/shop' ? activeTabStyle : null}>
            Shop
          </Button>
          <Button onClick={() => { setRoute("/openpacks"); closeDropdown(); }} sx={location.pathname === '/openpacks' ? activeTabStyle : null}>
            Open Packs
          </Button>
          <Button onClick={() => { setRoute("/collection"); closeDropdown(); }} sx={location.pathname === '/collection' ? activeTabStyle : null}>
            Collection
          </Button>
          <Button onClick={() => { setRoute("/referral"); closeDropdown(); }} sx={location.pathname === '/referral' ? activeTabStyle : null}>
            Referrals
          </Button>
          <Button onClick={() => { setRoute("/wallet"); closeDropdown(); }} sx={location.pathname === '/wallet' ? activeTabStyle : null}>
            Wallet
          </Button>

          {
            isDisconnected || (wallet === "Kondor" || wallet === "Konio") ? (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {address && (
                  <div className="navbar-box">
                    <Typography variant="subtitle1">
                      {username}
                    </Typography>
                  </div>
                )}
                {!address && (
                  <Button className="button" color="inherit" onClick={() => { login(); closeDropdown(); }} sx={{ marginLeft: 'auto' }}>
                    Login
                  </Button>
                )}
                {address && (
                  <Button className="button" color="inherit" onClick={logout}>
                    Logout
                  </Button>
                )}
              </Box>
            ) : (
              <appkit-button onClick={closeDropdown}>
              </appkit-button>
            )
          }
        </Box>
      </Drawer>
      <Box 
        sx={{ 
          width: '100%', 
          position: 'absolute', 
          top: isSmallScreen ? '60px' : '106px'
        }}>
        <img
          src={`${cdnUrl}/battle-menu/divider.png`}
          alt="divider"
          style={{ width: '100%', height: '72px' }}
        />
      </Box>
    </AppBar>
  );
}

export default Navbar;
