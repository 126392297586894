import { useEffect, useRef, useState } from 'react';
import { Graphics } from "react-pixi-fiber";

const GameOverlay = ({ app, ready, websocketData, isPlayReady, trigger }) => {
  const overlayRef = useRef();
  const [show, setShow] = useState(false)

  function drawOverlay() {
    overlayRef.current.clear();
    overlayRef.current.beginFill(0x000000, 0.7); // Semi-transparent black
    overlayRef.current.drawRect(0, 0, app.screen.width, app.screen.height);
    overlayRef.current.endFill();
  }

  useEffect(() => {
    if (trigger && show) drawOverlay()
  }, [trigger])

  useEffect(() => {
    if (app && ready && isPlayReady && !websocketData.winner && websocketData.turn == websocketData.mySeat) {
      switch (websocketData.round) {
        case (2):
          setShow(true)
          break
        case (3): 
          setShow(true)
          break;
        default:
          setShow(false)
          break
      }
    } else if (websocketData.winner && isPlayReady) {
      setShow(true)
    } else {
      setShow(false)
    }

  }, [websocketData.turn, isPlayReady, app, ready]);

  useEffect(() => {
    if (show) {
      drawOverlay()
      // if (boardRef.current) boardRef.current.children.sort((a, b) => a.zIndex - b.zIndex);
    } else {
      overlayRef.current.clear();
    }
  }, [show])

  return (
    <Graphics ref={overlayRef} zIndex={5}/>
  );
};


export default GameOverlay;