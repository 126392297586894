
import { getContracts, faithDecimals, koinDecimals } from '../constants';



export const getKoinBalance = (address) => {
  return getContracts().koinContract.functions.balanceOf({ owner: address }).then(res => res.result?.value / (10**koinDecimals))
}

export const getFaithBalance = (address) => {
  return getContracts().faithContract.functions.balanceOf({owner: address}).then(res => res.result?.value / (10**faithDecimals))
}