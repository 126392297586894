import { FX } from "@siri4/revolt-fx/lib";
import * as PIXI from "pixi.js";
export let fx = new FX();
export let loaded = false;

const cdnUrl = import.meta.env.VITE_CDN_URL;

function addAsset(url, alias) {
  console.log(url)
  if (!PIXI.Assets.cache.has(alias)) {
    PIXI.Assets.add(alias, url);
  }
}

const assets = [
  ['revoltfx/default-bundle.json', 'fx-settings'],
  ['revoltfx/revoltfx-spritesheet.json', 'fx-spritesheet'],
  ['custom-pixi-particles/multipacked-0.json', 'custom-spritesheet'],
  ['custom-pixi-particles/images.json', 'custom-spritesheet2'],
]

assets.forEach(([file, alias]) => addAsset(`${cdnUrl}/${file}`, alias));

export async function loadFx () {
  // Initialize FX with loaded settings
  // console.log("*** FX loaded ***", loaded)
  if (!loaded) {
		await PIXI.Assets.load(assets.map(([, alias]) => alias));
		let fxSettings = PIXI.Assets.get('fx-settings');
		// console.log("fxSettings", fxSettings)
		let fxSpritesheet = PIXI.Assets.get('fx-spritesheet');
		// console.log("fxSpritesheet", fxSpritesheet)
		await fx.initBundle(fxSettings, fxSpritesheet, false);
  }
  loaded = true;
  return fx;
}

