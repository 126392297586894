/* eslint-disable */


import { useEffect, useRef, useState, forwardRef, useCallback } from "react";
import { absoluteCoords } from "../utils"
import * as PIXI from 'pixi.js';
import { Stage, Sprite, withApp, Container } from "react-pixi-fiber";
import "../App.css";
import Background from "../components/Background/Test"
import GodCards from "../components/GodCards"
import OpponentGodCard from "../components/OpponentGodCard"
// import HeroHandCards from "../components/HeroHandCards"
// import BoardCards from "../components/Test/BoardCards"
import Card from "../components/card"
import BlinkingText from "../components/BlinkingText"
// import { setGameOver } from "../features/gameStatus/gameStatusSlice";
import RevoltFX from "../RevoltFX";
import { useDispatch } from 'react-redux';

const height = window.innerHeight;
const width = window.innerWidth;

const OPTIONS = {
  backgroundColor: 0x000000,
  height: height,
  width: width,
  antialias: true,
  resolution: window.devicePixelRatio || 1,
  autoDensity: true
};

const BackgroundWithApp = withApp(Background)

export default function Test({ setShowBoard, godRef1, godRef2, critterRef, slotRef, sendMessage, websocketData, combatRoundsData, address, acknowledgeAction, firstToAct, isReplay }) {
  const stageRef = useRef(null)
  const [trigger, setTrigger] = useState(false);
  const [app, setApp] = useState(null);
  const [godSelected, setGodSelected] = useState(false);
  const [discardedCards, setDiscardedCards] = useState(false);
  const [pointerDown, setPointerDown] = useState(false);
  const [isPlayReady, setPlayReady] = useState(true)
  const [scale, setScale] = useState(1);
  const [dragTargetId, setDragTargetId] = useState(null)
  const [isMyTurn, setIsMyTurn] = useState(false)
  const [combatBusy, setCombatBusy] = useState(false)
  const [critters, setCritters] = useState([]);
  const [dead, setDead] = useState([]);
  const [ready, setReady] = useState(false);
  const [newCritter, setNewCritter] = useState(null);
  const [discarded, setDiscarded] = useState([]);

  const onDragStart = (event, tokenId) => {
    if (!tokenId) return
    // console.log("onDragStart", tokenId)
    setDragTargetId(tokenId); // Assuming setDragTargetId updates the state with the current card's ID
  }


  useEffect(() => {
    // setShowBoard(true)
    setApp(stageRef.current._app.current)
  }, [])

  return (
    <Stage options={OPTIONS} ref={stageRef} x={0} y={0}>
      <RevoltFX app={app} setReady={setReady} ready={ready} loader={"Game"}>
       <BackgroundWithApp
        anchor={0}
        x={0}
        y={0}
        trigger={trigger} 
        resetTrigger={() => setTrigger(false)}
        websocketData={websocketData}
        sendMessage={sendMessage}
        isPlayReady={isPlayReady}
        setScale={setScale}
        scale={scale}
        isMyTurn={isMyTurn}
      />
      </RevoltFX>
    </Stage>
  );
}