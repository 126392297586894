import { useState } from 'react';
import Grid from '@mui/material/Grid2'; // Grid version 2
import { cdnUrl } from "../../constants.js";

const Footer = () => {
  const [hoveredIcon, setHoveredIcon] = useState(null);

  const handleMouseEnter = (icon) => {
    setHoveredIcon(icon);
  };

  const handleMouseLeave = () => {
    setHoveredIcon(null);
  };

  return (
    <footer style={styles.footer}>
      <Grid container size={{ xs: 12 }} alignItems="center" justifyContent="center">
        <Grid size={{ xs: 12 }} style={styles.logoContainer}>
          <img 
            src={`${cdnUrl}/landing/small-logo.png`} 
            alt="Company Logo" 
            style={styles.logo} 
          />
        </Grid>
        <Grid size={{ xs: 12 }} style={styles.textContainer}>
          <p style={styles.text}>Copyright © 2024 LordsForsaken. All rights reserved.</p>
        </Grid>
        <Grid size={{ xs: 12 }} style={styles.socialsContainer}>
          {/* Social Icons wrapped in links */}
          <a 
            href="https://twitter.com/lordsforsak3n" 
            target="_blank" 
            rel="noopener noreferrer" 
            onMouseEnter={() => handleMouseEnter('twitter')} 
            onMouseLeave={handleMouseLeave} 
            style={getIconStyle(hoveredIcon === 'twitter')}
          >
            <img 
              src={`${cdnUrl}/app/footer/socials/twitter.svg`} 
              alt="Twitter" 
              style={styles.icon} 
            />
          </a>
          <a 
            href="https://discord.gg/VBKmvh2u6S" 
            target="_blank" 
            rel="noopener noreferrer" 
            onMouseEnter={() => handleMouseEnter('discord')} 
            onMouseLeave={handleMouseLeave} 
            style={getIconStyle(hoveredIcon === 'discord')}
          >
            <img 
              src={`${cdnUrl}/app/footer/socials/discord.svg`} 
              alt="Discord" 
              style={styles.icon} 
            />
          </a>
          <a 
            href="https://t.me/lordsforsaken" 
            target="_blank" 
            rel="noopener noreferrer" 
            onMouseEnter={() => handleMouseEnter('telegram')} 
            onMouseLeave={handleMouseLeave} 
            style={getIconStyle(hoveredIcon === 'telegram')}
          >
            <img 
              src={`${cdnUrl}/app/footer/socials/telegram.svg`} 
              alt="Telegram" 
              style={styles.icon} 
            />
          </a>
          <a 
            href="https://wiki.lordsforsaken.com" 
            target="_blank" 
            rel="noopener noreferrer" 
            onMouseEnter={() => handleMouseEnter('wiki')} 
            onMouseLeave={handleMouseLeave} 
            style={getIconStyle(hoveredIcon === 'wiki')}
          >
            <img 
              src={`${cdnUrl}/app/footer/socials/wiki.svg`} 
              alt="Wiki" 
              style={styles.icon} 
            />
          </a>
          <a 
            href="https://github.com/lordsforsaken" 
            target="_blank" 
            rel="noopener noreferrer" 
            onMouseEnter={() => handleMouseEnter('github')} 
            onMouseLeave={handleMouseLeave} 
            style={getIconStyle(hoveredIcon === 'github')}
          >
            <img 
              src={`${cdnUrl}/app/footer/socials/github.svg`} 
              alt="GitHub" 
              style={styles.icon} 
            />
          </a>
        </Grid>
      </Grid>
    </footer>
  );

  function getIconStyle(isHovered) {
    return {
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      transition: 'transform 0.3s, opacity 0.3s',
      transform: isHovered ? 'scale(1.2)' : 'scale(1)',
      opacity: isHovered ? 1 : 0.8, // Change opacity on hover
    };
  }
};

const styles = {
  footer: {
    padding: '40px 0',
    gap: '0px',
    borderTop: '1px solid #E1B15E80',
    opacity: '1',
    backgroundColor: 'black',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  socialsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '15px',
    marginTop: '10px',
  },
  text: {
    margin: 0,
    color: 'white',
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '20px',
    textAlign: 'left',
  },
  logo: {
    width: '168px',
    height: '80px',
  },
  icon: {
    width: '20px',
    height: '20px', // Adjusted size for all icons
  },
};

export default Footer;
