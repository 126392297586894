
/* eslint-disable */


import { useState, useEffect } from 'react';
import { Snackbar, Alert, Button } from '@mui/material';
import { useNavigate } from "react-router-dom";

const nileLink = 'https://www.nile.build/swap?to=0x46BF60BD1d8e61fE8975B4122224f8ED81C1164f&from=0x373d04A7F3594FF9011Af5084587182C0056721e';
const koindxLink = 'https://app.koindx.com/swap'

// Mock function to fetch $FAITH balance and pack price (replace with actual API calls)
const fetchFaithBalance = async () => 100; // Example balance, replace with actual data
const fetchPackPrice = async () => 20; // Example pack price, replace with actual data

const PackAlert = ({ showCanBuyPacks, setShowCanBuyPacks, wallet }) => {
  const [faithBalance, setFaithBalance] = useState(0);
  const [packsCount, setPacksCount] = useState(0);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpen(false);
  };

  const handleRedirect = () => {
    if (wallet == "Konio" || wallet == "Kondor") navigate("/packs")
    else window.open(nileLink, '_blank')
  }

  // useEffect(() => {
  //   console.log("PACK ALERT showCanBuyPacks" , showCanBuyPacks)
  // }, [showCanBuyPacks])

  return (
    <Snackbar
      open={showCanBuyPacks > 0}
      autoHideDuration={10000}
      // onClose={() => setShowCanBuyPacks(0)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      sx={{
        '& .MuiSnackbarContent-root': {
          backgroundColor: '#1e1e1e', // Dark background
          color: '#EAC88C', // Golden font color
          fontFamily: '"Poppins", sans-serif', // Poppins font
          borderRadius: 3, // Slight border-radius for a soft look
          padding: '10px 20px',
        },
        '& .MuiAlert-root': {
          backgroundColor: '#1e1e1e', // Dark background for the Alert
          color: '#EAC88C', // Golden text color for Alert
          fontFamily: '"Poppins", sans-serif', // Apply Poppins font to Alert
          border: '1px solid #EAC88C', // Golden border
          borderRadius: 3, // Slight border-radius for a soft look
        },
      }}
    >
      <Alert
        onClose={() => setShowCanBuyPacks(0)}
        severity="info"
        sx={{
          display: 'flex',
          alignItems: 'center',
          fontFamily: '"Poppins", sans-serif', // Apply Poppins font here as well
          fontSize: '1rem',
          color: '#EAC88C', // Golden color for text
        }}
      >
        {`You can buy now ${showCanBuyPacks} pack${packsCount > 1 ? 's' : ''} with your current $FAITH balance!`}
        <Button
          variant="contained"
          color="primary"
          size="small"
          target="_blank"
          onClick={handleRedirect}
          sx={{
            ml: 2,
            color: 'black',
            fontFamily: '"Germania One", cursive', // Germania One font for button text
            backgroundColor: '#FFD700', // Golden button
            '&:hover': {
              backgroundColor: '#FFC107', // Slightly darker golden on hover
            },
          }}
        >
          Buy Now
        </Button>
      </Alert>
    </Snackbar>
  );
};

export default PackAlert;
