

import { useEffect, useRef } from "react";
import * as PIXI from 'pixi.js';
import { Container, Sprite, Text } from "react-pixi-fiber";
import { fx } from "../../loaders/loadFx.js";
import TextWrapper from "./TextWrapper.jsx"
import { customPixiParticles } from 'custom-pixi-particles'
import Button from "./Button"
import { getParticlesDefaultConfig } from "../../constants"

const idealBoardArea = 7500 * 5250; // Example ideal area, adjust based on your design preferences

const Background = ({ emitterRef, ready, setScale, scale, app, cardsMinted, pixiDustContainer, vortexEffectContainer, opened, revealAllHandler }) => {

  const backgroundSpriteRef = useRef();
  const fireEffectContainerRef1 = useRef();
  const fireEffectContainerRef2 = useRef();
  // const emitterRef = useRef(null);
  const pixiDustEmitterRef = useRef(null);
  const fireEmitter1Ref = useRef(null);
  const fireEmitter2Ref = useRef(null);
  const revealRef = useRef(null);

  async function resize() {
    console.log("resizing...")
    // Calculate the new width and height for the renderer
    let newWidth, newHeight;
    const windowAR = window.innerWidth / window.innerHeight;
    const bgAR = PIXI.utils.TextureCache['portal'].width / PIXI.utils.TextureCache['portal'].height;

    if (windowAR < bgAR) {
        // Window is narrower than the background aspect ratio
        newWidth = window.innerWidth;
        newHeight = window.innerWidth / bgAR;
    } else {
        // Window is wider than the background aspect ratio
        newHeight = window.innerHeight;
        newWidth = window.innerHeight * bgAR;
    }

    // Calculate the current area of the board
    const currentBoardArea = newWidth * newHeight;

    // Calculate the scale factor based on the ratio of the current to ideal board area
    const scaleFactor = Math.sqrt(currentBoardArea / idealBoardArea);

    // Resize the renderer
    app.renderer.resize(newWidth, newHeight);

    if (!backgroundSpriteRef.current.destroyed) {
      backgroundSpriteRef.current.width = newWidth;
      backgroundSpriteRef.current.height = newHeight;
      backgroundSpriteRef.current.x = app.screen.width / 2;
      backgroundSpriteRef.current.y = app.screen.height / 2;
      backgroundSpriteRef.current.anchor.set(0.5);
    }

    // Adjust the scale using the calculated scale factor
    setScale(scaleFactor);
    // console.log("*** SCALE FACTOR ***", scaleFactor)
  }

  useEffect(() => {
    if (app && ready) {
      backgroundSpriteRef.current.texture = PIXI.utils.TextureCache['portal'];
      window.addEventListener('resize', resize);
      resize()
    }

    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [app, ready])

  useEffect(() => {
    if (cardsMinted) {
      emitterRef.current.stop()
      fireEmitter1Ref.current.stop()
      fireEmitter2Ref.current.stop()
      if (fireEffectContainerRef1.current) fireEffectContainerRef1.current.destroy()
      if (fireEffectContainerRef2.current) fireEffectContainerRef2.current.destroy()
      if (backgroundSpriteRef.current) backgroundSpriteRef.current.visible = false;
      pixiDustEmitterRef.current = fx.getParticleEmitter('fairy-dust');
      pixiDustContainer.current.x = app.screen.width / 2;
      pixiDustContainer.current.y = app.screen.height / 2;
      pixiDustEmitterRef.current.init(pixiDustContainer.current, true, 1);
    }
  }, [cardsMinted])

  useEffect(() => {

    if (ready && app && scale) {
      let config = getParticlesDefaultConfig().fire;
      // console.log("scale", scale)

      if (!cardsMinted) {
        if (fireEffectContainerRef1?.current?.scale) fireEffectContainerRef1.current.scale.set(scale * 4)
        if (fireEffectContainerRef2?.current?.scale) fireEffectContainerRef2.current.scale.set(scale * 4)
      }

      if (!emitterRef.current) emitterRef.current = fx.getParticleEmitter("plasma-corona");
      emitterRef.current.init(vortexEffectContainer.current, true, 2);

      if (vortexEffectContainer.current) {
        vortexEffectContainer.current.x = app.screen.width * 0.505;
        vortexEffectContainer.current.y = app.screen.height * 0.54;
        vortexEffectContainer.current.scale.y = scale * 1.1;
        vortexEffectContainer.current.scale.x = scale * 1.66;
        vortexEffectContainer.current.skew.x = scale * 0.5;
      }

      if (!fireEmitter1Ref.current) {
        fireEmitter1Ref.current = fireEffectContainerRef1.current.addChild(customPixiParticles.create(config))
        fireEmitter1Ref.current.updatePosition({ x: app.screen.width * 0.275 / (scale*4), y: app.screen.height * 0.47 / (scale*4) })
        fireEmitter1Ref.current.play()
      }

      if (!fireEmitter2Ref.current) {
        fireEmitter2Ref.current = fireEffectContainerRef2.current.addChild(customPixiParticles.create(config))
        fireEmitter2Ref.current.updatePosition({ x: app.screen.width * 0.74 / (scale*4), y: app.screen.height * 0.47 / (scale*4) })
        fireEmitter2Ref.current.play()
      }
    }

  }, [ready, app, scale]);

  return (
    <Container>
      <Sprite ref={backgroundSpriteRef}></Sprite>
      <Container ref={vortexEffectContainer}></Container>
      <Container ref={fireEffectContainerRef1}></Container>
      <Container ref={fireEffectContainerRef2}></Container>
      <Container ref={pixiDustContainer}></Container>
      {
        ready && !cardsMinted && scale &&
        <>
          <Text
            text={"The Ritual of Awakening"}
            anchor={{x: 0.5, y: 0.5 }}
            x={app.screen.width * 0.26}
            y={app.screen.height * 0.86}
            style={{
              fill: "white",
              fontFamily: "Cinzel",
              fontStyle: "bold",
              fontSize: scale * 300
            }}
          />
          <TextWrapper
            text={"The opening of a card pack is not merely an act of discovery; it is a sacred ritual, a reenactment of the ancient Ritual of Awakening. Each card pack represents a sealed covenant, a dormant connection between the mortal realm and the divine. By invoking the Ritual of Awakening, players call upon the latent energies within the Cipher Cards, seeking to unlock the powers and wisdom of the Gods contained within."}
            boxWidth={app.screen.width * 0.5}
            x={app.screen.width * 0.75}
            y={app.screen.height * 0.86}
            style={{
              fill: "white",
              fontFamily: "Cinzel",
              fontStyle: "bold",
              fontSize: scale * 100
            }}
          />
        </>
      }
      {
        cardsMinted && opened && app && setScale &&
          <Button
            ref={revealRef}
            text="Reveal All"
            onClick={revealAllHandler}
            x={app.screen.width * 0.20} // Adjust x position to account for the width of the button
            y={app.screen.height * 0.90} // Adjust y position to account for the height of the button
            width={300} // Width of the button
            height={150} // Height of the button
            style={{
              fill: "white",
              fontFamily: "Cinzel",
              fontStyle: "bold",
              fontSize: scale * 200
            }}
          />
      }
    </Container>
  );
};

export default Background;
