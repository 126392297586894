// src/features/websocketData/websocketDataSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  // Placeholder initial state
  isLoaded: false,
  data: null
};

export const websocketDataSlice = createSlice({
  name: 'websocketData',
  initialState,
  reducers: {
    setInitialState: (state, action) => {
      return { ...action.payload, isLoaded: true };
    },
    updateData: (state, action) => {
      // console.log(action)
      action.payload.forEach(update => {
        const [keyPath, operator, value, index] = update;
        let toModify = state;
        const keys = keyPath.split('.');
        const lastKey = keys.pop();

        keys.forEach(key => {
          if (toModify[key] === undefined) toModify[key] = {};
          toModify = toModify[key];
        });

        try {
          switch (operator) {
            case 'inc':
              toModify[lastKey] = (toModify[lastKey] || 0) + value;
              break;

            case 'push':
              if (!Array.isArray(toModify[lastKey])) toModify[lastKey] = [];
              toModify[lastKey].push(value);
              break;
            case 'remove':
              toModify[lastKey].splice(index, 1)[0];
              break;

            case 'insert':
              toModify[lastKey].splice(index, 0, value)
              break;

            case 'set':
              toModify[lastKey] = value;
              break;

            default:
              break;
          }     
        } catch(e) {
          console.log(keyPath, operator, value, index)
          console.error(e)
        }
      });
    },

    resetData: (state, action) => {
      // Use initialState values for resetting
      const path = action.payload;
      if (!path) {
        return initialState; // Reset the entire state to initial state
      }
      let current = state;
      let initialCurrent = initialState;
      const keys = path.split('.');
      const lastKey = keys.pop();
      keys.forEach(key => {
        if (current[key] === undefined) {
          current[key] = {};
        }
        current = current[key];
        if (initialCurrent[key] === undefined) {
          initialCurrent[key] = {};
        }
        initialCurrent = initialCurrent[key];
      });

      // Reset specific nested value to its initial state value
      current[lastKey] = initialCurrent[lastKey] ?? {}; // Use default if not defined in initialState
    },

    setRequestAction: (state, action) => {
      return { ...state, ...action.payload }
    }
  }
});

export const { setInitialState, updateData, setRequestAction, resetData } = websocketDataSlice.actions;
export default websocketDataSlice.reducer;
