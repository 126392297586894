import { useEffect, useState } from 'react';
import { Box, Button, Tabs, Tab } from '@mui/material';
import Grid from '@mui/material/Grid2'; // Grid version 2
import { useNavigate } from 'react-router-dom';
import Foils from "./Foils"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { cdnUrl } from "../../constants.js";

const rarities = ["Common", "Rare", "Epic", "Legendary"];
const civMapping = {
  Purple: 'Norse',
  Yellow: 'Egypt',
  White: 'Greece'
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

function TopBanner({ tableData, foil, setFoil, tabValue, setTabValue }) {
  const [gradient, setGradient] = useState('linear-gradient(to right, white, white)');
  const [backgroundImg, setBackgroundImg] = useState(null);

  const handleChange = (event, newValue) => {
    console.log("newValue", newValue)
    setTabValue(newValue);
  };
  const navigate = useNavigate();

  useEffect(() => {
    if (tableData) {
      switch (tableData.color) {
        case "White":
          setGradient('linear-gradient(to right, #B2BEB5, #7393B3)');
          setBackgroundImg(`url("${cdnUrl}/logos-icons/civs-icons/gray/greece.png")`)
          break;
        case "Yellow":
          setGradient('linear-gradient(to right, yellow, orange)');
          setBackgroundImg(`url("${cdnUrl}/logos-icons/civs-icons/gray/egypt.png")`)
          break;
        case "Purple":
          setGradient('linear-gradient(to right, purple, pink)');
          setBackgroundImg(`url("${cdnUrl}/logos-icons/civs-icons/gray/norse.png")`)
          break;
        default:
          setGradient('linear-gradient(to right, white, white)');
          setBackgroundImg('url("")')
          break;
      }
    }
  }, [tableData]);

  const rarityStyle = (rarity) => {
    switch (rarity) {
      case 1: // Common
        return { color: 'white', fontWeight: '900' };
      case 2: // Rare
        return { color: 'blue', fontWeight: '900' };
      case 3: // Epic
        return { color: 'pink', fontWeight: '900' };
      case 4: // Legendary
        return { color: 'goldenrod', fontWeight: '900' };
      default:
        return {};
    }
  };

  return (
    <Box
      id="banner"
      sx={{
        flexGrow: 1,
        background: `${gradient}`,
        transition: 'background 0.5s ease',
        overflow: 'hidden',
        padding: '20px',
        height: '200px'
      }}
      style={{
        position: 'absolute',
        top: '140px',
        left: '0',
        width: '100%',
        boxSizing: 'border-box'
      }}
    >
      <Box
        sx={{
          content: '""',
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: '120%',
          height: '120%',
          backgroundImage: backgroundImg,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'contain',
          transform: 'translate(-60%, -45%) rotate(30deg)',
          zIndex: 0,
          opacity: 0.5
        }}
      />
        <Grid container spacing={2} sx={{ position: 'relative', zIndex: 1, height: '100px' }}>
          {/*ROW #1*/}
          <Grid size={{ xs: 2 }} offset={{ xs: 2 }}>
            <Button 
              startIcon={<ArrowBackIcon />}
              className="image-button"
              color="primary" 
              onClick={() => navigate('/collection')} 
              style={{ marginBottom: 20, width: '200px', height: '50px' }}
            >
              Collection
            </Button>
          </Grid>
          <Grid size={{ xs: 3, sm: 3, md: 1 }} offset={{ xs: 4 }}>
            <Foils foil={foil} setFoil={setFoil} />
          </Grid>

          {/*ROW #2*/}
          <Grid size={{ xs: 2, md: 2 }} offset={{ xs: 2 }}>
            <Box sx={{ marginBottom: '0px', fontFamily: 'Germania One' }}>
              <p style={{ fontSize: '1.7rem', fontWeight: "900", marginBottom: '0px', textAlign: 'left' }}>{tableData?.name}</p>
              <Box sx={{ display: 'flex', alignItems: 'left', marginTop: '0px' }}>
                <p style={{ ...rarityStyle(tableData?.rarity), marginRight: '10px', fontSize: '1.4rem' }}>{rarities[tableData?.rarity - 1]}</p>
                <p style={{ fontSize: '1.4rem' }}>{civMapping[tableData?.color]}</p>
              </Box>
            </Box>
          </Grid>

          <Grid size={{ xs: 8, md: 8 }} display="flex" justifyContent="center" alignItems="center">
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tabValue} onChange={handleChange} aria-label="card details tabs">
                <Tab
                  label="Cards"
                  {...a11yProps(0)}
                  sx={{ fontFamily: 'Poppins', fontSize: '1.5rem', fontWeight: '600' }} // Customize font size here
                />
                <Tab
                  label="Stats"
                  {...a11yProps(1)}
                  sx={{ fontFamily: 'Poppins', fontSize: '1.5rem', fontWeight: 'Bold' }} // Customize font size here
                />
                <Tab
                  label="Lore"
                  {...a11yProps(2)}
                  sx={{ fontFamily: 'Poppins', fontSize: '1.5rem', fontWeight: 'Bold' }} // Customize font size here
                />
              </Tabs>
            </Box>
          </Grid>
        </Grid>
      </Box>
  );
}

export default TopBanner;
