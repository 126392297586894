
import YouTube from 'react-youtube';

const YouTubeBackground = ({ videoId, onEnd }) => {
  const opts = {
    playerVars: {
      autoplay: 1,
      controls: 0,
      rel: 0,
      showinfo: 0,
      mute: 1,
      loop: 1,
      playlist: videoId,
      // origin: 'http://localhost:5173'
    },
  };

  return (
    <div className="youtube-background">
      <YouTube videoId={videoId} opts={opts} onEnd={onEnd} />
    </div>
  );
};

export default YouTubeBackground;
