import { useEffect, useRef, useState, forwardRef } from 'react';
import { Sprite, Container, Text } from "react-pixi-fiber";
import * as PIXI from "pixi.js";
import { GlowFilter } from '@pixi/filter-glow';
import { gsap } from "gsap";
import { passTurnAudio } from "../../audio";
const glowFilter = new GlowFilter({
    distance: 15,
    outerStrength: 2,
    color: 0xffc107, // Example: golden color
    quality: 0.5,
});

const PassTurnButton = forwardRef(({ app, ready, scale, websocketData, isPlayReady, onClick }, ref) => {

  let blinkTween; // Reference for the GSAP tween
  const textRef = useRef(null)
  const [width, setWidth] = useState(null)
  const [height, setHeight] = useState(null)
  const passButtonRef = useRef(null)
  const whiteOverlayRef = useRef(null)

  function startBlinkingEffect() {
    if (!whiteOverlayRef.current) return console.log("whiteOverlayRef does not exist!");
    blinkTween = gsap.to(whiteOverlayRef.current, {
      alpha: 1,
      repeat: 3,
      yoyo: true,
      duration: 0.25,
      onComplete: stopBlinkingEffect
    });
  }

  function stopBlinkingEffect() {
    if (blinkTween) {
      blinkTween.kill();
    }
    if (whiteOverlayRef.current && ref.current) {
      whiteOverlayRef.current.alpha = 0;
    }
  }

  function onPointerDown() {
      // console.log("onPointerDown")
      passButtonRef.current.scale.set(scale * 1.9)
  }

  function onPointerUp() {
      // console.log("onPointerUp")
      passButtonRef.current.scale.set(scale * 2)
      passTurnAudio.play();
      onClick();
  }


  const onMouseOver = () => {
    // console.log("onMouseOver")
    console.log(ref)
    if (!ref.current.filters.includes(glowFilter)) ref.current.filters.push(glowFilter)
  }

  const onMouseOut = () => {
      // console.log("onMouseOut")
      ref.current.filters = ref.current.filters.filter(f => f !== glowFilter)
  }

  useEffect(() => {
    if (ready) {
      if (websocketData?.requestAction?.includes("passTurn") && websocketData?.turn == websocketData?.mySeat && isPlayReady) {
        ref.current.interactive = true;
        ref.current.buttonMode = true;
      } else {
        ref.current.interactive = false;
        ref.current.buttonMode = false;
      }
    }

    return () => {
      if (ref.current) ref.current.interactive = false;
      if (ref.current) ref.current.buttonMode = false;
    }
  }, [websocketData.requestAction, websocketData.turn, isPlayReady, ready, websocketData.round])

  useEffect(() => {
    if (app && websocketData.turn == websocketData.mySeat && isPlayReady && websocketData.round > 3 && !websocketData.winner) startBlinkingEffect()
  }, [app, isPlayReady])

  useEffect(() => {
    if (app && ready && scale) {
      setWidth(PIXI.utils.TextureCache['wooden-button'].width * scale)
      setHeight(PIXI.utils.TextureCache['wooden-button'].height * scale)
      whiteOverlayRef.current = new PIXI.Graphics();
      whiteOverlayRef.current.beginFill(0xFFFFFF);
      whiteOverlayRef.current.drawRoundedRect(0, 0, PIXI.utils.TextureCache['wooden-button'].width * scale * 2, PIXI.utils.TextureCache['wooden-button'].height * scale * 2);
      whiteOverlayRef.current.endFill();
      whiteOverlayRef.current.alpha = 0;
      ref.current.addChild(whiteOverlayRef.current);
    }
  }, [app, ready, scale])

  useEffect(() => {
    
    if (app && ready && scale) {
      ref.current.interactive = true;
      ref.current.buttonMode = true;

      ref.current.filters = []
      ref.current.on('pointerdown', onPointerDown);
      ref.current.on('pointerup', onPointerUp);
      ref.current.on('pointerover', onMouseOver);
      ref.current.on('pointerout', onMouseOut);
    }
    return () => {
        if (ref && ref.current) {
            ref.current.off('pointerdown', onPointerDown);
            ref.current.off('pointerdown', onPointerUp);
            ref.current.off('pointerover', onMouseOver);
            ref.current.off('pointerout', onMouseOut);
        }
    };
  }, [app, ready, scale])

  return (
    <Container
      ref={ref}
      zIndex={2}
    >
      <Sprite 
        ref={passButtonRef}
        texture={PIXI.utils.TextureCache['wooden-button']}
        scale={scale * 2}
        // zIndex={2}
      />
      {
        ready &&
        <Text
          ref={textRef}
          x={width}
          y={height}
          text={'Pass Turn'}
          anchor={new PIXI.Point(0.5, 0.5)}
          style={
            new PIXI.TextStyle({
              fontFamily: 'Almendra',
              fontSize: app.screen.width / 70,
              fill: 'white',
              align: 'center'
            })
          }
        />
      }
    </Container>
  )
});

PassTurnButton.displayName = "PassTurnButton";

export default PassTurnButton;