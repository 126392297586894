
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App.jsx';
import { ThemeProvider } from '@mui/material/styles';
import Theme from "./components/Theme"

import { Web3ModalProvider } from './components/WalletConnect';
import './index.css';
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://f64733846e271d2153d0414e08876a65@o4508433786470400.ingest.us.sentry.io/4508433787912192",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "https://app.lordsforsaken.com",  // Your front-end domain
    "https://api.lordsforsaken.com",  // Your API domain
    /^\/api\//,  // Ensure that API calls from relative paths like "/api/" are included
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      
        <ThemeProvider theme={Theme}>
          <Web3ModalProvider>
            <App />
          </Web3ModalProvider>
        </ThemeProvider>
      
    </BrowserRouter>
  </Provider>
);
