// src/features/websocketData/websocketDataSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { trimAddress } from "../../utils"
const initialState = {
  guestList: {},
  users: {},
  nicknamesMapper: {},
  status: {},
  playerData: {},
  onlinePlayers: []
};

export const onlinePlayersSlice = createSlice({
  name: 'onlinePlayers',
  initialState,
  reducers: {
    setPlayersInitialState: (state, action) => {
      const { users, nicknamesMapper, status } = action.payload;
      return { 
        ...action.payload, 
        isLoaded: true,  
        onlinePlayers: Object.keys(users).map(address => ( { address: trimAddress(address), name: nicknamesMapper[address], status: status[address] } ))
      };
    },
    updatePlayersData: (state, action) => {
      action.payload.forEach(update => {
        const [keyPath, operator, value, index] = update;
        let toModify = state;
        const keys = keyPath.split('.');
        const lastKey = keys.pop();

        keys.forEach(key => {
          if (toModify[key] === undefined) toModify[key] = {};
          toModify = toModify[key];
        });

        try {
          switch (operator) {
            case 'inc':
              toModify[lastKey] = (toModify[lastKey] || 0) + value;
              break;

            case 'push':
              if (!Array.isArray(toModify[lastKey])) toModify[lastKey] = [];
              toModify[lastKey].push(value);
              break;
            case 'remove':
              toModify[lastKey].splice(index, 1)[0];
              break;

            case 'insert':
              toModify[lastKey].splice(index, 0, value)
              break;

            case 'set':
              toModify[lastKey] = value;
              break;

            case 'delete':
              delete toModify[lastKey];
              break;

            default:
              break;
          }    
        } catch(e) {
          console.log(keyPath, operator, value, index)
          console.error(e)
        }
      });
      state.onlinePlayers = Object.keys(state.users).map(address => ( 
        { 
          address, // : trimAddress(address), 
          name: state.nicknamesMapper[address], 
          status: state.status[address], 
          ...state.playerData[address] 
        } 
      ))
    },

    resetPlayersData: (state, action) => {
      // Use initialState values for resetting
      const path = action.payload;
      if (!path) {
        return initialState; // Reset the entire state to initial state
      }
      let current = state;
      let initialCurrent = initialState;
      const keys = path.split('.');
      const lastKey = keys.pop();
      keys.forEach(key => {
        if (current[key] === undefined) {
          current[key] = {};
        }
        current = current[key];
        if (initialCurrent[key] === undefined) {
          initialCurrent[key] = {};
        }
        initialCurrent = initialCurrent[key];
      });

      // Reset specific nested value to its initial state value
      current[lastKey] = initialCurrent[lastKey] ?? {}; // Use default if not defined in initialState
    },

  }
});

export const { setPlayersInitialState, updatePlayersData, resetPlayersData } = onlinePlayersSlice.actions;
export default onlinePlayersSlice.reducer;
