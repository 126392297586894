import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const OnlinePlayersTable = ({ onlinePlayersData }) => {
  console.log("onlinePlayersData", onlinePlayersData);
  
  if (!onlinePlayersData) return null; // Ensure to return null if there's no data

  return (
    <TableContainer 
      component={Paper} 
      sx={{ 
        maxWidth: '400px', // Set a maximum width for the table container
        margin: '0 auto',  // Center the table container
        padding: '8px',    // Add padding around the table container
        borderRadius: '8px', // Optional: for rounded corners
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)', // Optional: add a shadow for better visibility
      }}
    >
      <Table size="small" aria-label="online players table" sx={{ borderCollapse: 'separate', borderSpacing: '0 8px' }}>
        <TableHead>
          <TableRow>
            <TableCell><strong>Username</strong></TableCell>
            <TableCell><strong>Status</strong></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {onlinePlayersData.map((player, index) => (
            <TableRow key={index} sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
              <TableCell>{player.username}</TableCell>
              <TableCell>{player?.status?.charAt(0).toUpperCase() + player?.status?.slice(1)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OnlinePlayersTable;
