
import { useEffect, useRef, useState } from "react";
import { gsap } from "../utils"
import { Stage } from "react-pixi-fiber";
import RevoltFX from "../RevoltFX"
import Background from "../components/OpenPacks/Background.jsx"
import Pack from "../components/OpenPacks/Pack.jsx"
import Dialog from "../components/OpenPacks/Dialog.jsx";
import { useReadContract, useWriteContract, useWaitForTransactionReceipt } from 'wagmi'
import packAbi from "../contracts/linea/abis/pack.json";
import nftAbi from "../contracts/linea/abis/nft.json";

const cdnUrl = import.meta.env.VITE_CDN_URL;
const musicUrl = `${cdnUrl}/audio/portal-music.mp3`;
const musicAudio = new Audio(musicUrl);

const OPTIONS = {
  backgroundColor: 0x000000,
  height: window.innerHeight,
  width: window.innerHeight,
  antialias: true,
  resolution: window.devicePixelRatio || 1,
  autoDensity: true
};

function OpenPacks({ 
  config, 
  findNewCards, 
  address, 
  vortexEffectContainer, 
  cardPackContainerRef, 
  floatingAnimationRef, 
  pixiDustContainer, 
  setIsLoading, 
  cardsContainerRef, 
  emitterRef
}) {

  const stageRef = useRef()
  const [app, setApp] = useState(null);
  const [scale, setScale] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [isOpening, setIsOpening] = useState(false);
  const [openAmount, setOpenAmount] = useState(1);
  const [cardsMinted, setCardsMinted] = useState(null);
  const [revertOpening, setRevertOpening] = useState(false);
  const [opened, setOpened] = useState(false);
  const [revealAll, setRevealAll] = useState(false);
  const [ready, setReady] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [allowance, setAllowance] = useState(null);
  const [packsBalance, setPacksBalance] = useState(0);

  const { writeContractAsync, data: hash } = useWriteContract()
  const { contracts: { alphaEdition: { linea: { mainnet: { nft: nftContractAddress, pack: packContractAddress } } } } } = config;
  // const nftContractAddress = config.contracts.alphaEdition.linea.mainnet.nft;
  // const packContractAddress = config.contracts.alphaEdition.linea.mainnet.packs;


  const approve = () => {
    return writeContractAsync({ 
      abi: packAbi,
      address: packContractAddress,
      functionName: 'approve',
      args: [
        nftContractAddress,
        ((2n ** 256n) - 1n).toString()
      ],
    })
  }

  const handleOpenPacks = (amount) => {
    return writeContractAsync({ 
      abi: nftAbi,
      address: nftContractAddress,
      functionName: 'openCardPack',
      args: [
        amount,
        new Date().getTime()
      ],
    })
  }

  let { data: _allowance, error: allowanceError, isSuccess: readAllowanceSuccess } = useReadContract({
    abi: packAbi,
    address: packContractAddress,
    functionName: 'allowance',
    args: [address, nftContractAddress]
  });

  const payload = {
    abi: packAbi,
    address: packContractAddress,
    functionName: 'balanceOf',
    args: [address]
  }

  let { data: _packsBalance, error: packsBalanceError, isSuccess: packsBalanceSuccess } = useReadContract(payload);

  const { isLoading: isConfirming, isSuccess: isConfirmed } = useWaitForTransactionReceipt({ hash })

  // useEffect(() => {
  //   console.log("allowance", allowance)
  // }, [allowance])

  // useEffect(() => {
  //   console.log("_allowance", _allowance)
  // }, [_allowance])

  useEffect(() => {
    if (allowanceError) console.error("Could not load pack balance");
  }, [allowanceError])

  useEffect(() => {
    if (readAllowanceSuccess && allowance == null) {
      setAllowance(BigInt(_allowance) / BigInt(10n ** 18n));
    }
  }, [readAllowanceSuccess])

  useEffect(() => {
    if (packsBalanceError) console.error("Could not load pack balance")
  }, [packsBalanceError])

  useEffect(() => {
    if (packsBalanceSuccess && packsBalance == 0) setPacksBalance(Number(_packsBalance) / 10 ** 18)
  }, [packsBalanceSuccess])

  useEffect(() => {
    console.log("_packsBalance", _packsBalance)
  }, [_packsBalance]) 

  useEffect(() => {
    if (isConfirming && allowance == 0) {
      setIsLoading(true)
      setIsApproving(true)
    }
    if (isConfirmed && isApproving) {
      setIsLoading(false)
      setIsApproving(false)
      // allowance = readAllowance().data;
    }
  }, [isApproving, isConfirming, isConfirmed])

  useEffect(() => {
    if (isConfirming && isOpening) {
      console.log("isConfirming")
      setIsLoading(true)
    }
    if (isConfirmed && isOpening) {
      console.log("isConfirmed!, running 'findNewCards' func")
      findNewCards().then(res => {
        // console.log("new cards!", res)
        setIsLoading(false)
        setCardsMinted(res)
        // setPacksBalance(packsBalance - res.length)
      })
    }
  }, [isConfirmed, isConfirming, isOpening])

  useEffect(() => {
    // console.log("**** ISOPENING ****", isOpening)
    if (isOpening) handleOpenPacks(openAmount).catch(e => {
      console.error(e)
      setIsOpening(false);
      setRevertOpening(true);
    })
  }, [isOpening])

  useEffect(() => { 
    setApp(stageRef.current._app.current)
    musicAudio.play()

    return () => {
      musicAudio.pause()
    }
  }, []);

  const onDragMove = (event) => {
    if (cardPackContainerRef && cardPackContainerRef.current) {
      cardPackContainerRef.current.alpha = 0.5;
      cardPackContainerRef.current.parent.toLocal(event.global, null, cardPackContainerRef.current.position);
    }
  };

  const onPointerUp = (event) => {
    if (isDragging && cardPackContainerRef && cardPackContainerRef.current && !isOpening) {
      cardPackContainerRef.current.alpha = 1;
      const center = { x: app.screen.width / 2, y: app.screen.height / 2 }
      const distanceToCenter = (event.global.x - center.x) ^ 2 + (event.global.y - center.y) ^ 2
      const threshold = app.screen.width / 25;
      // console.log(distanceToCenter, threshold)
      if (Math.abs(distanceToCenter) < threshold) {
        gsap.to(cardPackContainerRef.current, {
          pixi: {
            scaleX: 0,
            scaleY: 0
          },
          duration: 1,
          onComplete: () => {
            if (allowance == 0 || BigInt(openAmount) > allowance) {
              approve().then(() => {
                setAllowance(1000)
                setRevertOpening(true)
              })
              // resetHandler()
            } else {
              setShowDialog(true)
            }
          }
        })
      } else {
        cardPackContainerRef.current.x = app.screen.width * 0.08;
        cardPackContainerRef.current.y = app.screen.height * 0.2;
        floatingAnimationRef.current.resume()
      }
    }
    setIsDragging(false)
  }

  useEffect(() => {
    if (app) {
      if (isDragging) {
        // Add the event listener
        app.stage.on('pointermove', onDragMove);
      } else {
        // Remove the event listener
        app.stage.off('pointermove', onDragMove);
      }
    }
    // Cleanup function to remove the event listener when the component unmounts or app/dragTargetId changes
    return () => {
      console.log(`Cleanup: removing onDragMove listener`);
      if (app && app.stage) app.stage.off('pointermove', onDragMove);
    };
  }, [app, isDragging]); // Include onDragMove in the dependency array

  useEffect(() => {
    if (app && allowance !== null) {
      // stageRef.current._app.current.stage.sortableChildren = true;
      app.ticker.speed = 1;
      app.stage.eventMode = 'static';
      app.stage.hitArea = app.screen;

      if (app && app.stage) {
        app.stage.off('pointerup', onPointerUp);
        app.stage.off('pointerupoutside', onPointerUp);
      }

      app.stage.on('pointerup', onPointerUp);
      app.stage.on('pointerupoutside', onPointerUp);

      // Cleanup
      return () => {
        if (app && app.stage) {
          app.stage.off('pointerup', onPointerUp);
          app.stage.off('pointerupoutside', onPointerUp);
        }
      };
    }
  }, [app, allowance, isOpening, isDragging]);

  const revealAllHandler = () => {
    setRevealAll(true)
  }

  function resetHandler() {
    console.log('*** resetHandler ***')
    if (!isOpening) floatingAnimationRef.current.resume()
    setIsOpening(false)
    setRevertOpening(false)
    setOpenAmount(1)
  }

  return (
    <div className="">
      <Stage options={OPTIONS} ref={stageRef} x={0} y={0}>
        {
          app &&
          <RevoltFX app={app} ready={ready} setReady={setReady} loader={"OpenPacks"}>
            <Background 
              app={app} 
              scale={scale} 
              setScale={setScale} 
              location={location} 
              vortexEffectContainer={vortexEffectContainer} 
              cardsMinted={cardsMinted}
              pixiDustContainer={pixiDustContainer}
              opened={opened}
              revealAllHandler={revealAllHandler}
              emitterRef={emitterRef}
            >
            </Background>
            <Pack 
              scale={scale} 
              app={app} 
              ref={cardPackContainerRef} 
              setIsDragging={setIsDragging} 
              x={app.screen.width * 0.08} 
              y={app.screen.height * 0.3} 
              packsBalance={packsBalance} 
              isOpening={isOpening} 
              floatingAnimationRef={floatingAnimationRef}
              cardsContainerRef={cardsContainerRef}
              openAmount={openAmount}
              cardsMinted={cardsMinted}
              revertOpening={revertOpening}
              setRevertOpening={setRevertOpening}
              setReset={resetHandler}
              opened={opened}
              setOpened={setOpened}
              revealAll={revealAll}
              />
          </RevoltFX>
        }
      </Stage>
      <Dialog 
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        packsBalance={packsBalance} 
        setIsOpening={setIsOpening}
        setOpenAmount={setOpenAmount}
        openAmount={openAmount}
        setRevertOpening={setRevertOpening}
      />
    </div>
  ) 
}
export default OpenPacks;