import { useEffect, useRef, useState } from 'react';
import { Sprite, Container, Text } from "react-pixi-fiber";
import * as PIXI from "pixi.js";
import Hud from "./Hud"
import AudioButton from "./AudioButton"
import { getParticlesDefaultConfig, constantsLoaded } from "../../constants"
import { customPixiParticles } from 'custom-pixi-particles' 
import PassTurnButton from "./PassTurnButton"
import SurrenderButton from "./SurrenderButton";
import GameOverlay from "./GameOverlay";

const idealBoardArea = 7500 * 5250; // Example ideal area, adjust based on your design preferences

function Background({ 
  trigger, 
  resetTrigger, 
  app, 
  ready, 
  websocketData, 
  sendMessage, 
  isPlayReady, 
  setScale, 
  scale,  
  isMyTurn, 
  handleSurrender,
  playerNameButton,
  playerNameTop,
  isGameOver,
  manaParticleContainerRef,
  manaParticleContainerRef2
}) {
    const [timeLeft, setTimeLeft] = useState(0);

    const boardSprite = useRef(null); 
    const manaRef = useRef(null); 
    const manaRef2 = useRef(null);
    const [mana, setMana] = useState(null)
    const [mana2, setMana2] = useState(null)
    // const manaParticleContainerRef = useRef(null)
    // const manaParticleContainerRef2 = useRef(null)
    const manaParticles = useRef(null)
    const manaParticles2 = useRef(null)
    const manaballContainerRef = useRef(null)
    const manaballContainerRef2 = useRef(null)
    const woodenButtonContainer = useRef()
    const surrenderContainer = useRef()
    const playerNameBottomRef = useRef()
    const playerNameTopRef = useRef()
    const audioButtonRef = useRef(null);
    const ref = useRef()

    const style = new PIXI.TextStyle({
        fontFamily: 'Germania One',
        fontSize: app.screen.width / 45,
        fontStyle: 'italic',
        fontWeight: 'bold',
        fill: ['#00CED1', '#DAA520'], // gradient
        // stroke: '#2E2E2E',
        strokeThickness: 5,
        dropShadow: true,
        dropShadowColor: '#000000',
        dropShadowBlur: 4,
        dropShadowAngle: Math.PI / 6,
        dropShadowDistance: 6,
        wordWrap: true,
        wordWrapWidth: 440,
        lineJoin: 'round',
    });

    const passTurn = () => {
      const msg = JSON.stringify({ type: "passTurn" })
      sendMessage(msg)
    }

    // useEffect(() => {
    //   ref.current.children.sort((a, b) => a.zIndex - b.zIndex);
    // }, []) 

    useEffect(() => {
      if (websocketData.winner) {
        playerNameBottomRef.current.text = playerNameButton;
        playerNameTopRef.current.text = playerNameTop;
      }
      if (websocketData.round > 1 && !websocketData.winner) {
        if (websocketData.mySeat == websocketData.turn) {
          if (timeLeft) {
            playerNameBottomRef.current.text = playerNameButton + `\n ⌛ ${timeLeft}`;
          }
        } else {
          playerNameBottomRef.current.text = playerNameButton;
        }

        if (websocketData.opponentSeat == websocketData.turn) {
          if (timeLeft) {
            playerNameTopRef.current.text = playerNameTop + `\n ⌛ ${timeLeft}`;
          }
        } else {
          playerNameTopRef.current.text = playerNameTop;
        }
      }
    }, [websocketData.turn, timeLeft, websocketData.winner])

    useEffect(() => {
      // if (!isPlayReady || timeLeft == 0) setAlpha(0)
      // else if (websocketData.winner) setAlpha(0)
      // else setAlpha(1)

      // Only start the timer if timeLeft is above 0
      if (timeLeft <= 0) {
        return;
      }

      // if (timeLeft < 7) setColor('red')

      // Decrease timeLeft by one every second
      const intervalId = setInterval(() => {
        setTimeLeft((time) => time - 1);
      }, 1000);

      // Clear the interval on component unmount or when timeLeft reaches 0
      return () => clearInterval(intervalId);
    }, [timeLeft, isPlayReady]);

    useEffect(() => {
      if (app && ready && websocketData?.config?.maxTurnTime && !websocketData.winner) {
        setTimeLeft(websocketData.config.maxTurnTime / 1000)
      }
    }, [websocketData.turn, app, ready, websocketData.winner])

    useEffect(() => {
      if (app && ready && constantsLoaded) {
        if (manaParticleContainerRef?.current && !manaParticles?.current) {
          console.log("*** manaParticleContainer ***")
          // manaParticleContainerRef.current.zIndex = 3;
          // manaParticleContainerRef2.current.zIndex = 3;
          // manaballContainerRef.current.zIndex = 1;
          // manaballContainerRef2.current.zIndex = 1;
          manaParticleContainerRef.current.parent.children.sort((a, b) => a.zIndex - b.zIndex);
          let config = getParticlesDefaultConfig().magic1
          config.emitterConfig.behaviours[3].start = { _r: 0, _g: 122, _b: 230, _alpha: 1}
          manaParticles.current = manaParticleContainerRef.current.addChild(customPixiParticles.create(config))
          manaParticles.current.play()
          manaParticles2.current = manaParticleContainerRef2.current.addChild(customPixiParticles.create(config))
          manaParticles2.current.play()
        }
      }

    }, [constantsLoaded, ready, app])

    async function resize() {
      // Calculate the new width and height for the renderer
      let newWidth, newHeight;
      const windowAR = window.innerWidth / window.innerHeight;
      const bgAR = PIXI.utils.TextureCache['board'].width / PIXI.utils.TextureCache['board'].height;

      if (windowAR < bgAR) {
          // Window is narrower than the background aspect ratio
          newWidth = window.innerWidth;
          newHeight = window.innerWidth / bgAR;
      } else {
          // Window is wider than the background aspect ratio
          newHeight = window.innerHeight;
          newWidth = window.innerHeight * bgAR;
      }

      // Calculate the current area of the board
      const currentBoardArea = newWidth * newHeight;

      // Calculate the scale factor based on the ratio of the current to ideal board area
      const scaleFactor = Math.sqrt(currentBoardArea / idealBoardArea);

      // Resize the renderer
      app.renderer.resize(newWidth, newHeight);

      boardSprite.current.width = newWidth;
      boardSprite.current.height = newHeight;
      boardSprite.current.x = app.screen.width / 2;
      boardSprite.current.y = app.screen.height / 2;
      boardSprite.current.anchor.set(0.5);

      manaRef.current.x = app.screen.width * 0.935
      manaRef.current.y = app.screen.height * 0.86
      manaRef2.current.x = app.screen.width * 0.115
      manaRef2.current.y = app.screen.height * 0.06

      woodenButtonContainer.current.x = app.screen.width * 0.11
      woodenButtonContainer.current.y = app.screen.height * 0.42

      surrenderContainer.current.x = app.screen.width * 0.11
      surrenderContainer.current.y = app.screen.height * 0.33

      playerNameTopRef.current.x = app.screen.width * 0.21
      playerNameTopRef.current.y = app.screen.height * 0.06

      playerNameBottomRef.current.x = app.screen.width * 0.82
      playerNameBottomRef.current.y = app.screen.height * 0.94

      audioButtonRef.current.x = newWidth * 0.9;
      audioButtonRef.current.y = newHeight * 0.07;
      audioButtonRef.current.scale.x = scaleFactor * 1.5;
      audioButtonRef.current.scale.y = scaleFactor * 1.5;
      audioButtonRef.current.zIndex = 2;
      audioButtonRef.current.texture = PIXI.utils.TextureCache['speaker'];

      // Adjust the scale using the calculated scale factor
      setScale(scaleFactor);
    }

    useEffect(() => {
      if (ready) {
        // console.log("**** DEBUG BG ****", boardSprite.current.parent.parent)
        boardSprite.current.texture = PIXI.utils.TextureCache['board'];
        resize()
      }
    }, [ready])

    useEffect(() => {
        if (trigger) {
            if (ready) resize()
            resetTrigger(); // Reset the trigger after executing
        }
    }, [trigger]);


    useEffect(() => {

      if (websocketData && websocketData.mySeat) {
        setMana(websocketData[websocketData.mySeat].mana < 0 ? 0 : websocketData[websocketData.mySeat].mana)
        setMana2(websocketData[websocketData.opponentSeat].mana < 0 ? 0 : websocketData[websocketData.opponentSeat].mana)
      }
      
    }, [websocketData[websocketData?.mySeat]?.mana])

    return (
      <Container zIndex={0} ref={ref}>
        <GameOverlay ready={ready} websocketData={websocketData} isPlayReady={isPlayReady} app={app} trigger={trigger} boardRef={ref}/>
        <Sprite ref={boardSprite}/>
          <Text
            ref={playerNameBottomRef}
            text={playerNameButton}
            anchor={new PIXI.Point(0.5, 0.5)}
            style={style}
            zIndex={20}
          />
          <Text
            ref={playerNameTopRef}
            text={playerNameTop}
            anchor={new PIXI.Point(0.5, 0.5)}
            style={style}
            zIndex={20}
          />

        {
          ready && import.meta.env.VITE_NODE_ENV == "development" &&
          <Hud trigger={trigger} app={app} ready={ready} websocketData={websocketData} isPlayReady={isPlayReady} isMyTurn={isMyTurn}/>
        }
        {
          ready &&
          <AudioButton ref={audioButtonRef} app={app} ready={ready} isGameOver={isGameOver}/>
        }
        <PassTurnButton ref={woodenButtonContainer} app={app} ready={ready} scale={scale} websocketData={websocketData} isPlayReady={isPlayReady} onClick={passTurn}/>
        <SurrenderButton ref={surrenderContainer} app={app} ready={ready} scale={scale} isPlayReady={isPlayReady} onClick={handleSurrender}/>
        { ready &&
          <Text
            ref={manaRef}
            text={mana}
            anchor={new PIXI.Point(0.5, 0.5)}
            style={
              new PIXI.TextStyle({
                fontFamily: 'Almendra',
                fontSize: app.screen.width / 15,
                fill: 'white',
                align: 'center'
              })
            }
            zIndex={7}
          />
        }
        { ready &&
          <Text
            ref={manaRef2}
            text={mana2}
            anchor={new PIXI.Point(0.5, 0.5)}
            style={
              new PIXI.TextStyle({
                fontFamily: 'Almendra',
                fontSize: app.screen.width / 18.75,
                fill: 'white',
                align: 'center'
              })
            }
            zIndex={7}
          />
        }
        <Container
          ref={manaParticleContainerRef}
          x={app.screen.width * 0.934}
          y={app.screen.height * 0.86}
          scale={scale * 1.5}
          zIndex={8}
        >
        </Container>
        <Container
          ref={manaballContainerRef}
          x={app.screen.width * 0.87}
          y={app.screen.height * 0.8}
          zIndex={6}
        >
          <Sprite 
            texture={PIXI.utils.TextureCache['manaball']}
            scale={scale * 2}
            // zIndex={6}
          />
        </Container>

        <Container
          ref={manaParticleContainerRef2}
          x={app.screen.width * 0.11}
          y={app.screen.height * 0.08}
          scale={scale * 1.125}
          zIndex={8}
        >
        </Container>
        <Container
          ref={manaballContainerRef2}
          x={app.screen.width * 0.16}
          y={app.screen.height * 0.12}
          rotation={180 * Math.PI / 180}
          zIndex={6}
        >
          <Sprite 
            texture={PIXI.utils.TextureCache['manaball']}
            scale={scale * 1.5}
            // zIndex={1}
          />
        </Container>
      </Container>
    );
}

export default Background;