import * as PIXI from "pixi.js";
import { getParticlesDefaultConfig, constantsLoaded } from "../constants";
import { wait } from "../utils";

const cdnUrl = import.meta.env.VITE_CDN_URL;


let units, abilities, particlesDefaultConfig;
let loaded = false;

export async function loadFX(progressCallback) {
  
  while (!constantsLoaded) {
    await wait(1000)
  }

  if (loaded) {
    // console.log("Assets already loaded");
    return { units, abilities, particlesDefaultConfig }
  }

  particlesDefaultConfig = getParticlesDefaultConfig();

  // Function to add assets with an alias if not already loaded
  function addAsset(url, alias) {
    if (!PIXI.Assets.cache.has(alias)) {
      PIXI.Assets.add(alias, url);
    }
  }

  // Load additional assets with alias
  const additionalAssets = [
    ['boards/board.v2.png', 'board'],
    ['cards/spritesheets/v3/standard-gold.json', 'standard-gold-json'],
    ['cards/spritesheets/v3/standard-silver.json', 'standard-silver-json'],
    ['cards/spritesheets/v3/tactical-gold.json', 'tactical-gold-json'],
    ['cards/spritesheets/v3/tactical-silver.json', 'tactical-silver-json'],
    ['abilities-icons/spritesheets/abilities.v3.json', 'abilities-json'],
    ['statuses-icons/spritesheets/statuses.v7.json', 'statuses-json'],
    ['cards/overlays/stone/spritesheets/stone.v3.json', 'stone-json'],
    ['stars-icons/spritesheets/stars.v3.json', 'stars-json'],
    ['boardAssets/spritesheets/boardassets.json', 'board-assets'],
    ['alpha-presale/portals/portal7.png', 'portal'],
    ['cards/card-backs/back-card2.png', 'card-back'], // to be added in spritesheet
    ['cards/banners/banner.webp', 'banner'], // to be added in spritesheet
    ['boardAssets/wooden-button.png', 'wooden-button'], // to be added in spritesheet
    ['custom-pixi-particles/custom-sprites/fangs5.png', 'fangs'], // to be added in spritesheet
    ['custom-pixi-particles/custom-sprites/hammer6.png', 'hammer'], // to be added in spritesheet
  ];

  const totalAssets = additionalAssets.length; // Adjust for additional assets // 2 * 2 for units account for gold/silver and tactical/standard
  let loadedAssets = 0;

  additionalAssets.forEach(([file, alias]) => addAsset(`${cdnUrl}/${file}`, alias));

  // Batch load assets and update progress
  const assets = [
    ...additionalAssets.map(([, alias]) => alias)
  ];

  while (assets.length > 0) {
    const batch = assets.splice(0, 1); // Load 10 assets at a time
    await PIXI.Assets.load(batch);
    loadedAssets += batch.length;
    const progress = (loadedAssets / totalAssets) * 100;
    progressCallback(progress);
  }
  loaded = true;
}
