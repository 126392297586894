/* eslint-disable */

import { createAppKit } from '@reown/appkit/react';
import { WagmiAdapter } from '@reown/appkit-adapter-wagmi';

import { createConfig, WagmiProvider, http } from 'wagmi';
// import { linea } from 'wagmi/chains';
import { linea } from '@reown/appkit/networks';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { walletConnect, injected, metaMask } from 'wagmi/connectors';
import { cdnUrl, isProd } from "../constants";

// 0. Setup queryClient
const queryClient = new QueryClient();

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = import.meta.env.VITE_WALLET_CONNECT_ID;

// 2. Create wagmiConfig
const metadata = {
  name: 'Lords Forsaken',
  description: 'p2e mythologycal NFT game',
  url: isProd ? 'https://app.lordsforsaken.com' : '192.168.250.81:5173',
  icons: ['https://avatars.githubusercontent.com/u/37784886']
};

// 3. Setup custom connectors with WalletConnect RPC override
const connectors = [
  walletConnect({
    projectId,
    metadata,
  }),
  // injected(),
  metaMask()
];

// 4. Setup wagmi configuration with custom connectors
const config = createConfig({
  metadata,
  chains: [
    {
      ...linea,
      rpcUrls: {
        default: {
          http: ['https://rpc.linea.build']
        },
        public: {
          http: ['https://linea.drpc.org'], // Optional fallback
        },
      },
    }
  ],
  connectors,
  transports: {
    [linea.id]: http({ url: 'https://rpc.linea.build' }), // Specify transport for linea
  }
});

// 5. Configure wagmiAdapter for AppKit
const wagmiAdapter = new WagmiAdapter({
  networks: [
    {
      ...linea,
      rpcUrls: {
        default: {
          http: ['https://rpc.linea.build'],
        },
      },
    },
  ],
  connectors,
  projectId,
  ssr: true
});

// 6. Initialize createAppKit with wagmiAdapter and custom configuration
createAppKit({
  adapters: [wagmiAdapter],
  networks: [linea],
  defaultNetwork: linea,
  projectId,
  metadata,
  features: {
    analytics: false,
    socials: [],
    email: false
  }
});

// 7. Export the Web3ModalProvider component
export function Web3ModalProvider({ children }) {
  return (
    <WagmiProvider config={wagmiAdapter.wagmiConfig}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  );
}
