/* eslint-disable */


import Badge from '@mui/material/Badge';
import { useState, useEffect, useRef } from 'react';
import { Dialog, DialogContent, Box, Fab, TextField, Button, Typography, IconButton, useMediaQuery } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';
import useWebSocket from '../hooks/useChatWebSocketHandler';
import { chatUrl } from "../../constants.js";
import { trimAddress } from "../../utils";
import OnlinePlayersTable from "./OnlinePlayersTable.jsx";
import MessageRenderer from "./MessageRenderer.jsx";

const FloatingChat = ({ accountData, address, wallet, onlinePlayers }) => {
  const { sendMessage, readyState, messageQueue, newMessage, setNewMessage } = useWebSocket(chatUrl, address);

  const [open, setOpen] = useState(false);
  const [username, setUsername] = useState(null);
  const [text, setText] = useState('');

  const messagesEndRef = useRef(null); // Reference for scrolling
  const inputRef = useRef(null); // Reference for TextField

  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSendMessage = () => {
    if (text.trim() && username.trim()) {
      const payload = { type: 'chat', data: { text, sender: username, timestamp: new Date() } };
      sendMessage(JSON.stringify(payload));
      setText(''); // Clear input after sending
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission (if any)
      handleSendMessage();
    }
  };

  useEffect(() => {
    const payload = localStorage.getItem("payload");
    if (payload && readyState === 1) {
      const msg = JSON.stringify({ type: 'login', data: JSON.parse(payload), wallet });
      sendMessage(msg);
    } else if (!payload && readyState === 1) { // guest mode
      const msg = JSON.stringify({ type: 'login' });
      sendMessage(msg);
    }
  }, [readyState]);

  useEffect(() => {
    if (open) {
      setNewMessage(0);
      setTimeout(() => {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        // Focus the input field when the dialog opens
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 200);
    }
  }, [open]);

  useEffect(() => {
    if (accountData && accountData.name) setUsername(accountData.name);
  }, [accountData]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messageQueue]);

  // Function to check if a player is online
  const isUserOnline = (address) => {
    return onlinePlayers.onlinePlayers.some(player => player.address === address);
  };

  // Function to detect if it's a server message
  const isServerMessage = (address) => {
    return address === "admin"; // Assuming server messages come from 'admin'
  };

  return (
    <>
      <Badge 
        badgeContent={newMessage} 
        color="error"
        style={{ position: 'fixed', bottom: 16, right: 16, zIndex: 1300  }}
      >
        <Fab 
          color="primary" 
          onClick={handleOpen} 
          style={{ position: 'fixed', bottom: 16, right: 16, zIndex: 1301  }}
        >
          <ChatIcon />
        </Fab>
      </Badge>
      {/* Chat Dialog */}
      <Dialog
        open={open} 
        onClose={handleClose} 
        fullScreen={window.innerWidth <= 600} // Use full-screen mode on small screens
        PaperProps={{ 
          style: { 
            border: '1px solid #E1B15E', 
            margin: isSmallScreen ? 0 : 'auto',       // Centered on larger screens
            position: 'fixed',
            top: isSmallScreen ? 0 : '60%',           // Adjust top position for larger screens
            right: isSmallScreen ? 0 : '20px',        // Adjust right position for larger screens
            bottom: isSmallScreen ? 0 : '60px',       // Adjust bottom position for larger screens
            width: isSmallScreen ? '100vw' : '60vh',     // Full width on small screens
            height: isSmallScreen ? '90vh' : '40vh', // Full height on small screens
          } 
        }}
      >
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between', // Space between messages and input box
            height: '100vh', // Take full height on small screens
            padding: 2,
          }}
        >

          {isSmallScreen && (
            <IconButton
              onClick={handleClose}
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                color: 'gray', // Adjust color as desired
              }}
            >
              <CloseIcon />
            </IconButton>
          )}

          {/* Display number of online players */}
          <Typography variant="h6" sx={{ mb: 1, fontFamily: 'Poppins' }}>
            Online Players: {onlinePlayers.onlinePlayers.length}
          </Typography>
          {/* Scrollable container for messages */}
          <Box 
            className="textBox"
            sx={{ 
              maxHeight: isSmallScreen ? '100vh' : '40vh', 
              flexGrow: 1,
              overflowY: 'auto', 
              display: 'flex', 
              flexDirection: 'column',
              gap: 1,
              mb: 2,
              fontFamily: 'Poppins'
            }}
          >
            {messageQueue.length > 0 && messageQueue.map((msg, index) => (
              <Box key={index} display="flex" flexDirection="column">
                {/* Status indicator and message text */}
                <Box display="flex" alignItems="center">
                  {
                    msg.type == 'chat' &&
                    <Box 
                      sx={{
                        width: 10,
                        height: 10,
                        borderRadius: '50%',
                        backgroundColor: isServerMessage(msg.address) ? 'blue' : (isUserOnline(msg.address) ? 'green' : 'red'),
                        marginRight: 1
                      }}
                    />
                  }
                  {
                    msg.type === 'chart' &&
                    // JSON.stringify(msg)
                    <OnlinePlayersTable onlinePlayersData={msg.onlineplayers}/>
                  }
                  {
                    msg.type == 'chat' && msg.address &&
                    <MessageRenderer msg={msg} onlinePlayers={onlinePlayers}/>
                  }
                </Box>
                {/* Timestamp */}
                {
                  msg.type == 'chat' &&
                  <Typography 
                    variant="caption" 
                    sx={{ fontFamily: 'Poppins', color: 'gray', marginLeft: 3 }} // Indent timestamp to align
                  >
                    {new Date(msg?.timestamp).toLocaleTimeString()}
                  </Typography>
                }
              </Box>
            ))}
            <div ref={messagesEndRef} /> {/* Empty div to act as scroll target */}
          </Box>

          {/* Input Field */}
          <Box display="flex">
            <TextField
              inputRef={inputRef} // Assign the ref to the TextField
              fullWidth
              value={text}
              onChange={(e) => setText(e.target.value)}
              label="Type a message"
              variant="outlined"
              size="small"
              onKeyDown={handleKeyDown} // Handle key down event
              InputProps={{
                style: { textTransform: 'none', fontFamily: 'Poppins' } // Prevent text from being capitalized
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#E1B15E', // Default border color
                  },
                  '&:hover fieldset': {
                    borderColor: '#white', // Border color on hover
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#E1B15E', // Border color when focused
                  },
                },
              }}
            />
            <Button 
              onClick={handleSendMessage} 
              variant="contained" 
              color="primary" 
              style={{ marginLeft: 8 }}
            >
              Send
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FloatingChat;
