import { useState, useEffect } from "react";
import Grid from '@mui/material/Grid2'; // Grid version 2
import {cdnUrl } from "../../constants.js";
import { cardFooterAStyle, overlayStyle, hoverButtonStyle } from "./Styles.jsx";
import { useTheme } from '@mui/material/styles';
// import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

function LeftGrid({ accountData, sendMessage, setIsSearching, handleLogin, address, setRoute, spacing }) {
  // const navigate = useNavigate();
  const [tutorialIsHovered, setTutorialIsHovered] = useState(false);
  const [packsIsHovered, setPacksIsHovered] = useState(false);
  const [referralIsHovered, setReferralIsHovered] = useState(false);
  const [league, setLeague] = useState(null);
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));

  const theme = useTheme(); // Access the theme
  const cardImageAStyle = { 
    width: '100%', 
    height: 'auto', 
    display: 'block', 
    margin: 0
  }

  useEffect(() => {
    if (accountData) {
      // console.log("accountData", accountData)
      if (accountData.elo < 1200) setLeague('bronze')
      else if (accountData.elo < 1400) setLeague('silver')
      else setLeague('gold')
    }
  }, [accountData])

  const practiseHandler = () => {
    if (!address) return handleLogin()
    setIsSearching(true)
    const msg = JSON.stringify({ type: 'playBot', data: {} });
    sendMessage(msg);
  }

  return (
  <Grid 
    container 
    size={{ xs: 12, md: 6 }} 
    spacing={spacing}
    justifyContent="center" 
    display="flex"
  >
    <Grid 
      size={{ xs: 12, md: 6 }}
      justifyContent="center" 
      display="flex"
    >
      <div 
        style={{ 
          position: 'relative',
          width: `${isSmallScreen ? '100%' : 'auto'}`, 
          display: 'flex',           // Enable flexbox
          flexDirection: 'column',   // Stack children vertically
          height: '100%',  
        }} 
        onMouseEnter={() => setTutorialIsHovered(true)}
        onMouseLeave={() => setTutorialIsHovered(false)}
      >
        <img 
          src={isSmallScreen ? `${cdnUrl}/app/mobile/battle/play-tutorial.webp` : `${cdnUrl}/app/battle-menu/A1.webp`} 
          style={{ ...cardImageAStyle, border: '1px solid', borderColor: '#937341', borderRadius: '8px 8px 0 0' }} 
        />
        {tutorialIsHovered && 
          <>
          <div style={overlayStyle}></div> 
          <button
            onClick={practiseHandler}
            style={{
              ...hoverButtonStyle,
              top: '50%'
            }}
          >Practise</button>
          </>
        }
        <div style={{ ...cardFooterAStyle, marginTop: 'auto' }}>
          <p
            style={{ fontSize: '0.9rm' }}
          >PRACTICE</p>
        </div>
      </div>
    </Grid>

    <Grid 
      size={{ xs: 12, md: 6 }}
      justifyContent="center" 
      display="flex"
    >
      <div 
        style={{ 
          position: 'relative',
          width: `${isSmallScreen ? '100%' : 'auto'}`,
          display: 'flex',           // Enable flexbox
          flexDirection: 'column',   // Stack children vertically
          height: '100%', 
      }}
      >
        <img 
          src={isSmallScreen ? `${cdnUrl}/app/mobile/battle/league-background.webp` : `${cdnUrl}/app/league/league-background.webp`} 
          style={{ ...cardImageAStyle, border: '1px solid', borderColor: '#937341', borderRadius: '8px 8px 0 0' }} 
        />
        {
          league &&
          <img
            src={`${cdnUrl}/app/league/${league}.webp`}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -72%)', // Center both horizontally and vertically
              width: '50%',
              height: 'auto',
              objectFit: 'cover',
              opacity: 1
            }}
          />
        }
        <div 
          style={{ ...cardFooterAStyle, marginTop: 'auto' }}
        >
          <Grid container size={{ xs: 12, md: 6 }}>
            <Grid size={{ xs: 2 }} style={{textAlign: 'left'}}>
              <p
                style={{
                  fontSize: '0.9rem' 
                }}
              >LEAGUE</p> 
              {
                league &&
                <p 
                style={{
                  color: theme.palette.text.primary, 
                  fontFamily: 'Germania One', 
                  fontSize: '1rem',
                  textTransform: 'uppercase' 
                }}
                >{league}</p>
              }
            </Grid>
            <Grid offset={{ xs: 7 }} size={{ xs: 2 }} style={{textAlign: 'right'}}>
              <p
                style={{
                  fontSize: '0.9rem' 
                }}
              >RATING</p>
              <p 
              style={{
                color: theme.palette.text.primary, 
                fontFamily: 'Germania One', 
                fontSize: '1rem' 
              }}
              >{accountData?.elo ? accountData.elo : 0}
              </p>
            </Grid>
          </Grid>
        </div>
      </div>
    </Grid>
    <Grid 
      size={{ xs: 12, md: 6 }}
      justifyContent="center" 
      display="flex"
    >
      <div
        style={{ 
          position: 'relative',
          width: `${isSmallScreen ? '100%' : 'auto'}`,
          display: 'flex',           // Enable flexbox
          flexDirection: 'column',   // Stack children vertically
          height: '100%', 
        }} 
        onMouseEnter={() => setPacksIsHovered(true)}
        onMouseLeave={() => setPacksIsHovered(false)}
      >
        <img 
          src={`${cdnUrl}/app/battle-menu/A3.webp`} 
          style={cardImageAStyle} 
        />
        {packsIsHovered && 
          <>
          <div style={overlayStyle}></div> 
          <button
            onClick={() => setRoute('/openpacks')}
            style={{
              ...hoverButtonStyle
            }}
          >Open Packs</button>
          </>
        }
        <div style={{ ...cardFooterAStyle, marginTop: 'auto' }}>
          <Grid container size={{ xs: 12 }} alignItems="center" justifyContent="center">
            <Grid size={{ xs: 12 }} style={{ textAlign: 'center', color: theme.palette.text.secondary }}>
              <p
                style={{
                  fontSize: '0.9rem' 
                }}
              >CARD PACKS</p>
            </Grid>
            <Grid size={{ xs: 12 }} style={{ textAlign: 'center', color: theme.palette.text.primary }}>
              <p
                style={{
                  fontSize: '1rem' 
                }}
              >Open 5 NFT&apos;s per pack</p>
              
            </Grid>
          </Grid>
        </div>
      </div>
    </Grid>
    <Grid 
      size={{ xs: 12, md: 6 }}
      justifyContent="center" 
      display="flex"
    >
      <div
        style={{ 
          position: 'relative',
          width: `${isSmallScreen ? '100%' : 'auto'}`,
          display: 'flex',           // Enable flexbox
          flexDirection: 'column',   // Stack children vertically
          height: '100%', 
        }} 
        onMouseEnter={() => setReferralIsHovered(true)}
        onMouseLeave={() => setReferralIsHovered(false)}
      >
        <img 
          src={`${cdnUrl}/app/battle-menu/A4.webp`} 
          style={cardImageAStyle} 
        />
        {referralIsHovered && 
          <>
          <div style={overlayStyle}></div> 
          <button
            onClick={() => setRoute('/referral')}
            style={{
              ...hoverButtonStyle,
              width: '180px',
              height: '45px',
              fontSize: '16px',
              color: '#EAC88C',
              fontFamily: 'Germania One',
              backgroundImage: `url(${cdnUrl}/landing/button.webp)`,
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
              backgroundColor: 'rgba(0,0,0,0)',
              border: 'none'
            }}
          >Learn More</button>
          </>
        }
        <div style={{ ...cardFooterAStyle, marginTop: 'auto' }}>
          <Grid container size={{ xs: 12 }} alignItems="center" justifyContent="center">
            <Grid size={{ xs: 12 }} style={{ textAlign: 'center', color: theme.palette.text.secondary }}>
              <p
                style={{
                  fontSize: '0.9rem' 
                }}
              >REFERRAL</p>
            </Grid>
            <Grid size={{ xs: 12 }} style={{ textAlign: 'center', color: theme.palette.text.primary }}>

              {
                !isSmallScreen &&
                <p
                  style={{
                    fontSize: '1rem' 
                  }}
                >Earn $FAITH Tokens
                </p>
              }
            </Grid>
          </Grid>
        </div>
      </div>
    </Grid>
    </Grid>
  )
}

export default LeftGrid;
