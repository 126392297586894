import { createTheme } from '@mui/material/styles';

const Theme = createTheme({
  palette: {
    primary: {
      main: '#DAA520', // Goldenrod (darker gold)
      dark: '#B8860B', // DarkGoldenrod (even darker gold)
      contrastText: '#fff', // White text
    },
    background: {
      default: '#A9A9A9', // Light grey
      paper: '#0A0A0C', // Dark grey
    },
    text: {
      primary: '#EAC88C', // White
      secondary: '#937341', // Black
    },
  },
  typography: {
    fontFamily: 'Cinzel, serif', // Default font family
  },
  components: {
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)', // Light white hover, adjust as needed
          },
          borderBottom: '1px solid #937341', // Set consistent border for all rows
        },
        head: {
          borderBottom: '2px solid #937341', // Thicker border for header rows
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #937341', // Ensure cells have a consistent border
          fontFamily: 'Inter',
          fontSize: '15px',
          fontWeight: 600,
          lineHeight: '24px',
          letterSpacing: '-0.01em',
        },
        head: {
          borderBottom: '2px solid #937341', // Thicker bottom border for header cells
          fontFamily: "'Germania One', sans-serif", // Apply Germania One font to table headers
          fontSize: '16px', // Set font size to 16px
          color: '#937341', // Set color to #937341
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter, serif', // Override font for buttons
          textTransform: 'none' 
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          // Root styles for Switch component
        },
        switchBase: {
          '&.Mui-disabled': {
            color: '#FF7F7F', // Color of the switch base when disabled
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            backgroundColor: '#FF7F7F', // Color of the track when switch is disabled
          }
        },
      }
    }
  }
});

export default Theme;
