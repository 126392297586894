import { Contract, Provider, utils } from "koilib";
import packsAbi from "./abis/packs.json";
import cardsAbi from "./abis/cards.json";
import { mainnet } from "./config.json";

export const network = mainnet; // import.meta.env.VITE_NODE_ENV == "development" ? testnet : mainnet;

console.log(`Seems like we are in ${import.meta.env.VITE_NODE_ENV} environment ..`)
// console.log("provider", network.urls.rpcNode)
export const provider = new Provider([network.urls.rpcNode]);

export const packsContract = new Contract({
  id: network.addresses.packs,
  provider,
  abi: packsAbi,
});
export const cardsContract = new Contract({
  id: network.addresses.cards,
  provider,
  abi: cardsAbi,
});

export const koinContract = new Contract({
  id: network.addresses.koin,
  abi: utils.tokenAbi,
  provider: provider,
  // signer: user1,
});

