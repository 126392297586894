
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { trimAddress }  from "../../utils";
// Assuming `Player` is a type, but here it's just for prop types
const ReferralsTable = ({ referrals }) => {
  return (
    <TableContainer sx={{ borderRadius: '16px', border: 'none', padding: '20px' }}>
      <Table>
        <TableHead>
          <TableRow sx={{ borderBottom: '2px solid #937341', height: '60px' }}>
            <TableCell sx={{ fontSize: '1.2rem', padding: '8px', textAlign: 'left', width: '50px' }}>Address</TableCell>
            <TableCell sx={{ fontSize: '1.2rem', padding: '8px', textAlign: 'left', width: '50px' }}>Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {referrals.map((referral, index) => (
            <TableRow key={index} sx={{ borderBottom: '1px solid #937341', height: '60px' }} >
              <TableCell sx={{ padding: '8px', textAlign: 'left' }}>{trimAddress(referral.address)}</TableCell>
              <TableCell>{referral.name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReferralsTable;
