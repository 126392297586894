/* eslint-disable */


import React, { useEffect, useRef, useState, forwardRef } from 'react';
import { CustomPIXIComponent, Sprite, Container, Text, Graphics } from "react-pixi-fiber";
import * as PIXI from "pixi.js";
import { getParticlesDefaultConfig, constantsLoaded, getCritters, getAbilities, getStatuses } from "../../constants"
import Hud from "./Hud"
import AudioButton from "./AudioButton"
import PassTurnButton from "./PassTurnButton"
import { customPixiParticles } from 'custom-pixi-particles'
import SurrenderButton from "./SurrenderButton";
// import GameOverlay from "./GameOverlay";
import Standard from "./Standard-test"

const cdnUrl = import.meta.env.VITE_CDN_URL;
const civs = ["egypt", "greece", "norse"]

const idealBoardArea = 7500 * 5250; // Example ideal area, adjust based on your design preferences


function Background({ trigger, resetTrigger, app, ready, websocketData, sendMessage, isPlayReady, setScale, scale, particlesDefaultConfig, isMyTurn }) {
    const [timeLeft, setTimeLeft] = useState(0);
    const [playerNameButton, setPlayerNameButton] = useState(null);
    const [playerNameTop, setPlayerNameTop] = useState(null);
    const boardSprite = useRef(null); 
    const manaRef = useRef(null); 
    const manaRef2 = useRef(null); 
    const roundedButtonRef = useRef(null);

    const [mana, setMana] = useState(null)
    const [canPass, setCanPass] = useState(false)
    const manaParticleContainerRef = useRef(null)
    const manaParticleContainerRef2 = useRef(null)
    const manaParticles = useRef(null)
    const manaParticles2 = useRef(null)
    const manaballContainerRef = useRef(null)
    const manaballContainerRef2 = useRef(null)

    const woodenButtonContainer = useRef()
    const surrenderContainer = useRef()

    const playerNameBottomRef = useRef()
    const playerNameTopRef = useRef()

    const testContainerRef = useRef()
    const testParticlesRef = useRef()

    const testContainerRef2 = useRef()
    const testParticlesRef2 = useRef()

    const ref = useRef()

    const standardRef = useRef()

    const [critter, setCritter] = useState(null)

    const passTurn = () => {
      const msg = JSON.stringify({ type: "passTurn" })
      sendMessage(msg)
    }

    const style = new PIXI.TextStyle({
        fontFamily: 'Germania One',
        fontSize: 36,
        fontStyle: 'italic',
        fontWeight: 'bold',
        fill: ['#00CED1', '#DAA520'], // gradient
        // stroke: '#2E2E2E',
        strokeThickness: 5,
        dropShadow: true,
        dropShadowColor: '#000000',
        dropShadowBlur: 4,
        dropShadowAngle: Math.PI / 6,
        dropShadowDistance: 6,
        wordWrap: true,
        wordWrapWidth: 440,
        lineJoin: 'round',
    });

  function shakeScreen(duration = 500, magnitude = 20) {
      const startTime = Date.now();
      const shake = () => {
          const elapsed = Date.now() - startTime;
          const remaining = duration - elapsed;
          if (remaining > 0) {
              const x = (Math.random() - 0.5) * magnitude;
              const y = (Math.random() - 0.5) * magnitude;
              app.stage.x = x;
              app.stage.y = y;
              requestAnimationFrame(shake);
          } else {
              // Reset the stage position
              app.stage.x = 0;
              app.stage.y = 0;
          }
      };
      shake();
  }
  // EARTHQUAKE TEST
  
    // useEffect(() => {
    //   if (app && ready) {
    //     setInterval(() => {
    //       shakeScreen()
    //     }, 3000)
    //   }

    // }, [app,ready])

    useEffect(() => {
      if (app && ready) {
        setTimeLeft(60)
        setPlayerNameTop('Bot - 1000')
        setPlayerNameButton('Rambo - 1200')
        const _critter = JSON.parse(JSON.stringify(getCritters()[0]))
        
        for (let key in _critter.stats) {
          const level = 2
          console.log(_critter.stats[key])
          console.log(_critter.stats[key][level])
          _critter.stats[key] = _critter.stats[key][level]
        }
        console.log("critter", _critter)
        setCritter(_critter)
      }
    }, [app, ready])


    useEffect(() => {
      if (app && ready && constantsLoaded) {
        if (manaParticleContainerRef.current && !manaParticles.current) {
          console.log("*** manaParticleContainer ***")
          manaParticleContainerRef.current.parent.children.sort((a, b) => a.zIndex - b.zIndex);
          let config = getParticlesDefaultConfig().magic1
          config.emitterConfig.behaviours[3].start = { _r: 0, _g: 122, _b: 230, _alpha: 1}
          manaParticles.current = manaParticleContainerRef.current.addChild(customPixiParticles.create(config))
          manaParticles.current.play()
          manaParticles2.current = manaParticleContainerRef2.current.addChild(customPixiParticles.create(config))
          manaParticles2.current.play()
          for (let key in getParticlesDefaultConfig()) {
            console.log(key)
          }
          // TEST
          const configTest = JSON.parse(JSON.stringify(getParticlesDefaultConfig().magic2))
          const configTest2 = JSON.parse(JSON.stringify(getParticlesDefaultConfig().counter))
          // testContainerRef.current.scale.x = 100
          // testContainerRef.current.scale.y = 100
          console.log("behaviours", configTest.emitterConfig.behaviours[3])
          // configTest.emitterConfig.behaviours[3].start = { _r: 0, _g: 20, _b: 20, _alpha: 1}
          // configTest.emitterConfig.behaviours[3].end = { _r: 0, _g: 122, _b: 255, _alpha: 1}
          // configTest.textures = ['hammer']

          // SIZE
          testContainerRef.current.scale.set(0.5)
          // testContainerRef2.current.scale.x = 0.5;
          // testContainerRef2.current.scale.y = 0.5;

          testParticlesRef.current = testContainerRef.current.addChild(customPixiParticles.create(configTest))
          testParticlesRef.current.play()
          testParticlesRef2.current = testContainerRef2.current.addChild(customPixiParticles.create(configTest2))
          // testParticlesRef2.current.play()
          console.log("testParticlesRef", testParticlesRef)  
          // setTimeout(() => { testParticlesRef.current.stop() }, 2000)
        }
      }
    }, [constantsLoaded, ready, app])

    async function resize() {
      // Calculate the new width and height for the renderer
      let newWidth, newHeight;
      const windowAR = window.innerWidth / window.innerHeight;
      const bgAR = PIXI.utils.TextureCache['board'].width / PIXI.utils.TextureCache['board'].height;

      if (windowAR < bgAR) {
          // Window is narrower than the background aspect ratio
          newWidth = window.innerWidth;
          newHeight = window.innerWidth / bgAR;
      } else {
          // Window is wider than the background aspect ratio
          newHeight = window.innerHeight;
          newWidth = window.innerHeight * bgAR;
      }

      // Calculate the current area of the board
      const currentBoardArea = newWidth * newHeight;

      // Calculate the scale factor based on the ratio of the current to ideal board area
      const scaleFactor = Math.sqrt(currentBoardArea / idealBoardArea);

      // Resize the renderer
      app.renderer.resize(newWidth, newHeight);

      boardSprite.current.width = newWidth;
      boardSprite.current.height = newHeight;
      boardSprite.current.x = app.screen.width / 2;
      boardSprite.current.y = app.screen.height / 2;
      boardSprite.current.anchor.set(0.5);


      manaRef.current.x = app.screen.width * 0.935
      manaRef.current.y = app.screen.height * 0.86
      manaRef2.current.x = app.screen.width * 0.115
      manaRef2.current.y = app.screen.height * 0.06

      // woodenButtonContainer.current.x = app.screen.width * 0.11
      // woodenButtonContainer.current.y = app.screen.height * 0.7
      // surrenderContainer.current.x = app.screen.width * 0.11
      // surrenderContainer.current.y = app.screen.height * 0.42

      playerNameTopRef.current.x = app.screen.width * 0.18
      playerNameTopRef.current.y = app.screen.height * 0.04

      playerNameBottomRef.current.x = app.screen.width * 0.82
      playerNameBottomRef.current.y = app.screen.height * 0.92
      // Adjust the scale using the calculated scale factor
      setScale(scaleFactor);
    }

    useEffect(() => {
      if (timeLeft) {
        playerNameBottomRef.current.text = playerNameButton + `\n ⌛ ${timeLeft}`
      }
    }, [timeLeft])

    useEffect(() => {
      // if (!isPlayReady || timeLeft == 0) setAlpha(0)
      // else if (websocketData.winner) setAlpha(0)
      // else setAlpha(1)

      // Only start the timer if timeLeft is above 0
      if (timeLeft <= 0) {
        return;
      }

      // if (timeLeft < 7) setColor('red')

      // Decrease timeLeft by one every second
      const intervalId = setInterval(() => {
        setTimeLeft((time) => time - 1);
      }, 1000);

      // Clear the interval on component unmount or when timeLeft reaches 0
      return () => clearInterval(intervalId);
    }, [timeLeft, isPlayReady]);

    useEffect(() => {
      if (ready) {
        // console.log("**** DEBUG BG ****", boardSprite.current.parent.parent)
        // boardSprite.current.texture = PIXI.utils.TextureCache['board'];
        // woodenButton.current.texture = PIXI.utils.TextureCache['wooden-button']
        resize()
      }
    }, [ready])

    useEffect(() => {
        if (trigger) {
            if (ready) resize()
            resetTrigger(); // Reset the trigger after executing
        }
    }, [trigger]);


    useEffect(() => {
      if (app && ready) {

        ref.current.children.sort((a, b) => a.zIndex - b.zIndex);
        console.log(ref.current.children.map(el => el.zIndex))
      }
    }, [app, ready])

    return (
      <Container ref={ref} zIndex={0}>
        {/*<GameOverlay isPlayReady={isPlayReady} app={app}/>*/}
        {
          critter &&
            <Standard
                ref={standardRef}
                baseStats={critter.stats}
                data={critter}
                state={critter.stats}
                ready={ready}
                scale={scale}
                level={0} // Or the appropriate level
                visible={true}
                isPlayReady={false}
                abilities={getAbilities()}
                isIntro={false}
                onHover={() => console.log("onhover")}
                baseStatsLoaded={true}
                statuses={getStatuses()}
            />
        }
        <Sprite ref={boardSprite}/>
        { ready &&
          <Text
            ref={playerNameBottomRef}
            text={playerNameButton}
            anchor={new PIXI.Point(0.5, 0.5)}
            style={style}
            zIndex={20}
          />
        }
        { ready &&
          <Text
            ref={playerNameTopRef}
            text={playerNameTop}
            anchor={new PIXI.Point(0.5, 0.5)}
            style={style}
            zIndex={20}
          />
        }
        {/*<SurrenderButton ref={surrenderContainer} app={app} ready={ready} scale={scale} isPlayReady={isPlayReady} onClick={passTurn}/>*/}
        { ready &&
          <Text
            ref={manaRef}
            text={mana}
            anchor={new PIXI.Point(0.5, 0.5)}
            style={
              new PIXI.TextStyle({
                fontFamily: 'Almendra',
                fontSize: app.screen.width / 15,
                fill: 'white',
                align: 'center'
              })
            }
            zIndex={20}
          />
        }
        { ready &&
          <Text
            ref={manaRef2}
            text={mana}
            anchor={new PIXI.Point(0.5, 0.5)}
            style={
              new PIXI.TextStyle({
                fontFamily: 'Almendra',
                fontSize: app.screen.width / 18.75,
                fill: 'white',
                align: 'center'
              })
            }
            zIndex={20}
          />
        }

        <Container
          ref={testContainerRef}
          x={app.screen.width * 0.5}
          y={app.screen.height * 0.5}
          // scale={scale * 1.5}
          zIndex={6}
        >
        </Container>
        <Container
          ref={testContainerRef2}
          x={app.screen.width * 0.5}
          y={app.screen.height * 0.5}
          // scale={scale * 1.5}
          zIndex={6}
        >
        </Container>

        <Container
          ref={manaParticleContainerRef}
          x={app.screen.width * 0.934}
          y={app.screen.height * 0.86}
          scale={scale * 1.5}
          zIndex={6}
        >
        </Container>

        <Container
          ref={manaParticleContainerRef2}
          x={app.screen.width * 0.11}
          y={app.screen.height * 0.08}
          scale={scale * 1.125}
          zIndex={2}
        >
        </Container>

        <Container
          ref={manaballContainerRef}
          x={app.screen.width * 0.87}
          y={app.screen.height * 0.8}
          zIndex={6}
        >
          <Sprite 
            texture={PIXI.utils.TextureCache['manaball']}
            scale={scale * 2}
            zIndex={6}
          />
        </Container>
        <Container
          ref={manaballContainerRef2}
          x={app.screen.width * 0.16}
          y={app.screen.height * 0.12}
          rotation={180 * Math.PI / 180}
          zIndex={6}
        >
          <Sprite 
            texture={PIXI.utils.TextureCache['manaball']}
            scale={scale * 1.5}
          />
        </Container>
      </Container>
    );
}

export default Background;