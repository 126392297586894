import { forwardRef, useRef, useEffect, useState } from "react";
import { Container, Graphics } from "react-pixi-fiber";
import { GlowFilter } from '@pixi/filter-glow';
import Card from "../card"
import * as PIXI from 'pixi.js';
import { adjust } from "./adjust";
import { fx } from "../../loaders/loadFx.js"
import ErrorBoundary from "../ErrorBoundary.jsx"
import { wait } from "../../utils";

let glow = new GlowFilter({ 
    distance: 15, 
    outerStrength: 4, 
    color: 0x00FF00, // Green color
    quality: 0.5 
});

const BoardCards = forwardRef(
  function BoardCards({ isReplay, ready, trigger, app, critterRef, pointerDown, godRef1, godRef2, websocketData, combatRoundsData, setPlayReady, scale, firstToAct, setIsMyTurn, isMyTurn, combatBusy, setCombatBusy }, ref) {

    const slots1 = useRef();
    const slots2 = useRef();

    const centerRectangle1 = useRef();
    const supportRectangle1 = useRef();
    const centerRectangle2 = useRef();
    const supportRectangle2 = useRef();

    const cardRefs = useRef(new Map());
    const [critters, setCritters] = useState([]);
    const [dead, setDead] = useState([]);

    const [newCritterId, setNewCritterId] = useState(null);
    const [newCritterIndex, setNewCritterIndex] = useState(null);
    const [actionsByRound, setActionsByRound] = useState([]);
    const addRoundOfActions = (newElement) => setActionsByRound(prevArray => [...prevArray, newElement]);
    const [combatRound, setCombatRound] = useState(0);
    const [autoMode, setAutoMode] = useState(false)
    
    const [fired, setFired] = useState(false)
    const [slotCoords, setSlotCoords] = useState(false)
    const [heroCrittersLoaded, setHeroCrittersLoaded] = useState(false);

    const [mySeat, setMySeat] = useState(null);
    const [opponentSeat, setOpponentSeat] = useState(null);

    const [heroCritters, setHeroCritters] = useState([]);

    useEffect(() => {
      if (isReplay && websocketData?.p2?.player) {
        setOpponentSeat('p2')
        setMySeat('p1')
      } else if (websocketData?.opponentSeat && websocketData?.mySeat) {
        setOpponentSeat(websocketData.opponentSeat)
        setMySeat(websocketData.mySeat)
      }
      return () => {
        setMySeat(null)
        setOpponentSeat(null)
      }
    }, [isReplay, websocketData])

    async function fireworks(app) {
      for (let i = 0;i < 10; i++) {
        var emitter = fx.getParticleEmitter('fireworks', true, true);
        emitter.settings.particleSettings.distanceMin = 700;
        emitter.settings.particleSettings.distanceMax = 800;
        emitter.x = app.screen.width * 0.5;
        emitter.y = app.screen.height;
        emitter.rotation = -Math.PI * 0.5 + (Math.random() - 0.5);
        var container = new PIXI.Container();
        container.zIndex = 10;
        app.stage.addChild(container)
        app.stage.children.sort((a, b) => a.zIndex - b.zIndex);
        emitter.init(container);
        await wait(500)
      }
    }

    async function executeCombatRound() {
      setCombatBusy(true)
      if (websocketData.round > 5 && actionsByRound.length == combatRound) {
        setCombatBusy(false)
        return console.log("GAME IS OVER!")
      } else {
        for (let i = 0; i < actionsByRound[combatRound].length; i++) {
          // console.log(`execute CombatRound autoMode ${mode} | combatRound ${combatRound}`)
          // console.log(actionsByRound[combatRound][i][1][1])
          await actionsByRound[combatRound][i][0](...actionsByRound[combatRound][i][1])
        }
        setCombatRound(combatRound + 1)
        setCombatBusy(false) 
      }     
    }

    function sort() {
      const { cardHeight, cardWidth } = adjust(app, slots1, slots2, centerRectangle1, centerRectangle2, supportRectangle1, supportRectangle2, critterRef, cardRefs)
      const slotCoords = []
      slotCoords.push({
          x: Math.round((centerRectangle2.current.x + cardWidth / 2) * 100) / 100,
          y: Math.round((centerRectangle2.current.y + cardHeight / 2) * 100) / 100
      })
      // Setting slot coords
      for (let i = 0; i < 5; i++) {
        slotCoords.push({
          x: Math.round((supportRectangle2.current.x + (0.5 + i) * cardWidth*1.1) * 100) / 100,
          y: Math.round((supportRectangle2.current.y + cardHeight / 2) * 100) / 100
        })
      }
      setSlotCoords(slotCoords)
    }

    const checkCrittersLoaded = () => {
      const crittersCombined = [
        ...(websocketData[websocketData?.mySeat]?.board?.critters || []),
        ...(websocketData[opponentSeat]?.board?.critters || []),
      ];

      const crittersCombinedTokenIds = crittersCombined.map(el => el.tokenId)
      const refCombinedTokenIds = [...cardRefs.current.keys(), ...critterRef.current.filter(el => !!el).map(el => el.data.tokenId)]
      // console.log("refCombinedTokenIds", refCombinedTokenIds)
      for (let i = 0; i < crittersCombinedTokenIds.length; i++) {
        const critterId = crittersCombinedTokenIds[i]
        // console.log("critterId", critterId)
        if (!refCombinedTokenIds.includes(critterId)) return setTimeout(() => { checkCrittersLoaded() } , 500)
      }
      return setHeroCrittersLoaded(true)
    }

    useEffect(() => {
      if (!combatBusy && isMyTurn) {
        setPlayReady(true)
        // console.log("COMBAT FINISHED, ENABLING PLAYREADY")
      } 
    }, [combatBusy, isMyTurn])

    // Combat starts in turn 4, and always takes place after player actions at the end of the round
    useEffect(() => {
      if (websocketData.round >= 5) {
        setPlayReady(false)
        setCombatBusy(true)
        setIsMyTurn(false)
        // console.log("START OF NEW ROUND, DISABLING PLAYREADY")
      }
    }, [websocketData.round])

    // IF OPPONENT PASSES TURN WE NEED TO SET isMyTurn = TRUE !!!

    // if we are first to act, we set isMyTurn to true
    useEffect(() => {
      // console.log("*** setIsMyTurn USE-EFFECT **")
      if (websocketData.winner) setIsMyTurn(true)
      else if (mySeat == firstToAct && websocketData.turn == firstToAct) setIsMyTurn(true)
      else if (mySeat !== firstToAct && websocketData.turn !== firstToAct) setIsMyTurn(true)
      else setIsMyTurn(false)
    }, [websocketData.turn, mySeat])

    useEffect(() => {
      if (websocketData.round > 4 && actionsByRound.length == combatRound) {
        if (websocketData.winner == websocketData.mySeat && !fired) {
          setFired(true)
          fireworks(app)
        }
      }
    }, [combatRound])

    // This hook controls the combat execution
    useEffect(() => {
      let timer;

      if (actionsByRound.length - 1 >= combatRound && websocketData.round >= 4) {
        timer = setTimeout(() => { executeCombatRound("OFF") }, 2000);
      }
      return () => clearTimeout(timer);
    }, [actionsByRound])

    useEffect(() => {
      // console.log("** AUTO USE EFECT ** ", autoMode, combatBusy)
      let timer;
      // console.log("ACTIVATING AUTO-COMBAT MODE IN 2 seconds ...")
      if (autoMode && !combatBusy) {
        timer = setTimeout(() => { executeCombatRound("ON") }, 2000);
      }
      return () => clearTimeout(timer);
    }, [autoMode, combatBusy])

    // useEffect(() => {
    //   console.log("heroCrittersLoaded", heroCrittersLoaded)
    // }, [heroCrittersLoaded])

    useEffect(()=> {
      if (combatRoundsData?.rounds?.length > 0) checkCrittersLoaded()
    }, [combatRoundsData?.rounds])


    useEffect(() => {
      if (opponentSeat && websocketData[opponentSeat]?.board?.critters?.length > 0 && ready && !combatBusy) {
        if (websocketData.round > (combatRound + 5)) {
          setAutoMode(true)
          console.log("*** DEBUG *** AUTOPLAY MODE ENABLED")
        }

        const newCritters = websocketData[opponentSeat].board.critters;
        // First case: there is a new critter

        for (let i = 0; i < newCritters.length; i++) {
          if (!critters.map(el => el.tokenId).includes(newCritters[i].tokenId)) {
            // console.log(`*** Dropping ${newCritters[i].name} at index ${i} ***`)
            setNewCritterId(newCritters[i].tokenId)
            console.log(`Villain ${newCritters[i].name} slotIndex: ${newCritters[i].startingIndex}`)
            setNewCritterIndex(newCritters[i].startingIndex)
            setCritters(newCritters)
            break;
          }
        }
      }
    }, [websocketData[opponentSeat]?.board.critters, combatBusy, opponentSeat]);

    useEffect(() => {
      if (mySeat && websocketData[mySeat]?.board?.critters?.length > 0 && ready && !combatBusy) {

        const newCritters = websocketData[mySeat].board.critters;
        // First case: there is a new critter

        for (let i = 0; i < newCritters.length; i++) {
          if (newCritters[i].playedBy == 'server' && !heroCritters.includes(newCritters[i].tokenId)) {
            console.log(`Hero ${newCritters[i].name} slotIndex: ${newCritters[i].startingIndex}`);
            const newHeroCritterRef = critterRef.current.find(el => el.data.tokenId == newCritters[i].tokenId)
            console.log("newHeroCritterRef", newHeroCritterRef)
            setHeroCritters(prev => [...prev, newHeroCritterRef.data.tokenId])
            newHeroCritterRef.releaseCardOnSlot(newCritters[i].startingIndex)
            break;
          }
        }
      }
    }, [websocketData[mySeat]?.board.critters, combatBusy, opponentSeat]);

    useEffect(() => {

      if (opponentSeat && websocketData[opponentSeat]?.board?.dead && ready && !combatBusy) {
        if (websocketData.round > (combatRound + 5)) {
          setAutoMode(true)
          console.log("*** DEBUG *** AUTOPLAY MODE ENABLED")
        }

        const newCritters = websocketData[opponentSeat].board.critters;
        const newDead = websocketData[opponentSeat].board.dead;

        // First case: there is a new critter
        for (let i = 0; i < newDead.length; i++) {
          if (!dead.map(el => el.tokenId).includes(newDead[i].tokenId)) {
            if (newCritterIndex === null) break;
            setNewCritterIndex(newDead[i].startingIndex)
            console.log(`${newCritters[i].name} slotIndex: ${newDead[i].startingIndex}`)
            setDead(newDead)
            break;
          }
        }
      }
    }, [websocketData[opponentSeat]?.board?.dead, combatBusy, opponentSeat]);

    useEffect(() => {

      if (critters.length > 0 && newCritterIndex !== null) {
        const newCritterCard = cardRefs.current.get(newCritterId)
        try {
          console.log(`New Critter ${newCritterCard.data.name} in slot ${newCritterIndex}`)
          // once opponent card has been place, we set isMyTurn to true
          newCritterCard.moveToSlot(newCritterIndex).then(() => setIsMyTurn(true))
        } catch(e) {
          console.log(cardRefs)
          console.log(newCritterCard)
          console.log(newCritterId)
          throw new Error(e)
        }
        setNewCritterIndex(null)
      }
    }, [critters])

    useEffect(() => {

      if (dead.length > 0 && newCritterIndex !== null) {
        const newCritterCard = cardRefs.current.get(newCritterId)
        // console.log(`** DEAD UseEffect ** newCritterIndex ${newCritterIndex}`)
        try {
          console.log(`New Critter ${newCritterCard.data.name} in slot ${newCritterIndex}`)
          // once opponent card has been place, we set isMyTurn to true
          newCritterCard.moveToSlot(newCritterIndex).then(() => setIsMyTurn(true))
        } catch(e) {
          console.log(cardRefs)
          console.log(newCritterCard)
          console.log(newCritterId)
          throw new Error(e)
        }
        setNewCritterIndex(null)
      }
    }, [dead])

    useEffect(() => {
      if (heroCrittersLoaded) {
        const roundOfActions = []
        const actions = combatRoundsData.rounds[combatRound] // combat starts at round 4
        // console.log("actions", actions)
        // console.log("cardRefs", cardRefs)
        // console.log(`*** combat actions of round = ${round} ***`)
        for (let i = 0; i < actions.length; i++) {
          const action = actions[i]
          console.log(action)
          const { type, initiator, target, targets } = action;

          let initiatorCard = cardRefs.current.get(initiator)
          let targetCard;
          let targetCards = [];

          if (type == 'explosive') {
            for (let i = 0; i < targets.length; i++) {
              let _targetCard = cardRefs.current.get(targets[i]);
              if (!_targetCard) _targetCard = critterRef.current.find(el => el.data.tokenId == targets[i])
              targetCards.push(_targetCard)
            }
          } else {  
            targetCard = cardRefs.current.get(target);
          }

          if (!initiatorCard && type !== "death" && type !== "statusEffect") initiatorCard = critterRef.current.find((el) => el.data.tokenId == initiator)
          if (!targetCard && type !== 'explosive') targetCard = critterRef.current.find(el => el.data.tokenId == target)

          if (type == "statusEffect") {
            roundOfActions.push([targetCard.executeStatusEffect, [targetCard, action]])
            continue;
          }

          if (type == "addStatus") {
            roundOfActions.push([targetCard.statusEffect, [initiatorCard, targetCard, action]])
            continue;
          }

          if (type == "removeStatus") {
            roundOfActions.push([targetCard.removeStatus, [targetCard, action]])
            continue;
          }

          if (type == "passiveAttack") {
            roundOfActions.push([targetCard.passiveAttack, [targetCard, action]])
            continue;
          }

          if (type == "death") {
            roundOfActions.push([targetCard.killCard, []])
            continue
          }

          if (type == "scavenger") {
            roundOfActions.push([targetCard.performScavenger, [action]])
            continue
          }

          if (type == "heal") {
            roundOfActions.push([initiatorCard.performHealing, [targetCard, action]])
            continue
          }

          if (type == "repair") {
            roundOfActions.push([initiatorCard.performRepair, [targetCard, action]])
            continue
          }

          if (type == "vampiric") {
            roundOfActions.push([initiatorCard.performVampiric, [targetCard, action]])
            continue
          }

          if (type == "armoredassault") {
            roundOfActions.push([initiatorCard.performArmoredAssault, [targetCard, action]])
            continue
          }

          if (type == "explosive") {
            roundOfActions.push([initiatorCard.performExplosive, [targetCards, action]])
            continue
          }

          if (type == "revive") {
            roundOfActions.push([targetCard.performRevive, [initiatorCard, targetCard, action]])
            continue
          }

          if (!initiatorCard) {
            console.log("no INITIATOR card found!!")
            console.log("DEBUGGG")
            console.log("initiator", initiator)
          }

          switch (type) {
            case "melee attack":
              roundOfActions.push([initiatorCard.performMeleeAttack, [targetCard, action]])
              break
            case "magic attack":
              roundOfActions.push([initiatorCard.performMagicAttack, [targetCard, action]])
              break
            case "ranged attack":
              roundOfActions.push([initiatorCard.performRangedAttack, [targetCard, action]])
              break

            default:
              break
          }
        }

        addRoundOfActions(roundOfActions)
        setHeroCrittersLoaded(false)
        if (websocketData.mySeat !== firstToAct) setIsMyTurn(false)
      }
    }, [heroCrittersLoaded]);

    useEffect(() => {
      if (ready && app) {
        slots1.current.isHero = true;
        slots2.current.isHero = false;
        centerRectangle1.current.role = "front";
        supportRectangle1.current.role = "support";
        centerRectangle2.current.role = "front";
        supportRectangle2.current.role = "support";
        sort()
      }
    }, [ready, app]);

    useEffect(() => {
      if (pointerDown && pointerDown <= websocketData[websocketData.mySeat].mana) {
        glow.color = 0x00FF00; // Change the color of the glow (e.g., to red)
        if (slots1.current.children.length > 2) supportRectangle1.current.filters = [glow]
        centerRectangle1.current.filters = [glow]
      } else if (pointerDown && pointerDown > websocketData[websocketData.mySeat].mana) {
        glow.color = 0xFF0000; // Change the color of the glow (e.g., to red)
        if (slots1.current.children.length > 2) supportRectangle1.current.filters = [glow]
        centerRectangle1.current.filters = [glow]
      } else {
        centerRectangle1.current.filters = []
        supportRectangle1.current.filters = []
      }

      return () => {
        if (centerRectangle1.current) centerRectangle1.current.filters = []
        if (centerRectangle2.current) centerRectangle2.current.filters = []
      }
    }, [pointerDown])

    useEffect(() => {
      if (app && ready && trigger) { 
        // console.log("resize trigger")
        // resetTrigger(); // Reset the trigger after executing
        sort()
      }

    }, [trigger]);
  
    return (
      
      <Container ref={ref} zIndex={1}>
        <Container ref={slots2} scale={1}>
          <Graphics ref={centerRectangle2} zIndex={1}/>
          <Graphics ref={supportRectangle2} zIndex={1}/>
          {critters.length > 0 && critters.map((critter, i) => (
            critter && critter.tokenId && (
              <ErrorBoundary key={`error-${critter.tokenId}`} app={app} scale={scale}>
                <Card
                  key={critter.tokenId}
                  ref={(el) => (cardRefs.current.set(critter.tokenId, el))}
                  data={critter}
                  setScale={scale}
                  ready={ready}
                  index={i}
                  app={app}
                  slotRef={ref}
                  isOpponent={true}
                  slotCoords={slotCoords}
                  trigger={trigger}
                  godRef1={godRef1}
                  godRef2={godRef2}
                  isReplay={isReplay}
                />
              </ErrorBoundary>
            )
          ))}
        </Container>

        <Container ref={slots1} scale={1}>
          <Graphics ref={supportRectangle1} zIndex={1}/>
          <Graphics ref={centerRectangle1} zIndex={1}/>
        </Container>
      </Container>
      
    );
  }
)
export default BoardCards;
