import { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import Button from '@mui/material/Button';

const cdnUrl = import.meta.env.VITE_CDN_URL;
const alertSoundUrl = `${cdnUrl}/audio/app/alert.mp3`;
const audio = new Audio(alertSoundUrl);

function ChallengeSnackbar({ challengeRequest, sendMessage, location, setRoute }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (challengeRequest) {
      setOpen(true)
      audio.play();
    }
  }, [challengeRequest])

  // useEffect(() => {
  //   if (open) {
  //     audio.play();
  //   }
  // }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleAccept = () => {
    console.log("Challenge accepted");
    const msg = JSON.stringify({ type: 'challengeAccept', data: { from: challengeRequest.from.address } });
    sendMessage(msg);
    handleClose();
    if (!location.pathname.includes("battle")) setRoute("/battle")
  };

  const handleReject = () => {
    console.log("Challenge rejected");
    handleClose();
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}
        autoHideDuration={15000} // auto hide after 6 seconds
      >
        <SnackbarContent
          message={`Challenge Request from ${challengeRequest?.from?.name ? challengeRequest.from.name : challengeRequest?.from?.address}`}
          action={[
            <Button key="accept" color="primary" size="small" onClick={handleAccept}>
              Accept
            </Button>,
            <Button key="reject" color="secondary" size="small" onClick={handleReject}>
              Reject
            </Button>
          ]}
        />
      </Snackbar>
    </div>
  );
}

export default ChallengeSnackbar;
