import  { forwardRef, useState, useEffect, useRef } from 'react';
import { Sprite, Container, Text } from 'react-pixi-fiber';
import * as PIXI from 'pixi.js';
import Melee from "../icons/Melee";
import Health from "../icons/Health";
import Magic from "../icons/Magic";
import Speed from "../icons/Speed";
import Ranged from "../icons/Ranged";
import Armor from "../icons/Armor";
import Mana from "../icons/Mana";

const Standard = forwardRef(({ card, baseStats, data, state, ready, setScale, level, visible, repeated }, ref) => {
    // const isGod = data.type == "Summoner";

    const isGod = data.type == "Summoner";
    const critterImageRef = useRef();
    const bannerRef = useRef();
    const iconScale = 0.35;
    const critterImageScale = 1;
    const [width, setWidth] = useState(null);
    const [height, setHeight] = useState(null);

    useEffect(() => {
      console.log("scale", setScale)
    }, [setScale])

    useEffect(() => {
      if (ready) {
        setWidth(PIXI.utils.TextureCache["standard-silver-1"].width  * 0.6)
        setHeight(PIXI.utils.TextureCache["standard-silver-1"].height * 0.6)
        critterImageRef.current.isCritterSprite = true;
      }
    }, [ready, critterImageScale])

    return (
        <Container ref={ref} scale={setScale} x={0} y={0} visible={visible} z-index={2}>
            <>
                <Sprite 
                    ref={critterImageRef}
                    texture={PIXI.utils.TextureCache[`standard-${card.foil == 2 ? "gold" : "silver"}-${data.cardId}`]} 
                    x={0} 
                    y={0} 
                    anchor={[0.5, 0.5]} 
                    visible={true}
                    scale={critterImageScale * 2.2}
                />
                {
                  repeated > 1 &&

                  <Container ref={bannerRef}>
                    <Sprite 
                        texture={PIXI.utils.TextureCache['banner']} 
                        x={width} 
                        y={-height * 1.5 } 
                        anchor={[0.5, 0.5]} 
                        visible={true}
                        scale={0.32}
                    />
                    <Text
                      text={`x${repeated}`}
                      anchor={[0.5, 0.5]} 
                      x={width * 1} 
                      y={-height * 1.5} 
                      style={{
                        fill: 'white',
                        fontFamily: "Almendra",
                        fontStyle: "bold",
                        fontSize: 100
                      }}
                    />
                  </Container>
                }
            </>
            {
                baseStats &&
                <>
                    <Melee 
                        baseValue={isGod ? baseStats.attack : baseStats.attack[level]}
                        data={data}
                        value={state.attack}
                        ready={ready}
                        scale={iconScale}
                        standard={true}
                    />

                    {(isGod || state.health > 0) && (
                        <Health 
                            baseValue={isGod ? baseStats.health : baseStats.health[level]}
                            data={data}
                            value={state.health}
                            ready={ready}
                            scale={iconScale}

                            standard={true}
                        />
                    )}

                    <Speed 
                        baseValue={isGod ? baseStats.speed : baseStats.speed[level]}
                        data={data}
                        value={state.speed}
                        ready={ready}
                        scale={iconScale}
                        standard={true}
                    />

                    <Mana 
                        baseValue={isGod ? baseStats.mana : baseStats.mana[level]}
                        data={data}
                        value={state.mana}
                        ready={ready}
                        scale={iconScale * 0.5}
                        standard={true}
                    />

                    <Magic 
                        baseValue={isGod ? baseStats.magic : baseStats.magic[level]}
                        data={data}
                        value={state.magic}
                        ready={ready}
                        scale={iconScale}
                        standard={true}
                    />

                    {(isGod || state.armor > 0) && (
                        <Armor 
                            baseValue={isGod ? baseStats.armor : baseStats.armor[level]}
                            data={data}
                            value={state.armor}
                            ready={ready}
                            scale={iconScale}
                            standard={true}
                        />
                    )}

                    <Ranged 
                        baseValue={isGod ? baseStats.ranged : baseStats.ranged[level]}
                        data={data}
                        value={state.ranged}
                        ready={ready}
                        scale={iconScale}
                        standard={true}
                    />
                </>
            }
        </Container>
    );
});

Standard.displayName = "Standard";

export default Standard;
