
import { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid2';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import PentagonIcon from '@mui/icons-material/Pentagon';
import HexagonIcon from '@mui/icons-material/Hexagon';

export default function IconButtons({ addRarity, removeRarity, direction = 'row', size = 56, legend = false }) {

  const [isCommon, setCommon] = useState(true)
  const [isRare, setRare] = useState(true)
  const [isEpic, setEpic] = useState(true)
  const [isLegendary, setLegendary] = useState(true)

  useEffect(() => {
    if (isCommon) {
      addRarity('common')
    } else {
      removeRarity('common')
    }
  }, [isCommon])

  useEffect(() => {
    if (isRare) {
      addRarity('rare')
    } else {
      removeRarity('rare')
    }
  }, [isRare])

  useEffect(() => {
    if (isEpic) {
      addRarity('epic')
    } else {
      removeRarity('epic')
    }
  }, [isEpic])

  useEffect(() => {
    if (isLegendary) {
      addRarity('legendary')
    } else {
      removeRarity('legendary')
    }
  }, [isLegendary])

  const renderButton = (label, color, borderColor, backgroundColor, clickHandler, state, icon) => (
    <>
      <Grid size={{ xs: 'auto'}}>
        <IconButton
          aria-label={label}
          onClick={() => clickHandler(!state)}
          sx={{
            margin: '5px',
            color: state ? color : `rgba(${color}, 0.5)`, // Lower opacity when inactive
            backgroundColor: state ? backgroundColor : 'rgba(0, 0, 0, 0.5)', // Lower opacity when inactive
            width: size,
            height: size,
            border: `2px solid ${borderColor}`,
            borderRadius: '50%',
            '&:hover': {
              backgroundColor: '#e0e0e0',
            },
          }}
        >
          {icon}
        </IconButton>
      </Grid>
      {legend && (
        <Grid size={{ xs: 'auto'}}>
          <p>{label}</p>
        </Grid>
      )}
    </>
  );

  return (
    <Grid container spacing={0} direction={direction} sx={{ display: 'flex', justifyContent: 'left' }}>
      <Grid size={{ xs: 3 }} sx={{ display: 'flex', justifyContent: 'left' }} alignItems="center">
        {renderButton('Common', 'gray', 'gray', '#000000', setCommon, isCommon, <RadioButtonUncheckedIcon sx={{ fontSize: size / 2 }} />)}
      </Grid>
      <Grid size={{ xs: 3 }} sx={{ display: 'flex', justifyContent: 'left' }} alignItems="center">
        {renderButton('Rare', '#0096FF', '#0096FF', '#000000', setRare, isRare, <ChangeHistoryIcon sx={{ fontSize: size / 2 }} />)}
      </Grid>
      <Grid size={{ xs: 3 }} sx={{ display: 'flex', justifyContent: 'left' }} alignItems="center">
        {renderButton('Epic', '#FF00FF', '#FF00FF', '#000000', setEpic, isEpic, <PentagonIcon sx={{ fontSize: size / 2 }} />)}
      </Grid>
      <Grid size={{ xs: 3 }} sx={{ display: 'flex', justifyContent: 'left' }} alignItems="center">
        {renderButton('Legendary', '#C4B454', '#C4B454', '#000000', setLegendary, isLegendary, <HexagonIcon sx={{ fontSize: size / 2 }} />)}
      </Grid>
    </Grid>
  );
}
