
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const _conditions = {
  "Unit": 7,
  "Divinity": 1
}

const ConditionList = ({ conditions }) => {


  return (
    <List>
      {Object.keys(conditions).map((key, index) => (
        <ListItem key={index}>
          <ListItemIcon>
            {conditions[key] >= _conditions[key.split(" ")[1]] ? (
              <CheckCircleIcon sx={{ color: 'green' }} />
            ) : (
              <CancelIcon sx={{ color: 'red' }} />
            )}
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body2" style={{ fontFamily: 'Poppins' }}>
                {key}: {conditions[key]} / {_conditions[key.split(" ")[1]]}
              </Typography>
            }
          />
        </ListItem>
      ))}
    </List>
  );
};

export default ConditionList;
