
import { useEffect, useState } from "react";
import { Dialog, DialogContent, Typography } from '@mui/material';
import { cdnUrl } from "../../constants.js";
import Grid from '@mui/material/Grid2'; // Grid version 2
import './SimplePopup.css';
import { trimAddress } from "../../utils";
import { playerPolygonStyle, playerInfoStyle, civButtonStyle, disabledButtonStyle } from "./Styles.jsx";
import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info'
import { useMediaQuery } from '@mui/material';

const civs = ["greece", "egypt", "norse"];

const Matchfound = ({ websocketData, gameStart, sendMessage, waitingForOpponent, showCivs, isReplay, colors }) => {
  
  const [player1, setPlayer1] = useState(null)
  const [player2, setPlayer2] = useState(null)
  const [gameStarting, setGameStarting] = useState(false)
  const [estimatedPayout, setEstimatedPayout] = useState(null)
  const [forbiddenCivIndex, setForbiddenCivIndex] = useState(null);

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const handleCiv = (colorIndex) => {
    const msg = JSON.stringify({ type: 'pickCiv', data: { colorIndex: colorIndex } });
    sendMessage(msg);
  }

  useEffect(() => {
    if (websocketData?.mySeat) console.log(`${websocketData.mySeat} waitingForOpponent ${waitingForOpponent}`)
    if (websocketData.civs && websocketData.civs.length > 0) {
      if (!websocketData.civs.includes(0)) setForbiddenCivIndex(0)
      else if (!websocketData.civs.includes(1)) setForbiddenCivIndex(1)
      else if (!websocketData.civs.includes(2)) setForbiddenCivIndex(2)
    }
  }, [waitingForOpponent, websocketData])

  useEffect(() => {
    if (websocketData?.p1?.player && websocketData?.p2?.player) {
      setPlayer1(`${websocketData?.p1?.name ? websocketData?.p1?.name : trimAddress(websocketData?.p1?.player)}`)
      setPlayer2(`${websocketData?.p2?.name ? websocketData?.p2?.name : trimAddress(websocketData?.p2?.player)}`)
    }
    if (websocketData?.round && websocketData?.p1?.colorIndex !== null && websocketData?.p2?.colorIndex !== null) setGameStarting(true)
    if (websocketData?.[websocketData?.mySeat]?.simPayout) setEstimatedPayout(websocketData[websocketData.mySeat].simPayout)
  }, [websocketData])

  return (
    <div>
      {/* Full-width popup with solid background */}
      <Dialog
        open={gameStart && !websocketData.winner}
        fullWidth
        maxWidth={false}  
        PaperProps={{
          sx: {
            width: isSmallScreen ? '80%' : '70%',
            height: isSmallScreen ? '80%' : 'auto',  // Set height to auto to adapt to content
            maxHeight: '90vh',  // Maximum height to avoid overflow
            backgroundColor: '#101318',
            color: '#fff',
            margin: 0,
            border: '1px solid #E1B15E',
            position: 'relative',
            overflow: 'hidden',  // Ensures no overflow in the dialog
            maxWidth: '1280px',
          },
        }}
      >
        {/* Background Image */}
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: '#0A0A0C',
          backgroundImage: `url("${cdnUrl}/app/matchmaking/combat.webp")`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover', // Cover ensures full container coverage
          zIndex: 0, // Background stays behind the content
        }}></div>

        {/* Content on top of the background */}
        <DialogContent sx={{ position: 'relative', zIndex: 2 }}>
          <Grid 
            container 
            size={{ xs: 12 }} 
            spacing={2} 
            justifyContent="center" 
            alignItems="center" 
            style={{ paddingTop: '5%' }}
          >
            <Grid 
              size={{ xs: 12 }} 
              style={{marginBottom: '0%'}}
            >
              <Typography 
              component="h2" 
              style={{ 
                color: '#937341', 
                fontFamily: 'Germania One', 
                fontWeight: '400', 
                fontSize: isSmallScreen ? '24px' : '42px',
                letterSpacing: '-2%', 
                textAlign: 'center',
              }}
              >
                MATCH FOUND
              </Typography>
            </Grid>

            {
              forbiddenCivIndex !== null &&
              <>
                <Grid 
                  size={{ xs: 12 }} 
                  style={{marginBottom: '0%'}}
                >
                  <Typography 
                  component="h2" 
                  style={{ 
                    color: '#937341', 
                    fontFamily: 'Germania One', 
                    fontWeight: '400', 
                    fontSize: isSmallScreen ? '20px' : '36px',
                    letterSpacing: '-2%', 
                    textAlign: 'center',
                  }}
                  >
                    Conditions
                  </Typography>
                </Grid>

                <Grid 
                  size={{ xs: 12 }} 
                  style={{
                    marginBottom: '0%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <div style={{ position: 'relative', width: '50px', height: '50px' }}>
                    <img
                      src={`${cdnUrl}/logos-icons/civs-icons/forbidden/${civs[forbiddenCivIndex]}.webp`}
                      height="50"
                      alt={`Civilization icon for ${civs[forbiddenCivIndex]}`}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                      }}
                    />
                  </div>
                </Grid>
              </>
            }

            <Grid 
              size={{ xs: 12 }} 
              style={{marginBottom: '1%'}}
            >
              <img
                src={`${cdnUrl}/app/referral/separator.svg`}
                alt="divider"
                style={{ width: '100%', height: 'auto', position: 'relative', zIndex: 3 }}
              />
            </Grid>

            {
              estimatedPayout &&
              <Grid 
                size={{ xs: 12 }} 
                display="flex" 
                flexDirection="row" 
                justifyContent="center" 
                alignItems="center"
              >
                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                  <Tooltip title="This estimation is under the assumption that you use all golden cards, with the subsequent x3 multiplier.">
                    <InfoIcon style={{ color: '#E1B15E', marginLeft: '5px', cursor: 'pointer', verticalAlign: 'middle' }} />
                  </Tooltip>
                  <p 
                    style={{ 
                      fontFamily: 'Poppins', 
                      color: '#E1B15E', 
                      margin: 0,
                      fontSize: isSmallScreen ? '12px' : '18px' 
                    }}>
                    Estimated payout: $FAITH {estimatedPayout}
                  </p>
                </div>
                <img src={`${cdnUrl}/faith-token/faith-white-fish.png`} width="25" style={{ marginLeft: '5px' }} />
              </Grid>
            }

            <Grid 
              container 
              size={{ xs: 10 }} 
              sx={{ 
                marginBottom: '3%', 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center' 
              }}
            >
              {/* Player A Section */}
              <Grid 
                container 
                size={{ xs: 12, md: 4 }} 
                display="flex" 
                justifyContent="center" 
                alignItems="center"
              >
                <Grid size={{ xs: 4 }} display="flex" justifyContent="center">
                  <button
                    style={playerPolygonStyle}
                  >
                    P1
                  </button>
                </Grid>

                <Grid style={playerInfoStyle} size={{ xs: 5 }} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                  <Grid 
                    size={{ xs: 12 }} 
                    textAlign="center"
                    style={{ fontSize: isSmallScreen ? '16px' : '26px' }}
                  >
                    {player1}
                  </Grid>
                  <Grid size={{ xs: 12 }} textAlign="center">
                    {websocketData?.p1?.elo}
                  </Grid>
                </Grid>

                <Grid size={{ xs: 3 }}>
                  <div style={{ position: "relative", display: "inline-block" }}>
                    {
                      websocketData?.p1?.colorIndex == null ? (
                        <h1 style={playerInfoStyle}>?</h1>
                      ) : (
                        <>
                          <img
                            src={`${cdnUrl}/logos-icons/civs-icons/gray/${civs[websocketData?.p1?.colorIndex]}.png`}
                            height="50"
                            alt={`Civilization icon for ${civs[websocketData?.p1?.colorIndex]}`}
                          />
                        </>
                      )
                    }
                  </div>
                </Grid>

              </Grid>

              {/* Battle Icon Section */}
              <Grid size={{ xs: 4 }} display="flex" justifyContent="center" alignItems="center">
                <img src={`${cdnUrl}/battle-menu/battle-icon.svg`} alt="Battle Icon" />
              </Grid>

              {/* Player B Section */}
              <Grid 
                container 
                size={{ xs:12, md: 4 }} 
                display="flex" 
                justifyContent="center" 
                alignItems="center"
              >
                <Grid size={{ xs: 3 }}>
                  {
                    websocketData?.p2?.colorIndex == null ? (
                      <h1 style={playerInfoStyle}>?</h1>
                    ) : (
                      <img 
                        src={`${cdnUrl}/logos-icons/civs-icons/gray/${civs[websocketData?.p2?.colorIndex]}.png`} 
                        height="50" 
                        alt={`Civilization icon for ${civs[colors?.p2]}`}
                      />
                    )
                  }
                </Grid>
                <Grid style={playerInfoStyle} size={{ xs: 5 }} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                  <Grid 
                    size={{ xs: 12 }} 
                    style={{ fontSize: isSmallScreen ? '16px' : '26px' }}
                    textAlign="center"
                  >
                    {player2}
                  </Grid>
                  <Grid size={{ xs: 12 }} textAlign="center">{websocketData?.p2?.elo}</Grid>
                </Grid>
                <Grid size={{ xs: 4 }} display="flex" justifyContent="center">
                  <button
                    style={playerPolygonStyle}
                  >
                    P2
                  </button>
                </Grid>
              </Grid>
            </Grid>

            {
              !isReplay && showCivs && !waitingForOpponent && websocketData?.[websocketData.mySeat]?.colorIndex == null && (
                <>
                  <Grid size={{ xs: 12, md: 12 }} display="flex" justifyContent="center" style={{ marginBottom: '30px', fontFamily: 'Germania One', fontWeight: '400', letterSpacing: '-2%', lineHeight: '28px', color: '#937341', fontSize: '24px'}}>
                    Pick your civilization
                  </Grid>

                  <Grid 
                    container 
                    size={{ xs: 8 }}
                    display="flex" 
                    justifyContent="center" 
                    spacing={2}
                    style={{marginBottom: '5%'}}
                  >
                    <Grid size={{ xs: 12, md: 4 }} display="flex" justifyContent="center">
                      <button
                        onClick={() => { handleCiv(0) }}
                        style={!websocketData.civs.includes(0) ? disabledButtonStyle : civButtonStyle }
                      >Greece</button>
                    </Grid>
                    <Grid size={{ xs: 12, md: 4 }} display="flex" justifyContent="center">
                      <button
                        onClick={() => { handleCiv(1) }}
                        style={!websocketData.civs.includes(1) ? disabledButtonStyle : civButtonStyle }
                      >Egypt</button>
                    </Grid>
                    <Grid size={{ xs: 12, md: 4 }} display="flex" justifyContent="center">
                      <button
                        onClick={() => { handleCiv(2) }}
                        style={!websocketData.civs.includes(2) ? disabledButtonStyle : civButtonStyle }
                      >Norse</button>
                    </Grid>
                  </Grid>
                </>
              )
            }

            {
              (!isReplay && !showCivs && !waitingForOpponent) ? (
                <Grid size={{ xs: 12 }} display="flex" justifyContent="center" style={{ marginBottom: '30px', fontFamily: 'Germania One', fontWeight: '400', letterSpacing: '-2%', lineHeight: '28px', color: '#937341', fontSize: '24px' }}>
                  Waiting for Opponent
                </Grid>
              ) : null
            }

            {
              gameStarting &&
                <Grid size={{ xs: 12 }} display="flex" justifyContent="center" style={{ marginBottom: '30px', fontFamily: 'Germania One', fontWeight: '400', letterSpacing: '-2%', lineHeight: '28px', color: '#937341', fontSize: '24px' }}>
                  Game starting..
                </Grid>
            }

          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Matchfound;
