// audio.js
const cdnUrl = import.meta.env.VITE_CDN_URL;

export const attackAudio = new Audio(`${cdnUrl}/audio/melee.mp3`);
export const magicAudio = new Audio(`${cdnUrl}/audio/magic.mp3`);
export const magicAudio2 = new Audio(`${cdnUrl}/audio/magic2.mp3`);
export const magicHitAudio = new Audio(`${cdnUrl}/audio/magic-hit.mp3`);
export const magicHitAudio2 = new Audio(`${cdnUrl}/audio/magic-hit2.mp3`);
export const rangedAudio = new Audio(`${cdnUrl}/audio/ranged.mp3`);
export const shieldAudio = new Audio(`${cdnUrl}/audio/shield.mp3`);
export const shieldAudio2 = new Audio(`${cdnUrl}/audio/shield2.mp3`);
export const cardSelectAudio = new Audio(`${cdnUrl}/audio/card-select.mp3`);
export const dropAudio = new Audio(`${cdnUrl}/audio/drop.mp3`);
export const meleeMissAudio = new Audio(`${cdnUrl}/audio/melee-miss1.mp3`);
export const rangedMissAudio = new Audio(`${cdnUrl}/audio/ranged-miss.mp3`);
export const cardBuffAudio = new Audio(`${cdnUrl}/audio/buff.mp3`);
export const healingAudio = new Audio(`${cdnUrl}/audio/heal.mp3`);
export const passTurnAudio = new Audio(`${cdnUrl}/audio/pass.mp3`);
export const evilLaughAudio = new Audio(`${cdnUrl}/audio/evil-laugh.mp3`);
export const repairAudio = new Audio(`${cdnUrl}/audio/repair.mp3`);
export const paralyzeAudio = new Audio(`${cdnUrl}/audio/paralyze.mp3`);
export const stampedeAudio = new Audio(`${cdnUrl}/audio/stampede.mp3`);
export const frenzyAudio = new Audio(`${cdnUrl}/audio/frenzy.mp3`);
export const armoredassaultAudio = new Audio(`${cdnUrl}/audio/armoredassault.mp3`);
export const laststandAudio = new Audio(`${cdnUrl}/audio/laststand.mp3`);
export const reviveAudio = new Audio(`${cdnUrl}/audio/revive.mp3`);
export const coinsAudio = new Audio(`${cdnUrl}/audio/coins.mp3`);
export const godzillaAudio = new Audio(`${cdnUrl}/audio/music/godzilla.mp3`);
export const slot1Audio = new Audio(`${cdnUrl}/audio/music/slot1.mp3`);
export const slot2Audio = new Audio(`${cdnUrl}/audio/music/slot2.mp3`);
export const slot3Audio = new Audio(`${cdnUrl}/audio/music/slot3.mp3`);
export const slot4Audio = new Audio(`${cdnUrl}/audio/music/slot4.mp3`);
export const slot5Audio = new Audio(`${cdnUrl}/audio/music/slot5.mp3`);
export const messageAudio = new Audio(`${cdnUrl}/audio/message.mp3`);

export function randomSlot() {
  const url = `${cdnUrl}/audio/slots/slot${Math.floor(Math.random() * 5 + 1)}.mp3`
  console.log(url)
  new Audio(url).play()
}

var vol = 0.20;
evilLaughAudio.volume = vol; // Set the initial volume
repairAudio.volume = vol;

var interval = 200; // 200ms interval

// Function to start fading out the audio
function startFadeOut() {
  var fadeout = setInterval(function() {
    // Reduce volume by 0.05 as long as it is above 0
    if (vol > 0) {
      vol -= 0.05;
      evilLaughAudio.volume = vol;
    } else {
      // Stop the setInterval when 0 is reached and pause the audio
      clearInterval(fadeout);
      evilLaughAudio.pause();
    }
  }, interval);
}

// Start the audio and then schedule the fade-out
export function playEvilLaughAudio() {
  evilLaughAudio.play();
  
  evilLaughAudio.onloadedmetadata = function() {
    var fadeOutStart = (evilLaughAudio.duration - 1) * 1000;
    setTimeout(startFadeOut, fadeOutStart);
  };
}

export function playRepairAudio() {
  repairAudio.play();
  
  repairAudio.onloadedmetadata = function() {
    var fadeOutStart = (repairAudio.duration - 3) * 1000;
    setTimeout(startFadeOut, fadeOutStart);
  };
}