// import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';

const Popup = ({ open, onClose, onConfirm, wallet }) => {
  return (
    <Dialog 
    open={open} 
    onClose={onClose} 
    PaperProps={{
      sx: { 
        border: '2px solid #675330', 
        borderRadius: '8px', 
        backgroundColor: 'black',
        padding: '10px'
      }
    }}
    >
      <DialogTitle sx={{ fontFamily: 'Poppins', fontWeight: '600', textAlign: 'center' }}>Confirm Withdrawal</DialogTitle>
      <DialogContent sx={{ alignItems: 'center', border: '1px solid #675330', borderRadius: '8px' }}>
          <Typography sx={{ padding: '10px'}} variant="body1" gutterBottom>
            Please note the following before proceeding with your withdrawal:
          </Typography>
          <Typography sx={{ padding: '10px'}} variant="body2" gutterBottom>
            - There is a processing fee of 10 FAITH.
          </Typography>
          {
            wallet !== 'Koinos' && wallet !== 'Kondor' &&
            <Typography sx={{ padding: '10px'}} variant="body2" gutterBottom>
              - Make sure to have ETH in your account to be able to operate the receiving $FAITH funds.
            </Typography>
          }
          <Typography sx={{ padding: '10px'}} variant="body2">
            - The transaction fees will be deducted from your selected amount.
          </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};


export default Popup;