import { forwardRef, useState, useRef, useEffect } from 'react';
import { Sprite, Container } from 'react-pixi-fiber';
import * as PIXI from 'pixi.js';
import Melee from "../icons/Melee";
import Health from "../icons/Health";
import Magic from "../icons/Magic";
import Speed from "../icons/Speed";
import Ranged from "../icons/Ranged";
import Armor from "../icons/Armor";

const Tactical = forwardRef(({ baseStatsLoaded, baseStats, data, state, ready, level, visible, isPlayReady, cardStatuses }, ref) => {

  const isGod = data.type == "Summoner";
  const [frameWidth, setFrameWidth] = useState(null)
  const [frameHeight, setFrameHeight] = useState(null)
  const [iconWidth, setIconWidth] = useState(null)
  const critterImageRef = useRef()
  const iconScale = 1.5;

  useEffect(() => {
    if (ready) {
      critterImageRef.current.isCritterSprite = true;
      critterImageRef.current.interactive = true;
      setFrameWidth(PIXI.utils.TextureCache["standard-silver-1"].width)
      setFrameHeight(PIXI.utils.TextureCache["standard-silver-1"].height)
      setIconWidth(PIXI.utils.TextureCache['challenge'].width * 1.5)
    }
  }, [ready])

  return (
      <Container ref={ref} scale={1} x={0} y={0} visible={visible} z-index={2}>
          <Sprite 
              ref={critterImageRef}
              texture={PIXI.utils.TextureCache[`tactical-${data.foil == 1 ? 'silver' : 'gold'}-${data.cardId}`]} 
              x={0} 
              y={0} 
              anchor={[0.5, 0.5]} 
              visible={true}
              scale={3} 
          />
          { 
            data.baseStats.abilities.map((ability, index) => (
                <Container key={ability}>
                    <Sprite 
                        zIndex={0}
                        key={`ability-${ability}`} 
                        texture={PIXI.utils.TextureCache[`${ability.replace(/\s+/g, '-').toLowerCase()}`]} 
                        x={-frameWidth * 1.1 + (iconWidth * index)} 
                        y={frameHeight * 0.85} 
                        anchor={[0.5, 0.5]} 
                        visible={true}
                        scale={iconScale} 
                    />
                </Container>
            ))                        
          }
          {
            cardStatuses.map((status, index) => (
                <Container key={status}>
                    <Sprite 
                        zIndex={0}
                        key={`status-${status}`} 
                        texture={PIXI.utils.TextureCache[`${status.replace(/\s+/g, '-').toLowerCase()}`]} 
                        x={-frameWidth * 1.1 + (iconWidth * index)} 
                        y={-frameHeight * 0.84} 
                        anchor={[0.5, 0.5]} 
                        visible={true}
                        scale={iconScale} 
                    />
                </Container>
            ))                        
          }
          {
            baseStats && baseStatsLoaded && Number.isInteger(level) && level > -1 &&
            <>
                <Melee 
                    baseValue={isGod ? baseStats?.attack : baseStats?.attack?.[level]}
                    data={data}
                    value={state.attack}
                    ready={ready}
                    scale={0.5}
                    standard={false}
                />

                <Health 
                    baseValue={isGod ? baseStats?.health : baseStats?.health?.[level]}
                    data={data}
                    value={state.health}
                    ready={ready}
                    scale={0.5}
                    standard={false}
                    isPlayReady={isPlayReady}
                />

                <Speed 
                    baseValue={isGod ? baseStats?.speed : baseStats?.speed?.[level]}
                    data={data}
                    value={state.speed}
                    ready={ready}
                    scale={0.5}
                    standard={false}
                />

                <Magic 
                    baseValue={isGod ? baseStats?.magic : baseStats?.magic?.[level]}
                    data={data}
                    value={state.magic}
                    ready={ready}
                    scale={0.5}
                    standard={false}
                />

                <Armor 
                    baseValue={isGod ? baseStats?.armor : baseStats?.armor?.[level]}
                    data={data}
                    value={state.armor}
                    ready={ready}
                    scale={0.5}
                    standard={false}
                    isPlayReady={isPlayReady}
                />

                <Ranged 
                    baseValue={isGod ? baseStats?.ranged : baseStats?.ranged?.[level]}
                    data={data}
                    value={state.ranged}
                    ready={ready}
                    scale={0.5}
                    standard={true}
                />
            </>
          }

      </Container>
  );
});


Tactical.displayName = "Tactical";
export default Tactical;
